import React from 'react';
import { ACCOUNT_SELECT_CUSTOMER } from '@src/utils/constants/routerConstants';
import { NavLink } from 'react-router-dom';
import { Button } from 'antd';

const SelectCustomer = ({
    isDefaultCustomer = true,
    customerName = '',
    nickName = '',
    customerNumber = '',
    address = '',
    phone = '',
    onReset,
    isCreditHold,
}) => (
    isDefaultCustomer
        ? (
            <div className="customer-badge">
                <div className="sct-info">
                    <div className="info-content">
                        <div className="info-item">
                            You're now using site as "
                            <b>{customerName}</b>
                            {
                                nickName
                                && <span className="text-secondary">{` - ${nickName}`}</span>
                            }
                            ".
                            <br />
                        </div>
                        <div className="info-item">
                            <NavLink id="selectCustomerLink" to={ACCOUNT_SELECT_CUSTOMER} activeClassName="selected">
                                Select Customer
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        )
        : (
            <div className="customer-badge">
                <div className="info" id="selectedCustomerInfoBlock">
                    <div className="info-content">
                        {
                            isCreditHold
                            && (
                                <span>
                                    This Account is
                                    &nbsp;
                                    <b className="color-red">Inactive</b>
                                    . You can not create new reports.
                                </span>
                            )
                        }
                        <div className="info-item">
                            <span>
                                You're now using site as "
                                <b>{customerName}</b>
                                {
                                    nickName
                                    && <span className="text-secondary">{` - ${nickName}`}</span>
                                }
                                ".
                            </span>
                        </div>
                        <div className="info-item">
                            <span>
                                Id:
                                &nbsp;
                                <b>{customerNumber}</b>
                            </span>
                            <span>
                                Phone:
                                &nbsp;
                                <b>{phone}</b>
                            </span>
                            <span>
                                Address:
                                &nbsp;
                                <b>{address}</b>
                            </span>
                        </div>
                        <div className="info-item">
                            <NavLink id="selectCustomerLink" to={ACCOUNT_SELECT_CUSTOMER} activeClassName="selected">
                                Select Customer
                            </NavLink>
                            <Button
                                id="resetToDefault"
                                type="link"
                                onClick={onReset}
                            >
                                Reset to Default Account
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        )
);

export default SelectCustomer;
