import React from 'react';
import moment from 'moment';
import { shortDateFormat } from '@src/utils/helpers/dateUtils';

export const getOrderInformationConfirmation = data => ({
    title: 'Order Information',
    content: [
        [
            { label: 'Reference Number', value: data.referenceNumber },
        ],
    ],
});

export const getContactInformationConfirmation = data => ({
    title: 'Contact Information',
    content: [
        [
            { label: 'Contact', value: data.contactInformation.contactName },
            { label: 'Email Confirmation to', value: data.contactInformation.confirmationEmail },
        ],
    ],
});

export const getDeliveryInformationConfirmation = data => ({
    title: 'Delivery Information',
    content: [
        [
            { label: 'Email Results', value: data.deliveryPreference.email },
            { label: 'Additional Email', value: data.deliveryPreference.additionalEmail },
            {
                label: 'Rush Order',
                value: data.deliveryPreference.rushDate
                    ? moment(data.deliveryPreference.rushDate).format(shortDateFormat)
                    : null,
            },
        ],
    ],
});

export const getChanceryDeliveryInformationConfirmation = data => ({
    title: 'Delivery Information',
    content: [
        [
            { label: 'Email Results', value: data.deliveryPreference.email },
            { label: 'Additional Email', value: data.deliveryPreference.additionalEmail },
            {
                label: 'Due Date',
                value: data.deliveryPreference.rushDate
                    ? moment(data.deliveryPreference.rushDate).format(shortDateFormat)
                    : null,
            },
        ],
    ],
});

export const getMainInformationConfirmation = ({ reportInfoList }, columns = ['type', 'reportName', 'price']) => {
    const availableColumns = {
        type: {
            title: 'Report',
            dataIndex: 'type',
        },
        reportName: {
            title: 'Name(s)',
            dataIndex: 'reportName',
        },
        price: {
            title: 'Price',
            dataIndex: 'price',
            render: text => (
                <div className="text-nowrap">{text}</div>
            ),
        },
    };
    return ({
        columns: columns.map(column => availableColumns[column]),
        data: reportInfoList,
    });
};

export const showErrorsIfInvalidOrPerformAction = (form, action) => {
    const { valid } = form.getState();
    if (!valid) {
        form.submit(); //to focus and scroll to error
    } else {
        action();
    }
};

export const gutter = 32;
