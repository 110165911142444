import qs from 'qs';
import { request, getRequestConfig } from '@src/utils/apiServices/api';
import { urlJudgmentLookupFile } from '@src/utils/constants/endpointConstants';

const jdgLookup = docketJdgIds => (
    request({
        ...getRequestConfig,
        params: { 'docketJdgIds': docketJdgIds },
        url: urlJudgmentLookupFile,
        paramsSerializer: params => qs.stringify(params, { indices: false }),
    })
);

export default jdgLookup;
