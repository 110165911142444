export const FETCHING_ACCOUNT_SETTINGS = 'FETCHING_ACCOUNT_SETTINGS';
export const FETCH_ACCOUNT_SETTINGS_SUCCESS = 'FETCH_ACCOUNT_SETTINGS_SUCCESS';
export const FETCH_ACCOUNT_SETTINGS_FAIL = 'FETCH_ACCOUNT_SETTINGS_FAIL';

export const SAVING_ACCOUNT_SETTINGS = 'SAVING_ACCOUNT_SETTINGS';
export const SAVE_ACCOUNT_SETTINGS_SUCCESS = 'SAVE_ACCOUNT_SETTINGS_SUCCESS';
export const FETCH_ACCOUNT_DELIVERY_SETTINGS_SUCCESS = 'FETCH_ACCOUNT_DELIVERY_SETTINGS_SUCCESS';
export const SAVE_ACCOUNT_SETTINGS_FAIL = 'SAVE_ACCOUNT_SETTINGS_FAIL';

export const SET_SETTINGS_CONTAINER_TITLE = 'SET_SETTINGS_CONTAINER_TITLE';
export const RESET_CHANGED_PASSWORD = 'RESET_CHANGED_PASSWORD';

export const setSettingsContainerTitle = title => ({
    type: SET_SETTINGS_CONTAINER_TITLE,
    title,
});

export const fetchingAccountSettings = () => ({
    type: FETCHING_ACCOUNT_SETTINGS,
});

export const fetchAccountSettingsSucceeded = response => ({
    type: FETCH_ACCOUNT_SETTINGS_SUCCESS,
    response,
});

export const fetchAccountDeliverySettingsSucceeded = response => ({
    type: FETCH_ACCOUNT_DELIVERY_SETTINGS_SUCCESS,
    response,
});

export const fetchAccountSettingsFailed = error => ({
    type: FETCH_ACCOUNT_SETTINGS_FAIL,
    error,
});

export const savingAccountSettings = () => ({
    type: SAVING_ACCOUNT_SETTINGS,
});

export const saveAccountSettingsSucceeded = (response, settingsType) => ({
    type: SAVE_ACCOUNT_SETTINGS_SUCCESS,
    response,
    settingsType,
});

export const saveAccountSettingsFailed = (error, settingsType) => ({
    type: SAVE_ACCOUNT_SETTINGS_FAIL,
    error,
    settingsType,
});

export const resetChangedPassword = () => ({
    type: RESET_CHANGED_PASSWORD,
});
