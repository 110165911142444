import { SHOW_DEACTIVATED_USERS, HIDE_DEACTIVATED_USERS } from '../../actions/userMaintanance/showDeactivated';

const initialState = {
    showDeactivated: false,
};

const deactivateOption = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_DEACTIVATED_USERS:
            return { ...state, showDeactivated: true };
        case HIDE_DEACTIVATED_USERS:
            return { ...state, showDeactivated: false };
        default:
            return state;
    }
};

export const isShowDeactivated = state => state.deactivateOption.showDeactivated;

export default deactivateOption;
