import { addErrorAlert } from '@src/store/actions/alerts';
import { fetchAccountSettings, postAccountSettings, fetchAccountDeliverySettings } from '@src/utils/apiServices/accountSettingsRequests';
import {
    fetchingAccountSettings,
    fetchAccountSettingsSucceeded,
    fetchAccountDeliverySettingsSucceeded,
    fetchAccountSettingsFailed,
    savingAccountSettings,
    saveAccountSettingsSucceeded,
    saveAccountSettingsFailed,
} from './settingsControl';

export const getAccountSettings = () => dispatch => {
    dispatch(fetchingAccountSettings());

    return fetchAccountSettings().then(response => {
        dispatch(fetchAccountSettingsSucceeded(response));
    }).catch(error => {
        dispatch(fetchAccountSettingsFailed(error));
        dispatch(addErrorAlert('Something went wrong while fetching account settings.', true));
    });
};

export const getAccountDeliverySettings = () => dispatch => {
    dispatch(fetchingAccountSettings());

    return fetchAccountDeliverySettings().then(response => {
        dispatch(fetchAccountDeliverySettingsSucceeded(response));
    }).catch(error => {
        dispatch(fetchAccountSettingsFailed(error));
        dispatch(addErrorAlert('Something went wrong while fetching account delivery settings.', true));
    });
};

export const saveAccountSettings = (data, settingsType) => dispatch => {
    dispatch(savingAccountSettings());

    return postAccountSettings(data, settingsType).then(response => {
        dispatch(saveAccountSettingsSucceeded(response, settingsType));
    }).catch(error => {
        dispatch(saveAccountSettingsFailed(error, settingsType));
        const errorMessage = (
            (error.response && error.response.data && error.response.data.message)
            || (error.response && error.response.data)
        );
        dispatch(
            addErrorAlert(
                errorMessage || 'Something went wrong while saving account settings.',
                true,
            ),
        );
    });
};
