import React, {
    useMemo,
    useState,
    useEffect,
    useRef,
    useCallback,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Tour from 'reactour';
import { Button } from 'antd';
import moment from 'moment';
import { InfoOutlined } from '@ant-design/icons';
import {
    getFetching,
    getCombinedReports,
} from '@src/store/reducers/reports';
import {
    combineReports,
    resendReportsByEmail,
    fetchingReports,
    fetchReportsFailed,
    fetchReportsSucceeded,
    removeReportsFromNew,
} from '@src/store/actions/reports';
import { getCurrentCustomer } from '@src/store/reducers/customers';
import { getCurrentUser } from '@src/store/reducers/userMaintanance';
import Loader from '@src/components/Loader';
import ReportsActionsBlock from '@src/components/ReportsActionsBlock';
import ReportsFilter from '@src/components/ReportsFilter';
import { getLastTwoWorkingDays } from '@src/utils/helpers/dateUtils';
import { getShouldRemoveFromNew } from '@src/utils/helpers/reports';
import { completedReportsTableColumns } from '@src/utils/constants/tablesColumns';
import { dxCompletedReportsTourSteps, defaultTourSettings } from '@src/utils/constants/tourSettings';
import { exportCsv } from '@src/store/actions/reports/reports';
import { getDataGridFilters, nonSelectableReportTypes } from '@src/utils/helpers/devExpressHelpers';
import ReportsTable from '@src/components/ReportsTable';
import { isSupportedBrowser } from '@src/utils/helpers/browserUtils';
import { reportsTableType } from '@src/utils/templates/reportType';

const reportsType = reportsTableType.completed;
const pagesLimit = 840;

const filterFields = ['refno', 'text', 'orderno', 'donedate', 'type'];

const initialFilterFormValues = {
    refno: { matchType: 'contains', value: [] },
    donedate: { matchType: 'between', value: [] },
    text: { matchType: 'contains', value: [] },
    type: { matchType: '=', value: [] },
    orderno: { matchType: 'contains', value: [] },
};

const selectionChangedHandler = (selectedRowsData, setSelectedReports, selectedRowKeys, setSelectedReportsPages) => {
    const numberOfSelectedPages = selectedRowsData.reduce((accumulator, row) => accumulator + row.pages, 0);
    setSelectedReports(selectedRowKeys);
    setSelectedReportsPages(numberOfSelectedPages);
};

const CompletedReportsContent = () => {
    const clearFormButtonRef = useRef();

    const isLoading = useSelector(state => getFetching(state));
    const combinedReports = useSelector(state => getCombinedReports(state));
    const {
        webDelivery,
        defaultCustomer,
        number: currentCustomerId,
        groupCode,
    } = useSelector(state => getCurrentCustomer(state));
    const { canSelectCustomer: isSctUser, customerGroupCode } = useSelector(getCurrentUser);

    const initialFiltersTemplate = {
        ...initialFilterFormValues,
        donedate: { matchType: 'between', value: [getLastTwoWorkingDays()[0], moment().endOf('day')] },
    };

    const [selectedReports, setSelectedReports] = useState([]);
    const [selectedReportsPages, setSelectedReportsPages] = useState(0);
    const [initialFilters] = useState(initialFiltersTemplate);
    const [filters, setFilters] = useState(initialFilters);
    const [sort, setSort] = useState([{ desc: true, selector: 'webno' }]);
    const [additionalValueForFilter, setAdditionalValueForFilter] = useState({ name: '', value: '' });
    const [isTourOpen, setIsTourOpen] = useState(false);
    const dispatch = useDispatch();

    const isSelectedReportsPagesExceeded = selectedReportsPages >= pagesLimit;
    const shouldRemoveFromNew = getShouldRemoveFromNew(defaultCustomer, groupCode, customerGroupCode);

    const onExport = useCallback(() => {
        dispatch(exportCsv(getDataGridFilters(filters), completedReportsTableColumns, sort, reportsType));
    }, [filters, sort]);

    useEffect(() => {
        if (currentCustomerId) {
            setFilters(initialFilters);
            clearFormButtonRef.current.click();
        }
    }, [currentCustomerId]);

    const table = useMemo(() => (
        <ReportsTable
            onSelectionChanged={({ selectedRowsData }) => {
                const selectedRows = selectedRowsData.filter(({ type }) => !nonSelectableReportTypes.includes(type));
                selectionChangedHandler(
                    selectedRows,
                    setSelectedReports,
                    selectedRows.map(({ id }) => id),
                    setSelectedReportsPages,
                );
            }}
            tableColumns={completedReportsTableColumns}
            handleLoadingStart={() => dispatch(fetchingReports(reportsType))}
            handleLoadingSuccess={response => dispatch(fetchReportsSucceeded(response, reportsType))}
            handleLoadingError={error => dispatch(fetchReportsFailed(error, reportsType))}
            pushAdditionalValueForFilter={setAdditionalValueForFilter}
            reportsType={reportsType}
            removeFromNew={id => dispatch(removeReportsFromNew([id]))}
            filters={filters}
            isExportAllowed={!!filters && isSupportedBrowser()}
            onExport={onExport}
            setSort={setSort}
            isSelectable
            helpLabel={filters === initialFilters && '*showing most recent 2 working days including weekends'}
            combinedReports={combinedReports}
            shouldRemoveFromNew={shouldRemoveFromNew}
            isWebDelivery={webDelivery}
            isSctUser={isSctUser}
            shouldReloadData={currentCustomerId}
        />
    ),
    [filters, onExport, combinedReports, webDelivery, currentCustomerId, shouldRemoveFromNew]);

    return (
        <div id="completedReportsContainer">
            <div className="contentHeader">
                <h1>
                    <span>Completed Reports</span>
                    <Button shape="circle" icon={<InfoOutlined />} onClick={() => setIsTourOpen(true)} />
                </h1>
            </div>
            <Loader show={isLoading}>
                <ReportsFilter
                    initialValues={initialFilterFormValues}
                    isDisabled={isLoading}
                    onSubmit={data => setFilters({ ...data })}
                    filterFields={filterFields}
                    additionalValueForFilter={additionalValueForFilter}
                    clearFormButtonRef={clearFormButtonRef}
                />
                {
                    currentCustomerId
                    && table
                }
                <ReportsActionsBlock
                    onCombineSelected={() => dispatch(combineReports(selectedReports, reportsType))}
                    isCombineSelectedDisabled={!selectedReports.length || isSelectedReportsPagesExceeded}
                    onResendSelectedByEmail={email => dispatch(resendReportsByEmail(selectedReports, email))}
                    isResendSelectedByEmailDisabled={!selectedReports.length}
                    isSelectedReportsPagesExceeded={isSelectedReportsPagesExceeded}
                    pagesLimit={pagesLimit}
                />
                <Tour
                    {...defaultTourSettings}
                    steps={dxCompletedReportsTourSteps}
                    isOpen={isTourOpen}
                    onRequestClose={() => setIsTourOpen(false)}
                />
            </Loader>
        </div>
    );
};

export default CompletedReportsContent;
