/**
 * Created by oleg on 12/20/16.
 */
import { REGISTRATION_SUBMIT, REGISTRATION_SUCCESS, REGISTRATION_ERROR } from '../../actions/registration';

const intialState = {
    submited: false,
    response: null,
    error: null,
};

const submitRegistration = (state = intialState, action) => {
    switch (action.type) {
        case REGISTRATION_SUBMIT:
            return intialState;
        case REGISTRATION_SUCCESS:
            return { submited: true, response: action.response, error: null };
        case REGISTRATION_ERROR:
            return { ...state, submited: false, error: action.error };
        default:
            return state;
    }
};

export const isRegistrationSucceeded = state => state.submitRegistration.submited;

export default submitRegistration;
