import React, { useState, useEffect } from 'react';
import {
    Button,
    Col,
    Row,
    Tooltip,
} from 'antd';
import DataGrid, {
    Column,
    Pager,
    Paging,
    FilterRow,
    Selection,
} from 'devextreme-react/data-grid';
import { v4 as uuid } from 'uuid';

const calculateBlockLotQualifier = ({ displayBlock, displayLot, qualify }) => (
    `${displayBlock} ${displayLot}${qualify ? ` /  ${qualify}` : ''}`
);

const renderOwnerAddress = ({ data: { ownerName, streetAddress: { addrLine1, addrLine2 } } }) => (
    <>
        {ownerName ? ownerName.map(name => name.unformattedName).join(', ') : ''}
        {ownerName && (addrLine1 || addrLine2) ? <br /> : ''}
        {addrLine1 || ''}
        {addrLine2 || ''}
    </>
);

const calculateOwnerAddress = ({ ownerName, streetAddress: { addrLine1, addrLine2 } }) => (
    [
        ownerName
            ? ownerName.map(name => name.unformattedName).join(', ')
            : '',
        addrLine1 || '',
        addrLine2 || '',
    ].join(', ')
);

const calculateCityStateZip = ({ streetAddress: { city, state, zip } }) => (
    [
        city,
        state || '',
        zip ? ` ${Object.values(zip).join(', ')}` : '',
    ].join(', ')
);

const renderCityStateZip = ({ data: { streetAddress: { city, state, zip } } }) => (
    <>
        {city}
        {(state || zip) ? <br /> : ''}
        {state || ''}
        {zip ? ` ${Object.values(zip).join('-')}` : ''}
    </>
);

const renderCountyAdditionalLot = ({ data: { addl_lots: additionalLots, streetAddress: { county } } }) => (
    <>
        {county}
        {(additionalLots) ? <br /> : ''}
        {additionalLots || ''}
    </>
);

const renderInfo = ({ data: { taxUrl, mapUrl, comments } }) => (
    <>
        {
            taxUrl
            && (
                <>
                    <a href={taxUrl} target="blank">Tax Data</a>
                    &nbsp;&nbsp;
                </>
            )
        }
        {
            mapUrl && <a href={mapUrl} target="blank">Map</a>
        }
        {
            comments
            && !!comments.length
            && (
                <Tooltip
                    title={() => (<ul>{comments.map(comment => <li key={uuid()}>{comment}</li>)}</ul>)}
                    trigger="click"
                    overlayStyle={{ width: 1000 }}
                >
                    <Button type="danger" size="small">Previous Orders</Button>
                </Tooltip>
            )
        }
    </>
);

const SelectPropertySearchResults = React.forwardRef(({
    goToSearchStep,
    goToEditStep,
    setSelectedProperty,
    selectedProperty,
    property,
    nextWithSelectedPropertyButtonRef,
    isContinuation,
}, ref) => {
    const [isErrorMessageVisible, setIsErrorMessageVisible] = useState(false);

    useEffect(() => {
        if (ref.current) {
            ref.current.instance.selectRows(selectedProperty.map(({ recordKey }) => recordKey));
        }
    },
    [selectedProperty]);

    const submitStep = () => {
        const selectedRowsData = ref.current.instance.getSelectedRowsData();
        if (selectedRowsData.length) {
            goToEditStep();
            setIsErrorMessageVisible(false);
            setSelectedProperty(selectedRowsData);
        } else {
            setIsErrorMessageVisible(true);
        }
    };

    const cancelSelectStep = () => {
        goToSearchStep();
        ref.current.instance.clearSelection();
        setSelectedProperty([]);
        setIsErrorMessageVisible(false);
    };

    return (
        <>
            <div className="formBlock">
                {
                    isErrorMessageVisible
                    && !ref.current.instance.getSelectedRowsData().length
                    && (
                        <div className="error-section">
                            Please select a property
                        </div>
                    )
                }
                <Row>
                    <Col span={24}>
                        {
                            !!property?.length
                            && <span className="help-block text-right">*showing maximum 200 properties</span>
                        }
                    </Col>
                </Row>
                <DataGrid
                    ref={ref}
                    id="property-data-grid"
                    dataSource={property}
                    allowColumnReordering
                    allowColumnResizing
                    columnAutoWidth
                    wordWrapEnabled
                    loadPanel={{ enabled: false }}
                    keyExpr="recordKey"
                    width="100%"
                    showBorders
                    showRowLines
                    showColumnLines
                    rowAlternationEnabled
                    headerFilter={{ visible: true }}
                    onSelectionChanged={() => setIsErrorMessageVisible(false)}
                >
                    <FilterRow
                        applyFilterText="Apply filter"
                        visible
                    />
                    <Paging defaultPageSize={10} />
                    <Pager
                        showPageSizeSelector
                        allowedPageSizes={[10, 25, 50, 100]}
                        showInfo
                    />
                    <Selection
                        mode={isContinuation ? 'none' : 'multiple'}
                        selectAllMode="page"
                        showCheckBoxesMode="always"
                    />
                    <Column
                        dataField="displayBlock"
                        caption="Block Lot/ Qualifier"
                        cssClass="cell-block-lot-qualifier"
                        dataType="string"
                        allowHeaderFiltering={false}
                        calculateCellValue={calculateBlockLotQualifier}
                        width={80}
                    />
                    <Column
                        dataField="recordKey"
                        width={80}
                        visible={false}
                    />
                    <Column
                        dataField="ownerName"
                        caption="Owner/Address"
                        cssClass="cell-owner-address"
                        dataType="string"
                        calculateCellValue={calculateOwnerAddress}
                        cellRender={renderOwnerAddress}
                        allowHeaderFiltering={false}
                    />
                    <Column
                        dataField="streetAddress.city"
                        caption="City/ST Zip"
                        cssClass="cell-city-st-zip"
                        allowHeaderFiltering={false}
                        calculateCellValue={calculateCityStateZip}
                        cellRender={renderCityStateZip}
                    />
                    <Column
                        dataField="streetAddress.county"
                        caption="County/ Additional"
                        cssClass="cell-county-additional"
                        allowHeaderFiltering={false}
                        cellRender={renderCountyAdditionalLot}
                        width={100}
                    />
                    <Column
                        caption="Info"
                        cssClass="cell-info"
                        allowHeaderFiltering={false}
                        cellRender={renderInfo}
                        width={140}
                    />
                </DataGrid>
            </div>
            {
                !isContinuation
                && (
                    <Button
                        onClick={cancelSelectStep}
                        type="primary"
                    >
                        Cancel Select
                    </Button>
                )
            }
            <Button
                className="btn pull-right"
                id="next-with-selected-property-button"
                ref={nextWithSelectedPropertyButtonRef}
                onClick={submitStep}
            >
                Next
            </Button>
        </>
    );
});

export default SelectPropertySearchResults;
