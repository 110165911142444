import React from 'react';
import { Button, Col, Row } from 'antd';
import { gutter } from '@src/utils/helpers/orders';
import { Field, Form } from 'react-final-form';
import InputGroupAntInputAdapter from '@src/components/input/InputGroupAntInputAdapter';
import { composeValidators, maxLength, required } from '@src/utils/helpers/validationRules';
import createDecorator from 'final-form-focus';

const focusOnError = createDecorator();

const ForgotPasswordForm = ({
    title,
    onSubmit,
    processing,
    error,
}) => (
    <>
        {
            title
            && <h3>{title}</h3>
        }
        {
            error
            && <div className="error-section top-buffer">{error}</div>
        }
        <Form
            onSubmit={onSubmit}
            decorators={[focusOnError]}
        >
            {
                params => {
                    const {
                        handleSubmit,
                    } = params;

                    return (
                        <form onSubmit={handleSubmit} id="forgot-password-form">
                            <Row gutter={gutter}>
                                <Col md={24} lg={12}>
                                    <Field
                                        name="username"
                                        component={InputGroupAntInputAdapter}
                                        controlId="username"
                                        labelText="Username"
                                        isRequired
                                        validate={composeValidators(required, maxLength(180))}
                                    />
                                </Col>
                                <Col md={24} lg={8}>
                                    <Button
                                        className="right-buffer-xs"
                                        htmlType="submit"
                                        type="primary"
                                        loading={processing}
                                        id="forgot-password-form--submit-button"
                                    >
                                        Submit
                                    </Button>
                                </Col>
                            </Row>
                        </form>
                    );
                }
            }
        </Form>
    </>
);

export default ForgotPasswordForm;
