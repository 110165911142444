import React, { useRef, useCallback } from 'react';
import StepsNavigationItem from '@src/containers/orderServicePropertySearch/StepsNavigationItem';

const StepsNavigation = ({
    mode,
    setMode,
    selectedPropertyDataGrid,
    selectPropertyForm,
    goToSearchStep,
    servicesForm,
    orderServiceModes,
    nextWithoutSearchButton,
    nextWithSelectedPropertyButton,
    validateSearchOrderFormButton,
    previewOrderButton,
}) => {
    const {
        EDIT,
        SELECT_RESULTS,
        SELECT_FORM,
        PREVIEW,
    } = orderServiceModes;

    const previewOrderStepRef = useRef();

    const goToEditStep = useCallback(() => {
        if (mode === SELECT_FORM) {
            nextWithoutSearchButton.click();
        } else if (mode === SELECT_RESULTS) {
            nextWithSelectedPropertyButton.click();
        } else {
            setMode(EDIT);
        }
    }, [nextWithSelectedPropertyButton, nextWithoutSearchButton, mode]);

    const goToPreviewStep = () => {
        if (mode === EDIT) {
            previewOrderButton.click();
            return;
        }

        if (mode === SELECT_FORM) {
            validateSearchOrderFormButton.click();

            if (!selectPropertyForm.getState().valid) {
                return;
            }
        }

        if (
            mode === SELECT_RESULTS
            && !selectedPropertyDataGrid?.instance.getSelectedRowKeys().length
        ) {
            nextWithSelectedPropertyButton.click();
            return;
        }

        servicesForm.submit();

        if (!servicesForm.getState().valid) {
            setMode(EDIT);
            return;
        }

        setMode(PREVIEW);
    };

    return (
        <div id="navigationMenuBlock">
            <ul className="navMenu" id="navMenu">
                <StepsNavigationItem
                    isCurrent={[SELECT_FORM, SELECT_RESULTS].includes(mode)}
                    isPassed={mode > SELECT_RESULTS}
                    clickHandler={goToSearchStep}
                    id="editOrder_step"
                    name="Select Property"
                    description="Find a property or enter address"
                />
                <StepsNavigationItem
                    isCurrent={mode === EDIT}
                    isPassed={mode > EDIT}
                    clickHandler={goToEditStep}
                    id="selectService_step"
                    name="Select Services"
                    description="Select services you wish to order"
                />
                <StepsNavigationItem
                    isCurrent={mode === PREVIEW}
                    isPassed={false}
                    clickHandler={goToPreviewStep}
                    id="previewOrder_step"
                    ref={previewOrderStepRef}
                    name="Preview"
                    description="Preview your order"
                />
            </ul>
        </div>
    );
};

export default StepsNavigation;
