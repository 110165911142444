import React from 'react';
import { Checkbox } from 'antd';
import FormGroup from '@src/components/input/FormGroup';
import { getValidationState } from '@src/utils/helpers/validationRules';

const InputGroupAntCheckBoxAdapter = ({
    input,
    controlId,
    isRequired,
    meta: { touched, error, warning },
    labelText,
    ...rest
}) => (
    <FormGroup
        controlId={controlId}
        validationState={getValidationState(touched, error, warning)}
        isRequired={isRequired}
        error={error}
        warning={warning}
        touched={touched}
    >
        <Checkbox
            {...input}
            {...rest}
        >
            {labelText}
        </Checkbox>
    </FormGroup>
);

export default InputGroupAntCheckBoxAdapter;
