export const SHOW_DEACTIVATED_USERS = 'SHOW_DEACTIVATED_USERS';
export const HIDE_DEACTIVATED_USERS = 'HIDE_DEACTIVATED_USERS';

export const showDeactivatedUsers = () => ({
    type: SHOW_DEACTIVATED_USERS,
});

export const hideDeactivatedUsers = () => ({
    type: HIDE_DEACTIVATED_USERS,
});

export const changeShowDeactivated = value => dispatch => (
    value ? dispatch(showDeactivatedUsers()) : dispatch(hideDeactivatedUsers())
);
