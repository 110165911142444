// TODO: Add validation for zip and phone number
import {
    onlyNumbersPattern,
    emailPattern,
    multiEmailPattern,
} from './regexps';

const required = value => (value ? undefined : 'Required');
const requiredAtLeastOneInArray = (value, entityName = '') => (
    value && value.length
        ? undefined
        : `At least one ${entityName}${entityName ? ' ' : ''}is required`
);
const requiredAtLeastOneField = value => (value && value.length && value.find(item => !!item) ? undefined : 'At least one is required');
const conditionRequired = (conditionValue, dependantValue) => (
    !conditionValue || (conditionValue && !!dependantValue)
        ? undefined
        : `Required`
);
const number = value => (value && Number.isNaN(Number(value)) ? 'Must be a number' : undefined);
const naturalNumber = value => (value && !onlyNumbersPattern.test(value) ? 'Must be a natural number' : undefined);
const minNumber = min => value => (value && Number(value) < min ? `Must be equal or more then ${min}` : undefined);
const maxNumber = max => value => (value && Number(value) > max ? `Must be equal or less then ${max}` : undefined);
const maxLength = max => value => (value && value.length > max ? `Must be ${max} characters or less` : undefined);
const minLength = min => value => (value && value.length < min ? `Must be at least ${min}` : undefined);
const staticLength = number => value => (
    value && value.length < number ? `Must contain ${number} characters` : undefined
);
const onlyDigits = value => (value && !onlyNumbersPattern.test(value) ? 'Must contain only digits' : undefined);
const maxLengthForTags = (max, joiner) => value => maxLength(max)(value.join(joiner));
const email = value => (
    value && !emailPattern.test(value)
        ? 'Invalid email address'
        : undefined
);

const multiEmail = value => (
    value && !multiEmailPattern.test(value)
        ? 'Invalid email address'
        : undefined
);

const multiEmailTags = value => {
    const validationMethod = Array.isArray(value) ? () => value.some(email) : email;

    return (
        value && validationMethod(value)
            ? 'Invalid email address'
            : undefined
    );
};

const equalTo = (equalToValue, equalToName) => value => (
    value === equalToValue
        ? undefined
        : `Should be equal to ${equalToName}`
);
const phoneNumber = value => (value && value.length < 14 ? `Invalid Format` : undefined);

const onlyIntegersInArray = array => (
    Array.isArray(array) && array.some(item => !+item || +item >= 2147483647)
        ? `Should contain only numbers less then 2147483647`
        : undefined
);

const composeValidators = (...validators) => value => (
    validators.reduce((error, validator) => error || validator(value), undefined)
);

const hasPropertiesWithTrue = obj => (
    Object.values(obj).some(item => item === true)
        ? undefined
        : 'At least one should be selected'
);

const notEqualNamesForService = ({ namesToSearch }, service) => {
    const namesForService = { 'Individual': [], 'Company': [] };
    namesToSearch.forEach(({
        selectedServicesTypes,
        selectedServices,
        nameSearchCategory: {
            type,
            Company: { companyName },
            Individual: {
                firstName,
                lastName,
                middleName,
                prefix,
                suffix,
            },
        },
    }) => {
        if (selectedServicesTypes[service]) {
            let serviceType = type;
            if (service === 'judgmentSearch') {
                if (!namesForService[`${type}_${selectedServices.judgmentSearch.nameQualifier}`]) {
                    namesForService[`${type}_${selectedServices.judgmentSearch.nameQualifier}`] = [];
                }
                serviceType = `${type}_${selectedServices.judgmentSearch.nameQualifier}`;
            }
            namesForService[serviceType]
                .push((
                    type === 'Company'
                        ? companyName
                        : `${prefix} ${firstName} ${middleName} ${lastName} ${suffix}`
                ).toLowerCase());
        }
    });
    if (
        Object.values(namesForService).some(item => item.length !== new Set(item).size)
    ) {
        switch (service) {
            case 'judgmentSearch':
                return 'Duplicate judgment names are present in the request. Please, edit your order.';
            case 'childSupportSearch':
                return 'Duplicate child support names are present in the request. Please, edit your order.';
            default:
                return 'Duplicate names are present in the request. Please, edit your order.';
        }
    }

    return undefined;
};

const uniqueNamesInRequest = names => (
    names.length === new Set(names).size
        ? undefined
        : 'This name is already exist in request.'
);

const getValidationState = (touched, error, warning, success) => {
    if (touched) {
        if (error) return 'error';
        if (warning) return 'warning';
        if (success) return 'success';
    }

    return null;
};

export {
    required, conditionRequired, number, naturalNumber, maxLength, email, multiEmailTags,
    minLength, equalTo, composeValidators, minNumber, maxNumber, phoneNumber,
    getValidationState, multiEmail, onlyIntegersInArray, requiredAtLeastOneInArray,
    hasPropertiesWithTrue, requiredAtLeastOneField, notEqualNamesForService, maxLengthForTags,
    uniqueNamesInRequest, staticLength, onlyDigits,
};
