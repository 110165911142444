import React from 'react';
import Footer from '@src/components/Footer';
import Header from '@src/containers/header/Header';

const OneColumnLayout = ({ children }) => (
    <div id="mainContainer">
        <header id="header">
            <Header />
        </header>
        <main className="container overlayedZone" role="main" id="main">
            {children}
        </main>
        <footer>
            <Footer />
        </footer>
    </div>
);

export default OneColumnLayout;
