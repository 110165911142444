import {
    CHANCERY_ABSTRACT,
    COMPLETED_REPORTS,
    NAME_SEARCH,
    NEW_ORDERS,
} from '@src/utils/templates/accountSettings';

export const getDefaultPageOptions = (options, allowWebOrders, webDelivery) => {
    const disabledValues = [];
    const defaultPageOptions = [...options];

    if (!webDelivery) {
        disabledValues.push(NEW_ORDERS);
    }
    if (!allowWebOrders) {
        disabledValues.push(NAME_SEARCH, CHANCERY_ABSTRACT);
    }

    disabledValues.forEach(disabledValue => {
        const disabledOption = []
            .concat(...defaultPageOptions.map(({ options }) => options))
            .find(({ value }) => (disabledValue === value));
        disabledOption.disabled = true;
    });

    return defaultPageOptions;
};

export const getDefaultPage = (defaultPageFromSettings, allowWebOrders, webDelivery) => {
    if (
        (!allowWebOrders && [NAME_SEARCH, CHANCERY_ABSTRACT].includes(defaultPageFromSettings))
        || (!webDelivery && [NEW_ORDERS].includes(defaultPageFromSettings))
        || !defaultPageFromSettings
    ) {
        return COMPLETED_REPORTS;
    }

    return defaultPageFromSettings;
};
