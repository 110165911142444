import React from 'react';
import { Col, Row } from 'antd';
import { Field } from 'react-final-form';
import InputGroupAntCheckBoxAdapter from '@src/components/input/InputGroupAntCheckBoxAdapter';
import InputGroupAntInputAdapter from '@src/components/input/InputGroupAntInputAdapter';
import InputGroupAntSelectAdapter from '@src/components/input/InputGroupAntSelectAdapter';
import AdditionalStatesField from '@src/containers/orderServiceNameSearch/AdditionalStatesField';

const ServiceUccSearch = ({
    name,
    values,
    index,
    gutter,
    countyOptions,
    states,
}) => (
    <div className="serviceBlock formBlock">
        <Row className="serviceHeader">
            <Col md={24} lg={16}>
                <Field
                    name={`${name}.selectedServicesTypes.uccSearch`}
                    component={InputGroupAntCheckBoxAdapter}
                    controlId={`${name}.selectedServicesTypes.uccSearch`}
                    labelText="UCC SEARCH"
                    type="checkbox"
                />
            </Col>
        </Row>
        {
            values.namesToSearch[index].selectedServicesTypes.uccSearch
            && (
                <>
                    <Row gutter={gutter}>
                        <Col md={24} lg={16}>
                            <Field
                                name={`${name}.selectedServices.uccSearch.comments`}
                                component={InputGroupAntInputAdapter}
                                controlId={`${name}.selectedServices.uccSearch.comments`}
                                labelText="Comments"
                            />
                        </Col>
                    </Row>
                    {
                        values.namesToSearch[index].selectedServices.uccSearch.uccSearchType === 'EXACT_ADDRESS'
                        && (
                            <>
                                <hr />
                                <Row gutter={gutter}>
                                    <Col md={24} lg={16}>
                                        <Field
                                            name={`${name}.selectedServices.uccSearch.EXACT_ADDRESS.addressLine1`}
                                            component={InputGroupAntInputAdapter}
                                            controlId={`${name}.selectedServices.uccSearch.EXACT_ADDRESS.addressLine1`}
                                            labelText="Address Line 1"
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={gutter}>
                                    <Col md={24} lg={16}>
                                        <Field
                                            name={`${name}.selectedServices.uccSearch.EXACT_ADDRESS.addressLine2`}
                                            component={InputGroupAntInputAdapter}
                                            controlId={`${name}.selectedServices.uccSearch.EXACT_ADDRESS.addressLine2`}
                                            labelText="Address Line 2"
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={gutter}>
                                    <Col md={24} lg={8}>
                                        <Field
                                            name={`${name}.selectedServices.uccSearch.EXACT_ADDRESS.city`}
                                            component={InputGroupAntInputAdapter}
                                            controlId={`${name}.selectedServices.uccSearch.EXACT_ADDRESS.city`}
                                            labelText="City"
                                        />
                                    </Col>
                                    <Col md={24} lg={8}>
                                        <Field
                                            name={`${name}.selectedServices.uccSearch.EXACT_ADDRESS.county`}
                                            component={InputGroupAntSelectAdapter}
                                            controlId={`${name}.selectedServices.uccSearch.EXACT_ADDRESS.county`}
                                            labelText="County"
                                            options={countyOptions}
                                        />
                                    </Col>
                                    <Col md={24} lg={8}>
                                        <Field
                                            name={`${name}.selectedServices.uccSearch.EXACT_ADDRESS.zip`}
                                            component={InputGroupAntInputAdapter}
                                            controlId={`${name}.selectedServices.uccSearch.EXACT_ADDRESS.zip`}
                                            labelText="Zip"
                                        />
                                    </Col>
                                </Row>
                            </>
                        )
                    }
                    <hr />
                    <AdditionalStatesField
                        name={name}
                        serviceName="uccSearch"
                        values={values}
                        index={index}
                        gutter={gutter}
                        states={states}
                    />
                </>
            )
        }
    </div>
);

export default ServiceUccSearch;
