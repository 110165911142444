import { capitalize } from '@src/utils/helpers/normalize';

export const isIncompletedStatus = status => status === 'I';

export const generateProcessName = selectedReports => {
    let name = selectedReports[0];
    if (selectedReports.length > 1) {
        name = `${name}, ${selectedReports[1]}`;
    }
    if (selectedReports.length > 2) {
        name = `${name} and ${selectedReports.length - 2} others`;
    }

    return name;
};

export const getNumberOfSelectedPages = reports => reports.reduce((accumulator, row) => accumulator + row.pages, 0);

export const pagesLimit = 840;

export const getIsSelectedReportsPagesExceeded = pages => pages >= pagesLimit;

export const getShouldRemoveFromNew = (defaultCustomer, groupCode, customerGroupCode) => (
    defaultCustomer
    || groupCode === customerGroupCode
);

export const getNameWithDetails = data => {
    const {
        name,
        matchExactNameOnly,
        nameQualifier,
    } = data;

    const nameDetails = [
        matchExactNameOnly && 'Exact name only',
        nameQualifier !== 'SELECT_ONE' && capitalize(nameQualifier?.toLowerCase()),
    ].filter(i => i);

    return `${name} ${nameDetails.length ? `(${nameDetails.join(', ')})` : ''}`;
};
