import { request, getRequestConfig, postRequestConfig } from '@src/utils/apiServices/api';
import { interpolate } from '@src/utils/helpers/normalize';
import {
    urlUsers,
    urlUsersResendConfirmationCode,
    urlUsersUserName,
    urlUsersUserNameActiveState,
    urlUsersUserNameAdminState,
    urlUsersNew,
    urlUsersCurrent,
} from '@src/utils/constants/endpointConstants';

export const fetchAllUsers = () => (
    request({
        ...getRequestConfig,
        url: urlUsers,
    })
);

export const fetchCurrentUser = () => (
    request({
        ...getRequestConfig,
        url: urlUsersCurrent,
    })
);

export const updateUser = user => (
    request({
        ...postRequestConfig,
        url: interpolate(urlUsersUserName, user.username),
        data: user.attributes,
    })
);

export const createUser = user => (
    request({
        ...postRequestConfig,
        url: urlUsersNew,
        data: user,
    })
);

export const updateUserActiveState = (user, activeState) => (
    request({
        ...postRequestConfig,
        url: interpolate(urlUsersUserNameActiveState, user.username),
        data: { activeState },
    })
);

export const updateUserAdminState = (user, adminState) => (
    request({
        ...postRequestConfig,
        url: interpolate(urlUsersUserNameAdminState, user.username),
        data: { adminState },
    })
);

export const resendConfirmationCode = user => (
    request({
        ...postRequestConfig,
        url: urlUsersResendConfirmationCode,
        data: { username: user.email },
    })
);
