import { createUser } from '@src/utils/apiServices/userRequests';
import { addErrorAlert } from '@src/store/actions/alerts';
import {
    createUserAction, createUserSubmit,
    createUserCancel, createUserSucceeded, createUserFailed,
} from './userMaintenanceControl';

export const performCreateUser = () => dispatch => (dispatch(createUserAction()));

export const submitCreateUser = user => dispatch => {
    dispatch(createUserSubmit());

    return createUser({ ...user.attributes, username: user.username }).then(response => {
        dispatch(createUserSucceeded(response));
    }).catch(error => {
        dispatch(createUserFailed(error));
        dispatch(addErrorAlert('Can not create user. Please, try again later.', true));
    });
};

export const cancelCreateUser = () => dispatch => (dispatch(createUserCancel()));
