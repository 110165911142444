import React from 'react';
import { ACCOUNT_VIEW_MY_ORDERS_ORDER_DETAILS } from '@src/utils/constants/routerConstants';

const orderDetailsRef = orderId => `${ACCOUNT_VIEW_MY_ORDERS_ORDER_DETAILS}/${orderId}`;

const OrderConformationMessage = ({ orderId }) => (
    <>
        <p>Thank you for your order. Your request has been submitted and it will be processed shortly.</p>
        <p>
            Your order number is:
            <b>{` ${orderId}`}</b>
            .
        </p>
        <p>
            To track the status of your request, please visit
            <b> Order History </b>
            page or click the following link:
        </p>
        <br />
        <a //eslint-disable-line react/jsx-no-target-blank
            className="h4"
            target="_blank"
            rel="noopener noreferrer"
            href={orderDetailsRef(orderId)}
        >
            View Order
        </a>
        <br />
        <br />
        <p>Thank You,</p>
        <p>State Capital Title &amp; Abstract Co.</p>
    </>
);

export default OrderConformationMessage;
