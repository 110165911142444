import moment from 'moment';

export const yearFormat = 'YYYY';
export const shortDateFormat = 'MM/DD/YYYY';
export const shortYearDateFormat = 'MM/DD/YY';
export const monthDateFormat = 'MM/DD';
export const dxShortDateFormat = 'MM/dd/yyyy';
export const shortDateFormatUniversal = [
    'MM/DD/YYYY',
    'M/DD/YYYY',
    'MM/D/YYYY',
    'M/D/YYYY',
    'MM/DD/YY',
    'M/DD/YY',
    'MM/D/YY',
    'M/D/YY',
];

export const ranges = {
    'Today': [
        moment().startOf('day'),
        moment(),
    ],
    'Yesterday': [
        moment().subtract(1, 'days').startOf('day'),
        moment().subtract(1, 'days').endOf('day'),
    ],
    'Last 7 Days': [
        moment().subtract(6, 'days').startOf('day'),
        moment(),
    ],
    'Last 30 Days': [
        moment().subtract(29, 'days').startOf('day'),
        moment(),
    ],
    'This Month': [
        moment().startOf('month'),
        moment().endOf('month'),
    ],
    'Last Month': [
        moment().subtract(1, 'month').startOf('month'),
        moment().subtract(1, 'month').endOf('month'),
    ],
};

const getIsWeekend = date => {
    const day = date.isoWeekday();

    return (day === 6 || day === 7);
};

const getPreviousWorkingDay = date => {
    let previousWorkingDay = date;

    while (getIsWeekend(previousWorkingDay)) {
        previousWorkingDay = previousWorkingDay.subtract(1, 'day');
    }

    return previousWorkingDay;
};

export const getLastTwoWorkingDays = () => {
    const endDate = getPreviousWorkingDay(moment().endOf('day'));
    const startDate = getPreviousWorkingDay(endDate.clone().startOf('day').subtract(1, 'day'));

    return ([
        startDate,
        endDate,
    ]);
};

export const getShortDate = date => (date ? moment(date).format(shortDateFormat) : '');
export const getCurrentYear = () => moment().format(yearFormat);

export const disabledDateByActivePeriod = (current, activeRange) => (
    current
    && (current < activeRange[0] || current > activeRange[1])
);
