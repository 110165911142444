/* eslint-disable no-multi-spaces */
/* eslint-disable max-len */
export const STATIC_SITE_URL = (process.env.ENVIRONMENT === 'prod' && 'https://www.statecapital.net')
    || (process.env.ENVIRONMENT === 'qa' && 'https://qa-static-site.statecap.co')
    || undefined;

export const FAQS                              = `${STATIC_SITE_URL}/faqs`;
export const FAQS_NAME_QUALIFIER               = `${FAQS}/#name-qual`;
export const CONTACT_INFO                      = `${STATIC_SITE_URL}/contact-info`;

export const ROOT                              = '/';
export const LOGIN                              = '/login';
export const FORGOT_PASSWORD                    = '/forgotpassword';
export const ACCOUNT                            = '/account';
export const REGISTRATION                       = '/registration';

export const ACCOUNT_SETTINGS                   = `${ACCOUNT}/settings`;
export const ACCOUNT_SETTINGS_PERSONAL          = `${ACCOUNT_SETTINGS}/personal`;
export const ACCOUNT_SETTINGS_REPORTS           = `${ACCOUNT_SETTINGS}/reports`;
export const ACCOUNT_SETTINGS_PROPERTY_SEARCH   = `${ACCOUNT_SETTINGS}/propertySearch`;
export const ACCOUNT_SETTINGS_BUSINESS_CONTACT  = `${ACCOUNT_SETTINGS}/businesscontact`;
export const ACCOUNT_SETTINGS_INDIVIDUAL        = `${ACCOUNT_SETTINGS}/individual`;
export const ACCOUNT_SETTINGS_USER_MAINTENANCE  = `${ACCOUNT_SETTINGS}/usermaintenance`;
export const ACCOUNT_SETTINGS_CHANGE_PASSWORD   = `${ACCOUNT_SETTINGS}/changepassword`;

export const ACCOUNT_SELECT_CUSTOMER            = `${ACCOUNT}/selectcustomer`;

export const ACCOUNT_VIEW_MY_ORDERS                   = `${ACCOUNT}/viewmyorders`;
export const ACCOUNT_VIEW_MY_ORDERS_NEW_REPORTS       = `${ACCOUNT_VIEW_MY_ORDERS}/newreports`;
export const ACCOUNT_VIEW_MY_ORDERS_COMPLETED_REPORTS = `${ACCOUNT_VIEW_MY_ORDERS}/completedreports`;
export const ACCOUNT_VIEW_MY_ORDERS_OPEN_REPORTS      = `${ACCOUNT_VIEW_MY_ORDERS}/openorders`;
export const ACCOUNT_VIEW_MY_ORDERS_ORDER_DETAILS     = `${ACCOUNT_VIEW_MY_ORDERS}/orderdetails`;
export const ACCOUNT_VIEW_MY_ORDERS_ORDER_DETAILS_ID  = `${ACCOUNT_VIEW_MY_ORDERS_ORDER_DETAILS}/:id`;

export const ORDER_SERVICE                                      = `/orderservice`;
export const ORDER_SERVICE_JUDGMENT_LOOK_UP                     = `${ORDER_SERVICE}/jdglookup`;
export const ORDER_SERVICE_NAME_SEARCH                          = `${ORDER_SERVICE}/namesearchorderform`;
export const ORDER_SERVICE_ABSTRACT_SEARCH                      = `${ORDER_SERVICE}/abstractsearchorderform`;
export const ORDER_SERVICE_JUDGMENT                             = `${ORDER_SERVICE}/judgment`;
export const ORDER_SERVICE_JUDGMENT_CONTINUATION                = `${ORDER_SERVICE_JUDGMENT}/continuation`;
export const ORDER_SERVICE_JUDGMENT_CONTINUATION_ORDER_FORM     = `${ORDER_SERVICE_JUDGMENT_CONTINUATION}/appendorderform/:id`;
export const ORDER_SERVICE_JUDGMENT_CONTINUATION_SELECT_TABLE   = `${ORDER_SERVICE_JUDGMENT_CONTINUATION}/select`;
export const ORDER_SERVICE_PROPERTY_SEARCH                                 = `${ORDER_SERVICE}/propertysearch`;
export const ORDER_SERVICE_PROPERTY_SEARCH_ORDER_FORM                      = `${ORDER_SERVICE_PROPERTY_SEARCH}/orderform`;
export const ORDER_SERVICE_PROPERTY_SEARCH_ORDER_FORM_ID                   = `${ORDER_SERVICE_PROPERTY_SEARCH_ORDER_FORM}/:id`;
export const ORDER_SERVICE_PROPERTY_SEARCH_TAX_CONTINUATION                = `${ORDER_SERVICE_PROPERTY_SEARCH}/tax/continuation/select`;
export const ORDER_SERVICE_PROPERTY_SEARCH_TAX_CONTINUATION_ORDER_FORM     = `${ORDER_SERVICE_PROPERTY_SEARCH}/:id`;
