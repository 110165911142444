import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const RequireAuth = ({ children, isAvailable, redirectTo }) => {
    const location = useLocation();
    if (!isAvailable) {
        return <Navigate to={redirectTo} state={{ from: location }} replace />;
    }

    return children;
};

export default RequireAuth;
