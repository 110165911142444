import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { Row, Col, Button } from 'antd';
import createDecorator from 'final-form-focus';
import Loader from '@src/components/Loader';
import { saveAccountSettings, setSettingsContainerTitle } from '@src/store/actions/accountSettings';
import { getIndividualSettings, isAccountSettingsSaving } from '@src/store/reducers/accountSettings';
import { INDIVIDUAL_SETTINGS } from '@src/utils/constants/settingsConstants';
import InputGroupAntSelectAdapter from '@src/components/input/InputGroupAntSelectAdapter';
import InputGroupAntCheckBoxAdapter from '@src/components/input/InputGroupAntCheckBoxAdapter';
import { gutter } from '@src/utils/helpers/orders';

const focusOnError = createDecorator();

const outputFormatOptions = [{ value: 'BOLD', label: 'Bold' }, { value: 'UNBOLD', label: 'Unbold' }];
const outputSequenceOptions = [{ value: 'BY_COURT', label: 'By Court' }, { value: 'BY_NAME', label: 'By Name' }];

const IndividualSearchesContent = () => {
    const individualSearches = useSelector(state => getIndividualSettings(state));
    const isSaving = useSelector(state => isAccountSettingsSaving(state));
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setSettingsContainerTitle('Individual Searches'));
    }, []);

    return (
        <Form
            onSubmit={data => dispatch(saveAccountSettings(data, INDIVIDUAL_SETTINGS))}
            initialValues={individualSearches}
            decorators={[focusOnError]}
        >
            {
                ({
                    handleSubmit, form, pristine,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Loader show={isSaving}>
                            <div className="formBlock">
                                <div className="h4">
                                    Judgment & Child Support Search
                                </div>
                                <Row gutter={gutter}>
                                    <Col md={12} xs={24}>
                                        <Field
                                            name="outputFormat"
                                            component={InputGroupAntSelectAdapter}
                                            labelText="Output Format"
                                            options={outputFormatOptions}
                                            controlId="outputFormat"
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={gutter}>
                                    <Col md={12} xs={24}>
                                        <Field
                                            name="outputSequence"
                                            component={InputGroupAntSelectAdapter}
                                            labelText="Output Sequence"
                                            options={outputSequenceOptions}
                                            controlId="outputSequence"
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={gutter}>
                                    <Col md={12} xs={24}>
                                        <Field
                                            type="checkbox"
                                            name="docketIndex"
                                            component={InputGroupAntCheckBoxAdapter}
                                            labelText="Generate Docket Index"
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={gutter} className="form-group">
                                    <Col md={8} xs={24}>
                                        <Field
                                            type="checkbox"
                                            name="nameIndex"
                                            component={InputGroupAntCheckBoxAdapter}
                                            labelText="Generate Name Index"
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Loader>
                        <Row gutter={gutter}>
                            <Col md={24} xs={24}>
                                <Button
                                    id="saveChanges"
                                    className="btn btn-primary pull-right"
                                    htmlType="submit"
                                    type="primary"
                                    disabled={isSaving || pristine}
                                >
                                    Save
                                </Button>
                                <Button
                                    id="cancelChanges"
                                    className="cancel btn btn-default pull-right right-buffer-xs"
                                    htmlType="button"
                                    onClick={form.reset}
                                    disabled={isSaving || pristine}
                                >
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                    </form>
                )
            }
        </Form>
    );
};

export default IndividualSearchesContent;
