/**
 * Created by oleg on 11/25/16.
 */
import React, { useEffect } from 'react';
import NavigationMenu from '@src/containers/registration/components/navigation/NavigationMenu';
import PageHeader from '@src/containers/registration/components/utils/PageHeader';
import RegistrationContentContainer from '@src/containers/registration/components/RegistrationContentContainer';
import ReactGA from 'react-ga4';

const trackingId = process.env.GOOGLE_ANALYTICS_MEASUREMENT_ID__REGISTRATION;
const eventId = process.env.GOOGLE_ANALYTICS_MEASUREMENT_EVENT_ID__REGISTRATION;

const Registration = () => {
    useEffect(() => {
        if (trackingId && eventId) {
            ReactGA.gtag('config', trackingId);
            ReactGA.gtag('event', 'conversion', { send_to: `${trackingId}/${eventId}` });

            return () => {
                ReactGA.gtag('config', trackingId, { send_page_view: false });
            };
        }
        return () => {};
    }, []);

    return (
        <div id="registrationContent">
            <PageHeader pageName="Register New Account" />
            <div className="content">
                <NavigationMenu />
                <RegistrationContentContainer />
            </div>
        </div>
    );
};

export default Registration;
