import React from 'react';
import {
    useSelector,
    useDispatch,
} from 'react-redux';
import { Button, Collapse, List } from 'antd';
import {
    CloseOutlined,
    DownloadOutlined,
    CheckOutlined,
    AlertOutlined,
    SyncOutlined,
} from '@ant-design/icons';
import {
    getCombiningReports,
} from '@src/store/reducers/reports';
import {
    deleteAllCombiningReports,
    deleteCombiningReports,
} from '@src/store/actions/reports';
import { generateProcessName } from '@src/utils/helpers/reports';
import { combiningStatuses } from '@src/utils/templates/reportType';

const { processing, completed, error } = combiningStatuses;

const getStatusIcon = status => {
    switch (status) {
        case processing:
            return <SyncOutlined spin />;
        case completed:
            return <CheckOutlined />;
        case error:
            return <AlertOutlined />;
        default:
            return null;
    }
};

const CombiningFilesBarContent = () => {
    const dispatch = useDispatch();
    const combiningReports = useSelector(state => getCombiningReports(state));
    const files = Object.values(combiningReports);

    return (
        files.length
            ? (
                <div id="combiningProcessBlock" className="affix">
                    <Collapse
                        defaultActiveKey={['1']}
                    >
                        <Collapse.Panel
                            header="Combining Files"
                            key="1"
                            extra={(
                                <CloseOutlined
                                    onClick={event => {
                                        event.stopPropagation();
                                        dispatch(deleteAllCombiningReports());
                                    }}
                                />
                            )}
                        >
                            <List
                                itemLayout="horizontal"
                                dataSource={Object.values(files)}
                                renderItem={({ ids, status, url }) => (
                                    <List.Item
                                        actions={
                                            url
                                                ? [
                                                    <Button
                                                        icon={<DownloadOutlined />}
                                                        type="link"
                                                        href={url}
                                                        key="list-download"
                                                        target="_blank"
                                                    />,
                                                    <Button
                                                        icon={<CloseOutlined />}
                                                        onClick={() => dispatch(deleteCombiningReports(ids))}
                                                        key="list-delete"
                                                        type="link"
                                                    />,
                                                ]
                                                : []
                                        }
                                    >
                                        <span className={status}>
                                            {getStatusIcon(status)}
                                            &nbsp;
                                            {generateProcessName(ids)}
                                        </span>
                                    </List.Item>
                                )}
                            />
                        </Collapse.Panel>
                    </Collapse>
                </div>
            )
            : null
    );
};

export default CombiningFilesBarContent;
