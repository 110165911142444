import React from 'react';
import { Table, Button } from 'antd';
import Loader from '@src/components/Loader';
import OrderInfoBlock from '@src/components/orders/OrderInfoBlock';
import OrderConformationMessage from '@src/components/orders/OrderConfirmationMessage';

const OrderConfirmation = ({
    setNextStep,
    downLoadConfirmation,
    isPostingOrder,
    postedOrderError,
    order,
    orderInformation,
    contactInformation,
    deliveryInformation,
    mainInformation,
    ordersList,
}) => (
    <>
        <div className="contentHeader text-center">
            <h1>Order Confirmation</h1>
        </div>
        <Loader show={isPostingOrder} className="order-confirmation-loader">
            {
                postedOrderError
                    ? (
                        <div className="error-section">
                            <div className="error">{postedOrderError.message}</div>
                            {
                                postedOrderError.response && postedOrderError.response.data
                                && <div className="error">{postedOrderError.response.data}</div>
                            }
                        </div>
                    )
                    : (
                        <>
                            <div className="formBlock">
                                <OrderConformationMessage orderId={order.orderId} />
                            </div>
                            <div className="formBlock">
                                <OrderInfoBlock
                                    title={orderInformation.title}
                                    subTitle={orderInformation.subTitle}
                                    content={orderInformation.content}
                                />
                            </div>
                            <div className="formBlock">
                                <OrderInfoBlock
                                    title={contactInformation.title}
                                    subTitle={contactInformation.subTitle}
                                    content={contactInformation.content}
                                    hideEmpty
                                />
                            </div>
                            <div className="formBlock">
                                <OrderInfoBlock
                                    title={deliveryInformation.title}
                                    subTitle={deliveryInformation.subTitle}
                                    content={deliveryInformation.content}
                                    hideEmpty
                                />
                            </div>
                            {
                                mainInformation
                                && (
                                    <div className="formBlock">
                                        <OrderInfoBlock
                                            title={mainInformation.title}
                                            subTitle={mainInformation.subTitle}
                                            content={mainInformation.content}
                                        />
                                    </div>
                                )
                            }
                            {
                                ordersList
                                && (
                                    <div className="formBlock" id="reportsTable">
                                        <Table
                                            columns={ordersList.columns}
                                            dataSource={ordersList.data}
                                            size="small"
                                            pagination={false}
                                            rowKey={({ requestNumber }) => requestNumber}
                                        />
                                    </div>
                                )
                            }
                        </>
                    )
            }
            <Button
                id="nextOrderButton"
                htmlType="button"
                type="primary"
                className="btn btn-primary pull-right"
                onClick={setNextStep}
            >
                Next Order
            </Button>
            <Button
                id="edit-order"
                className="btn btn-default pull-right right-buffer-xs"
                htmlType="button"
                disabled={!order.orderId}
                onClick={() => downLoadConfirmation(order.orderId)}
            >
                Download Confirmation
            </Button>
        </Loader>
    </>
);

export default OrderConfirmation;
