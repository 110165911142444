/**
 * Created by oleg on 11/30/16.
 */
import React from 'react';
import { Field } from 'react-final-form';
import { Col, Row } from 'antd';
import InputGroupAntInputAdapter from '@src/components/input/InputGroupAntInputAdapter';
import {
    composeValidators, email, maxLength, required,
} from '@src/utils/helpers/validationRules';
import Address from '@src/containers/registration/components/utils/Address';
import { gutter } from '@src/utils/helpers/orders';

const BillingInformationContent = () => (
    <div className="billingInformation">
        <Address formSectionName="billingInformation" />
        <Row gutter={gutter}>
            <Col md={12} xs={24} className="top-buffer">
                <Field
                    name="billingInformation.email"
                    component={InputGroupAntInputAdapter}
                    controlId="billingInformation.email"
                    isRequired
                    labelText="Email"
                    validate={composeValidators(required, email, maxLength(70))}
                />
            </Col>
        </Row>
    </div>
);

export default BillingInformationContent;
