import {
    FETCH_USERS, FETCH_USERS_SUCCESS, FETCH_USERS_FAIL,
    FETCH_CURRENT_USER, FETCH_CURRENT_USER_SUCCESS, FETCH_CURRENT_USER_FAIL,
    UPDATE_USER_SUCCESS, CREATE_USER_SUCCESS, UPDATE_USER_ACTIVE_STATE_SUCCESS, UPDATE_USER_ADMIN_STATE_SUCCESS,
    RESET_CURRENT_USER,
} from '../../actions/userMaintanance';

const initialState = {
    fetching: false,
    users: [],
    fetchingCurrentUser: false,
    currentUser: {},
    isAuthUser: null,
    error: null,
};

const users = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_USERS:
            return { ...state, fetching: true };
        case FETCH_USERS_SUCCESS:
            return { ...state, fetching: false, users: action.response };
        case FETCH_USERS_FAIL:
            return { ...state, fetching: false, error: action.payload };
        case FETCH_CURRENT_USER:
            return { ...state, fetchingCurrentUser: true };
        case FETCH_CURRENT_USER_SUCCESS:
            return {
                ...state,
                fetchingCurrentUser: false,
                currentUser: action.response,
                isAuthUser: true,
            };
        case FETCH_CURRENT_USER_FAIL:
            return {
                ...state,
                fetchingCurrentUser: false,
                error: action.payload,
                isAuthUser: false,
            };
        case RESET_CURRENT_USER:
            return { ...state, currentUser: {}, isAuthUser: false };
        case UPDATE_USER_SUCCESS: {
            const user = action.response;
            const updatedUsers = state.users.map(item => {
                if (item.username === user.username) {
                    return user;
                }
                return item;
            });

            return { ...state, users: updatedUsers };
        }

        case UPDATE_USER_ACTIVE_STATE_SUCCESS:
            return {
                ...state,
                users: state.users.map(item => {
                    if (item.username === action.username) {
                        return { ...item, enabled: !item.enabled };
                    }

                    return item;
                }),
            };

        case UPDATE_USER_ADMIN_STATE_SUCCESS:
            return {
                ...state,
                users: state.users.map(item => {
                    if (item.username === action.username) {
                        return { ...item, admin: !item.admin };
                    }

                    return item;
                }),
            };
        case CREATE_USER_SUCCESS:
            return { ...state, users: [...state.users, action.response] };
        default:
            return state;
    }
};

export const getUsers = state => state.users.users;
export const isUsersFetching = state => state.users.fetching;
export const getCurrentUser = state => state.users.currentUser;
export const getCurrentUserFetching = state => state.users.fetchingCurrentUser;
export const getIsAuthUser = state => state.users.isAuthUser;

export default users;
