import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
    Tag, Button, Dropdown, Menu, Space,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { multiEmailSplitter } from '@src/utils/helpers/regexps';
import { performUserAction } from '@src/store/actions/userMaintanance';

const getEmailsInSeparateTags = (emails = '') => (
    <>
        {
            emails.split(multiEmailSplitter)
                .filter(item => !!item.length)
                .map(item => (
                    <span key={item} className="email-item">
                        {item}
                        ;&nbsp;
                    </span>
                ))
        }
    </>
);

const UserInformation = ({ user }) => {
    const dispatch = useDispatch();
    const userName = `${user.attributes.firstName} ${user.attributes.lastName}`;
    const active = !user.enabled ? <Tag color="#C5100D">DEACTIVATED</Tag> : null;
    const admin = user.admin ? <Tag color="#313c50">ADMIN</Tag> : null;
    const ext = user.attributes.phoneExtension
        ? (
            <div>
                <span className="key text-muted">ext</span>
                <span className="info">{user.attributes.phoneExtension}</span>
            </div>
        )
        : null;
    const notConfirmed = user.status === 'FORCE_CHANGE_PASSWORD';
    const notConfirmedLabel = notConfirmed ? <Tag color="#313c50">Waiting for Confirmation</Tag> : null;

    const activationTitle = !user.enabled ? 'Activate' : 'Deactivate';
    const administrationTitle = !user.admin ? 'Make Admin' : 'Remove Admin';

    const items = [
        {
            label: 'Edit',
            key: 'edit',
        },
        {
            label: activationTitle,
            key: 'switchActive',
        },
        {
            label: administrationTitle,
            key: 'switchAdmin',
        },
    ];

    if (notConfirmed) {
        items.push({
            label: 'Resend Confirmation Code',
            key: 'resendConfirmationCode',
        });
    }

    const menu = (
        <Menu
            onClick={env => (dispatch(performUserAction(env, user)))}
            items={items}
        />
    );

    return (
        <div className="formBlock">
            <div className="userName">
                <span>{userName}</span>
                <Dropdown overlay={menu} id="userAction">
                    <Button>
                        <Space>
                            Actions
                            <DownOutlined />
                        </Space>
                    </Button>
                </Dropdown>
            </div>
            <div className="badges">
                {active}
                {admin}
                {notConfirmedLabel}
            </div>
            <div>
                <span className="key text-muted">username</span>
                <span className="info">
                    <a href="mailto:#">{getEmailsInSeparateTags(user.email)}</a>
                </span>
            </div>
            <div>
                <span className="key text-muted">email</span>
                <span className="info">
                    <a href="mailto:#">{getEmailsInSeparateTags(user.attributes.deliveryEmail)}</a>
                </span>
            </div>
            {ext}
            <div>
                <span className="key text-muted">phone</span>
                <span className="info">{user.attributes.phoneNumber}</span>
            </div>
        </div>
    );
};

UserInformation.propTypes = {
    user: PropTypes.shape({
        admin: PropTypes.bool,
        attributes: {
            firstName: PropTypes.string,
            lastName: PropTypes.string,
            deliveryEmail: PropTypes.string,
            phoneExtension: PropTypes.string,
            phoneNumber: PropTypes.string,
        },
        contactId: PropTypes.number,
        customerId: PropTypes.string,
        email: PropTypes.string,
        emailVerified: PropTypes.bool,
        enabled: PropTypes.bool,
        status: PropTypes.string,
        username: PropTypes.string,
    }),
};

export default UserInformation;
