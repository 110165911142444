import React, { useState, useRef, useEffect } from 'react';
import { Button } from 'antd';
import { Form, Field } from 'react-final-form';
import {
    composeValidators,
    email,
    required,
    maxLength,
} from '@src/utils/helpers/validationRules';
import InputGroupAntInputAdapter from '@src/components/input/InputGroupAntInputAdapter';

const EmailResend = ({ resendHandler, disabled = false }) => {
    const [isVisible, setIsVisible] = useState(false);

    const wrapperRef = useRef(null);

    const handleClickOutside = event => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });

    return (
        <div className="emailResendContainer" ref={wrapperRef}>
            <div className="emailResendBlock">
                <Button
                    disabled={disabled}
                    htmlType="button"
                    onClick={() => setIsVisible(true)}
                    id="resendSelectedTrigger"
                >
                    Resend by Email
                </Button>
                {
                    isVisible
                    && (
                        <div className="emailResendContainer">
                            <div className="emailResendGroup input-group">
                                <Form
                                    onSubmit={data => resendHandler(data.email)}
                                    inline
                                >
                                    {
                                        ({ handleSubmit }) => (
                                            <form onSubmit={handleSubmit}>
                                                <Field
                                                    labelText="Email"
                                                    name="email"
                                                    component={InputGroupAntInputAdapter}
                                                    controlId="resendEmail"
                                                    isRequired
                                                    validate={composeValidators(required, maxLength(70), email)}
                                                />
                                                <span className="input-group-btn">
                                                    <Button
                                                        className="btn btn-primary"
                                                        htmlType="submit"
                                                        type="primary"
                                                        id="resendSelected"
                                                    >
                                                        Send
                                                    </Button>
                                                </span>
                                            </form>
                                        )
                                    }
                                </Form>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default EmailResend;
