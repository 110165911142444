import React from 'react';
import { createRoot } from 'react-dom/client';
import App from '@src/App';
import ReactGA from 'react-ga4';

if (process.env.GOOGLE_ANALYTICS_MEASUREMENT_ID) {
    ReactGA.initialize(process.env.GOOGLE_ANALYTICS_MEASUREMENT_ID);
}

const container = document.getElementById('root');
const root = createRoot(container);

root.render(<App />);
