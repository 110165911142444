import { request, getRequestConfig } from '@src/utils/apiServices/api';
import {
    urlStates, urlMunicipalities,
} from '@src/utils/constants/endpointConstants';

export const fetchAllStates = () => (
    request({
        ...getRequestConfig,
        url: urlStates,
    })
);

export const fetchMunicipalities = () => (
    request({
        ...getRequestConfig,
        url: urlMunicipalities,
    })
);
