import checkUsernameUnique from '@src/utils/apiServices/usernameRequests';
import {
    usernameChangedAction, usernameCheckAction, usernameCheckFailed, usernameCheckSucceeded,
} from './usernameControl';

const usernameUniqueErrorText = 'This email is already in use. Please, enter another one.';

export const performUsernameChanged = () => dispatch => {
    dispatch(usernameChangedAction());
};

export const performUsernameCheck = (values, dispatch) => {
    dispatch(usernameCheckAction());

    return checkUsernameUnique(values.username).then(response => {
        dispatch(usernameCheckSucceeded(response));
        if (!response) {
            // eslint-disable-next-line no-throw-literal
            throw {
                user: { username: usernameUniqueErrorText },
                username: usernameUniqueErrorText,
            };
        }
    }).catch(error => {
        if (error.user.username) {
            throw error;
        }

        dispatch(usernameCheckFailed(error));
    });
};
