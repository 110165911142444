export const USERNAME_CHANGED_ACTION = 'USERNAME_CHANGED_ACTION';
export const USERNAME_CHECK_ACTION = 'USERNAME_CHECK_ACTION';
export const USERNAME_CHECK_SUCCESS = 'USERNAME_CHECK_SUCCESS';
export const USERNAME_CHECK_FAIL = 'USERNAME_CHECK_FAIL';

export const usernameChangedAction = () => ({
    type: USERNAME_CHANGED_ACTION,
});

export const usernameCheckAction = () => ({
    type: USERNAME_CHECK_ACTION,
});

export const usernameCheckSucceeded = response => ({
    type: USERNAME_CHECK_SUCCESS,
    response,
});

export const usernameCheckFailed = error => ({
    type: USERNAME_CHECK_FAIL,
    error,
});
