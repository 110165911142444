import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { postVerificationCode, resetPassword } from '@src/store/actions/authorization/authorization';
import {
    postVerificationCodeProcessing,
    getIsVerificationCodeSent,
    getResetPasswordProcessing,
    getVerificationCodeError,
    getUserName,
    getError,
} from '@src/store/reducers/authorization';
import { defaultPageUrls } from '@src/utils/templates/accountSettings';
import ForgotPasswordForm from './ForgotPasswordForm';
import ResetPasswordForm from './ResetPasswordForm';

const ForgotPasswordContent = () => {
    const dispatch = useDispatch();
    const resetPasswordProcessing = useSelector(getResetPasswordProcessing);
    const verificationCodeProcessing = useSelector(postVerificationCodeProcessing);
    const isVerificationCodeSent = useSelector(getIsVerificationCodeSent);
    const verificationCodeError = useSelector(getVerificationCodeError);
    const error = useSelector(getError);
    const userName = useSelector(getUserName);
    const defaultPage = 'LOGIN';
    const navigate = useNavigate();
    const redirect = () => navigate(`${defaultPageUrls[defaultPage]}/?isPasswordChanged=true`);

    return (
        <div className="col-md-8 col-md-offset-2 col-xs-12">
            <div className="contentHeader"><h1>Forgot Password</h1></div>
            <div className="formBlock">
                {
                    isVerificationCodeSent
                        ? (
                            <ResetPasswordForm
                                onSubmit={data => dispatch(resetPassword(data, redirect))}
                                processing={resetPasswordProcessing}
                                userName={userName}
                                error={error}
                            />
                        )
                        : (
                            <ForgotPasswordForm
                                title="Enter your username"
                                onSubmit={data => dispatch(postVerificationCode(data))}
                                processing={verificationCodeProcessing}
                                error={verificationCodeError}
                            />
                        )
                }
            </div>
        </div>
    );
};

export default ForgotPasswordContent;
