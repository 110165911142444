export const searchTypeOptions = [
    { label: 'Company', value: 'Company' },
    { label: 'Individual', value: 'Individual' },
];

export const searchTypeOptionsShortForm = [
    { label: 'Company', value: 'E' },
    { label: 'Individual', value: 'I' },
];

export const uccSearchTypeOptions = [
    { label: 'Copies of Liens at any Address', value: 'ANY_ADDRESS' },
    { label: 'Copies of Liens at Specific Address Only', value: 'EXACT_ADDRESS' },
];

export const countyOptions = [
    { label: 'Atlantic', value: '1' },
    { label: 'Bergen', value: '2' },
    { label: 'Burlington', value: '3' },
    { label: 'Camden', value: '4' },
    { label: 'Cape May', value: '5' },
    { label: 'Cumberland', value: '6' },
    { label: 'Essex', value: '7' },
    { label: 'Gloucester', value: '8' },
    { label: 'Hudson', value: '9' },
    { label: 'Hunterdon', value: '10' },
    { label: 'Mercer', value: '11' },
    { label: 'Middlesex', value: '12' },
    { label: 'Monmouth', value: '13' },
    { label: 'Morris', value: '14' },
    { label: 'Ocean', value: '15' },
    { label: 'Passaic', value: '16' },
    { label: 'Salem', value: '17' },
    { label: 'Somerset', value: '18' },
    { label: 'Sussex', value: '19' },
    { label: 'Union', value: '20' },
    { label: 'Warren', value: '21' },
];

export const companyTypeOptions = [
    { label: 'Corporation', sectionLabel: 'Corporate', value: 'CRP' },
    { label: 'L.L.C.', sectionLabel: 'L.L.C.', value: 'LLC' },
    { label: 'L.L.P.', sectionLabel: 'L.L.P.', value: 'LLP' },
    { label: 'L.P.', sectionLabel: 'L.P.', value: 'LP' },
    { label: 'G.P.', sectionLabel: 'G.P.', value: 'GP' },
];

export const nameQualifierOptions = [
    { label: '', value: 'SELECT_ONE' },
    { label: 'Trustee', value: 'TRUSTEE' },
    { label: 'Executor', value: 'EXECUTOR' },
    { label: 'Estate Of', value: 'ESTATE_OF' },
    { label: 'Guardian', value: 'GUARDIAN' },
    { label: 'Administrator', value: 'ADMINISTRATOR' },
];

export const nameQualifierOptionsShortForm = [
    { label: '', value: '' },
    { label: 'Trustee', value: 'T' },
    { label: 'Executor', value: 'EX' },
    { label: 'Estate Of', value: 'EO' },
    { label: 'Guardian', value: 'G' },
    { label: 'Administrator', value: 'A' },
];

export const goodStndTypeOptions = [
    { label: 'Regular (Short Form)', value: 'Regular' },
    { label: 'Long Form Listing Charter Documents', value: 'Charter' },
    { label: 'Long Form Listing Officers & Directors', value: 'Officers' },
];

export const copiesOfEntityFilingsTypeOptions = [
    { label: 'All Documents', value: 'allDoc' },
    { label: 'Certificate of Incorporation', value: 'certInc' },
];
