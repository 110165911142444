import React from 'react';
import { Tooltip } from 'antd';
import { NavLink } from 'react-router-dom';

const NavLinkWithTooltip = ({
    isTooltipVisible,
    tooltipText,
    children,
    ...other
}) => (
    isTooltipVisible
        ? (
            <Tooltip
                placement="right"
                title={tooltipText}
            >
                <div>
                    <NavLink {...other}>
                        {children}
                    </NavLink>
                </div>
            </Tooltip>
        )
        : (
            <NavLink {...other}>
                {children}
            </NavLink>
        )
);

export default NavLinkWithTooltip;
