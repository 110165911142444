import { register } from '@src/utils/apiServices/registrationRequests';
import { addErrorAlert } from '@src/store/actions/alerts';
import { registrationSubmit, registrationError, registrationSuccess } from './registrationControl';

const submitRegistration = parameters => dispatch => {
    dispatch(registrationSubmit());

    return register(parameters).then(response => {
        dispatch(registrationSuccess(response));
    }).catch(error => {
        dispatch(registrationError(error));
        dispatch(addErrorAlert('Can not register customer. Please, try again later.', true));
    });
};

export default submitRegistration;
