import React from 'react';
import { Button, Col, Row } from 'antd';
import { gutter } from '@src/utils/helpers/orders';
import { Field, Form } from 'react-final-form';
import createDecorator from 'final-form-focus';
import InputGroupAntInputAdapter from '@src/components/input/InputGroupAntInputAdapter';
import InputGroupHiddenFieldAdapter from '@src/components/input/InputGroupHiddenFieldAdapter';
import {
    composeValidators, equalTo, minLength, required,
} from '@src/utils/helpers/validationRules';
import { leadingWhitespacesNormalizer } from '@src/utils/helpers/normalize';

const focusOnError = createDecorator();

const ResetPasswordForm = ({
    title,
    onSubmit,
    processing,
    userName,
    error,
}) => (
    <>
        {
            title
            && <h3>{title}</h3>
        }
        {
            error
            && <div className="error-section top-buffer">{error}</div>
        }
        <div className="infoCard ">
            <span>Verification code has been sent to your email account.</span>
            <br />
            <br />
            <span>Please follow next instructions to set up new password :</span>
            <div id="passwordRequirements">
                <ul className="list-style-circle">
                    <li><span>length should be at least 8 characters</span></li>
                    <li><span>at least one character in upper case</span></li>
                    <li><span>at least one number</span></li>
                </ul>
            </div>
        </div>
        <Form
            onSubmit={onSubmit}
            decorators={[focusOnError]}
            validate={({ repeatPassword, password }) => ({
                repeatPassword: equalTo(password, 'Enter Password')(repeatPassword),
            })}
        >
            {
                params => {
                    const {
                        handleSubmit,
                        values,
                    } = params;

                    return (
                        <form onSubmit={handleSubmit} id="reset-password-form">
                            <Field
                                name="username"
                                component={InputGroupHiddenFieldAdapter}
                                controlId="username"
                                defaultValue={userName}
                            />
                            <Row gutter={gutter} className="top-buffer">
                                <Col md={24} lg={12}>
                                    <Field
                                        name="verificationCode"
                                        component={InputGroupAntInputAdapter}
                                        controlId="verificationCode"
                                        labelText="Verification Code"
                                        isRequired
                                        validate={composeValidators(
                                            required,
                                        )}
                                        parse={leadingWhitespacesNormalizer}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={gutter}>
                                <Col md={24} lg={12}>
                                    <Field
                                        name="password"
                                        component={InputGroupAntInputAdapter}
                                        controlId="password"
                                        labelText="Enter Password"
                                        isRequired
                                        validate={composeValidators(
                                            required,
                                            minLength(8),
                                        )}
                                        type="password"
                                        parse={leadingWhitespacesNormalizer}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={gutter}>
                                <Col md={24} lg={12}>
                                    <Field
                                        name="repeatPassword"
                                        component={InputGroupAntInputAdapter}
                                        controlId="repeatPassword"
                                        labelText="Repeat Password"
                                        isRequired
                                        validate={composeValidators(
                                            required,
                                            minLength(8),
                                            equalTo(values.password, 'Enter Password'),
                                        )}
                                        type="password"
                                        parse={leadingWhitespacesNormalizer}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={gutter}>
                                <Col md={24} lg={12}>
                                    <Button
                                        className="right-buffer-xs"
                                        htmlType="submit"
                                        type="primary"
                                        loading={processing}
                                        id="reset-password-form--submit-button"
                                    >
                                        Save
                                    </Button>
                                </Col>
                            </Row>
                        </form>
                    );
                }
            }
        </Form>
    </>
);

export default ResetPasswordForm;
