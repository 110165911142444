import { LOOKUP_SUBMIT, LOOKUP_SUCCESS, LOOKUP_ERROR } from '../../actions/jdglookup/jdglookupControl';

const initialState = {
    submitting: false,
};

const jdglookup = (state = initialState, action) => {
    switch (action.type) {
        case LOOKUP_SUBMIT:
            return { ...state, submitting: true };
        case LOOKUP_SUCCESS:
        case LOOKUP_ERROR:
            return { ...state, submitting: false };
        default:
            return state;
    }
};

export const getIsSubmitting = state => state.jdglookup.submitting;

export default jdglookup;
