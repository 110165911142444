import React from 'react';
import { Field, Form } from 'react-final-form';
import createDecorator from 'final-form-focus';
import { Button } from 'antd';
import { v4 as uuid } from 'uuid';
import {
    notEqualNamesForService,
} from '@src/utils/helpers/validationRules';
import OrderInfoBlock from '@src/components/orders/OrderInfoBlock';
import OrderContactBlock from '@src/components/orders/OrderContactBlock';
import OrderDeliveryBlock from '@src/components/orders/OrderDeliveryBlock';
import InputGroupHiddenFieldAdapter from '@src/components/input/InputGroupHiddenFieldAdapter';

const focusOnError = createDecorator();

const OrderPreview = ({
    setPrevStep,
    setNextStep,
    setDeliveryData,
    onSubmitOrder,
    orderInformation,
    deliveryInformation,
    mainInformation,
    serviceData = null,
    header,
    isAccutitleVisible,
    isRushVisible,
    rushOrderLabel,
}) => (
    <>
        <Form
            onSubmit={data => {
                setNextStep();
                onSubmitOrder(data);
            }}
            decorators={[focusOnError]}
            initialValues={deliveryInformation}
            validate={() => {
                if (serviceData) {
                    // This validation is used for name search order
                    const judgmentSearchValidationResult = notEqualNamesForService(serviceData, 'judgmentSearch');
                    const result = {
                        judgmentSearchNames: judgmentSearchValidationResult
                            && (
                                <>
                                    <p>{judgmentSearchValidationResult}</p>
                                    <h5>
                                        <i>
                                            {
                                                `Note, you could use the same name for
                                                Judgment search with different qualifiers.`
                                            }
                                        </i>
                                    </h5>
                                </>
                            ),
                        childSupportSearchNames: notEqualNamesForService(serviceData, 'childSupportSearch'),
                    };

                    return result;
                }
                return {};
            }}
        >
            {
                ({
                    handleSubmit, values,
                }) => (
                    <form onSubmit={handleSubmit}>
                        {
                            header
                            && (
                                <div className="contentHeader text-center">
                                    <h1>{header}</h1>
                                </div>
                            )
                        }
                        <Field
                            name="judgmentSearchNames"
                            component={InputGroupHiddenFieldAdapter}
                            controlId="judgmentSearchNames"
                        />
                        <Field
                            name="childSupportSearchNames"
                            component={InputGroupHiddenFieldAdapter}
                            controlId="childSupportSearchNames"
                        />
                        {
                            orderInformation
                            && (
                                <div className="formBlock">
                                    <OrderInfoBlock
                                        title={orderInformation.title}
                                        subTitle={orderInformation.subTitle}
                                        content={orderInformation.content}
                                    />
                                </div>
                            )
                        }
                        <div className="formBlock">
                            <OrderContactBlock
                                confirmationEmail={deliveryInformation.confirmationEmail}
                                confirmationEmailList={deliveryInformation.confirmationEmailList}
                                contactList={deliveryInformation.contactList}
                            />
                        </div>
                        <div className="formBlock inline-form-block">
                            <OrderDeliveryBlock
                                deliveryPreference={values.deliveryPreference}
                                isAccutitleVisible={isAccutitleVisible}
                                isRushVisible={isRushVisible}
                                rushOrderLabel={rushOrderLabel}
                            />
                        </div>
                        {
                            mainInformation.map(({
                                title,
                                subTitle,
                                content,
                            }) => (
                                <div className="formBlock" key={uuid()}>
                                    <OrderInfoBlock
                                        title={title}
                                        subTitle={subTitle}
                                        content={content}
                                    />
                                </div>
                            ))
                        }
                        <Button
                            id="placeOrderButton"
                            htmlType="submit"
                            type="primary"
                            className="btn btn-primary pull-right"
                        >
                            Place Order
                        </Button>
                        <Button
                            id="edit-order"
                            className="btn btn-default pull-right right-buffer-xs"
                            htmlType="button"
                            onClick={() => {
                                setPrevStep();
                                setDeliveryData(values);
                            }}
                        >
                            Edit Order
                        </Button>
                    </form>
                )
            }
        </Form>
    </>
);

export default OrderPreview;
