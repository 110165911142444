import { reportsTableType, combiningStatuses } from '@src/utils/templates/reportType';
import {
    FETCHING_REPORTS,
    FETCH_REPORTS_SUCCESS,
    FETCH_REPORTS_FAIL,
    FETCHING_REPORT_CHAIN,
    FETCH_REPORT_CHAIN_FAIL,
    FETCH_REPORT_CHAIN_SUCCESS,
    FETCHING_PROPERTY,
    FETCH_PROPERTY_SUCCESS,
    FETCH_PROPERTY_FAIL,
    RESET_PROPERTY,
    RESET_TAX_CONTINUATION_PROPERTY,
    FETCHING_TAX_CONTINUATION_PROPERTY,
    FETCH_TAX_CONTINUATION_PROPERTY_SUCCESS,
    FETCH_TAX_CONTINUATION_PROPERTY_FAIL,
    COMBINING_REPORTS,
    COMBINE_REPORTS_SUCCESS,
    COMBINE_REPORTS_FAIL,
    DELETE_COMBINING_REPORTS,
    DELETE_ALL_COMBINING_REPORTS,
    RESENDING_REPORTS_BY_EMAIL,
    RESEND_REPORTS_BY_EMAIL_SUCCESS,
    RESEND_REPORTS_BY_EMAIL_FAIL,
    REMOVING_REPORTS_FROM_NEW,
    REMOVE_REPORTS_FROM_NEW_SUCCESS,
    REMOVE_REPORTS_FROM_NEW_FAIL,
    SELECT_ALL_JUDGEMENT_CONTINUATION_REPORTS,
    REMOVE_REPORT_BY_ID,
} from '../../actions/reports';

const {
    new: newReports,
    completed: completedReports,
    opened: openedReports,
    judgementContinuation: judgementContinuationReports,
    taxContinuation: taxContinuationReports,
} = reportsTableType;
const initialReports = { data: [] };
const initialJudgementReportChain = {
    namesFromLastRequest: [],
    reportList: [],
    continuationBranchCorrect: true,
};

const getReportTypeKey = type => `${type}Reports`;
const initialState = {
    'fetching': false,
    'combining': false,
    'resending': false,
    'removing': false,
    'canceling': false,
    'error': false,
    'isAllJudgmentContinuationReportsSelected': false,
    [getReportTypeKey(newReports)]: initialReports,
    [getReportTypeKey(completedReports)]: initialReports,
    [getReportTypeKey(openedReports)]: initialReports,
    [getReportTypeKey(judgementContinuationReports)]: initialReports,
    [getReportTypeKey(taxContinuationReports)]: initialReports,
    'combiningReports': {},
    'combinedReports': [],
    'fetchingReportChain': false,
    'judgementReportChain': initialJudgementReportChain,
    'fetchingProperty': false,
    'property': null,
    'taxContinuationReference': null,
    'taxContinuationCriteria': null,
};

const reports = (state = initialState, action) => {
    switch (action.type) {
        case FETCHING_REPORTS:
            return {
                ...state,
                fetching: !action.isSilent,
                error: false,
            };
        case FETCH_REPORTS_SUCCESS: {
            const dataKeyFetchSuccess = getReportTypeKey(action.reportsType);

            return {
                ...state,
                [dataKeyFetchSuccess]: action.response.data ? action.response : { data: action.response },
                fetching: false,
            };
        }
        case FETCH_REPORTS_FAIL: {
            const dataKeyFetchFail = getReportTypeKey(action.reportsType);

            return {
                ...state,
                fetching: false,
                error: action.error,
                [dataKeyFetchFail]: initialReports,
            };
        }
        case FETCHING_REPORT_CHAIN:
            return {
                ...state,
                fetchingReportChain: true,
                judgementReportChain: initialJudgementReportChain,
                error: false,
            };
        case FETCH_REPORT_CHAIN_SUCCESS:
            return {
                ...state,
                fetchingReportChain: false,
                judgementReportChain: action.response,
            };
        case FETCH_REPORT_CHAIN_FAIL:
            return {
                ...state,
                fetchingReportChain: false,
                error: action.error,
            };
        case FETCHING_PROPERTY:
            return {
                ...state,
                fetchingProperty: true,
                property: null,
                error: false,
            };
        case FETCH_PROPERTY_SUCCESS:
            return {
                ...state,
                fetchingProperty: false,
                property: action.response,
            };
        case FETCH_TAX_CONTINUATION_PROPERTY_FAIL:
        case FETCH_PROPERTY_FAIL:
            return {
                ...state,
                fetchingProperty: false,
                error: action.error,
            };
        case FETCHING_TAX_CONTINUATION_PROPERTY:
            return {
                ...state,
                fetchingProperty: true,
                property: null,
                taxContinuationReference: null,
                taxContinuationCriteria: null,
                error: false,
            };
        case RESET_TAX_CONTINUATION_PROPERTY:
            return {
                ...state,
                property: null,
                taxContinuationReference: null,
                taxContinuationCriteria: null,
                error: false,
            };
        case FETCH_TAX_CONTINUATION_PROPERTY_SUCCESS: {
            const { taxContinuationProperty, taxContinuationCriteria } = action.response;

            return {
                ...state,
                fetchingProperty: false,
                property: taxContinuationProperty ? [taxContinuationProperty] : null,
                taxContinuationCriteria: taxContinuationCriteria || null,
                taxContinuationReference: action.response.reference,
            };
        }
        case RESET_PROPERTY:
            return {
                ...state,
                property: null,
            };
        case COMBINING_REPORTS:
            return {
                ...state,
                combining: action.ids,
                error: false,
                combiningReports: {
                    ...state.combiningReports,
                    [action.ids.join('')]: {
                        ids: action.ids,
                        status: combiningStatuses.processing,
                        url: '',
                    },
                },
            };
        case COMBINE_REPORTS_SUCCESS: {
            const newAllReports = {};
            Object.values(reportsTableType).forEach(type => {
                const dataKeyReports = getReportTypeKey(type);
                newAllReports[dataKeyReports] = {
                    ...state[dataKeyReports],
                    data: state[dataKeyReports].data
                        .filter(report => !action.ids.includes(report.id)),
                };
            });

            return {
                ...state,
                combining: false,
                ...newAllReports,
                combinedReports: [...state.combinedReports, ...action.ids],
                combiningReports: {
                    ...state.combiningReports,
                    [action.ids.join('')]: {
                        ids: action.ids,
                        status: combiningStatuses.completed,
                        url: action.url,
                    },
                },
            };
        }
        case COMBINE_REPORTS_FAIL:
            return {
                ...state,
                combining: false,
                error: action.error,
                combiningReports: {
                    ...state.combiningReports,
                    [action.ids.join('')]: {
                        ids: action.ids,
                        status: combiningStatuses.error,
                        url: '',
                    },
                },
            };
        case DELETE_COMBINING_REPORTS: {
            const updatedCombiningReports = { ...state.combiningReports };

            delete updatedCombiningReports[action.ids.join('')];
            return { ...state, combiningReports: updatedCombiningReports };
        }
        case DELETE_ALL_COMBINING_REPORTS: {
            return { ...state, combiningReports: initialState.combiningReports };
        }
        case RESENDING_REPORTS_BY_EMAIL:
            return {
                ...state,
                resending: true,
                error: false,
            };
        case RESEND_REPORTS_BY_EMAIL_SUCCESS:
            return {
                ...state,
                resending: false,
            };
        case RESEND_REPORTS_BY_EMAIL_FAIL:
            return {
                ...state,
                resending: false,
                error: action.error,
            };
        case REMOVING_REPORTS_FROM_NEW:
            return {
                ...state,
                removing: true,
                error: false,
            };
        case REMOVE_REPORTS_FROM_NEW_SUCCESS: {
            return {
                ...state,
                removing: false,
            };
        }
        case REMOVE_REPORTS_FROM_NEW_FAIL:
            return {
                ...state,
                removing: false,
                error: action.error,
            };
        case SELECT_ALL_JUDGEMENT_CONTINUATION_REPORTS: {
            return {
                ...state,
                isAllJudgmentContinuationReportsSelected: action.isSelected,
            };
        }
        case REMOVE_REPORT_BY_ID: {
            const dataKeyRemoveReport = getReportTypeKey(action.reportsType);

            return {
                ...state,
                [dataKeyRemoveReport]: {
                    ...state[dataKeyRemoveReport],
                    data: state[dataKeyRemoveReport].data.filter(report => report.id !== action.id),
                },
            };
        }
        default:
            return state;
    }
};

export const getFetching = state => state.reports.fetching;
export const getCombining = state => state.reports.combining;
export const getResending = state => state.reports.resending;
export const getRemoving = state => state.reports.removing;
export const getCanceling = state => state.reports.canceling;
export const getError = state => state.reports.error;
export const getNewReports = state => state.reports[getReportTypeKey(newReports)].data;
export const getCompletedReports = state => state.reports[getReportTypeKey(completedReports)];
export const getOpenReports = state => state.reports[getReportTypeKey(openedReports)];
export const getCombinedReports = state => state.reports.combinedReports;
export const getCombiningReports = state => state.reports.combiningReports;
export const getJudgmentContinuationReports = state => state.reports[getReportTypeKey(judgementContinuationReports)];
export const getTaxContinuationReports = state => state.reports[getReportTypeKey(taxContinuationReports)];
export const getFetchingReportChain = state => state.reports.fetchingReportChain;
export const getJudgementReportChain = state => state.reports.judgementReportChain;
export const getFetchingProperty = state => state.reports.fetchingProperty;
export const getProperty = state => state.reports.property;
export const getTaxContinuationReference = state => state.reports.taxContinuationReference;
export const getTaxContinuationCriteria = state => state.reports.taxContinuationCriteria;

export default reports;
