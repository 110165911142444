import {
    POSTING_ORDER,
    POST_ORDER_SUCCESS,
    POST_ORDER_FAIL,
    ORDER_DETAILS_FETCHING_SUCCESS,
    ORDER_DETAILS_FETCHING_FAIL,
    ORDER_DETAILS_FETCHING,
} from '@src/store/actions/orders';
import {
    REMOVE_REPORTS_FROM_NEW_SUCCESS,
    REMOVING_REPORTS_FROM_NEW,
} from '@src/store/actions/reports';

const initialState = {
    posting: false,
    fetching: false,
    error: false,
    orderDetails: {
        reportInfoList: [],
        deliveryPreference: {},
        contactInformation: {},
        orderInformation: {},
        orderId: '',
        referenceNumber: '',
    },
    postedOrder: {
        abstractDetails: {
            absType: '',
            address1: '',
            address2: '',
            block: '',
            city: '',
            claimNo: '',
            comments: '',
            defendantFirstName: '',
            defendantLastName: '',
            docket: '',
            lot: '',
            municipality: '',
            plaintiffFirstName: '',
            plaintiffLastName: '',
            state: '',
            zip: '',
        },
        contactInformation: {
            confirmationEmail: '',
            contactName: '',
        },
        deliveryPreference: {
            accutitle: false,
            additionalEmail: '',
            email: '',
            emailResults: true,
            rushOrder: false,
            serviceResults: false,
            webDelivery: true,
        },
        orderId: '',
        orderType: '',
        referenceNumber: '',
    },
};

const orders = (state = initialState, action) => {
    switch (action.type) {
        case POSTING_ORDER:
            return {
                ...state,
                posting: true,
                error: false,
                postedOrder: initialState.postedOrder,
            };
        case POST_ORDER_SUCCESS: {
            return {
                ...state,
                posting: false,
                postedOrder: action.response,
            };
        }
        case POST_ORDER_FAIL: {
            return {
                ...state,
                posting: false,
                error: action.error,
                postedOrder: initialState.postedOrder,
            };
        }
        case ORDER_DETAILS_FETCHING_SUCCESS:
            return { ...state, fetching: false, orderDetails: action.response };
        case ORDER_DETAILS_FETCHING_FAIL:
            return { ...state, fetching: false, orderDetails: null };
        case ORDER_DETAILS_FETCHING:
            return { ...state, fetching: true };
        case REMOVING_REPORTS_FROM_NEW:
            return { ...state, fetching: true };
        case REMOVE_REPORTS_FROM_NEW_SUCCESS: {
            const newReportInfoList = state.orderDetails.reportInfoList.map(item => {
                if (action.ids.includes(item.requestNumber)) {
                    return { ...item, newReport: false };
                }
                return item;
            });
            const newOrderDetails = { ...state.orderDetails };
            newOrderDetails.reportInfoList = newReportInfoList;
            return { ...state, fetching: false, orderDetails: newOrderDetails };
        }
        default:
            return state;
    }
};

export const getPosting = state => state.orders.posting;
export const getError = state => state.orders.error;
export const getPostedOrder = state => state.orders.postedOrder;
export const getIsFetching = state => state.orders.fetching;
export const getOrderDetails = state => state.orders.orderDetails;

export default orders;
