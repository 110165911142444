import React from 'react';
import { Row, Col } from 'antd';
import moment from 'moment';
import { Field } from 'react-final-form';
import InputGroupAntInputAdapter from '@src/components/input/InputGroupAntInputAdapter';
import InputGroupAntDatePickerAdapter from '@src/components/input/InputGroupAntDatePickerAdapter';
import InputGroupAntCheckBoxAdapter from '@src/components/input/InputGroupAntCheckBoxAdapter';
import InputGroupAntSelectAdapter from '@src/components/input/InputGroupAntSelectAdapter';
import {
    composeValidators,
    email,
} from '@src/utils/helpers/validationRules';

const disabledDate = current => current && current < moment().startOf('day');

const OrderDeliveryBlock = ({
    deliveryPreference: {
        canMakeAccutitle,
        canChangeWebDelivery,
        emailResults,
        availableEmails,
        rushOrder,
    },
    isAccutitleVisible = true,
    isRushVisible = true,
    rushOrderLabel = 'Rush Date',
}) => (
    <>
        <h4>Delivery Preference</h4>
        {
            canMakeAccutitle && isAccutitleVisible
            && (
                <Row gutter={16} className="form-inline">
                    <Col span={24}>
                        <Field
                            name="deliveryPreference.accutitle"
                            component={InputGroupAntCheckBoxAdapter}
                            controlId="deliveryPreference.accutitle"
                            labelText="Accutitle"
                            type="checkbox"
                        />
                    </Col>
                </Row>
            )
        }
        {
            canChangeWebDelivery
            && (
                <Row gutter={16} className="form-inline">
                    <Col span={24}>
                        <Field
                            name="deliveryPreference.webDelivery"
                            component={InputGroupAntCheckBoxAdapter}
                            controlId="deliveryPreference.webDelivery"
                            labelText="Web Delivery"
                            type="checkbox"
                        />
                    </Col>
                </Row>
            )
        }
        <Row gutter={16} className="form-inline">
            <Col md={24} lg={6}>
                <Field
                    name="deliveryPreference.emailResults"
                    component={InputGroupAntCheckBoxAdapter}
                    controlId="deliveryPreference.emailResults"
                    labelText="Email Results"
                    type="checkbox"
                />
            </Col>
            {
                emailResults
                && (
                    <>
                        <Col md={24} lg={9}>
                            <Field
                                name="deliveryPreference.email"
                                component={InputGroupAntSelectAdapter}
                                controlId="deliveryPreference.email"
                                options={availableEmails.map(email => (
                                    { label: email, value: email }
                                ))}
                                dropdownMatchSelectWidth={false}
                                disabled={availableEmails.length < 2}
                            />
                        </Col>
                        <Col md={24} lg={9}>
                            <Field
                                name="deliveryPreference.additionalEmail"
                                component={InputGroupAntInputAdapter}
                                validate={composeValidators(email)}
                                controlId="deliveryPreference.additionalEmail"
                                placeholder="Additional Email"
                            />
                        </Col>
                    </>
                )
            }
        </Row>
        {
            isRushVisible
            && (
                <Row gutter={16} className="form-inline">
                    <Col md={24} lg={6}>
                        <Field
                            name="deliveryPreference.rushOrder"
                            component={InputGroupAntCheckBoxAdapter}
                            controlId="deliveryPreference.rushOrder"
                            labelText={rushOrderLabel}
                            type="checkbox"
                        />
                    </Col>
                    {
                        rushOrder
                        && (
                            <Col md={24} lg={9}>
                                <Field
                                    name="deliveryPreference.rushDate"
                                    component={InputGroupAntDatePickerAdapter}
                                    controlId="deliveryPreference.rushDate"
                                    placeholder="Need by Date"
                                    disabledDate={disabledDate}
                                />
                            </Col>
                        )
                    }
                </Row>
            )
        }
    </>
);

export default OrderDeliveryBlock;
