/* eslint-disable max-len */
import React, { useState } from 'react';
import {
    Row,
    Col,
    Affix,
    Button,
} from 'antd';
import {
    Form,
    Field,
} from 'react-final-form';
import InputGroupAntInputAdapter from '@src/components/input/InputGroupAntInputAdapter';
import InputGroupHiddenFieldAdapter from '@src/components/input/InputGroupHiddenFieldAdapter';
import {
    composeValidators,
    hasPropertiesWithTrue,
    onlyDigits,
    required,
    requiredAtLeastOneField,
    staticLength,
} from '@src/utils/helpers/validationRules';
import { showErrorsIfInvalidOrPerformAction, gutter } from '@src/utils/helpers/orders';
import createDecorator from 'final-form-focus';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import OrderNameOrCompanySection from './OrderNameOrCompanySection';
import OrderNamesMenu from './OrderNamesMenu';
import ServiceUccSearch from './OrderFormSections/ServiceUccSearch';
import ServiceJudgementSearch from './OrderFormSections/ServiceJudgementSearch';
import ServicePatriotSearch from './OrderFormSections/ServicePatriotSearch';
import ServiceChildSupportSearch from './OrderFormSections/ServiceChildSupportSearch';
import ServiceLitigationSearch from './OrderFormSections/ServiceLitigationSearch';
import ServiceCorporateStatus from './OrderFormSections/ServiceCorporateStatus';
import ServiceFranchiseTaxReport from './OrderFormSections/ServiceFranchiseTaxReport';
import ServiceGoodStndSearch from './OrderFormSections/ServiceGoodStndSearch';
import ServiceCopiesOfEntityFiling from './OrderFormSections/ServiceCopiesOfEntityFiling';
import ServiceOffAndDirLetter from './OrderFormSections/ServiceOffAndDirLetter';
import ServiceNameAvailability from './OrderFormSections/ServiceNameAvailability';
import ServiceRegAgentLetter from './OrderFormSections/ServiceRegAgentLetter';

const focusOnError = createDecorator();

const addNewNameToForm = (values, initialFormData, push, setActiveNameIndex) => {
    const {
        nameQualifier: initialNameQualifier,
        exactName: initialExactName,
        addressLine1: initialAddressLine1,
        addressLine2: initialAddressLine2,
        city: initialCity,
        zip: initialZip,
    } = initialFormData.namesToSearch[0].selectedServices.judgmentSearch;
    push(
        'namesToSearch',
        {
            ...values.namesToSearch[values.namesToSearch.length - 1],
            nameSearchCategory: {
                ...initialFormData.namesToSearch[0].nameSearchCategory,
                type: values.namesToSearch[values.namesToSearch.length - 1].nameSearchCategory.type,
                Individual: {
                    ...initialFormData.namesToSearch[0].nameSearchCategory.Individual,
                    lastName: values.namesToSearch[values.namesToSearch.length - 1].nameSearchCategory.Individual.lastName,
                },
            },
            selectedServices: {
                ...values.namesToSearch[values.namesToSearch.length - 1].selectedServices,
                corporateStatus: {
                    ...values.namesToSearch[values.namesToSearch.length - 1].selectedServices.corporateStatus,
                    name: initialFormData.namesToSearch[0].selectedServices.corporateStatus.name,
                },
                judgmentSearch: {
                    ...values.namesToSearch[values.namesToSearch.length - 1].selectedServices.judgmentSearch,
                    nameQualifier: initialNameQualifier,
                    exactName: initialExactName,
                    addressLine1: initialAddressLine1,
                    addressLine2: initialAddressLine2,
                    city: initialCity,
                    zip: initialZip,
                },
                childSupportSearch: {
                    ...values.namesToSearch[values.namesToSearch.length - 1].selectedServices.childSupportSearch,
                    exactName: initialFormData.namesToSearch[0].selectedServices.childSupportSearch.exactName,
                    ssn: initialFormData.namesToSearch[0].selectedServices.childSupportSearch.ssn,
                },
            },
        },
    );
    setActiveNameIndex(values.namesToSearch.length);
};

const OrderNameSearchForm = ({
    setNextStep,
    searchTypeOptions,
    companyTypeOptions,
    uccSearchTypeOptions,
    countyOptions,
    nameQualifierOptions,
    goodStndTypeOptions,
    copiesOfEntityFilingsTypeOptions,
    states,
    formData,
    defaultFormData,
    isReferenceRequired,
    defaultJudgementSearchPeriod,
}) => {
    const [activeNameIndex, setActiveNameIndex] = useState(0);

    return (
        <>
            <div className="contentHeader text-center">
                <h1>Name Search / Corporate Order Form</h1>
            </div>
            <Form
                onSubmit={args => setNextStep(args)}
                decorators={[focusOnError]}
                mutators={{ ...arrayMutators }}
                initialValues={formData}
                validate={({ reference, namesToSearch }) => {
                    const result = { namesToSearch: [] };
                    if (isReferenceRequired) result.reference = required(reference);
                    namesToSearch.map(({
                        selectedServicesTypes,
                        selectedServices: {
                            nameAvailability: { primary, second, last },
                            childSupportSearch: { ssn },
                        },
                        nameSearchCategory: {
                            type,
                            Individual: { firstName, lastName },
                            Company: { companyName },
                        },
                    // eslint-disable-next-line array-callback-return
                    }, index) => {
                        const selectedServicesTypesError = hasPropertiesWithTrue(selectedServicesTypes);
                        result.namesToSearch[index] = {};
                        result.namesToSearch[index].selectedServices = {};
                        result.namesToSearch[index].nameSearchCategory = { [type]: {} };
                        result.namesToSearch[index].selectedServicesTypes = selectedServicesTypesError && <>{selectedServicesTypesError}</>;
                        if (selectedServicesTypes.childSupportSearch) {
                            result.namesToSearch[index].selectedServices.childSupportSearch = {};
                            result.namesToSearch[index].selectedServices.childSupportSearch.ssn = composeValidators(onlyDigits, staticLength(4))(ssn);
                        }
                        switch (type) {
                            case 'Individual':
                                result.namesToSearch[index].nameSearchCategory[type].firstName = required(firstName);
                                result.namesToSearch[index].nameSearchCategory[type].lastName = required(lastName);
                                break;
                            case 'Company':
                                result.namesToSearch[index].nameSearchCategory[type].companyName = required(companyName);
                                if (selectedServicesTypes.nameAvailability) {
                                    result.namesToSearch[index].selectedServices.nameAvailability = {};
                                    result.namesToSearch[index].selectedServices.nameAvailability.primary = requiredAtLeastOneField([primary, second, last]);
                                    result.namesToSearch[index].selectedServices.nameAvailability.second = requiredAtLeastOneField([primary, second, last]);
                                    result.namesToSearch[index].selectedServices.nameAvailability.last = requiredAtLeastOneField([primary, second, last]);
                                }
                                break;
                            default:
                                break;
                        }
                    });

                    return result;
                }}
            >
                {
                    params => {
                        const {
                            handleSubmit,
                            form,
                            form: {
                                mutators: { push },
                                change,
                            },
                            values,
                        } = params;

                        return (
                            <form onSubmit={handleSubmit} className="name-search-form">
                                <div className="formBlock">
                                    <Row gutter={gutter}>
                                        <Col md={24} lg={16}>
                                            <Field
                                                name="reference"
                                                component={InputGroupAntInputAdapter}
                                                controlId="reference"
                                                labelText="Reference Number"
                                                isRequired={isReferenceRequired}
                                                autoFocus={values.namesToSearch.length < 2}
                                                tabIndex="0"
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                {
                                    values.namesToSearch.length > 1
                                    && (
                                        <div className="names-container-wrapper">
                                            <Affix offsetBottom={20} offsetTop={20}>
                                                <div className="names-container formBlock">
                                                    <OrderNamesMenu
                                                        activeItemIndex={activeNameIndex}
                                                        setActiveItemIndex={setActiveNameIndex}
                                                        onRemoveItem={index => change('namesToSearch', values.namesToSearch.filter((_value, i) => i !== index))}
                                                        namesToSearch={values.namesToSearch}
                                                        onSelect={({ key }) => {
                                                            showErrorsIfInvalidOrPerformAction(form, () => {
                                                                if (key !== activeNameIndex) {
                                                                    setActiveNameIndex(+key);
                                                                }
                                                            });
                                                        }}
                                                    />
                                                    <Button
                                                        htmlType="button"
                                                        className="btn btn-default addNameToSearch right-buffer-xs top-buffer-xs"
                                                        onClick={() => {
                                                            showErrorsIfInvalidOrPerformAction(form, () => {
                                                                addNewNameToForm(values, defaultFormData, push, setActiveNameIndex);
                                                            });
                                                        }}
                                                    >
                                                        Add Name to Search
                                                    </Button>
                                                    <Button
                                                        id="preview-order"
                                                        className="btn btn-primary top-buffer-xs"
                                                        type="primary"
                                                        htmlType="submit"
                                                    >
                                                        Preview Order
                                                    </Button>
                                                </div>
                                            </Affix>
                                        </div>
                                    )
                                }
                                <h3>Search</h3>
                                <FieldArray
                                    initialValues={formData.namesToSearch}
                                    name="namesToSearch"
                                >
                                    {({ fields }) => fields.map((name, index) => (
                                        <div key={name} className={index === activeNameIndex ? 'visible' : 'hide'}>
                                            <OrderNameOrCompanySection
                                                name={name}
                                                values={values}
                                                index={index}
                                                gutter={gutter}
                                                companyTypeOptions={companyTypeOptions}
                                                searchTypeOptions={searchTypeOptions}
                                            />
                                            <h3>Services</h3>
                                            <Field
                                                name={`${name}.selectedServicesTypes`}
                                                component={InputGroupHiddenFieldAdapter}
                                                controlId={`${name}.selectedServicesTypes`}
                                            />
                                            {/* this field needs to prevent autocomplete additional states */}
                                            <Field
                                                name="state"
                                                component={InputGroupHiddenFieldAdapter}
                                                controlId="state"
                                                hideMessage
                                            />
                                            <ServiceUccSearch
                                                name={name}
                                                values={values}
                                                index={index}
                                                gutter={gutter}
                                                uccSearchTypeOptions={uccSearchTypeOptions}
                                                countyOptions={countyOptions}
                                                states={states}
                                            />
                                            {
                                                values.namesToSearch[index].nameSearchCategory.type === 'Company'
                                                && (
                                                    <>
                                                        <ServiceCorporateStatus
                                                            name={name}
                                                            values={values}
                                                            index={index}
                                                            gutter={gutter}
                                                            states={states}
                                                        />
                                                        <ServiceFranchiseTaxReport
                                                            name={name}
                                                            values={values}
                                                            index={index}
                                                            gutter={gutter}
                                                            states={states}
                                                        />
                                                        <ServiceGoodStndSearch
                                                            name={name}
                                                            values={values}
                                                            index={index}
                                                            gutter={gutter}
                                                            states={states}
                                                            goodStndTypeOptions={goodStndTypeOptions}
                                                        />
                                                    </>
                                                )
                                            }
                                            {
                                                <>
                                                    <ServiceJudgementSearch
                                                        setFieldValue={form.change}
                                                        name={name}
                                                        values={values}
                                                        index={index}
                                                        gutter={gutter}
                                                        nameQualifierOptions={nameQualifierOptions}
                                                        defaultJudgementSearchPeriod={defaultJudgementSearchPeriod}
                                                        states={states}
                                                    />
                                                    <ServicePatriotSearch
                                                        name={name}
                                                    />
                                                </>
                                            }
                                            {
                                                values.namesToSearch[index].nameSearchCategory.type === 'Individual'
                                                && (
                                                    <ServiceChildSupportSearch
                                                        name={name}
                                                        values={values}
                                                        index={index}
                                                        gutter={gutter}
                                                    />
                                                )
                                            }
                                            {
                                                <ServiceLitigationSearch
                                                    name={name}
                                                    values={values}
                                                    index={index}
                                                    gutter={gutter}
                                                    states={states}
                                                />
                                            }
                                            {
                                                values.namesToSearch[index].nameSearchCategory.type === 'Company'
                                                && (
                                                    <>
                                                        <ServiceCopiesOfEntityFiling
                                                            name={name}
                                                            values={values}
                                                            index={index}
                                                            gutter={gutter}
                                                            states={states}
                                                            copiesOfEntityFilingsTypeOptions={copiesOfEntityFilingsTypeOptions}
                                                        />
                                                        <ServiceRegAgentLetter
                                                            name={name}
                                                        />
                                                        <ServiceOffAndDirLetter
                                                            name={name}
                                                        />
                                                        <ServiceNameAvailability
                                                            name={name}
                                                            values={values}
                                                            index={index}
                                                            gutter={gutter}
                                                            states={states}
                                                        />
                                                    </>
                                                )
                                            }
                                        </div>
                                    ))}
                                </FieldArray>
                                <Button
                                    id="preview-order"
                                    className="btn pull-right"
                                    htmlType="submit"
                                    type="primary"
                                >
                                    Preview Order
                                </Button>
                                <Button
                                    htmlType="button"
                                    id="addNameToSearch"
                                    className="btn btn-default addNameToSearch right-buffer-xs pull-right"
                                    onClick={() => {
                                        showErrorsIfInvalidOrPerformAction(form, () => {
                                            addNewNameToForm(values, defaultFormData, push, setActiveNameIndex);
                                        });
                                    }}
                                >
                                    Add Name to Search
                                </Button>
                            </form>
                        );
                    }
                }
            </Form>
        </>
    );
};

export default OrderNameSearchForm;
