import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { Row, Col, Button } from 'antd';
import createDecorator from 'final-form-focus';
import Loader from '@src/components/Loader';
import { saveAccountSettings, setSettingsContainerTitle } from '@src/store/actions/accountSettings';
import { getPropertySearchSettings, isAccountSettingsSaving } from '@src/store/reducers/accountSettings';
import { PROPERTY_SEARCH_SETTINGS } from '@src/utils/constants/settingsConstants';
import InputGroupAntSelectAdapter from '@src/components/input/InputGroupAntSelectAdapter';
import InputGroupAntCheckBoxAdapter from '@src/components/input/InputGroupAntCheckBoxAdapter';
import { gutter } from '@src/utils/helpers/orders';

const focusOnError = createDecorator();

const floodSearchSupplierOptions = [
    { value: 'NONE', label: 'None' },
    { value: 'PRIOR', label: 'Priority Search Services' },
    { value: 'WEST', label: 'Western Technologies Group' },
];
const taxSearchSupplierOptions = [
    { value: 'NONE', label: 'None' },
    { value: 'PRIOR', label: 'Priority Search Services' },
];
const tidelandSearchSupplierOptions = [
    { value: 'NONE', label: 'None' },
    { value: 'WEST', label: 'Western Technologies Group' },
];
const wetlandsSearchSupplierOptions = [
    { value: 'NONE', label: 'None' },
    { value: 'WEST', label: 'Western Technologies Group' },
];

const PropertySearchesContent = () => {
    const propertySearches = useSelector(state => getPropertySearchSettings(state));
    const isSaving = useSelector(state => isAccountSettingsSaving(state));
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setSettingsContainerTitle('Property Searches'));
    }, []);

    return (
        <Form
            onSubmit={data => dispatch(saveAccountSettings(data, PROPERTY_SEARCH_SETTINGS))}
            initialValues={propertySearches}
            decorators={[focusOnError]}
        >
            {
                ({ handleSubmit, form, pristine }) => (
                    <form onSubmit={handleSubmit}>
                        <Loader show={isSaving}>
                            <div className="formBlock">
                                <Row gutter={gutter}>
                                    <Col md={18} xs={24}>
                                        <Field
                                            name="floodSearchSupplier"
                                            component={InputGroupAntSelectAdapter}
                                            labelText="Flood Search Supplier"
                                            options={floodSearchSupplierOptions}
                                            controlId="floodSearchSupplier"
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={gutter}>
                                    <Col md={18} xs={24}>
                                        <Field
                                            name="wetlandsSearchSupplier"
                                            component={InputGroupAntSelectAdapter}
                                            labelText="Wetlands Search Supplier"
                                            options={wetlandsSearchSupplierOptions}
                                            controlId="wetlandsSearchSupplier"
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={gutter}>
                                    <Col md={18} xs={24}>
                                        <Field
                                            name="taxSearchSupplier"
                                            component={InputGroupAntSelectAdapter}
                                            labelText="Tax Search Supplier"
                                            options={taxSearchSupplierOptions}
                                            controlId="taxSearchSupplier"
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={gutter}>
                                    <Col md={18} xs={24}>
                                        <Field
                                            name="tidelandSearchSupplier"
                                            component={InputGroupAntSelectAdapter}
                                            labelText="Tideland Search Supplier"
                                            options={tidelandSearchSupplierOptions}
                                            controlId="tidelandSearchSupplier"
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={gutter}>
                                    <Col md={24} xs={24}>
                                        <hr />
                                        <span className="form-group">
                                            If subject property is found to have a Tidelands Claim, WTG will
                                            perform a review for prior grants and leases of any claimed area.
                                        </span>
                                    </Col>
                                </Row>
                                <Row gutter={gutter}>
                                    <Col md={24} xs={24}>
                                        <Field
                                            type="checkbox"
                                            name="tideAddlCharge"
                                            component={InputGroupAntCheckBoxAdapter}
                                            labelText="Confirm acceptance of an additional charge for a search involving a Tidelands Claim."
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={gutter}>
                                    <Col md={24} xs={24}>
                                        <Field
                                            type="checkbox"
                                            name="tideNeedGrants"
                                            component={InputGroupAntCheckBoxAdapter}
                                            labelText="WTG can obtain any instruments related to leases or grants on the property at no additional charge."
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Loader>
                        <Row gutter={gutter}>
                            <Col md={24} xs={24}>
                                <Button
                                    id="saveChanges"
                                    className="btn btn-primary pull-right"
                                    type="primary"
                                    htmlType="submit"
                                    disabled={isSaving || pristine}
                                >
                                    Save
                                </Button>

                                <Button
                                    id="cancelChanges"
                                    className="cancel btn btn-default pull-right right-buffer-xs"
                                    htmlType="button"
                                    onClick={form.reset}
                                    disabled={isSaving || pristine}
                                >
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                    </form>
                )
            }
        </Form>
    );
};

export default PropertySearchesContent;
