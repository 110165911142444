import { request, getRequestConfig, postRequestConfig } from '@src/utils/apiServices/api';
import { getCayenneExpression } from '@src/utils/helpers/cayenExpressionAdapter';
import { interpolate } from '@src/utils/helpers/normalize';
import {
    urlJudgmentChain,
    urlReportExportCsv,
    urlReports,
    urlReportsCombine,
    urlReportsRemoveFromNew,
    urlReportsResend,
    urlOrdersPropertySearch,
    urlOrdersTaxContinuationProperty,
} from '@src/utils/constants/endpointConstants';

const getCayenneSorting = sort => (
    sort && sort.length
        ? JSON.stringify(
            sort.map(({ selector, desc }) => (
                {
                    property: selector,
                    direction: desc ? 'DESC' : 'ASC',
                }
            )),
        )
        : null);

export const fetchReports = (
    type,
    includes = [],
    params = {},
) => {
    const {
        filter,
        skip,
        take,
        sort,
    } = params;

    return request({
        ...getRequestConfig,
        params: {
            include: includes,
            start: skip,
            limit: take,
            sort: getCayenneSorting(sort),
            exp: getCayenneExpression(filter),
        },
        url: interpolate(urlReports, type),
    });
};

export const fetchReportChain = id => request({
    ...getRequestConfig,
    url: interpolate(urlJudgmentChain, id),
});

export const fetchProperty = params => request({
    ...postRequestConfig,
    data: params,
    url: urlOrdersPropertySearch,
});

export const fetchTaxContinuationProperty = id => request({
    ...getRequestConfig,
    url: interpolate(urlOrdersTaxContinuationProperty, id),
});

export const exportCsv = (
    filter = {},
    includes = [],
    sort,
    type,
) => request({
    ...getRequestConfig,
    params: {
        include: includes,
        sort: getCayenneSorting(sort),
        exp: getCayenneExpression(filter),
    },
    url: interpolate(urlReportExportCsv, type),
});

export const combineReports = ids => (
    request({
        ...postRequestConfig,
        url: urlReportsCombine,
        data: ids,
    })
);

export const resendReportsByEmail = (reportNumbers, email) => (
    request({
        ...postRequestConfig,
        url: urlReportsResend,
        data: {
            email,
            reportNumbers,
        },
    })
);

export const removeReportsFromNew = ids => (
    request({
        ...postRequestConfig,
        url: urlReportsRemoveFromNew,
        data: ids,
    })
);
