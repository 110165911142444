import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'antd';
import OrderConfirmation from '@src/components/orders/OrderConfirmation';
import OrderPreview from '@src/components/orders/OrderPreview';
import { getAccountDeliverySettings } from '@src/store/actions/accountSettings';
import { postOrder, downloadConfirmation } from '@src/store/actions/orders';
import { getDeliverySettings, isAccountSettingsFetching } from '@src/store/reducers/accountSettings';
import { getPostedOrder, getPosting } from '@src/store/reducers/orders';
import { getCurrentCustomer } from '@src/store/reducers/customers';
import Loader from '@src/components/Loader';
import {
    getContactInformationConfirmation,
    getOrderInformationConfirmation,
    getChanceryDeliveryInformationConfirmation,
} from '@src/utils/helpers/orders';
import OrderChanceryForm from './OrderChanceryForm';

const orderServiceModes = {
    EDIT: 'edit',
    PREVIEW: 'preview',
    CONFIRMATION: 'confirmation',
};

const initialServiceData = {
    addressLine1: '',
    addressLine2: '',
    blockNo: '',
    city: '',
    claimNo: '',
    comments: '',
    defendantFirstName: '',
    defendantLastName: '',
    docket: '',
    lot: '',
    municipalityName: '',
    plaintiffFirstName: '',
    plaintiffLastName: '',
    reference: '',
    searchType: 'FULL_ABSTRACT',
    state: 'NJ',
    zip: '',
};

const serviceOptions = [
    { value: 'FULL_ABSTRACT', label: 'Full Abstract' },
    { value: 'SHORT_FORM_ABSTRACT', label: 'Short Form Abstract' },
    { value: 'CASE_STATUS_REPORT', label: 'Case Status Report' },
    { value: 'COPY_WORK', label: 'Copy Work' },
];

const getOrderInformation = data => (
    {
        content: [[{ label: 'Reference Number', value: data.reference }]],
    }
);

const getMainInformation = data => {
    const plainTiff = `${data.plaintiffFirstName} ${data.plaintiffLastName}`;
    const firstDefendant = `${data.defendantFirstName} ${data.defendantLastName}`;

    return (
        [
            {
                title: 'Chancery Abstract',
                subTitle: serviceOptions.find(option => option.value === data.searchType).label,
                content: [
                    [
                        { label: 'Docket Number', value: data.docket || '' },
                        { label: 'Plaintiff', value: plainTiff },
                        { label: 'First Named Defendant', value: firstDefendant },
                    ],
                    [
                        { label: 'Address', value: data.addressLine1 },
                        { label: '', value: data.addressLine2 },
                        { label: '', value: data.city },
                        { label: '', value: data.zip },
                    ],
                    [
                        { label: 'Lot', value: data.lot },
                        { label: 'Block', value: data.blockNo },
                        { label: 'Mun', value: data.municipalityName },
                        { label: 'Claim or Schedule Number', value: data.claimNo },
                    ],
                    [
                        { label: 'Comments', value: data.comments },
                    ],
                ],
            },
        ]
    );
};

const getMainInformationConfirmation = data => {
    const plainTiff = `${data.abstractDetails.plaintiffFirstName} ${data.abstractDetails.plaintiffLastName}`;
    const firstDefendant = `${data.abstractDetails.defendantFirstName} ${data.abstractDetails.defendantLastName}`;

    return ({
        title: 'Chancery Abstract',
        subTitle: data.abstractDetails.absType,
        content: [
            [
                { label: 'Docket Number', value: data.abstractDetails.docket },
                { label: 'Plaintiff', value: plainTiff },
                { label: 'First Named Defendant', value: firstDefendant },
            ],
            [
                { label: 'Address', value: data.abstractDetails.address1 },
                { label: '', value: data.abstractDetails.address2 },
                { label: '', value: data.abstractDetails.state },
                { label: '', value: data.abstractDetails.city },
                { label: '', value: data.abstractDetails.zip },
            ],
            [
                { label: 'Lot', value: data.abstractDetails.lot },
                { label: 'Block', value: data.abstractDetails.block },
                { label: 'Mun', value: data.abstractDetails.municipality },
                { label: 'Claim or Schedule Number', value: data.abstractDetails.claimNo },
            ],
            [
                { label: 'Comments', value: data.abstractDetails.comments },
            ],
        ],
    });
};

const OrderServiceChanceryContent = () => {
    const { EDIT, PREVIEW, CONFIRMATION } = orderServiceModes;

    const deliveryOptions = useSelector(state => useMemo(() => getDeliverySettings(state), [state]));
    const isDeliverySettingsFetching = useSelector(state => isAccountSettingsFetching(state));
    const isPostingOrder = useSelector(state => getPosting(state));
    const postedOrder = useSelector(state => getPostedOrder(state));
    const { number: currentCustomerId } = useSelector(getCurrentCustomer);

    const [mode, setMode] = useState(EDIT);
    const [serviceData, setServiceData] = useState(initialServiceData);
    const [deliveryData, setDeliveryData] = useState(deliveryOptions);

    const dispatch = useDispatch();

    useEffect(() => {
        if (currentCustomerId) {
            dispatch(getAccountDeliverySettings());
            setServiceData(initialServiceData);
            setMode(EDIT);
        }
    }, [currentCustomerId]);

    useEffect(() => {
        setDeliveryData(deliveryOptions);
    }, [deliveryOptions]);

    return (
        <Loader show={isDeliverySettingsFetching}>
            <Row>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 16, offset: 4 }}>
                    {
                        mode === EDIT
                        && (
                            <OrderChanceryForm
                                setNextStep={data => {
                                    setMode(PREVIEW);
                                    setServiceData({
                                        ...serviceData,
                                        ...data,
                                    });
                                }}
                                serviceOptions={serviceOptions}
                                formData={serviceData}
                                isReferenceRequired={deliveryData.referenceRequired}
                            />
                        )
                    }
                    {
                        mode === PREVIEW
                        && (
                            <OrderPreview
                                setNextStep={() => setMode(CONFIRMATION)}
                                setPrevStep={() => setMode(EDIT)}
                                onSubmitOrder={data => {
                                    setDeliveryData(data);
                                    dispatch(postOrder('abstractSearch', { ...serviceData, ...data }));
                                }}
                                setDeliveryData={setDeliveryData}
                                orderInformation={getOrderInformation(serviceData)}
                                deliveryInformation={deliveryData}
                                mainInformation={getMainInformation(serviceData)}
                                header="Chancery Abstract Order Preview"
                                rushOrderLabel="Due Date"
                            />
                        )
                    }
                    {
                        mode === CONFIRMATION
                        && (
                            <OrderConfirmation
                                setNextStep={() => {
                                    setServiceData(initialServiceData);
                                    setDeliveryData(deliveryOptions);
                                    setMode(EDIT);
                                }}
                                downLoadConfirmation={orderId => dispatch(downloadConfirmation(orderId))}
                                isPostingOrder={isPostingOrder}
                                order={postedOrder}
                                orderInformation={getOrderInformationConfirmation(postedOrder)}
                                contactInformation={getContactInformationConfirmation(postedOrder)}
                                deliveryInformation={getChanceryDeliveryInformationConfirmation(postedOrder)}
                                mainInformation={getMainInformationConfirmation(postedOrder)}
                            />
                        )
                    }
                </Col>
            </Row>
        </Loader>
    );
};

export default OrderServiceChanceryContent;
