import React from 'react';
import { Transfer } from 'antd';
import FormGroup from '@src/components/input/FormGroup';

import { getValidationState } from '@src/utils/helpers/validationRules';

const InputGroupAntTransferAdapter = ({
    input,
    input: { value },
    controlId,
    isRequired,
    labelText,
    meta: { pristine, error, warning },
    ...rest
}) => (
    <FormGroup
        controlId={controlId}
        validationState={getValidationState(!pristine, error, warning)}
        isRequired={isRequired}
        label={labelText}
        error={error}
        warning={warning}
        touched={!pristine}
    >
        <Transfer
            {...input}
            {...rest}
            targetKeys={value}
            render={item => item.title}
            filterOption={(inputValue, option) => option.title.toLowerCase().includes(inputValue.toLowerCase())}
        />
    </FormGroup>
);

export default InputGroupAntTransferAdapter;
