import React from 'react';
import FormGroup from '@src/components/input/FormGroup';
import { v4 as uuid } from 'uuid';
import { getValidationState } from '@src/utils/helpers/validationRules';

const InputGroupHiddenFieldAdapter = ({
    controlId,
    input,
    meta: {
        touched,
        error,
        data: warning,
    },
    hideMessage,
}) => {
    const errorMessage = typeof error === 'string'
        || (typeof error === 'object' && error.type === React.Fragment)
        ? error
        : '';

    return (
        <FormGroup
            className="field-array-error"
            controlId={controlId}
            validationState={getValidationState(touched, error, warning)}
        >
            {
                !hideMessage
                && touched
                && errorMessage
                && (
                    <div className="error-section" id={`error-${controlId}`}>
                        <div className="error">{errorMessage}</div>
                    </div>
                )
            }
            <input
                {...input}
                tabIndex="-1"
                style={{ position: 'absolute', opacity: 0 }}
                autoComplete={uuid()}
            />
        </FormGroup>
    );
};

export default InputGroupHiddenFieldAdapter;
