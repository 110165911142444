import React from 'react';
import { TreeSelect } from 'antd';
import FormGroup from '@src/components/input/FormGroup';

import { getValidationState } from '@src/utils/helpers/validationRules';

const InputGroupAntTreeSelectAdapter = ({
    input: { value, onChange },
    controlId,
    isRequired,
    meta: { touched, error, warning },
    labelText,
    placeholder,
    ...rest
}) => (
    <FormGroup
        controlId={controlId}
        validationState={getValidationState(touched, error, warning)}
        isRequired={isRequired}
        label={labelText}
        error={error}
        warning={warning}
        touched={touched}
    >
        <TreeSelect
            id={controlId}
            treeCheckable
            allowClear
            value={value}
            onChange={onChange}
            treeNodeFilterProp="title"
            {...rest}
            style={{ width: '100%' }}
            dropdownRender={originNode => <div id={`${controlId}_list`}>{originNode}</div>}
        />
    </FormGroup>
);

export default InputGroupAntTreeSelectAdapter;
