/**
 * Created by oleg on 11/29/16.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { gutter } from '@src/utils/helpers/orders';
import { Col, Row } from 'antd';

const ContentHeader = ({ contentName }) => (
    <Row gutter={gutter}>
        <Col span={24}>
            <h3 className="h3 with-text-shadow"><strong>{contentName}</strong></h3>
        </Col>
    </Row>
);

ContentHeader.propType = {
    contentName: PropTypes.string,
};

export default ContentHeader;
