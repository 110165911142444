export const onlyDigits = value => {
    if (!value) {
        return value;
    }
    return value.replace(/[^\d]/g, '');
};

export const parseTemplatesFromArray = (data, string) => {
    if (data && data.length > 0) {
        const templateString = data.map(item => ` %${item}%`);
        return `${string} ${templateString}.`;
    }

    return false;
};

export const getOptions = (data, label, value) => data.map(item => (
    { label: item[label], value: item[value] }
));

export const getLabelByValue = (arr, value) => {
    const option = arr.find(item => value === item.value);

    return option && option.label;
};

export const getValueByLabel = (arr, label) => {
    const option = arr.find(item => label === item.label);

    return option && option.value;
};
