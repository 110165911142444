import React from 'react';
import moment from 'moment';
import { Field } from 'react-final-form';
import { Col, Row } from 'antd';
import { shortDateFormat, disabledDateByActivePeriod } from '@src/utils/helpers/dateUtils';
import { nameQualifierOptionsShortForm } from '@src/utils/templates/orderServicesOptions';
import { getLabelByValue } from '@src/utils/helpers/parse';
import InputGroupAntCheckBoxAdapter from '@src/components/input/InputGroupAntCheckBoxAdapter';
import InputGroupAntDatePickerAdapter from '@src/components/input/InputGroupAntDatePickerAdapter';
import {
    composeValidators,
    required,
} from '@src/utils/helpers/validationRules';

const OldNamesSection = ({
    oldNames,
    defaultJudgementSearchPeriod,
    setFieldValue,
    gutter,
}) => (
    <div className="serviceBody">
        {oldNames.map(({
            continuationSearchBegin,
            originalSearchBegin,
            originalSearchEnd,
            name,
            nameQualifier,
            lastPatriotSearch,
            exactMatch,
            lastOrdered,
        }, index) => (
            <Row gutter={gutter} className="section" key={name}>
                <Col md={24} lg={24}>
                    <div className="section-main-field-wrapper">
                        <Field
                            className={`continuation-old-name-field-${index}`}
                            name={`oldNames.${index}.selectedToContinue`}
                            component={InputGroupAntCheckBoxAdapter}
                            controlId={`oldNames.${index}.selectedToContinue`}
                            labelText={name}
                            type="checkbox"
                        />
                    </div>
                    <div>
                        <dl className="dl-horizontal">
                            <dt>Continuation Search Dates</dt>
                            <dd>
                                <div className="dl-horizontal-with-input">
                                    <div className="continuation-search-from-date-value">
                                        {`${continuationSearchBegin} -`}
                                    </div>
                                    <div>
                                        <Field
                                            name={`oldNames.${index}.continuationSearchEnd`}
                                            component={InputGroupAntDatePickerAdapter}
                                            controlId={`oldNames.${index}.continuationSearchEnd`}
                                            size="small"
                                            disabledDate={current => disabledDateByActivePeriod(
                                                current.startOf('day'),
                                                [
                                                    moment(continuationSearchBegin),
                                                    defaultJudgementSearchPeriod[1],
                                                ],
                                            )}
                                            showToday={false}
                                            validate={composeValidators(required)}
                                            onChange={date => setFieldValue(
                                                `oldNames.${index}.continuationSearchEnd`,
                                                date || defaultJudgementSearchPeriod[1],
                                            )}
                                        />
                                    </div>
                                </div>
                            </dd>
                        </dl>
                    </div>
                    <div>
                        <dl className="dl-horizontal">
                            <dt>Original Search Dates</dt>
                            <dd className="original-search-dates-value">
                                {/* eslint-disable-next-line max-len */}
                                {`${moment(originalSearchBegin).format(shortDateFormat)} - ${moment(originalSearchEnd).format(shortDateFormat)}`}
                            </dd>
                        </dl>
                    </div>
                    <div>
                        <dl className="dl-horizontal">
                            <dt>Exact Match</dt>
                            <dd className="exact-match-value">{exactMatch ? 'YES' : 'NO'}</dd>
                        </dl>
                    </div>
                    {
                        nameQualifier
                        && (
                            <div>
                                <dl className="dl-horizontal">
                                    <dt>Name Qualifier</dt>
                                    <dd className="name-qualifier-value">
                                        {getLabelByValue(nameQualifierOptionsShortForm, nameQualifier)}
                                    </dd>
                                </dl>
                            </div>
                        )
                    }
                    <div>
                        <dl className="dl-horizontal">
                            <dt>Last Patriot Search</dt>
                            <dd className="last-patriot-search-value">{lastPatriotSearch ? 'YES' : 'NO'}</dd>
                        </dl>
                    </div>
                    <div>
                        <dl className="dl-horizontal">
                            <dt>Last Ordered</dt>
                            <dd className="last-ordered-value">{lastOrdered}</dd>
                        </dl>
                    </div>
                </Col>
            </Row>
        ))}
    </div>
);

export default OldNamesSection;
