import React from 'react';
import { Field, Form } from 'react-final-form';
import { Button, Col, Row } from 'antd';
import createDecorator from 'final-form-focus';
import InputGroupAntInputAdapter from '@src/components/input/InputGroupAntInputAdapter';
import InputGroupHiddenFieldAdapter from '@src/components/input/InputGroupHiddenFieldAdapter';
import PasswordRequirements from '@src/components/PasswordRequirements';
import {
    composeValidators, equalTo,
    minLength,
    required,
} from '@src/utils/helpers/validationRules';
import { gutter } from '@src/utils/helpers/orders';

const focusOnError = createDecorator();

const NewPasswordForm = ({
    title,
    onSubmit,
    processing,
    userName,
    sessionId,
}) => (
    <>
        {
            title
            && <h3>{title}</h3>
        }
        <>
            <PasswordRequirements />
            <Form
                onSubmit={onSubmit}
                decorators={[focusOnError]}
                validate={({ repeatPassword, password }) => ({
                    repeatPassword: equalTo(password, 'Enter Password')(repeatPassword),
                })}
            >
                {
                    params => {
                        const {
                            handleSubmit,
                            values,
                        } = params;

                        return (
                            <form onSubmit={handleSubmit} id="new-password-form">
                                <Field
                                    name="username"
                                    component={InputGroupHiddenFieldAdapter}
                                    controlId="username"
                                    defaultValue={userName}
                                />
                                <Field
                                    name="sessionId"
                                    component={InputGroupHiddenFieldAdapter}
                                    controlId="sessionId"
                                    defaultValue={sessionId}
                                />
                                <Row gutter={gutter} className="top-buffer">
                                    <Col md={24} lg={12}>
                                        <Field
                                            name="password"
                                            component={InputGroupAntInputAdapter}
                                            controlId="password"
                                            labelText="Enter Password"
                                            isRequired
                                            validate={composeValidators(
                                                required,
                                                minLength(8),
                                            )}
                                            type="password"
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={gutter}>
                                    <Col md={24} lg={12}>
                                        <Field
                                            name="repeatPassword"
                                            component={InputGroupAntInputAdapter}
                                            controlId="repeatPassword"
                                            labelText="Repeat Password"
                                            isRequired
                                            validate={composeValidators(
                                                required,
                                                minLength(8),
                                                equalTo(values.password, 'Enter Password'),
                                            )}
                                            type="password"
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={gutter}>
                                    <Col md={24} lg={12}>
                                        <Button
                                            className="right-buffer-xs"
                                            htmlType="submit"
                                            type="primary"
                                            loading={processing}
                                            id="new-password-form--submit-button"
                                        >
                                            Save
                                        </Button>
                                    </Col>
                                </Row>
                            </form>
                        );
                    }
                }
            </Form>
        </>
    </>
);

export default NewPasswordForm;
