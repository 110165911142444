import React from 'react';
import {
    required,
    maxLength,
    phoneNumber,
    email,
    composeValidators,
    requiredAtLeastOneInArray,
    multiEmailTags,
    maxLengthForTags,
} from '@src/utils/helpers/validationRules';
import { uniqueUserName } from '@src/utils/helpers/validationRulesAsync';
import InputGroupAntTagBoxAdapter from '@src/components/input/InputGroupAntTagBoxAdapter';
import { emailsJoiner, emailsSeparators } from '@src/utils/constants/formConstants';
import { Col, Row } from 'antd';
import { Field } from 'react-final-form';
import { phone, leadingWhitespacesNormalizer } from '@src/utils/helpers/normalize';
import InputGroupAntInputAdapter from '@src/components/input/InputGroupAntInputAdapter';
import { gutter } from '@src/utils/helpers/orders';

const requiredMaxLength25 = composeValidators(required, maxLength(25));
const requiredEmailUniqueMaxLength150 = composeValidators(required, email, uniqueUserName, maxLength(150));
const maxLength10 = composeValidators(maxLength(10));
const requiredMultiEmailMaxLength70 = composeValidators(
    requiredAtLeastOneInArray,
    multiEmailTags,
    maxLengthForTags(70, emailsJoiner),
);

const EditUserContent = ({ isNewUser }) => (
    <>
        <Row gutter={gutter}>
            <Col md={24} xs={24}>
                <Field
                    name="attributes.firstName"
                    component={InputGroupAntInputAdapter}
                    controlId="userFirstName"
                    isRequired
                    labelText="First Name"
                    validate={requiredMaxLength25}
                    autoFocus
                />
            </Col>
        </Row>
        <Row className={isNewUser ? '' : 'last-row'}>
            <Col md={24} xs={24}>
                <Field
                    name="attributes.lastName"
                    component={InputGroupAntInputAdapter}
                    controlId="userLastName"
                    isRequired
                    labelText="Last Name"
                    validate={requiredMaxLength25}
                />
            </Col>
        </Row>
        {
            isNewUser
                ? (
                    <>
                        <Row className="last-row">
                            <Col md={24} xs={24}>
                                <Field
                                    name="username"
                                    component={InputGroupAntInputAdapter}
                                    controlId="username"
                                    isRequired
                                    labelText="Email"
                                    validate={requiredEmailUniqueMaxLength150}
                                    parse={leadingWhitespacesNormalizer}
                                />
                            </Col>
                        </Row>
                        <hr />
                    </>
                )
                : (
                    <>
                        <hr />
                        <Row>
                            <Col md={24} xs={24}>
                                <Field
                                    name="attributes.deliveryEmail"
                                    controlId="deliveryEmail"
                                    labelText="Delivery Email"
                                    component={InputGroupAntTagBoxAdapter}
                                    tokenSeparators={emailsSeparators}
                                    validate={requiredMultiEmailMaxLength70}
                                    isRequired
                                />
                            </Col>
                        </Row>
                    </>
                )
        }
        <Row className="last-row" gutter={gutter}>
            <Col md={16} xs={24}>
                <Field
                    name="attributes.phoneNumber"
                    component={InputGroupAntInputAdapter}
                    controlId="phone"
                    isRequired
                    labelText="Phone"
                    validate={composeValidators(required, phoneNumber)}
                    format={phone}
                    parse={phone}
                />
            </Col>
            <Col md={8} xs={24}>
                <Field
                    name="attributes.phoneExtension"
                    component={InputGroupAntInputAdapter}
                    controlId="ext"
                    labelText="ext"
                    validate={maxLength10}
                />
            </Col>
        </Row>
    </>
);

export default EditUserContent;
