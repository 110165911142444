/**
 * Created by oleg on 7/10/17.
 */
import React from 'react';
import { connect } from 'react-redux';
import { getAlerts } from '@src/store/reducers/alerts';
import { removeAlert } from '@src/store/actions/alerts';
import AlertMessage from './AlertMessage';

const AlertsContent = ({ alerts, removeAlert }) => {
    const toAlertMessage = alert => (
        <AlertMessage
            alertType={alert.alertType}
            message={alert.message}
            removeAlert={removeAlert}
            autoClosable={alert.autoClosable}
            alertId={alert.alertId}
            key={alert.alertId}
        />
    );

    return (
        <div id="systemMessageContainer">
            <div id="alertContainer">
                {alerts.map(toAlertMessage)}
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    alerts: getAlerts(state),
});

const mapDispatchToProps = {
    removeAlert,
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertsContent);
