import React from 'react';
import { InfoCircleFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';

const InfoLink = ({ text, href, tooltip }) => (
    <>
        {text}
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <a href={href} target="_blank" rel="noreferrer">
            <Tooltip title={tooltip}>
                <InfoCircleFilled />
            </Tooltip>
        </a>
    </>
);

export default InfoLink;
