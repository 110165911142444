import React, { useEffect } from 'react';
import {
    useSelector,
    useDispatch,
} from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Button, Dropdown, Badge } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import {
    getNewReports,
} from '@src/store/reducers/reports';
import { getCurrentCustomer } from '@src/store/reducers/customers';
import {
    fetchReports,
    combineReports,
} from '@src/store/actions/reports';
import { reportsTableType } from '@src/utils/templates/reportType';
import {
    ACCOUNT_VIEW_MY_ORDERS_NEW_REPORTS,
} from '@src/utils/constants/routerConstants';
import {
    getNumberOfSelectedPages,
    getIsSelectedReportsPagesExceeded,
} from '@src/utils/helpers/reports';
import NewReportsBarTable from '@src/containers/newReportsBar/NewReportsBarTable';

const reportsType = reportsTableType.new;

const NewReportsBarContent = () => {
    const dispatch = useDispatch();
    const reports = useSelector(getNewReports);
    const { number: currentCustomerId } = useSelector(getCurrentCustomer);

    const isSelectedReportsPagesExceeded = getIsSelectedReportsPagesExceeded(getNumberOfSelectedPages(reports));

    useEffect(() => {
        const fetchData = () => dispatch(fetchReports(reportsType, [], {}, true));
        const interval = setInterval(fetchData, 60000);
        if (currentCustomerId) {
            fetchData();
        }

        return () => clearInterval(interval);
    }, [currentCustomerId]);

    return (
        <>
            <Dropdown
                overlay={(
                    <div className="menu-dropdown newReportBlock" id="newReportBlock">
                        <div className="detailsNameBlock">
                            <span className="detailsName">
                                <NavLink
                                    id="newReportsBarLink"
                                    to={ACCOUNT_VIEW_MY_ORDERS_NEW_REPORTS}
                                    activeClassName="selected"
                                >
                                    New Reports
                                </NavLink>
                            </span>
                        </div>
                        {
                            reports.length
                                ? (
                                    <div className="detailsTableBlock">
                                        <NewReportsBarTable reports={reports} />
                                        <div className="buttonsBlock">
                                            <Button
                                                className="btn btn-default"
                                                id="combineAllBar"
                                                htmlType="button"
                                                onClick={() => dispatch(combineReports(reports.map(({ id }) => id)))}
                                                disabled={isSelectedReportsPagesExceeded}
                                            >
                                                Combine All
                                            </Button>
                                        </div>
                                    </div>
                                )
                                : (
                                    <div className="detailsInfoBlock"><span>You don't have any new requests yet.</span></div>
                                )
                        }
                    </div>
                )}
                placement="bottomCenter"
                trigger={['click', 'hover']}
            >
                <div className="newReportsBar" id="newReportsBar">
                    <Badge count={reports.length}>
                        <Button type="link" icon={<InboxOutlined />} />
                    </Badge>
                </div>
            </Dropdown>
        </>
    );
};

export default NewReportsBarContent;
