import React from 'react';
import { Input } from 'antd';
import { Field } from 'react-final-form';
import InputGroupAntSelectAdapter from '@src/components/input/InputGroupAntSelectAdapter';
import { matchTypeOptionsDefault } from '@src/utils/helpers/devExpressHelpers';

const InputGroupWithMatchTypeChooser = ({
    fieldName,
    labelText,
    component,
    matchTypeOptions = matchTypeOptionsDefault,
    virtual,
    ...rest
}) => (
    <Input.Group compact className="input-group-with-match-type-chooser">
        <Field
            {...rest}
            name={`${fieldName}.value`}
            key={`${fieldName}.value`}
            component={component}
            controlId={`${fieldName}.value`}
            virtual={virtual}
        />
        <Field
            name={`${fieldName}.matchType`}
            key={`${fieldName}.matchType`}
            component={InputGroupAntSelectAdapter}
            controlId={`${fieldName}.matchType`}
            options={
                matchTypeOptions.map(({ label, icon, value }) => (
                    {
                        value,
                        label: (
                            <span className="match-type-chooser-option-wrapper" key={value}>
                                <i className={`dx-icon-${icon}`} />
                                <span>{label}</span>
                            </span>
                        ),
                    }
                ))
            }
            labelText={labelText}
            showArrow={false}
            dropdownMatchSelectWidth={false}
            virtual={virtual}
        />
    </Input.Group>
);

export default InputGroupWithMatchTypeChooser;
