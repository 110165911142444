import React, { useMemo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Tour from 'reactour';
import { Button } from 'antd';
import { InfoOutlined } from '@ant-design/icons';
import {
    getFetching,
    getNewReports,
    getRemoving,
} from '@src/store/reducers/reports';
import {
    fetchReports,
    combineReports,
    resendReportsByEmail,
    removeReportsFromNew,
} from '@src/store/actions/reports';
import { getCurrentCustomer } from '@src/store/reducers/customers';
import { getCurrentUser } from '@src/store/reducers/userMaintanance';
import { dxNewReportsTourSteps, defaultTourSettings } from '@src/utils/constants/tourSettings';
import {
    getNumberOfSelectedPages,
    getIsSelectedReportsPagesExceeded,
    pagesLimit,
    getShouldRemoveFromNew,
} from '@src/utils/helpers/reports';
import { reportsTableType } from '@src/utils/templates/reportType';
import Loader from '@src/components/Loader';
import ReportsActionsBlock from '@src/components/ReportsActionsBlock';
import NewReportsTable from './NewReportsTable';

const reportsType = reportsTableType.new;

const selectionChangedHandler = (selectedRowsData, setSelectedReports, selectedRowKeys, setSelectedReportsPages) => {
    const numberOfSelectedPages = getNumberOfSelectedPages(selectedRowsData);
    setSelectedReports(selectedRowKeys);
    setSelectedReportsPages(numberOfSelectedPages);
};

const NewReportsContent = () => {
    const isLoading = useSelector(state => getFetching(state));
    const isRemoving = useSelector(state => getRemoving(state));
    const reports = useSelector(state => getNewReports(state));
    const { defaultCustomer, groupCode } = useSelector(getCurrentCustomer);
    const { customerGroupCode } = useSelector(getCurrentUser);

    const [selectedReports, setSelectedReports] = useState([]);
    const [selectedReportsPages, setSelectedReportsPages] = useState(0);
    const [isTourOpen, setIsTourOpen] = useState(false);
    const dispatch = useDispatch();

    const newReportsTable = useMemo(() => (
        <NewReportsTable
            data={reports}
            onSelectionChanged={({ selectedRowKeys, selectedRowsData }) => {
                selectionChangedHandler(selectedRowsData, setSelectedReports, selectedRowKeys, setSelectedReportsPages);
            }}
            onRemove={id => dispatch(removeReportsFromNew([id]))}
            shouldRemoveFromNew={getShouldRemoveFromNew(defaultCustomer, groupCode, customerGroupCode)}
            isSelectable
        />
    ), [reports, defaultCustomer]);

    const isSelectedReportsPagesExceeded = getIsSelectedReportsPagesExceeded(selectedReportsPages);

    useEffect(() => {
        dispatch(fetchReports(reportsType));
    }, []);

    return (
        <div id="newReportsContainer">
            <div className="contentHeader">
                <h1>
                    <span>New Reports</span>
                    <Button shape="circle" icon={<InfoOutlined />} onClick={() => setIsTourOpen(true)} />
                </h1>
            </div>
            <Loader show={isLoading || isRemoving}>
                {newReportsTable}
                <ReportsActionsBlock
                    onCombineSelected={() => dispatch(combineReports(selectedReports, reportsType))}
                    isCombineSelectedDisabled={!selectedReports.length || isSelectedReportsPagesExceeded}
                    onResendSelectedByEmail={email => dispatch(resendReportsByEmail(selectedReports, email))}
                    onRemoveSelectedFromNew={() => dispatch(removeReportsFromNew(selectedReports))}
                    isResendSelectedByEmailDisabled={!selectedReports.length}
                    isSelectedReportsPagesExceeded={isSelectedReportsPagesExceeded}
                    isRemoveSelectedFromNewDisabled={!selectedReports.length}
                    pagesLimit={pagesLimit}
                />
                <Tour
                    {...defaultTourSettings}
                    steps={dxNewReportsTourSteps}
                    isOpen={isTourOpen}
                    onRequestClose={() => setIsTourOpen(false)}
                />
            </Loader>
        </div>
    );
};

export default NewReportsContent;
