export const LOOKUP_SUBMIT = 'LOOKUP_SUBMIT';
export const LOOKUP_SUCCESS = 'LOOKUP_SUCCESS';
export const LOOKUP_ERROR = 'LOOKUP_ERROR';

export const lookupSubmit = message => ({
    type: LOOKUP_SUBMIT,
    message,
});

export const lookupSuccess = response => ({
    type: LOOKUP_SUCCESS,
    response,
});

export const lookupFailed = error => ({
    type: LOOKUP_ERROR,
    error,
});
