import React, { useState, useEffect } from 'react';
import {
    useLocation,
    NavLink,
} from 'react-router-dom';
import Menu, { SubMenu, MenuItem } from 'rc-menu';
import 'rc-menu/assets/index.css';
import {
    LOGIN,
    ORDER_SERVICE_NAME_SEARCH,
    ORDER_SERVICE_PROPERTY_SEARCH,
    ORDER_SERVICE_PROPERTY_SEARCH_TAX_CONTINUATION,
    ORDER_SERVICE_ABSTRACT_SEARCH,
    ORDER_SERVICE_JUDGMENT_CONTINUATION,
    ORDER_SERVICE_JUDGMENT_LOOK_UP,
    ACCOUNT_VIEW_MY_ORDERS_NEW_REPORTS,
    ACCOUNT_VIEW_MY_ORDERS_COMPLETED_REPORTS,
    ACCOUNT_VIEW_MY_ORDERS_OPEN_REPORTS,
    FAQS,
    CONTACT_INFO,
} from '@src/utils/constants/routerConstants';
import NavLinkWithTooltip from '@src/components/NavLinkWithTooltip';

const permissionText = 'Is not permitted for this account';

const MainMenu = ({
    mode = 'horizontal',
    isAuthorized,
    allowPropertySearch,
    allowWebOrders,
    isWebDelivery,
}) => {
    const { pathname } = useLocation();
    const [current, setCurrent] = useState(pathname);

    useEffect(() => {
        if (current !== pathname) {
            setCurrent(pathname);
        }
    }, [pathname]);

    return (
        <Menu
            onClick={({ key }) => setCurrent(key)}
            selectedKeys={[current]}
            mode={mode}
        >
            {
                isAuthorized
                && (
                    <SubMenu key="SubMenu1" title="Place an Order">
                        <MenuItem
                            key={ORDER_SERVICE_NAME_SEARCH}
                            disabled={!allowWebOrders}
                        >
                            <NavLinkWithTooltip
                                to={ORDER_SERVICE_NAME_SEARCH}
                                activeClassName="selected"
                                isTooltipVisible={!allowWebOrders}
                                tooltipText={permissionText}
                            >
                                Name Searches/Corporate
                            </NavLinkWithTooltip>
                        </MenuItem>
                        <MenuItem
                            key={ORDER_SERVICE_PROPERTY_SEARCH}
                            disabled={!allowPropertySearch || !allowWebOrders}
                        >
                            <NavLinkWithTooltip
                                to={ORDER_SERVICE_PROPERTY_SEARCH}
                                activeClassName="selected"
                                isTooltipVisible={!allowPropertySearch || !allowWebOrders}
                                tooltipText={permissionText}
                            >
                                Property Searches
                            </NavLinkWithTooltip>
                        </MenuItem>
                        <MenuItem
                            key={ORDER_SERVICE_PROPERTY_SEARCH_TAX_CONTINUATION}
                            disabled={!allowPropertySearch || !allowWebOrders}
                        >
                            <NavLinkWithTooltip
                                to={ORDER_SERVICE_PROPERTY_SEARCH_TAX_CONTINUATION}
                                activeClassName="selected"
                                isTooltipVisible={!allowPropertySearch || !allowWebOrders}
                                tooltipText={permissionText}
                            >
                                Tax Continuation
                            </NavLinkWithTooltip>
                        </MenuItem>
                        <MenuItem
                            key={ORDER_SERVICE_ABSTRACT_SEARCH}
                            disabled={!allowWebOrders}
                        >
                            <NavLinkWithTooltip
                                to={ORDER_SERVICE_ABSTRACT_SEARCH}
                                activeClassName="selected"
                                isTooltipVisible={!allowWebOrders}
                                tooltipText={permissionText}
                            >
                                Chancery Abstract
                            </NavLinkWithTooltip>
                        </MenuItem>
                        <MenuItem
                            key={ORDER_SERVICE_JUDGMENT_CONTINUATION}
                            disabled={!allowWebOrders}
                        >
                            <NavLinkWithTooltip
                                to={ORDER_SERVICE_JUDGMENT_CONTINUATION}
                                activeClassName="selected"
                                isTooltipVisible={!allowWebOrders}
                                tooltipText={permissionText}
                            >
                                Judgment Continuation/Append
                            </NavLinkWithTooltip>
                        </MenuItem>
                        <MenuItem
                            key={ORDER_SERVICE_JUDGMENT_LOOK_UP}
                            disabled={!allowWebOrders}
                        >
                            <NavLinkWithTooltip
                                to={ORDER_SERVICE_JUDGMENT_LOOK_UP}
                                activeClassName="selected"
                                isTooltipVisible={!allowWebOrders}
                                tooltipText={permissionText}
                            >
                                Judgment Lookup
                            </NavLinkWithTooltip>
                        </MenuItem>
                    </SubMenu>
                )
            }
            {
                isAuthorized
                && (
                    <SubMenu key="SubMenu2" title="Order History">
                        {
                            isWebDelivery
                            && (
                                <MenuItem key={ACCOUNT_VIEW_MY_ORDERS_NEW_REPORTS}>
                                    <NavLink to={ACCOUNT_VIEW_MY_ORDERS_NEW_REPORTS} activeClassName="selected">
                                        New Reports
                                    </NavLink>
                                </MenuItem>
                            )
                        }
                        <MenuItem key={ACCOUNT_VIEW_MY_ORDERS_COMPLETED_REPORTS}>
                            <NavLink to={ACCOUNT_VIEW_MY_ORDERS_COMPLETED_REPORTS} activeClassName="selected">
                                Completed Reports
                            </NavLink>
                        </MenuItem>
                        <MenuItem key={ACCOUNT_VIEW_MY_ORDERS_OPEN_REPORTS}>
                            <NavLink to={ACCOUNT_VIEW_MY_ORDERS_OPEN_REPORTS} activeClassName="selected">
                                Open Orders
                            </NavLink>
                        </MenuItem>
                    </SubMenu>
                )
            }
            {
                !isAuthorized
                && (
                    <MenuItem key={LOGIN}>
                        <NavLink to={LOGIN} activeClassName="selected">
                            Log In
                        </NavLink>
                    </MenuItem>
                )
            }
            <SubMenu key="SubMenu3" title="Help">
                <MenuItem key="setting10">
                    <a href={FAQS} target="blank">FAQ’s</a>
                </MenuItem>
                <MenuItem key="setting11">
                    <a href={CONTACT_INFO} target="blank">Contact Us</a>
                </MenuItem>
            </SubMenu>
        </Menu>
    );
};

export default MainMenu;
