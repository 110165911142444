const departmentsArray = [
    {
        department: 'Abstract',
        key: 'ABS',
        types: [
            { text: 'Chancery Abstract', key: 'CCHANCERY' },
            { text: 'Full Chancery Abstract', key: 'FCHANCERY' },
            { text: 'Short Form Chancery Abstract', key: 'SCHANCERY' },
            { text: 'Litigation Search', key: 'LITIGATION' },
        ],
    },
    {
        department: 'Corporation',
        key: 'CORP',
        types: [
            { text: 'Officers & Directors Ltr', key: 'OFFDIR' },
            { text: 'Certificate of Formation', key: 'COPIES - FORM' },
            { text: 'Certificate of Incorporation', key: 'COPIES - INC' },
            { text: 'Annual Report', key: 'COPIES - ANNRPT' },
            { text: 'Good Standing Certificate', key: 'GOODSTND' },
            { text: 'UCC Search', key: 'UCC' },
            { text: 'Franchise Tax Report', key: 'FRANTAX' },
            { text: 'Entity Filings (All Reports)', key: 'COPIES - ALL' },
            { text: 'Entity Status', key: 'ENTSTAT' },
            { text: 'Registered Agent', key: 'REGAGT' },
            { text: 'Availabilty', key: 'NAMEAVAIL' },
        ],
    },
    {
        department: 'Judgment',
        key: 'JDG',
        types: [
            { text: 'Judgment Search', key: 'JUDGMENT' },
            { text: 'Child Support Search', key: 'CHILDSPPT' },
            { text: 'Child Support Status', key: 'SPPTSTAT' },
            { text: 'Patriot Search', key: 'PATRIOT' },
            { text: 'Miscellaneous Judgments', key: 'MISC_JDG' },
        ],
    },
    {
        department: 'Property Searches',
        key: 'PROP',
        types: [
            { text: 'Tax Search', key: 'TAX_SEARCH' },
            { text: 'Tax Continuation', key: 'TAX_CONT' },
            { text: 'Tideland Search', key: 'TIDELAND' },
            { text: 'Flood Search', key: 'FLOOD' },
            { text: 'Flood Life Of Loan Search', key: 'FLOOD_LOL' },
        ],
    },
    {
        department: 'Foreclosure/Miscellaneous',
        key: 'MISC_FC',
        types: [
            { text: 'Foreclosure', key: 'FORECLOSURE' },
            { text: 'Miscellaneous', key: 'MISC_HAMBURGER' },
        ],
    },
];

export default departmentsArray;
