import React from 'react';
import { Field } from 'react-final-form';
import InputGroupHiddenFieldAdapter from '@src/components/input/InputGroupHiddenFieldAdapter';
import { disabledDateByActivePeriod } from '@src/utils/helpers/dateUtils';
import { replaceMultipleWhitespaces } from '@src/utils/helpers/normalize';
import { FAQS_NAME_QUALIFIER } from '@src/utils/constants/routerConstants';
import { nameQualifierOptionsShortForm, searchTypeOptionsShortForm } from '@src/utils/templates/orderServicesOptions';
import {
    Col,
    Divider,
    Radio,
    Row,
    Button,
} from 'antd';
import InputGroupAntCheckBoxAdapter from '@src/components/input/InputGroupAntCheckBoxAdapter';
import InputGroupAntDatePickerAdapter from '@src/components/input/InputGroupAntDatePickerAdapter';
import InputGroupAntRadioAdapter from '@src/components/input/InputGroupAntRadioAdapter';
import InputGroupAntInputAdapter from '@src/components/input/InputGroupAntInputAdapter';
import {
    composeValidators,
    required,
} from '@src/utils/helpers/validationRules';
import InputGroupAntSelectAdapter from '@src/components/input/InputGroupAntSelectAdapter';
import InfoLink from '@src/components/InfoLink';

const NewNameFormSection = ({
    newNames,
    editName,
    editNameIndex,
    gutter,
    defaultJudgementSearchPeriod,
    removeNameHandler,
    saveNameHandler,
    setFieldValue,
}) => (
    <>
        <div className="section">
            <Row gutter={gutter} className="form-group">
                <Col md={24} lg={24}>
                    <Radio.Group
                        defaultValue="E"
                        size="large"
                    >
                        <Row gutter={gutter}>
                            {
                                searchTypeOptionsShortForm.map(({
                                    label,
                                    value,
                                }) => (
                                    <Col
                                        md={24}
                                        lg={4}
                                        key={`${editName}.category-${value}`}
                                    >
                                        <Field
                                            key={`${editName}.category-${value}`}
                                            type="radio"
                                            component={InputGroupAntRadioAdapter}
                                            value={value}
                                            label={label}
                                            name={`${editName}.category`}
                                            tabIndex="-1"
                                        />
                                    </Col>
                                ))
                            }
                        </Row>
                    </Radio.Group>
                </Col>
            </Row>
            <Field
                name={`${editName}.name`}
                component={InputGroupHiddenFieldAdapter}
                controlId={`${editName}.name`}
            />
            {
                newNames[editNameIndex].category === 'I'
                && (
                    <Row gutter={gutter} className="form-group-horizontal">
                        <Col md={24} lg={4}>
                            <Field
                                name={`${editName}.individualNamePrefix`}
                                component={InputGroupAntInputAdapter}
                                controlId={
                                    `${editName}.individualNamePrefix`
                                }
                                labelText="Prefix"
                                hasAddonAbove
                                parse={replaceMultipleWhitespaces}
                            />
                        </Col>
                        <Col md={24} lg={6}>
                            <Field
                                name={`${editName}.individualNameFirst`}
                                component={InputGroupAntInputAdapter}
                                controlId={
                                    `${editName}.individualNameFirst`
                                }
                                labelText="First Name"
                                isRequired
                                validate={composeValidators(required)}
                                hasAddonAbove
                                autoFocus
                                parse={replaceMultipleWhitespaces}
                            />
                        </Col>
                        <Col md={24} lg={4}>
                            <Field
                                name={`${editName}.individualNameMiddle`}
                                component={InputGroupAntInputAdapter}
                                controlId={
                                    `${editName}.individualNameMiddle`
                                }
                                labelText="Middle"
                                hasAddonAbove
                                parse={replaceMultipleWhitespaces}
                            />
                        </Col>
                        <Col md={24} lg={6}>
                            <Field
                                name={`${editName}.individualNameLast`}
                                component={InputGroupAntInputAdapter}
                                controlId={
                                    `${editName}.individualNameLast`
                                }
                                labelText="Last Name"
                                validate={composeValidators(required)}
                                isRequired
                                hasAddonAbove
                                parse={replaceMultipleWhitespaces}
                            />
                        </Col>
                        <Col md={24} lg={4}>
                            <Field
                                name={`${editName}.individualNameSuffix`}
                                component={InputGroupAntInputAdapter}
                                controlId={
                                    `${editName}.individualNameSuffix`
                                }
                                labelText="Suffix"
                                hasAddonAbove
                                tabIndex="-1"
                                parse={replaceMultipleWhitespaces}
                            />
                        </Col>
                    </Row>
                )
            }
            {
                newNames[editNameIndex].category === 'E'
                && (
                    <Row gutter={gutter}>
                        <Col md={24} lg={16}>
                            <Field
                                name={`${editName}.companyName`}
                                component={InputGroupAntInputAdapter}
                                controlId={`${editName}.companyName`}
                                labelText="Company Name"
                                isRequired
                                validate={composeValidators(required)}
                                autoFocus
                                parse={replaceMultipleWhitespaces}
                            />
                        </Col>
                    </Row>
                )
            }
        </div>
        <Divider />
        <div>
            <Row gutter={gutter}>
                <Col md={12} lg={8}>
                    <Field
                        name={`${editName}.originalSearchBegin`}
                        component={InputGroupAntDatePickerAdapter}
                        controlId={`${editName}.originalSearchBegin`}
                        labelText="From"
                        disabledDate={current => disabledDateByActivePeriod(
                            current,
                            [
                                defaultJudgementSearchPeriod[0],
                                newNames[editNameIndex].originalSearchEnd || defaultJudgementSearchPeriod[1],
                            ],
                        )}
                        defaultPickerValue={defaultJudgementSearchPeriod[0]}
                        isRequired
                        showToday={false}
                        onChange={date => setFieldValue(
                            `${editName}.originalSearchBegin`,
                            date || defaultJudgementSearchPeriod[0],
                        )}
                    />
                </Col>
                <Col md={12} lg={8}>
                    <Field
                        name={`${editName}.originalSearchEnd`}
                        component={InputGroupAntDatePickerAdapter}
                        controlId={`${editName}.originalSearchEnd`}
                        labelText="To"
                        disabledDate={current => disabledDateByActivePeriod(
                            current,
                            [
                                newNames[editNameIndex].originalSearchBegin || defaultJudgementSearchPeriod[0],
                                defaultJudgementSearchPeriod[1],
                            ],
                        )}
                        defaultPickerValue={defaultJudgementSearchPeriod[1]}
                        isRequired
                        showToday={false}
                        onChange={date => setFieldValue(
                            `${editName}.originalSearchEnd`,
                            date || defaultJudgementSearchPeriod[1],
                        )}
                    />
                </Col>
            </Row>
            <Row gutter={gutter}>
                <Col md={24} lg={16}>
                    <Field
                        name={`${editName}.nameQualifier`}
                        component={InputGroupAntSelectAdapter}
                        controlId={`${editName}.nameQualifier`}
                        labelText={(
                            <InfoLink
                                text="Name Qualifier"
                                href={FAQS_NAME_QUALIFIER}
                                tooltip="How to use name qualifiers"
                            />
                        )}
                        options={nameQualifierOptionsShortForm}
                    />
                </Col>
            </Row>
            <Row gutter={gutter}>
                <Col md={24} lg={24}>
                    <Field
                        name={`${editName}.exactMatch`}
                        component={InputGroupAntCheckBoxAdapter}
                        controlId={`${editName}.exactMatch`}
                        labelText="Exact Name Only"
                        type="checkbox"
                    />
                </Col>
            </Row>
            <Row gutter={gutter}>
                <Col md={24} lg={24}>
                    <Button
                        className="pull-right"
                        onClick={() => removeNameHandler(editNameIndex)}
                    >
                        Remove Name
                    </Button>
                    <Button
                        className="right-buffer-xs pull-right"
                        type="primary"
                        onClick={() => saveNameHandler(editNameIndex)}
                    >
                        Save Name
                    </Button>
                </Col>
            </Row>
        </div>
    </>
);

export default NewNameFormSection;
