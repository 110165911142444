/**
 * Created by oleg on 12/23/16.
 */
import React from 'react';
import { Spin } from 'antd';
import PropTypes from 'prop-types';

const Loader = ({
    show = false,
    message = '',
    className = '',
    children,
}) => {
    const messageFinal = (<span>{message}</span>);

    return (
        <Spin
            wrapperClassName={`loading ${className}`}
            tip={messageFinal}
            spinning={show}
            indicator={(
                <div
                    className="loader"
                    style={{
                        width: 60,
                        height: 60,
                        display: 'inline-block',
                        border: '6px solid #c6cddc',
                        borderTop: '6px solid #171c26',
                        borderBottom: '6px solid #171c26',
                        borderRadius: '50%',
                        animation: 'spin 1500ms linear infinite',
                        left: '50%',
                        top: '50%',
                        zIndex: '90',
                        margin: '-30px 0 0 -30px !important',
                        position: 'absolute',
                    }}
                />
            )}
        >
            {children}
        </Spin>
    );
};

Loader.propTypes = {
    show: PropTypes.bool,
    message: PropTypes.string,
    className: PropTypes.string,
};

export default Loader;
