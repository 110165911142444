import React, { useState } from 'react';
import {
    Select,
    Tag,
    Dropdown,
    Menu,
    Button,
} from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import FormGroup from '@src/components/input/FormGroup';

import { getValidationState } from '@src/utils/helpers/validationRules';
import { copyTextToClipboard } from '@src/utils/helpers/browserUtils';

const InputGroupAntTagBoxAdapter = ({
    input,
    controlId,
    isRequired,
    meta: { touched, error, warning },
    labelText,
    formSubmit = () => {},
    ...rest
}) => {
    const [isFirstOptionActive, setIsFirstOptionActive] = useState(true);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    return (
        <FormGroup
            controlId={controlId}
            validationState={getValidationState(touched, error, warning)}
            isRequired={isRequired}
            label={labelText}
            error={error}
            warning={warning}
            touched={touched}
        >
            <Select
                id={controlId}
                open={isDropdownOpen}
                mode="tags"
                allowClear
                maxTagTextLength={40}
                notFoundContent=""
                {...input}
                {...rest}
                value={input?.value ? input?.value?.filter(item => item?.trim().length) : []}
                style={{ width: '100%' }}
                defaultActiveFirstOption={isFirstOptionActive}
                onInputKeyDown={(e => {
                    if (!isFirstOptionActive) setIsFirstOptionActive(true);
                    if (!isDropdownOpen) setIsDropdownOpen(true);

                    if (e.key === 'Enter') {
                        if (!isDropdownOpen) {
                            formSubmit();
                        }
                        setIsFirstOptionActive(false);
                        setIsDropdownOpen(false);
                    } else if (e.key === 'Escape') {
                        setIsDropdownOpen(false);
                    }
                })}
                onBlur={() => {
                    input.onBlur();
                    setIsDropdownOpen(false);
                }}
                tagRender={props => (
                    <Tag {...props}>
                        <Dropdown
                            trigger={['click']}
                            overlay={(
                                <Menu onClick={() => { copyTextToClipboard(props.label); }}>
                                    <Menu.Item key="1" icon={<CopyOutlined />}>
                                        Copy
                                    </Menu.Item>
                                </Menu>
                            )}
                            placement="topRight"
                        >
                            <Button size="small" type="text">{props.label}</Button>
                        </Dropdown>
                    </Tag>
                )}
            />
        </FormGroup>
    );
};

export default InputGroupAntTagBoxAdapter;
