import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox } from 'antd';
import { changeShowDeactivated } from '@src/store/actions/userMaintanance';
import { isShowDeactivated } from '@src/store/reducers/userMaintanance/deactivateOption';

const DeactivateUser = () => {
    const dispatch = useDispatch();
    const isDeactivatedShown = useSelector(isShowDeactivated);

    return (
        <Checkbox
            onChange={event => dispatch(changeShowDeactivated(event.target.checked))}
            id="deactivateUserCheckbox"
            checked={isDeactivatedShown}
        >
            Show Deactivated Users
        </Checkbox>
    );
};

export default DeactivateUser;
