import React from 'react';
import moment from 'moment';
import { getNameWithDetails } from '@src/utils/helpers/reports';

export const shortDateFormat = 'MM/DD/YYYY';

export function formatDate(value, format = shortDateFormat) {
    return value ? moment(value).format(format) : (value || '');
}

const ReportInfoJdgName = ({ judgmentNames }) => (
    <div>
        {
            judgmentNames && judgmentNames.map(judgmentName => {
                const { nameIndex } = judgmentName;

                return (
                    <div className="judgmentName" key={nameIndex}>
                        {getNameWithDetails(judgmentName)}
                        <br />
                        {`${formatDate(judgmentName.searchFrom)} - ${formatDate(judgmentName.searchTo)}`}
                    </div>
                );
            })
        }
    </div>
);

export default ReportInfoJdgName;
