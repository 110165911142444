export const ORDER_DETAILS_FETCHING = 'ORDER_DETAILS_FETCHING';
export const ORDER_DETAILS_FETCHING_SUCCESS = 'ORDER_DETAILS_FETCHING_SUCCESS';
export const ORDER_DETAILS_FETCHING_FAIL = 'ORDER_DETAILS_FETCHING_FAIL';

export const POSTING_ORDER = 'POSTING_ORDER';
export const POST_ORDER_SUCCESS = 'POST_ORDER_SUCCESS';
export const POST_ORDER_FAIL = 'POST_ORDER_FAIL';

export const postingOrder = type => ({
    type: POSTING_ORDER,
    reportsType: type,
});

export const postOrderSucceeded = (response, type) => ({
    type: POST_ORDER_SUCCESS,
    reportsType: type,
    response,
});

export const postOrderFailed = (error, type) => ({
    type: POST_ORDER_FAIL,
    reportsType: type,
    error,
});

export const orderDetailsFetchingAction = message => ({
    type: ORDER_DETAILS_FETCHING,
    message,
});

export const orderDetailsFetchingSucceeded = response => ({
    type: ORDER_DETAILS_FETCHING_SUCCESS,
    response,
});

export const orderDetailsFetchingFailed = error => ({
    type: ORDER_DETAILS_FETCHING_FAIL,
    error,
});
