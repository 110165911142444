import { getSortByFieldComparator } from '@src/utils/helpers/devExpressHelpers';
import {
    FETCH_STATES, FETCH_STATES_SUCCESS, FETCH_STATES_FAIL,
    FETCHING_MUNICIPALITIES, FETCH_MUNICIPALITIES_SUCCESS, FETCH_MUNICIPALITIES_FAIL,
} from '../../actions/states';

const initialState = {
    fetching: false,
    states: [],
    fetchingMunicipalities: false,
    municipalities: {},
    counties: [{ name: 'Atlantic', id: '1' }],
    error: null,
};

const states = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_STATES:
            return { ...state, fetching: true };
        case FETCH_STATES_SUCCESS:
            return {
                ...state,
                fetching: false,
                states: action.response.data.sort(getSortByFieldComparator('stateDesc')),
            };
        case FETCH_STATES_FAIL:
            return {
                ...state,
                fetching: false,
                error: action.payload,
            };
        case FETCHING_MUNICIPALITIES:
            return { ...state, fetchingMunicipalities: true };
        case FETCH_MUNICIPALITIES_SUCCESS:
            return {
                ...state,
                fetchingMunicipalities: false,
                municipalities: action.response,
                counties: Object.keys(action.response).map(countyId => action.response[countyId][0].county),
            };
        case FETCH_MUNICIPALITIES_FAIL:
            return {
                ...state,
                fetchingMunicipalities: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const getStates = state => state.states.states;
export const getFetching = state => state.states.fetching;
export const getMunicipalities = state => state.states.municipalities;
export const getCounties = state => state.states.counties;

export default states;
