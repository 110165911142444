import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Field } from 'react-final-form';
import createDecorator from 'final-form-focus';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { submitLookup } from '@src/store/actions/jdglookup/jdglookup';
import { getIsSubmitting } from '@src/store/reducers/jdglookup';
import { gutter } from '@src/utils/helpers/orders';
import InputGroupAntSelectAdapter from '@src/components/input/InputGroupAntSelectAdapter';
import InputGroupAntInputAdapter from '@src/components/input/InputGroupAntInputAdapter';
import {
    required,
    maxLength,
    number,
    composeValidators,
} from '@src/utils/helpers/validationRules';
import Loader from '@src/components/Loader';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Row, Col } from 'antd';

const options = [
    { value: 'Bankruptcy', label: 'Bankruptcy', disabled: true },
    { value: 'BK', label: 'BK' },
    { value: 'Superior', label: 'Superior', disabled: true },
    { value: 'J', label: 'J' },
    { value: 'DJ', label: 'DJ' },
    { value: 'ML', label: 'ML' },
    { value: 'MP1', label: 'MP' },
    { value: 'PD', label: 'PD' },
    { value: 'District', label: 'District', disabled: true },
    { value: 'AP', label: 'AP' },
    { value: 'CB', label: 'CB' },
    { value: 'CR', label: 'CR' },
    { value: 'CV', label: 'CV' },
    { value: 'MG', label: 'MG' },
    { value: 'MP2', label: 'MP' },
    { value: 'MS', label: 'MS' },
    { value: 'RG000', label: 'RG000' },
    { value: 'RGCAM', label: 'RGCAM' },
    { value: 'RGNEW', label: 'RGNEW' },
    { value: 'RGTRN', label: 'RGTRN' },
];

const showYear = jdgLookupItem => {
    const { court } = jdgLookupItem;
    return !(court && ['RG000', 'RGCAM', 'RGNEW', 'RGTRN'].includes(court));
};

const defaultJdgLookup = { court: '', year: (new Date()).getFullYear().toString(), sequence: '' };

const validateSequence = composeValidators(required, maxLength(50));
const validateYear = composeValidators(required, maxLength(4), number);

const focusOnError = createDecorator();

const JdgLookupForm = () => {
    const dispatch = useDispatch();
    const isSubmitting = useSelector(getIsSubmitting);

    return (
        <Loader show={isSubmitting}>
            <Row justify="center" className="contentHeader">
                <Col md={16} sm={20} xs={24}>
                    <h1>Judgment Lookup</h1>
                </Col>
            </Row>
            <Row justify="center">
                <Col md={16} sm={20} xs={24}>
                    <Form
                        onSubmit={data => dispatch(submitLookup(data))}
                        decorators={[focusOnError]}
                        mutators={{ ...arrayMutators }}
                        initialValues={{ jdgLookup: [defaultJdgLookup] }}
                    >
                        {
                            params => {
                                const {
                                    handleSubmit,
                                    form: {
                                        mutators: { push, remove },
                                    },
                                    values,
                                } = params;

                                return (
                                    <form onSubmit={handleSubmit} className="jdgLookups-form" name="jdgLookupForm">
                                        <FieldArray
                                            initialValues={[defaultJdgLookup]}
                                            name="jdgLookup"
                                        >
                                            {({ fields }) => fields.map((name, index) => (
                                                <div className="formBlock" key={name}>
                                                    <Row gutter={gutter} className="form-group-horizontal">
                                                        {fields.length > 1 && (
                                                            <Button
                                                                className="formBlock-close-button"
                                                                shape="circle"
                                                                size="small"
                                                                icon={<CloseOutlined />}
                                                                onClick={() => remove('jdgLookup', index)}
                                                            />
                                                        )}
                                                        <Col md={8} xs={24} className="top-buffer-on-xs">
                                                            <Field
                                                                name={`${name}.court`}
                                                                component={InputGroupAntSelectAdapter}
                                                                isRequired
                                                                labelText="Court"
                                                                options={options}
                                                                controlId="court"
                                                                validate={composeValidators(required)}
                                                                showSearch
                                                            />
                                                        </Col>
                                                        <Col md={10} xs={24} className="top-buffer-on-xs">
                                                            <Field
                                                                name={`${name}.sequence`}
                                                                component={InputGroupAntInputAdapter}
                                                                isRequired
                                                                labelText="Sequence"
                                                                type="text"
                                                                controlId="sequence"
                                                                className="caseSequence"
                                                                validate={validateSequence}
                                                            />
                                                        </Col>
                                                        {
                                                            showYear(values.jdgLookup[index])
                                                            && (
                                                                <Col md={6} xs={24} className="top-buffer-on-xs">
                                                                    <Field
                                                                        name={`${name}.year`}
                                                                        component={InputGroupAntInputAdapter}
                                                                        isRequired
                                                                        labelText="Year"
                                                                        type="number"
                                                                        min="1970"
                                                                        max="2100"
                                                                        controlId="year"
                                                                        className="caseYear"
                                                                        validate={validateYear}
                                                                    />
                                                                </Col>
                                                            )
                                                        }
                                                    </Row>
                                                </div>
                                            ))}
                                        </FieldArray>
                                        <div className="no-padding">
                                            <div className="pull-right">
                                                <Button
                                                    htmlType="button"
                                                    disabled={values.jdgLookup.length >= 5}
                                                    onClick={() => push('jdgLookup', defaultJdgLookup)}
                                                    className="btn btn-default addAnother right-buffer-xs"
                                                >
                                                    Add Another
                                                </Button>
                                                <Button
                                                    htmlType="submit"
                                                    type="primary"
                                                    className="btn btn-primary"
                                                >
                                                    Lookup
                                                </Button>
                                            </div>
                                        </div>
                                    </form>
                                );
                            }
                        }
                    </Form>
                </Col>
            </Row>
        </Loader>
    );
};

export default JdgLookupForm;
