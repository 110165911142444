import { request, getRequestConfig } from '@src/utils/apiServices/api';
import { interpolate } from '@src/utils/helpers/normalize';
import { urlAccountIsUsernameUnique } from '@src/utils/constants/endpointConstants';

export const checkUsernameUnique = name => (
    request({
        ...getRequestConfig,
        url: interpolate(urlAccountIsUsernameUnique, name),
    })
);

export default checkUsernameUnique;
