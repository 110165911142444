import React from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const disableBody = target => disableBodyScroll(target);
const enableBody = target => enableBodyScroll(target);

const steps = {
    dxGroupingPanel: {
        selector: '.dx-datagrid-group-panel',
        content: 'This is your grouping area. To group table by any field, drag and drop table header to this area.',
    },
    dxHeaderRowGrouping: {
        selector: '.dx-header-row',
        content: 'Select different column header, drag and drop it on area above, to group the data in table by this field.',
    },
    dxHeaderRowOrdering: {
        selector: '.dx-header-row',
        content: 'Also you can change order of columns in table.',
    },
    dxTableSavingSettings: {
        selector: '.dx-datagrid',
        content: 'All you setting as sortings, grouping up and column ordering over the table will be saved in current browser',
    },
    dxTableSortingSettings: {
        selector: '.dx-header-row',
        content: 'Click by column name to do a sorting by this field.',
    },
    dxTableSearchSettings: {
        selector: '.dx-datagrid-filter-row',
        content: 'Search by entering data in inputs under specific column header.',
    },
    dxTableSearchTypeSettings: {
        selector: '.dx-filter-menu',
        content: 'Change type of searching.',
    },
    dxTableNewReports: {
        selector: '.dx-datagrid',
        content: 'A New Report is a report that was completed within the last two weeks and has not been opened by anyone in your office.  Once the report is opened or becomes older then two weeks it will be moved to the completed reports page.',
    },
    dxTableNewReportsActions: {
        selector: '.dx-datagrid .ant-btn-group',
        content: () => (
            <p>
                Actions available are :
                <ul>
                    <li>view order details</li>
                    <li>view file</li>
                    <li>remove from new</li>
                </ul>
            </p>
        ),
    },
};

const {
    dxGroupingPanel, dxHeaderRowGrouping, dxHeaderRowOrdering,
    dxTableNewReports, dxTableNewReportsActions, dxTableSortingSettings, dxTableSearchTypeSettings,
    dxTableSearchSettings,
    // dxTableSavingSettings,
} = steps;

export const dxOpenOrdersTourSteps = [
    dxGroupingPanel, dxHeaderRowGrouping, dxHeaderRowOrdering,
    // dxTableSavingSettings,
];

export const dxCompletedReportsTourSteps = [
    dxGroupingPanel, dxHeaderRowGrouping, dxHeaderRowOrdering,
    // dxTableSavingSettings,
];

export const dxNewReportsTourSteps = [
    dxTableNewReports, dxTableNewReportsActions, dxTableSortingSettings, dxTableSearchTypeSettings,
    dxTableSearchSettings,
    // dxTableSavingSettings,
];

export const defaultTourSettings = {
    onAfterOpen: disableBody,
    onBeforeClose: enableBody,
    rounded: 4,
    accentColor: '#0d2c59',
};
