import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm, useFormState } from 'react-final-form';
import useFieldValue from './useFieldValue';

const WhenFieldChanges = ({
    shouldChangeHandler,
    field,
    set,
    to,
}) => {
    const { values } = useFormState();
    const form = useForm();
    const { setFieldTouched = () => {} } = form.mutators;

    const [value, prevValue] = useFieldValue(field);

    useEffect(() => {
        if (value !== prevValue) {
            if (shouldChangeHandler) {
                if (shouldChangeHandler(values, field)) {
                    form.change(set, to);
                    setFieldTouched(set, true);
                }
            } else {
                form.change(set, to);
                setFieldTouched(set, true);
            }
        }
    }, [shouldChangeHandler, value, prevValue]);

    return null;
};

WhenFieldChanges.propTypes = {
    field: PropTypes.string.isRequired,
    set: PropTypes.string.isRequired,
    shouldChangeHandler: PropTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    to: PropTypes.any.isRequired,
};
WhenFieldChanges.defaultProps = {};

export default WhenFieldChanges;
