/**
 * Created by oleg on 12/9/16.
 */
import React from 'react';
import FormDelimiter from '@src/components/FormDelimiter';

const BillingInformationReview = ({
    billingInformation: {
        address1,
        address2,
        city,
        state,
        zip,
        email,
    },
}) => (
    <div>
        <FormDelimiter />
        <div className="h4">Billing Address</div>
        <dl className="dl-horizontal">
            <dt>Address Line 1</dt>
            <dd>{address1}</dd>
            <dt>Address Line 2</dt>
            <dd>{address2}</dd>
        </dl>
        <FormDelimiter />
        <dl className="dl-horizontal">
            <dt>City</dt>
            <dd>{city}</dd>
            <dt>State</dt>
            <dd>{state}</dd>
            <dt>Zip</dt>
            <dd>{zip}</dd>
        </dl>
        <FormDelimiter />
        <dl className="dl-horizontal">
            <dt>Email</dt>
            <dd>{email}</dd>
        </dl>
    </div>
);

export default BillingInformationReview;
