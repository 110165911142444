import React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import moment from 'moment';
import { Field } from 'react-final-form';
import { shortDateFormat } from '@src/utils/helpers/dateUtils';
import { nameQualifierOptionsShortForm } from '@src/utils/templates/orderServicesOptions';
import { getLabelByValue } from '@src/utils/helpers/parse';
import {
    Divider,
    Button,
} from 'antd';
import { EditOutlined } from '@ant-design/icons';
import InputGroupAntCheckBoxAdapter from '@src/components/input/InputGroupAntCheckBoxAdapter';
import NewNameFormSection from '@src/containers/orderServiceJudgmentContinuation/OrderFormSections/NewNameFormSection';

const NewNamesSection = ({
    newNames = [],
    areNewNamesSelected,
    serviceData,
    gutter,
    defaultJudgementSearchPeriod,
    removeNameHandler,
    saveNameHandler,
    editNameIndex,
    setEditNameIndex,
    setFieldValue,
}) => (
    <>
        {
            !!newNames.length
            && areNewNamesSelected
            && (
                <div className="serviceBody">
                    <FieldArray
                        initialValues={serviceData.newNames}
                        name="newNames"
                    >
                        {({ fields }) => fields.map((name, index) => (
                            <div className="section" key={name}>
                                <div className="field-with-actions">
                                    <Field
                                        className={`continuation-new-name-field-${index}`}
                                        name={`${name}.selectedToContinue`}
                                        component={InputGroupAntCheckBoxAdapter}
                                        controlId={`${name}.selectedToContinue`}
                                        labelText={`${newNames[index].name.toUpperCase() || 'New Name'} (${newNames[index].category === 'E' ? 'Entity' : 'Individual'})`}
                                        disabled={!newNames[index].name}
                                        type="checkbox"
                                    />
                                    <div className="field-with-actions--actions-wrapper">
                                        <Button
                                            icon={<EditOutlined />}
                                            size="small"
                                            onClick={() => setEditNameIndex(index)}
                                            disabled={editNameIndex !== null}
                                        />
                                    </div>
                                </div>
                                <div className={index !== editNameIndex ? 'visible' : 'hidden'}>
                                    <div>
                                        <dl className="dl-horizontal">
                                            <dt>Search Date</dt>
                                            <dd className="search-date-value">
                                                {/* eslint-disable-next-line max-len */}
                                                {`${moment(newNames[index].originalSearchBegin).format(shortDateFormat)} - ${moment(newNames[index].originalSearchEnd).format(shortDateFormat)}`}
                                            </dd>
                                        </dl>
                                    </div>
                                    <div>
                                        <dl className="dl-horizontal">
                                            <dt>Exact Match</dt>
                                            <dd className="exact-match-value">
                                                {newNames[index].exactMatch ? 'YES' : 'NO'}
                                            </dd>
                                        </dl>
                                    </div>
                                    <div>
                                        <dl className="dl-horizontal">
                                            <dt>Name Qualifier</dt>
                                            <dd className="name-qualifier-value">
                                                {getLabelByValue(
                                                    nameQualifierOptionsShortForm,
                                                    newNames[index].nameQualifier,
                                                ) || 'NO'}
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                                <div className={index === editNameIndex ? 'visible' : 'hidden'}>
                                    <NewNameFormSection
                                        newNames={newNames}
                                        editName={name}
                                        editNameIndex={index}
                                        gutter={gutter}
                                        defaultJudgementSearchPeriod={defaultJudgementSearchPeriod}
                                        removeNameHandler={removeNameHandler}
                                        saveNameHandler={saveNameHandler}
                                        setFieldValue={setFieldValue}
                                    />
                                </div>
                                {index !== fields.length - 1 && <Divider />}
                            </div>
                        ))}
                    </FieldArray>
                </div>
            )
        }
    </>
);

export default NewNamesSection;
