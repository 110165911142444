import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { Row, Col } from 'antd';

import { isAdmin, getSettingsContainerTitle, isAccountSettingsFetching } from '@src/store/reducers/accountSettings';
import { getCurrentCustomer } from '@src/store/reducers/customers';
import { getAccountSettings } from '@src/store/actions/accountSettings';
import Loader from '@src/components/Loader';
import { gutter } from '@src/utils/helpers/orders';
import SettingsNavigation from './SettingsNavigation';

const SettingsContent = () => {
    const isLoading = useSelector(isAccountSettingsFetching);
    const containerTitle = useSelector(getSettingsContainerTitle);
    const isAdminRole = useSelector(isAdmin);
    const dispatch = useDispatch();
    const currentCustomerId = useSelector(getCurrentCustomer);

    useEffect(() => {
        dispatch(getAccountSettings());
    }, [currentCustomerId]);

    return (
        <Loader show={isLoading}>
            {
                !isLoading
                && (
                    <>
                        <div className="contentHeader text-center"><h1>{containerTitle}</h1></div>
                        <Row gutter={gutter}>
                            <Col md={6} xs={24}>
                                {
                                    isAdminRole
                                    && (
                                        <div id="settingsNavigationMenu">
                                            <SettingsNavigation />
                                        </div>
                                    )
                                }
                            </Col>
                            <Col md={18} xs={24}>
                                <div id="settingsContentContainer">
                                    <Outlet />
                                </div>
                            </Col>
                        </Row>
                    </>
                )
            }
        </Loader>
    );
};

export default SettingsContent;
