import React from 'react';
import { useSelector } from 'react-redux';
import {
    getUsers,
    isUserOnUpdate,
    isShowDeactivated,
} from '../../../store/reducers/userMaintanance';
import UserInformation from './UserInformation';
import Loader from '../../../components/Loader';

const UserInformationContainer = () => {
    const users = useSelector(state => getUsers(state));
    const isUserOnUpdateState = useSelector(state => isUserOnUpdate(state));
    const isShowDeactivatedState = useSelector(state => isShowDeactivated(state));

    if (Array.isArray(users) && users.length) {
        return (
            <div id="userInformationContainer">
                {users.map((user, index) => (
                    isShowDeactivatedState
                        ? userInformation(user, index, isUserOnUpdateState)
                        : user.enabled && userInformation(user, index, isUserOnUpdateState)
                ))}
            </div>
        );
    }

    return <div />;
};

const userInformation = (user, index, isUserOnUpdate) => (
    isUserOnUpdate(user.username)
        ? (
            <Loader show key={index}>
                <UserInformation user={user} />
            </Loader>
        )
        : <UserInformation user={user} key={index} />);

export default UserInformationContainer;
