import React from 'react';
import { Col, Radio, Row } from 'antd';
import { Field } from 'react-final-form';
import InputGroupAntInputAdapter from '@src/components/input/InputGroupAntInputAdapter';
import InputGroupAntSelectAdapter from '@src/components/input/InputGroupAntSelectAdapter';
import WhenFieldChanges from '@src/components/input/WhenFieldChanges';
import InputGroupAntRadioAdapter from '@src/components/input/InputGroupAntRadioAdapter';
import { getCompanyTypeLabel } from '@src/containers/orderServiceNameSearch/helper';
import { replaceMultipleWhitespaces } from '@src/utils/helpers/normalize';

const OrderNameOrCompanySection = ({
    name,
    values,
    index,
    gutter,
    companyTypeOptions,
    searchTypeOptions,
}) => (
    <div className="formBlock">
        <Row gutter={gutter} className="form-group">
            <Col md={24} lg={24}>
                <Radio.Group
                    defaultValue={
                        values
                            .namesToSearch[values.namesToSearch.length - 1]
                            .nameSearchCategory
                            .type
                    }
                    size="large"
                >
                    <Row gutter={gutter}>
                        {
                            searchTypeOptions.map(({ label, value }) => (
                                <Col md={24} lg={4} key={`${name}.nameSearchCategory.type-${value}`}>
                                    <Field
                                        key={`${name}.nameSearchCategory.type-${value}`}
                                        type="radio"
                                        component={InputGroupAntRadioAdapter}
                                        value={value}
                                        label={label}
                                        name={`${name}.nameSearchCategory.type`}
                                        tabIndex="-1"
                                    />
                                </Col>
                            ))
                        }
                    </Row>
                </Radio.Group>
                <WhenFieldChanges
                    field={`${name}.nameSearchCategory.type`}
                    set={`${name}.selectedServicesTypes`}
                    to={{
                        ...values.namesToSearch[index].selectedServicesTypes,
                        'childSupportSearch': false,
                        'corporateStatus': false,
                        'franchiseTaxReport': false,
                        'goodStndSearch': false,
                        'copiesOfEntityFiling': false,
                        'regAgentLetter': false,
                        'offAndDirLetter': false,
                        'nameAvailability': false,
                    }}
                />
            </Col>
        </Row>
        {
            values.namesToSearch[index].nameSearchCategory.type === 'Individual'
            && (
                <Row gutter={gutter} className="form-group-horizontal">
                    <Col md={24} lg={4}>
                        <Field
                            name={`${name}.nameSearchCategory.Individual.prefix`}
                            component={InputGroupAntInputAdapter}
                            controlId={`${name}.nameSearchCategory.Individual.prefix`}
                            labelText="Prefix"
                            hasAddonAbove
                            tabIndex="-1"
                            formatOnBlur
                            format={value => value && replaceMultipleWhitespaces(value.trim())}
                        />
                    </Col>
                    <Col md={24} lg={6}>
                        <Field
                            name={`${name}.nameSearchCategory.Individual.firstName`}
                            component={InputGroupAntInputAdapter}
                            controlId={
                                `${name}.nameSearchCategory.Individual.firstName`
                            }
                            labelText="First Name"
                            isRequired
                            hasAddonAbove
                            autoFocus={values.namesToSearch.length > 1}
                            formatOnBlur
                            format={value => value && replaceMultipleWhitespaces(value.trim())}
                        />
                    </Col>
                    <Col md={24} lg={4}>
                        <Field
                            name={`${name}.nameSearchCategory.Individual.middleName`}
                            component={InputGroupAntInputAdapter}
                            controlId={
                                `${name}.nameSearchCategory.Individual.middleName`
                            }
                            labelText="Middle"
                            hasAddonAbove
                            formatOnBlur
                            format={value => value && replaceMultipleWhitespaces(value.trim())}
                        />
                    </Col>
                    <Col md={24} lg={6}>
                        <Field
                            name={`${name}.nameSearchCategory.Individual.lastName`}
                            component={InputGroupAntInputAdapter}
                            controlId={
                                `${name}.nameSearchCategory.Individual.lastName`
                            }
                            labelText="Last Name"
                            isRequired
                            hasAddonAbove
                            formatOnBlur
                            format={value => value && replaceMultipleWhitespaces(value.trim())}
                        />
                    </Col>
                    <Col md={24} lg={4}>
                        <Field
                            name={`${name}.nameSearchCategory.Individual.suffix`}
                            component={InputGroupAntInputAdapter}
                            controlId={
                                `${name}.nameSearchCategory.Individual.suffix`
                            }
                            labelText="Suffix"
                            hasAddonAbove
                            tabIndex="-1"
                            formatOnBlur
                            format={value => value && replaceMultipleWhitespaces(value.trim())}
                        />
                    </Col>
                </Row>
            )
        }
        {
            values.namesToSearch[index].nameSearchCategory.type === 'Company'
            && (
                <Row gutter={gutter}>
                    <Col md={24} lg={16}>
                        <Field
                            name={`${name}.nameSearchCategory.Company.companyName`}
                            component={InputGroupAntInputAdapter}
                            controlId={`${name}.nameSearchCategory.Individual.companyName`}
                            labelText="Company Name"
                            isRequired
                            autoFocus={values.namesToSearch.length > 1}
                            formatOnBlur
                            format={value => value && replaceMultipleWhitespaces(value.trim())}
                        />
                        <WhenFieldChanges
                            field={`${name}.nameSearchCategory.Company.companyName`}
                            set={`${name}.selectedServices.nameAvailability.primary`}
                            to={values.namesToSearch[index].nameSearchCategory.Company.companyName}
                        />
                    </Col>
                    <Col md={24} lg={16}>
                        <Field
                            name={`${name}.nameSearchCategory.Company.companyType`}
                            component={InputGroupAntSelectAdapter}
                            controlId={`${name}.nameSearchCategory.Company.companyType`}
                            labelText="Type"
                            options={companyTypeOptions}
                        />
                        <WhenFieldChanges
                            field={`${name}.nameSearchCategory.Company.companyType`}
                            set={`${name}.selectedServices.corporateStatus.name`}
                            to={
                                `${getCompanyTypeLabel(
                                    values.namesToSearch[index].nameSearchCategory.Company.companyType,
                                )} status`
                            }
                        />
                    </Col>
                </Row>
            )
        }
    </div>
);

export default OrderNameOrCompanySection;
