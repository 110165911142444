import React from 'react';
import { Col, Row } from 'antd';
import { gutter } from '@src/utils/helpers/orders';
import { Field } from 'react-final-form';
import InputGroupAntInputAdapter from '@src/components/input/InputGroupAntInputAdapter';
import InputGroupAntCheckBoxAdapter from '@src/components/input/InputGroupAntCheckBoxAdapter';

const TaxSearchSection = ({
    searchSupplier,
    values,
}) => (
    <div className="serviceBlock formBlock tax-search-block">
        <Row className="serviceHeader">
            <Col span={24}>
                <div className="providedBy text-light">
                    {searchSupplier}
                </div>
                <Field
                    name="selectedServicesTypes.taxSearchService"
                    component={InputGroupAntCheckBoxAdapter}
                    controlId="selectedServicesTypes.taxSearchService"
                    labelText="Tax Search"
                    type="checkbox"
                />
            </Col>
        </Row>
        {
            values.selectedServicesTypes.taxSearchService
            && (
                <>
                    <Row gutter={gutter}>
                        <Col md={24} lg={12}>
                            <Field
                                name="selectedServices.taxSearchService.assessment"
                                component={InputGroupAntCheckBoxAdapter}
                                controlId="selectedServices.taxSearchService.assessment"
                                labelText="Unconfirmed Assessment"
                                type="checkbox"
                            />
                        </Col>
                        <Col md={24} lg={12}>
                            <Field
                                name="selectedServices.taxSearchService.utilities"
                                component={InputGroupAntCheckBoxAdapter}
                                controlId="selectedServices.taxSearchService.utilities"
                                labelText="Utilities"
                                type="checkbox"
                            />
                        </Col>
                    </Row>
                    <Row gutter={gutter}>
                        <Col md={24} lg={16}>
                            <Field
                                name="selectedServices.taxSearchService.comment"
                                component={InputGroupAntInputAdapter}
                                controlId="selectedServices.taxSearchService.comment"
                                labelText="Comments"
                            />
                        </Col>
                    </Row>
                </>
            )
        }
    </div>
);

export default TaxSearchSection;
