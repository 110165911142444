// TODO: Add validation for zip and phone number
import checkUsernameUnique from '@src/utils/apiServices/usernameRequests';

export const uniqueUserName = async name => (
    await checkUsernameUnique(name)
        ? undefined
        : 'This email is already in use. Please, enter another one.'
);

export default uniqueUserName;
