const getPadParams = (originalCurrentString, originalTargetLength, originalPadString) => {
    // floor if number or convert non-number to 0;
    // eslint-disable-next-line no-bitwise
    let targetLength = originalTargetLength >> 0;
    let padString = String(originalPadString || ' ');
    const currentString = String(originalCurrentString);

    if (currentString.length > targetLength) {
        padString = '';
    } else {
        targetLength -= currentString.length;
        if (targetLength > padString.length) {
            // append to original to ensure we are longer than needed
            padString += padString.repeat(targetLength / padString.length);
        }
    }

    return { targetLength, padString, currentString };
};

export const padStart = (originalCurrentString, originalTargetLength, originalPadString) => {
    const {
        targetLength,
        padString,
        currentString,
    } = getPadParams(originalCurrentString, originalTargetLength, originalPadString);

    return padString.slice(0, targetLength) + currentString;
};

export const padEnd = (originalCurrentString, originalTargetLength, originalPadString) => {
    const {
        targetLength,
        padString,
        currentString,
    } = getPadParams(originalCurrentString, originalTargetLength, originalPadString);

    return currentString + padString.slice(0, targetLength);
};
