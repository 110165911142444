import React from 'react';
import { Row, Col } from 'antd';
import SelectOrderTable from '@src/containers/orderServicePropertyTaxContinuation/SelectOrderTable';

const OrderServicePropertyTaxContinuationContent = props => (
    <Row>
        <Col xs={{ span: 24, offset: 0 }} lg={{ span: 24, offset: 0 }}>
            <SelectOrderTable {...props} />
        </Col>
    </Row>
);

export default OrderServicePropertyTaxContinuationContent;
