import {
    updateUserActiveState,
    updateUserAdminState,
    updateUser,
    resendConfirmationCode,
} from '@src/utils/apiServices/userRequests';
import { addErrorAlert, addSuccessAlert } from '@src/store/actions/alerts';
import { emailsJoiner } from '@src/utils/constants/formConstants';
import {
    updateUserAction,
    updateUserFailed,
    updateUserSucceeded,
    updateUserActiveStateAction,
    updateUserActiveStateSucceeded,
    updateUserActiveStateFailed,
    updateUserAdminStateAction,
    updateUserAdminStateSucceeded,
    updateUserAdminStateFailed,
    editUserAction,
    editUserSubmit,
    resendConfirmationCodeAction,
    resendConfirmationCodeFailed,
    resendConfirmationCodeSucceeded,
} from './userMaintenanceControl';

export const performUserAction = (e, user) => dispatch => {
    switch (e.key) {
        case 'switchActive':
            return performUpdateUserActiveState(user, !user.enabled, dispatch);
        case 'switchAdmin':
            return performUpdateUserAdminState(user, !user.admin, dispatch);
        case 'edit':
            return performEditUser(user)(dispatch);
        case 'resendConfirmationCode':
            return performResendConfirmationCode(user, dispatch);
        default:
            return null;
    }
};

const performUpdateUserActiveState = (user, activeState, dispatch) => {
    dispatch(updateUserActiveStateAction(user.username));

    return updateUserActiveState(user, activeState)
        .then(response => {
            dispatch(updateUserActiveStateSucceeded(response, user.username));
        })
        .catch(error => {
            dispatch(updateUserActiveStateFailed(error, user.username));
            dispatch(addErrorAlert('Can not update user active state. Please, try again later.', true));
        });
};

const performUpdateUserAdminState = (user, adminState, dispatch) => {
    dispatch(updateUserAdminStateAction(user.username));

    return updateUserAdminState(user, adminState).then(response => {
        dispatch(updateUserAdminStateSucceeded(response, user.username));
    }).catch(error => {
        dispatch(updateUserAdminStateFailed(error, user.username));
        dispatch(addErrorAlert('Can not update user admin state. Please, try again later.', true));
    });
};

const performUpdateUser = (user, dispatch) => {
    dispatch(updateUserAction(user.username));

    return updateUser(user).then(response => {
        dispatch(updateUserSucceeded(response, user.username));
    }).catch(error => {
        dispatch(updateUserFailed(error, user.username));
        dispatch(addErrorAlert('Can not update user. Please, try again later.', true));
    });
};

const performResendConfirmationCode = (user, dispatch) => {
    dispatch(resendConfirmationCodeAction(user.username));

    return resendConfirmationCode(user).then(response => {
        dispatch(resendConfirmationCodeSucceeded(response, user.username));
        dispatch(addSuccessAlert('Email with confirmation code has been successfully resent.', true));
    }).catch(error => {
        dispatch(resendConfirmationCodeFailed(error, user.username));
        dispatch(addErrorAlert('Can not send conformation email. Please, try again later.', true));
    });
};

export const performEditUser = user => dispatch => {
    dispatch(editUserAction(user));
};

export const submitEditUser = user => dispatch => {
    const normalizedUser = {
        ...user,
        attributes: {
            ...user.attributes,
            deliveryEmail: user.attributes.deliveryEmail?.join(emailsJoiner),
        },
    };
    dispatch(editUserSubmit(normalizedUser));

    return performUpdateUser(normalizedUser, dispatch);
};
