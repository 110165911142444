import React from 'react';
import { Row, Col, Button } from 'antd';
import { Form, Field, FormSpy } from 'react-final-form';
import setFieldData from 'final-form-set-field-data';
import InputGroupAntInputAdapter from '@src/components/input/InputGroupAntInputAdapter';
import InputGroupAntSelectAdapter from '@src/components/input/InputGroupAntSelectAdapter';
import InputGroupAntTextAreaAdapter from '@src/components/input/InputGroupAntTextAreaAdapter';
import {
    composeValidators, conditionRequired,
    required, maxLength,
} from '@src/utils/helpers/validationRules';
import { isOldDocket } from '@src/utils/helpers/docketJdg';
import createDecorator from 'final-form-focus';
import { gutter } from '@src/utils/helpers/orders';

const focusOnError = createDecorator();

const WarningEngine = ({ mutators: { setFieldData } }) => (
    <FormSpy
        subscription={{ values: true }}
        onChange={({ values }) => {
            setFieldData('docketYear', {
                warning: values.docketYear && isOldDocket(values.docketYear)
                    ? 'Please allow 4-6 weeks for dockets prior to 2006.'
                    : undefined,
            });
        }}
    />
);

const OrderChanceryForm = ({
    setNextStep,
    serviceOptions,
    formData,
    isReferenceRequired,
}) => (
    <>
        <div className="contentHeader text-center">
            <h1>Chancery Abstract Order Form</h1>
        </div>
        <Form
            onSubmit={args => setNextStep(args)}
            decorators={[focusOnError]}
            mutators={{ setFieldData }}
            initialValues={formData}
            validate={
                ({
                    docketNumber,
                    docketYear,
                }) => ({
                    docketNumber: conditionRequired(docketYear, docketNumber),
                    docketYear: conditionRequired(docketNumber, docketYear),
                })
            }
        >
            {
                ({
                    handleSubmit, form,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="formBlock">
                            <Row gutter={gutter}>
                                <Col md={24} lg={17}>
                                    <Field
                                        name="reference"
                                        component={InputGroupAntInputAdapter}
                                        controlId="reference"
                                        labelText="Reference Number"
                                        validate={isReferenceRequired && composeValidators(required)}
                                        isRequired={isReferenceRequired}
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className="formBlock">
                            <h4>Enter Search Criteria</h4>
                            <Row gutter={gutter}>
                                <Col md={24} lg={17}>
                                    <Field
                                        id="searchType"
                                        name="searchType"
                                        component={InputGroupAntSelectAdapter}
                                        controlId="searchType"
                                        labelText="Service"
                                        options={serviceOptions}
                                    />
                                </Col>
                            </Row>
                            <h4>Docket</h4>
                            <Row gutter={gutter}>
                                <Col md={24} lg={12}>
                                    <Field
                                        name="docket"
                                        component={InputGroupAntInputAdapter}
                                        controlId="docket"
                                        label="Docket Number"
                                        validate={composeValidators(maxLength(15))}
                                        placeholder="Ex. F-12345-15"
                                    />
                                </Col>
                            </Row>
                            <h4>Plaintiff</h4>
                            <Row gutter={gutter}>
                                <Col md={24} lg={12}>
                                    <Field
                                        name="plaintiffLastName"
                                        component={InputGroupAntInputAdapter}
                                        controlId="plaintiffLastName"
                                        labelText="Entity/Last Name"
                                    />
                                </Col>
                                <Col md={24} lg={12}>
                                    <Field
                                        name="plaintiffFirstName"
                                        component={InputGroupAntInputAdapter}
                                        controlId="plaintiffFirstName"
                                        labelText="First Name"
                                    />
                                </Col>
                            </Row>
                            <h4>Defendant</h4>
                            <Row gutter={gutter}>
                                <Col md={24} lg={12}>
                                    <Field
                                        name="defendantLastName"
                                        component={InputGroupAntInputAdapter}
                                        controlId="defendantLastName"
                                        labelText="Entity/Last Name"
                                    />
                                </Col>
                                <Col md={24} lg={12}>
                                    <Field
                                        name="defendantFirstName"
                                        component={InputGroupAntInputAdapter}
                                        controlId="defendantFirstName"
                                        labelText="First Name"
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className="formBlock">
                            <h4>Premises description as set forth in the action</h4>
                            <Row gutter={gutter}>
                                <Col md={24} lg={24}>
                                    <Field
                                        name="addressLine1"
                                        component={InputGroupAntInputAdapter}
                                        controlId="addressLine1"
                                        labelText="Address Line 1"
                                    />
                                </Col>
                            </Row>
                            <Row gutter={gutter}>
                                <Col md={24} lg={24}>
                                    <Field
                                        name="addressLine2"
                                        component={InputGroupAntInputAdapter}
                                        controlId="addressLine2"
                                        labelText="Address Line 2"
                                    />
                                </Col>
                            </Row>
                            <Row gutter={gutter}>
                                <Col md={24} lg={10}>
                                    <Field
                                        name="city"
                                        component={InputGroupAntInputAdapter}
                                        controlId="city"
                                        labelText="City"
                                    />
                                </Col>
                                <Col md={24} lg={8}>
                                    <Field
                                        name="state"
                                        component={InputGroupAntSelectAdapter}
                                        controlId="state"
                                        labelText="State"
                                        options={[
                                            { value: 'NJ', label: 'NJ' },
                                        ]}
                                        disabled
                                    />
                                </Col>
                                <Col md={24} lg={6}>
                                    <Field
                                        name="zip"
                                        component={InputGroupAntInputAdapter}
                                        controlId="zip"
                                        labelText="Zip"
                                    />
                                </Col>
                            </Row>
                            <Row gutter={gutter}>
                                <Col md={24} lg={10}>
                                    <Field
                                        name="municipalityName"
                                        component={InputGroupAntInputAdapter}
                                        controlId="municipalityName"
                                        labelText="Municipality"
                                    />
                                </Col>
                                <Col md={24} lg={8}>
                                    <Field
                                        name="blockNo"
                                        component={InputGroupAntInputAdapter}
                                        controlId="blockNo"
                                        labelText="Block"
                                    />
                                </Col>
                                <Col md={24} lg={6}>
                                    <Field
                                        name="lot"
                                        component={InputGroupAntInputAdapter}
                                        controlId="lot"
                                        labelText="Lot"
                                    />
                                </Col>
                            </Row>
                            <h4>If "In Rem" foreclosure, please advise claim or schedule number</h4>
                            <Row>
                                <Col md={24} lg={24}>
                                    <Field
                                        name="claimNo"
                                        component={InputGroupAntInputAdapter}
                                        controlId="claimNo"
                                        labelText="Claim Number"
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className="formBlock">
                            <Row gutter={gutter}>
                                <Col md={24} lg={24}>
                                    <Field
                                        name="comments"
                                        component={InputGroupAntTextAreaAdapter}
                                        controlId="comments"
                                        labelText="Comments"
                                        rows={3}
                                    />
                                </Col>
                            </Row>
                        </div>
                        <Button
                            id="preview-order"
                            className="btn btn-primary pull-right"
                            htmlType="submit"
                            type="primary"
                        >
                            Preview Order
                        </Button>
                        <WarningEngine mutators={form.mutators} />
                    </form>
                )
            }
        </Form>
    </>
);

export default OrderChanceryForm;
