export const reportInfoType = {
    JUDGMENT: 'JUDGMENT',
    CHILDSPPT: 'CHILDSPPT',
    PATRIOT: 'PATRIOT',
};

export const reportsTableType = {
    judgementContinuation: 'judgment/judgmentContinuation',
    taxContinuation: 'propertySearch/taxContinuation',
    new: 'new',
    opened: 'opened',
    completed: 'completed',
};

export const combiningStatuses = {
    processing: 'processing',
    completed: 'completed',
    error: 'error',
};
