const initDownload = (data, filename, type = 'pdf') => {
    //IE11
    const blobType = fileBlobType[type];
    const name = `${filename}${fileExtentions[type]}`;
    const encoded = encodeURIComponent(data);
    if (window.navigator.msSaveOrOpenBlob) {
        const byteCharacters = atob(encoded);
        const byteNumbers = new Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { 'type': blobType });
        window.navigator.msSaveOrOpenBlob(blob, name);
    } else {
        const tempLink = document.createElement('a');
        tempLink.style.display = 'none';
        tempLink.href = `data:${blobType};${typeCharsets[type]},${encoded}`;
        tempLink.setAttribute('download', name);

        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
    }
};

const fileExtentions = {
    'pdf': '.pdf',
    'csv': '.csv',
};

const fileBlobType = {
    'pdf': 'application/pdf',
    'csv': 'text/csv',
};

const typeCharsets = {
    'pdf': 'base64',
    'csv': 'charset=utf-8',
};

export default initDownload;
