import { request, postRequestConfig } from '@src/utils/apiServices/api';
import {
    urlBaseRegistration,
} from '@src/utils/constants/endpointConstants';

export const register = data => (
    request({
        ...postRequestConfig,
        url: urlBaseRegistration,
        data,
    })
);

export default register;
