/**
 * Created by oleg on 11/30/16.
 */
import React, { useMemo } from 'react';
import { Row, Col } from 'antd';
import { Field } from 'react-final-form';
import { useSelector } from 'react-redux';
import FormDelimiter from '@src/components/FormDelimiter';
import { required, maxLength, composeValidators } from '@src/utils/helpers/validationRules';
import { getStates, getFetching } from '@src/store/reducers/states';
import InputGroupAntInputAdapter from '@src/components/input/InputGroupAntInputAdapter';
import InputGroupAntSelectAdapter from '@src/components/input/InputGroupAntSelectAdapter';
import { gutter } from '@src/utils/helpers/orders';

const Address = ({ formSectionName = '' }) => {
    const states = useSelector(getStates);
    const fetching = useSelector(getFetching);

    const options = useMemo(() => states.map(({ state, stateDesc }) => ({
        label: stateDesc, value: state,
    })), [states]);

    const fieldNamePrefix = formSectionName && `${formSectionName}.`;

    return (
        <>
            <Row gutter={gutter}>
                <Col md={16} xs={24}>
                    <Field
                        name={`${fieldNamePrefix}address1`}
                        component={InputGroupAntInputAdapter}
                        isRequired
                        labelText="Address Line 1"
                        validate={composeValidators(required, maxLength(60))}
                    />
                </Col>

                <Col md={16} xs={24} className="top-buffer">
                    <Field
                        name={`${fieldNamePrefix}address2`}
                        component={InputGroupAntInputAdapter}
                        labelText="Address Line 2"
                        validate={composeValidators(maxLength(60))}
                    />
                </Col>
            </Row>
            <FormDelimiter />
            <Row gutter={gutter}>
                <Col md={12} xs={24}>
                    <Field
                        name={`${fieldNamePrefix}city`}
                        component={InputGroupAntInputAdapter}
                        isRequired
                        labelText="City"
                        validate={composeValidators(required, maxLength(40))}
                    />
                </Col>
            </Row>
            <Row gutter={gutter}>
                <Col md={12} xs={24} className="top-buffer clear-left">
                    <Field
                        name={`${fieldNamePrefix}state`}
                        component={InputGroupAntSelectAdapter}
                        labelText="State"
                        options={options}
                        disabled={fetching}
                        loading={fetching}
                        optionFilterProp="label"
                        showSearch
                    />
                </Col>

                <Col md={12} xs={24} className="top-buffer">
                    <Field
                        name={`${fieldNamePrefix}zip`}
                        component={InputGroupAntInputAdapter}
                        labelText="Zip"
                        validate={composeValidators(maxLength(12))}
                    />
                </Col>
            </Row>
        </>
    );
};

export default Address;
