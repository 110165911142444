import React from 'react';
import { Navigate } from 'react-router-dom';

import UserMaintenance from '@src/containers/userMaintenance/UserMaintenanceContent';
import ProfileSettings from '@src/containers/settingsContainer/ProfileSettingsContent';
import BusinessContact from '@src/containers/settingsContainer/BusinessContactContent';
import IndividualSearches from '@src/containers/settingsContainer/IndividualSearchesContent';
import PropertySearches from '@src/containers/settingsContainer/PropertySearchesContent';
import ReportOptions from '@src/containers/settingsContainer/ReportOptionsContent';
import ChangePassword from '@src/containers/settingsContainer/ChangePasswordContent';
import {
    ACCOUNT_SETTINGS,
    ACCOUNT_SETTINGS_PERSONAL,
    ACCOUNT_SETTINGS_REPORTS,
    ACCOUNT_SETTINGS_PROPERTY_SEARCH,
    ACCOUNT_SETTINGS_BUSINESS_CONTACT,
    ACCOUNT_SETTINGS_INDIVIDUAL,
    ACCOUNT_SETTINGS_USER_MAINTENANCE,
    ACCOUNT_SETTINGS_CHANGE_PASSWORD,
} from '@src/utils/constants/routerConstants';
import RequireAuth from '@src/router/requireAuth';

const defaultPageUrl = '/account/settings/personal';

const settingsRoutes = isAdmin => ([
    {
        path: ACCOUNT_SETTINGS,
        element: (
            <Navigate to={ACCOUNT_SETTINGS_PERSONAL} replace />
        ),
    },
    {
        path: ACCOUNT_SETTINGS_PERSONAL,
        element: (
            <ProfileSettings />
        ),
    },
    {
        path: ACCOUNT_SETTINGS_REPORTS,
        element: (
            <RequireAuth
                isAvailable={isAdmin}
                redirectTo={defaultPageUrl}
            >
                <ReportOptions />
            </RequireAuth>
        ),
    },
    {
        path: ACCOUNT_SETTINGS_PROPERTY_SEARCH,
        element: (
            <RequireAuth
                isAvailable={isAdmin}
                redirectTo={defaultPageUrl}
            >
                <PropertySearches />
            </RequireAuth>
        ),
    },
    {
        path: ACCOUNT_SETTINGS_BUSINESS_CONTACT,
        element: (
            <RequireAuth
                isAvailable={isAdmin}
                redirectTo={defaultPageUrl}
            >
                <BusinessContact />
            </RequireAuth>
        ),
    },
    {
        path: ACCOUNT_SETTINGS_INDIVIDUAL,
        element: (
            <RequireAuth
                isAvailable={isAdmin}
                redirectTo={defaultPageUrl}
            >
                <IndividualSearches />
            </RequireAuth>
        ),
    },
    {
        path: ACCOUNT_SETTINGS_USER_MAINTENANCE,
        element: (
            <RequireAuth
                isAvailable={isAdmin}
                redirectTo={defaultPageUrl}
            >
                <UserMaintenance />
            </RequireAuth>
        ),
    },
    {
        path: ACCOUNT_SETTINGS_CHANGE_PASSWORD,
        element: (
            <ChangePassword />
        ),
    },
]);

export default settingsRoutes;
