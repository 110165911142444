import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Modal,
    Button,
} from 'antd';
import { Form } from 'react-final-form';
import { performUsernameCheck } from '@src/store/actions/userMaintanance/username';
import {
    isUserEditing, getUserToEdit, isCreateNewUser, isPostingUserChanges,
} from '@src/store/reducers/userMaintanance';
import {
    submitEditUser,
    submitCreateUser,
    getCurrentUser,
} from '@src/store/actions/userMaintanance';
import { editUserCancel } from '@src/store/actions/userMaintanance/userMaintenanceControl';
import { multiEmailSplitter } from '@src/utils/helpers/regexps';
import EditUserContent from './EditUserContent';

const EditUser = () => {
    const dispatch = useDispatch();
    const isModalVisible = useSelector(isUserEditing);
    const isCreatingMode = useSelector(isCreateNewUser);
    const isPostingData = useSelector(isPostingUserChanges);
    const user = useSelector(getUserToEdit);

    const checkNewUser = values => (
        isCreatingMode ? performUsernameCheck(values.user, dispatch) : new Promise(resolve => resolve())
    );

    const handler = isCreatingMode
        ? data => dispatch(submitCreateUser(data))
        : data => dispatch(submitEditUser(data)).then(() => dispatch(getCurrentUser()));

    const handleClose = () => dispatch(editUserCancel());

    return (
        <Form
            onSubmit={handler}
            initialValues={{
                ...user,
                attributes: {
                    ...user.attributes,
                    deliveryEmail: user.attributes.deliveryEmail?.split(multiEmailSplitter),
                },
            }}
        >
            {
                ({ handleSubmit, form, pristine }) => (
                    <form id="editUserForm" onSubmit={handleSubmit}>
                        <Modal
                            visible={isModalVisible}
                            destroyOnClose
                            title={isCreatingMode ? 'Add User' : 'Edit User'}
                            onCancel={handleClose}
                            footer={[
                                <Button key="back" onClick={handleClose} id="editUserCancelButton">
                                    Cancel
                                </Button>,
                                <Button
                                    key="submit"
                                    type="primary"
                                    htmlType="submit"
                                    loading={isPostingData}
                                    id="editUserSubmitButton"
                                    onClick={form.submit}
                                    disabled={pristine || isPostingData}
                                >
                                    Submit
                                </Button>,
                            ]}
                        >
                            <EditUserContent isNewUser={isCreatingMode} asyncValidate={checkNewUser} />
                        </Modal>
                    </form>
                )
            }
        </Form>
    );
};

export default EditUser;
