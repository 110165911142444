import React from 'react';
import { connect } from 'react-redux';
import { removeReportsFromNew } from '@src/store/actions/reports';
import ReportInfoJdgName from './reportinfo/ReportInfoJdgName';
import ReportInfoCombinedName from '../../../components/reportinfo/ReportInfoCombinedName';
import ReportInfoName from '../../../components/reportinfo/ReportInfoName';
import { reportInfoType } from '../../../utils/templates/reportType';

const reportNameBlock = reportInfo => {
    const { reportType } = reportInfo;
    switch (reportType) {
        case reportInfoType.JUDGMENT: {
            const { judgmentNames } = reportInfo;
            return <ReportInfoJdgName judgmentNames={judgmentNames} />;
        }
        case reportInfoType.CHILDSPPT: {
            const { judgmentNames } = reportInfo;
            return <ReportInfoCombinedName serviceNames={judgmentNames} />;
        }
        case reportInfoType.PATRIOT: {
            const { patriotNames } = reportInfo;
            return <ReportInfoCombinedName serviceNames={patriotNames} />;
        }
        default: {
            const { reportName } = reportInfo;
            return <ReportInfoName reportName={reportName} />;
        }
    }
};

const NameDetails = ({
    reportInfoList, isOrderCompleted, removeReportsFromNew, shouldRemoveFromNew,
}) => (
    <div className="formBlock table-responsive">
        <table id="orderDetailsTable" className="table">

            <thead>
                <tr>
                    <th>Report</th>
                    <th>Name(s)</th>
                    {isOrderCompleted && <th>Price</th>}
                    <th>Status</th>
                </tr>
            </thead>

            <tbody>
                {reportInfoList && reportInfoList.map(reportInfo => {
                    const {
                        type, price, requestNumber, reportStatus, reportURL, newReport,
                    } = reportInfo;
                    const reportClass = `label ${reportStatus.toLowerCase()}Status`;
                    return (
                        <tr key={requestNumber}>
                            <td>{type}</td>
                            <td>{reportNameBlock(reportInfo)}</td>
                            {isOrderCompleted && <td>{reportStatus === 'COMPLETED' && price}</td>}
                            <td>
                                <div>
                                    <span className={reportClass}>{reportStatus}</span>
                                    {reportStatus === 'COMPLETED' && (
                                        <div className="inline-block">
                                            <a //eslint-disable-line react/jsx-no-target-blank
                                                href={reportURL}
                                                onClick={() => (
                                                    newReport
                                                    && shouldRemoveFromNew
                                                    && removeReportsFromNew([requestNumber])
                                                )}
                                                className="typeLink"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                Download file
                                            </a>
                                        </div>
                                    )}
                                </div>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    </div>
);

const mapDispatchToProps = {
    removeReportsFromNew,
};

export default connect(null, mapDispatchToProps)(NameDetails);
