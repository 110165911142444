const orderType = {
    NAME: 'NAME',
    ABSTRACT: 'ABSTRACT',
    JUDGMENT: 'JUDGMENT',
    JDG_CONTINUATION: 'JDG_CONTINUATION',
    PROPERTY_SEARCH: 'PROPERTY_SEARCH',
    TAX_CONTINUATION: 'TAX_CONTINUATION',
};

export default orderType;
