import React from 'react';
import { Button } from 'antd';

const StepsNavigationItem = React.forwardRef(({
    isCurrent,
    isPassed,
    isDisabled,
    clickHandler,
    id,
    name,
    description,
}, ref) => (
    <li
        className={`${isCurrent ? 'current' : ''} ${isPassed ? 'done' : ''} ${isDisabled ? 'disabled' : ''}`}
    >
        <Button
            id={id}
            ref={ref}
            onClick={isDisabled ? () => {} : clickHandler}
        >
            <div>
                <div className="topTriangle" />
                <div className="bottomTriangle" />
            </div>
            <div className="stepContent">
                <span className="stepName">{name}</span>
                <div className="stepDesc">{description}</div>
            </div>
            <div className="frontTriangle" />
        </Button>
    </li>
));

export default StepsNavigationItem;
