import {
    request,
    getRequestConfig,
    postRequestConfig,
} from '@src/utils/apiServices/api';
import { getCayenneExpression } from '@src/utils/helpers/cayenExpressionAdapter';
import {
    urlCustomers,
    urlAccountSelectCustomer,
    urlAccountSelectCustomerReset,
    urlCurrentCustomer,
    urlDefaultCustomer,
    urlAccountAffiliatedCustomers,
    urlAccountAffiliatedCustomersSelect,
} from '@src/utils/constants/endpointConstants';
import { interpolate } from '@src/utils/helpers/normalize';

export const fetchCustomers = (
    includes,
    {
        filter,
        skip,
        take,
        sort,
    },
) => (
    request({
        ...getRequestConfig,
        params: {
            include: includes,
            start: skip,
            limit: take,
            sort: sort[0].selector,
            dir: sort[0].desc ? 'DESC' : 'ASC',
            exp: getCayenneExpression(filter),
        },
        url: urlCustomers,
    })
);

export const fetchCurrentCustomer = () => (
    request({
        ...getRequestConfig,
        url: urlCurrentCustomer,
    })
);

export const selectCustomer = id => (
    request({
        ...postRequestConfig,
        url: urlAccountSelectCustomer,
        data: id,
    })
);

export const selectCustomerReset = () => (
    request({
        ...postRequestConfig,
        url: urlAccountSelectCustomerReset,
    })
);

export const checkDefaultCustomer = () => (
    request({
        ...getRequestConfig,
        url: urlDefaultCustomer,
    })
);

export const fetchAffiliatedCustomers = () => (
    request({
        ...getRequestConfig,
        url: urlAccountAffiliatedCustomers,
    })
);

export const selectAffiliatedCustomer = id => (
    request({
        ...postRequestConfig,
        url: interpolate(urlAccountAffiliatedCustomersSelect, id),
    })
);
