/**
 * Created by oleg on 11/29/16.
 */
import React from 'react';
import PropTypes from 'prop-types';

const PageHeader = ({ pageName }) => (
    <div className="contentHeader text-center">
        <h1>
            {pageName}
        </h1>
    </div>
);

PageHeader.propTypes = {
    pageName: PropTypes.string,
};

export default PageHeader;
