/**
 * Created by oleg on 12/20/16.
 */
import React from 'react';
import FormDelimiter from '@src/components/FormDelimiter';
import ContentHeader from '@src/containers/registration/components/utils/ContentHeader';
import BillingInformationReview from '@src/containers/registration/components/previewAndRegister/BillingInformationReview';

const ReviewAndRegister = ({
    values: {
        companyName,
        mailingInformation,
        businessPhone,
        employeeFirstName,
        employeeLastName,
        username,
        employeePhone,
        employeeExt,
        billingInformation,
        billingAddressSameAsCompany,
    },
}) => (
    <div id="finishRegistrationForm" className="full-width inline-block">
        <ContentHeader contentName="Finish Registration" />

        <div className="formBlock">
            <h4>Business Contact Information</h4>
            <dl className="dl-horizontal">
                <dt>Company Name</dt>
                <dd>{companyName}</dd>
            </dl>
            <FormDelimiter />
            <dl className="dl-horizontal">
                <dt>Address Line 1</dt>
                <dd>{mailingInformation.address1}</dd>
                <dt>Address Line 2</dt>
                <dd>{mailingInformation.address2}</dd>
            </dl>
            <FormDelimiter />
            <dl className="dl-horizontal">
                <dt>City</dt>
                <dd>{mailingInformation.city}</dd>
                <dt>State</dt>
                <dd>{mailingInformation.state}</dd>
                <dt>Zip</dt>
                <dd>{mailingInformation.zip}</dd>
            </dl>
            <FormDelimiter />
            <dl className="dl-horizontal">
                <dt>Phone</dt>
                <dd className="phoneNumber">{businessPhone}</dd>
                <dt>Email</dt>
                <dd className="email">{mailingInformation.email}</dd>
            </dl>

            {
                !billingAddressSameAsCompany
                && (
                    <BillingInformationReview
                        billingInformation={billingInformation}
                    />
                )
            }
        </div>

        <div className="formBlock">

            <div className="h4">Employee Information</div>

            <dl className="dl-horizontal">
                <dt>First Name</dt>
                <dd>{employeeFirstName}</dd>
                <dt>Last Name</dt>
                <dd>{employeeLastName}</dd>
                <dt>Email</dt>
                <dd>{username}</dd>
                <dt>Phone</dt>
                <dd className="phoneNumber">{employeePhone}</dd>
                <dt>ext</dt>
                <dd>{employeeExt}</dd>
            </dl>
        </div>
    </div>
);

export default ReviewAndRegister;
