/**
 * Created by oleg on 7/7/17.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'antd';

class AlertMessage extends Component {
    constructor(...args) {
        super(...args);
        this.closeTimeout = null;
        this.closeAlert = this.closeAlert.bind(this);
    }

    //TODO rewrite to functional component

    // eslint-disable-next-line react/no-deprecated
    componentWillMount() {
        const { autoClosable } = this.props;
        if (autoClosable) {
            this.closeTimeout = setTimeout(this.closeAlert, 10000);
        }
    }

    componentWillUnmount() {
        if (this.closeTimeout) {
            clearTimeout(this.closeTimeout);
        }
    }

    closeAlert() {
        const {
            removeAlert, alertId,
        } = this.props;
        removeAlert(alertId);
    }

    render() {
        const {
            alertType,
            message,
            removeAlert, alertId,
        } = this.props;

        return (
            <Alert
                message={message}
                type={alertType}
                showIcon
                closable
                onClose={() => removeAlert(alertId)}
            />
        );
    }
}

AlertMessage.propTypes = {
    alertType: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    removeAlert: PropTypes.func.isRequired,
    alertId: PropTypes.string.isRequired,
    autoClosable: PropTypes.bool,
};

AlertMessage.defaultProps = {
    autoClosable: false,
};

export default AlertMessage;
