import React from 'react';
import { Col, Row } from 'antd';
import { Field } from 'react-final-form';
import InputGroupAntCheckBoxAdapter from '@src/components/input/InputGroupAntCheckBoxAdapter';

const ServiceRegAgentLetter = ({
    name,
}) => (
    <div className="serviceBlock formBlock">
        <Row className="serviceHeader">
            <Col md={24} lg={16}>
                <Field
                    name={`${name}.selectedServicesTypes.regAgentLetter`}
                    component={InputGroupAntCheckBoxAdapter}
                    controlId={`${name}.selectedServicesTypes.regAgentLetter`}
                    labelText="REGISTERED AGENT LETTER"
                    type="checkbox"
                />
            </Col>
        </Row>
    </div>
);

export default ServiceRegAgentLetter;
