import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { Row, Col, Button } from 'antd';
import createDecorator from 'final-form-focus';
import Loader from '@src/components/Loader';
import {
    composeValidators, phoneNumber, required,
    requiredAtLeastOneInArray, multiEmailTags,
    maxLengthForTags,
} from '@src/utils/helpers/validationRules';
import { getOptions } from '@src/utils/helpers/parse';
import { multiEmailSplitter } from '@src/utils/helpers/regexps';
import { fetchAllStates } from '@src/utils/apiServices/accountSettingsRequests';
import { getCurrentUser } from '@src/store/actions/userMaintanance';
import { setSettingsContainerTitle, saveAccountSettings } from '@src/store/actions/accountSettings';
import { getBusinessContactSettings, isAccountSettingsSaving } from '@src/store/reducers/accountSettings';
import { BUSINESS_CONTACT_SETTINGS } from '@src/utils/constants/settingsConstants';
import { phone } from '@src/utils/helpers/normalize';
import { emailsJoiner, emailsSeparators } from '@src/utils/constants/formConstants';
import InputGroupAntTagBoxAdapter from '@src/components/input/InputGroupAntTagBoxAdapter';
import InputGroupAntInputAdapter from '@src/components/input/InputGroupAntInputAdapter';
import InputGroupAntSelectAdapter from '@src/components/input/InputGroupAntSelectAdapter';
import { gutter } from '@src/utils/helpers/orders';

const focusOnError = createDecorator();

const BusinessContactContent = () => {
    const businessContactSettings = useSelector(state => getBusinessContactSettings(state));
    const isSaving = useSelector(state => isAccountSettingsSaving(state));
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [states, setStates] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const allStates = await fetchAllStates();
            setStates(getOptions(allStates, 'stateDesc', 'state'));
            setLoading(false);
        };

        dispatch(setSettingsContainerTitle('Business Contact Information'));
        fetchData();
    }, []);

    return (
        <Form
            onSubmit={data => {
                dispatch(saveAccountSettings(
                    {
                        ...data,
                        mailingSettings: {
                            ...data.mailingSettings,
                            email: data.mailingSettings.email.join(emailsJoiner),
                        },
                        billingSettings: {
                            ...data.billingSettings,
                            email: data.billingSettings.email.join(emailsJoiner),
                        },
                    },
                    BUSINESS_CONTACT_SETTINGS,
                )).then(() => dispatch(getCurrentUser()));
            }}
            initialValues={{
                ...businessContactSettings,
                mailingSettings: {
                    ...businessContactSettings.mailingSettings,
                    email: businessContactSettings.mailingSettings.email
                        ? businessContactSettings.mailingSettings.email
                            .split(multiEmailSplitter)
                            .filter(item => !!item.length)
                        : [],
                },
                billingSettings: {
                    ...businessContactSettings.billingSettings,
                    email: businessContactSettings.billingSettings.email
                        ? businessContactSettings.billingSettings.email
                            .split(multiEmailSplitter)
                            .filter(item => !!item.length)
                        : [],
                },
            }}
            decorators={[focusOnError]}
        >
            {
                ({ handleSubmit, form, pristine }) => (
                    <form onSubmit={handleSubmit}>
                        <Loader show={loading || isSaving}>
                            <div className="formBlock">
                                <div className="h4">
                                    Mailing Address
                                </div>
                                <Row gutter={gutter}>
                                    <Col md={16} xs={24}>
                                        <Field
                                            name="mailingSettings.addr1"
                                            component={InputGroupAntInputAdapter}
                                            controlId="mailingSettings.addr1"
                                            labelText="Address Line 1"
                                            isRequired
                                            validate={composeValidators(required)}
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={gutter}>
                                    <Col md={16} xs={24}>
                                        <Field
                                            name="mailingSettings.addr2"
                                            component={InputGroupAntInputAdapter}
                                            controlId="mailingSettings.addr2"
                                            labelText="Address Line 2"
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={gutter}>
                                    <Col md={12} xs={24}>
                                        <Field
                                            name="mailingSettings.city"
                                            component={InputGroupAntInputAdapter}
                                            controlId="mailingSettings.city"
                                            labelText="City"
                                            isRequired
                                            validate={composeValidators(required)}
                                        />
                                    </Col>
                                    <Col md={12} xs={24}>
                                        <Field
                                            name="mailingSettings.state"
                                            component={InputGroupAntSelectAdapter}
                                            labelText="State"
                                            options={states}
                                            controlId="mailingSettings.state"
                                            showSearch
                                            optionFilterProp="label"
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={gutter}>
                                    <Col md={12} xs={24}>
                                        <Field
                                            name="mailingSettings.zip"
                                            component={InputGroupAntInputAdapter}
                                            controlId="mailingSettings.zip"
                                            labelText="Zip"
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={gutter}>
                                    <Col md={16} xs={24}>
                                        <Field
                                            name="mailingSettings.email"
                                            controlId="mailingSettings.email"
                                            labelText="Email"
                                            component={InputGroupAntTagBoxAdapter}
                                            tokenSeparators={emailsSeparators}
                                            validate={composeValidators(
                                                requiredAtLeastOneInArray,
                                                multiEmailTags,
                                                maxLengthForTags(255, emailsJoiner),
                                            )}
                                            isRequired
                                            formSubmit={form.submit}
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div className="formBlock">
                                <div className="h4">
                                    Billing Address
                                </div>
                                <Row gutter={gutter}>
                                    <Col md={16} xs={24}>
                                        <Field
                                            name="billingSettings.addr1"
                                            component={InputGroupAntInputAdapter}
                                            controlId="billingSettings.addr1"
                                            labelText="Address Line 1"
                                            isRequired
                                            validate={composeValidators(required)}
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={gutter}>
                                    <Col md={16} xs={24}>
                                        <Field
                                            name="billingSettings.addr2"
                                            component={InputGroupAntInputAdapter}
                                            controlId="billingSettings.addr2"
                                            labelText="Address Line 2"
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={gutter}>
                                    <Col md={12} xs={24}>
                                        <Field
                                            name="billingSettings.city"
                                            component={InputGroupAntInputAdapter}
                                            controlId="billingSettings.city"
                                            labelText="City"
                                            isRequired
                                            validate={composeValidators(required)}
                                        />
                                    </Col>
                                    <Col md={12} xs={24}>
                                        <Field
                                            name="billingSettings.state"
                                            component={InputGroupAntSelectAdapter}
                                            labelText="State"
                                            options={states}
                                            controlId="billingSettings.state"
                                            showSearch
                                            optionFilterProp="label"
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={gutter}>
                                    <Col md={12} xs={24}>
                                        <Field
                                            name="billingSettings.zip"
                                            component={InputGroupAntInputAdapter}
                                            controlId="billingSettings.zip"
                                            labelText="Zip"
                                        />
                                    </Col>
                                </Row>
                                <hr className="pull-left full-width" />
                                <Row gutter={gutter}>
                                    <Col md={12} xs={24}>
                                        <Field
                                            name="billingSettings.phone"
                                            component={InputGroupAntInputAdapter}
                                            controlId="billingSettings.phone"
                                            labelText="Phone"
                                            validate={composeValidators(phoneNumber)}
                                            format={phone}
                                            parse={phone}
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={gutter}>
                                    <Col md={16} xs={24}>
                                        <Field
                                            name="billingSettings.email"
                                            controlId="billingSettings.email"
                                            labelText="Email"
                                            component={InputGroupAntTagBoxAdapter}
                                            tokenSeparators={emailsSeparators}
                                            validate={composeValidators(
                                                requiredAtLeastOneInArray,
                                                multiEmailTags,
                                                maxLengthForTags(255, emailsJoiner),
                                            )}
                                            isRequired
                                            formSubmit={form.submit}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Loader>
                        <Row gutter={gutter}>
                            <Col md={24} xs={24} className="noGutter">
                                <Button
                                    value="Save"
                                    id="saveChanges"
                                    className="btn btn-primary pull-right"
                                    htmlType="submit"
                                    type="primary"
                                    disabled={isSaving || pristine}
                                >
                                    Save
                                </Button>
                                <Button
                                    id="cancelChanges"
                                    className="cancel btn btn-default pull-right right-buffer-xs"
                                    htmlType="button"
                                    onClick={form.reset}
                                    disabled={isSaving || pristine}
                                >
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                    </form>
                )
            }
        </Form>
    );
};

export default BusinessContactContent;
