import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { performCreateUser } from '../../../store/actions/userMaintanance';

const AddNewUser = () => {
    const dispatch = useDispatch();
    return (
        <Button
            htmlType="button"
            onClick={() => dispatch(performCreateUser())}
            id="addNewUserButton"
            icon={<PlusOutlined className="color-green" />}
        >
            Add User
        </Button>
    );
};

export default AddNewUser;
