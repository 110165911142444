/**
 * Created by oleg on 7/7/17.
 */
import { v4 as uuid } from 'uuid';
import { ADD_ALERT, REMOVE_ALERT } from '@src/store/actions/alerts';

const alerts = (state = [], action) => {
    switch (action.type) {
        case ADD_ALERT:
            return [
                ...state,
                {
                    message: action.message,
                    alertType: action.alertType,
                    alertId: uuid(),
                    autoClosable: action.autoClosable,
                },
            ];
        case REMOVE_ALERT:
            return state.filter(alert => alert.alertId !== action.id);
        default:
            return state;
    }
};

export const getAlerts = state => state.alerts;
export default alerts;
