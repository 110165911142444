/**
 * Created by oleg on 1/3/17.
 */
import { register } from '@src/utils/apiServices/registrationRequests';
import { addErrorAlert } from '@src/store/actions/alerts';

export const REGISTRATION_SUBMIT = 'REGISTRATION_SUBMIT';
export const REGISTRATION_ERROR = 'REGISTRATION_ERROR';
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS';

export const submitRegistration = parameters => {
    const that = parameters;
    return dispatch => {
        dispatch(registrationSubmit());

        return register(that).then(response => {
            dispatch(registrationSuccess(response));
        }).catch(error => {
            dispatch(registrationError(error));
            dispatch(addErrorAlert('Can not register customer. Please, try again later.', true));
        });
    };
};

export const registrationSubmit = () => ({
    type: REGISTRATION_SUBMIT,
});

export const registrationError = error => ({
    type: REGISTRATION_ERROR,
    error,
});

export const registrationSuccess = response => ({
    type: REGISTRATION_SUCCESS,
    response,
});
