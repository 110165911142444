import { addErrorAlert } from '@src/store/actions/alerts';
import initDownload from '@src/utils/helpers/downloadFile';
import {
    postOrder as postOrderApi,
    downloadConfirmation as downloadConfirmationApi,
    fetchOrderDetails as fetchOrderDetailsApi,
} from '@src/utils/apiServices/ordersRequests';
import {
    orderDetailsFetchingAction, orderDetailsFetchingFailed,
    orderDetailsFetchingSucceeded,
} from '@src/store/actions/orders';
import {
    postingOrder,
    postOrderSucceeded,
    postOrderFailed,
} from './ordersControl';

export const postOrder = (type, data) => dispatch => {
    dispatch(postingOrder(type));

    return postOrderApi(type, data).then(response => {
        dispatch(postOrderSucceeded(response, type));
    }).catch(error => {
        dispatch(postOrderFailed(error));
    });
};

export const downloadConfirmation = orderId => dispatch => downloadConfirmationApi(orderId).then(response => {
    initDownload(response, `orderConfirmation${orderId}`, 'pdf');
}).catch(error => {
    // eslint-disable-next-line no-console
    console.error(error);
    dispatch(addErrorAlert('Something went wrong during order confirmation download. Please, try again later.', true));
});

export const fetchOrderDetails = orderId => dispatch => {
    dispatch(orderDetailsFetchingAction());
    return fetchOrderDetailsApi(orderId).then(response => {
        dispatch(orderDetailsFetchingSucceeded(response));
    }).catch(error => {
        dispatch(orderDetailsFetchingFailed(error));
        dispatch(addErrorAlert('Something went wrong during fetching order details'));
    });
};
