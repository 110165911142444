import React, { useState } from 'react';
import { Button, Typography } from 'antd';
import { SelectOutlined } from '@ant-design/icons';
import DataGrid, {
    Column,
    Pager,
    Paging,
    RemoteOperations,
    FilterRow,
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { fetchCustomers } from '@src/utils/apiServices/customersRequests';

let filtersAreReady = false;

const getDataSource = (
    tableColumns,
    handleLoadingStart,
    handleLoadingSuccess,
    handleLoadingError,
) => ({
    store: new CustomStore({
        useDefaultSearch: true,
        onLoaded(result) {
            handleLoadingSuccess(result);
        },
        onLoading() {
            handleLoadingStart();
        },
        load({
            sort,
            filter,
            take,
            skip,
        }) {
            if (filtersAreReady) {
                return fetchCustomers(
                    tableColumns,
                    {
                        sort,
                        filter,
                        take,
                        skip,
                    },
                )
                    .then(data => (
                        {
                            data: data.data,
                            totalCount: data.total,
                        }
                    ))
                    .catch(error => {
                        handleLoadingError(error);
                        throw new Error(error);
                    });
            }
            return new Promise(resolve => resolve({
                data: [],
                totalCount: 0,
            }));
        },
    }),
});

const calculateAddress = ({ city, region, postalCode }) => [city, region, postalCode].filter(i => i).join(', ');

const renderPriceCode = ({ value }) => value || 'N/A';

const renderActions = ({ row: { data: { custid } } }, handleSelectCustomer) => (
    <div className="buttons-block-wrapper">
        <Button.Group>
            <Button
                size="small"
                icon={<SelectOutlined />}
                type="link"
                onClick={() => handleSelectCustomer(custid)}
                target="_blank"
                rel="noopener noreferrer"
                title="Select Customer"
            />
        </Button.Group>
    </div>
);

const renderName = ({ row: { data: { custName, nickName } } }) => (
    <div className="customer-name-cell-wrapper">
        {custName}
        {
            nickName
            && <Typography.Text type="secondary">{` - ${nickName}`}</Typography.Text>
        }
    </div>
);

const renderStatus = ({ value }) => (
    value
        ? <span className="label label-danger font-weight-normal">Inactive</span>
        : <span className="label label-success font-weight-normal">Active</span>
);

const onEditorPreparingHandler = (e, creditHoldFilter, setCreditHoldFilter) => {
    if (e.parentType === 'filterRow') {
        if (e.dataField === 'creditHold') {
            e.editorName = 'dxSelectBox';
            e.editorOptions = {
                items: [
                    { text: 'All', value: null },
                    { text: 'Active', value: false },
                    { text: 'Inactive', value: true },
                ],
                displayExpr: 'text',
                valueExpr: 'value',
                value: creditHoldFilter,
                onValueChanged: args => {
                    setCreditHoldFilter(args.value);
                    e.setValue(args.value);
                },
            };
            filtersAreReady = true;
        }
    }
};

const onEditorPreparedHandler = e => {
    if (e.dataField === 'custName') {
        e.editorElement.querySelector('.dx-texteditor-input').focus();
    }
};

const CustomersTable = ({
    handleSelectCustomer,
    tableColumns,
    handleLoadingStart,
    handleLoadingSuccess,
    handleLoadingError,
}) => {
    const [creditHoldFilter, setCreditHoldFilter] = useState(false);

    return (
        <DataGrid
            id="gridContainer"
            dataSource={getDataSource(
                tableColumns,
                handleLoadingStart,
                handleLoadingSuccess,
                handleLoadingError,
            )}
            allowColumnReordering
            allowColumnResizing
            columnHidingEnabled
            loadPanel={{ enabled: false }}
            showBorders
            showRowLines
            showColumnLines
            rowAlternationEnabled
            wordWrapEnabled
            onEditorPreparing={e => onEditorPreparingHandler(e, creditHoldFilter, setCreditHoldFilter)}
            onEditorPrepared={onEditorPreparedHandler}
            onOptionChanged={e => {
                if (e.name === 'columns') {
                    if ((e.fullName.endsWith('sortOrder') || (e.fullName.endsWith('filterValue')))) {
                        e.component.pageIndex(0);
                    }
                } else if (e.name === 'paging') {
                    if (e.fullName.endsWith('pageSize')) {
                        e.component.pageIndex(0);
                    }
                }
            }}
            onCellPrepared={info => {
                if (info.rowType === 'filter' && info.column.dataField === 'custName') {
                    // add class for testing purposes
                    info.cellElement.classList.add('customer-name-filter-field');
                }
            }}
        >
            <FilterRow
                applyFilterText="Apply filter"
                visible
            />
            <Paging defaultPageSize={25} />
            <Pager
                showPageSizeSelector
                allowedPageSizes={[10, 25, 50, 100]}
                showInfo
            />
            <RemoteOperations
                sorting
                paging
                filtering
            />
            <Column
                dataField="custid"
                cssClass="cell-customer-id"
                caption="ID"
                defaultSortOrder="asc"
                width={90}
            />
            <Column
                dataField="custName"
                cssClass="cell-customer-name"
                caption="Name"
                cellRender={renderName}
            />
            <Column
                dataField="creditHold"
                cssClass="cell-status"
                caption="Status"
                width={100}
                cellRender={renderStatus}
                alignment="left"
                defaultFilterValue={false}
                hidingPriority={3}
            />
            <Column
                dataField="priceCode"
                cssClass="cell-price-code"
                caption="Price Code"
                cellRender={renderPriceCode}
                width={140}
                allowFiltering={false}
                hidingPriority={2}
            />
            <Column
                dataField="city"
                cssClass="cell-address"
                caption="Address"
                allowSorting={false}
                allowFiltering={false}
                calculateDisplayValue={calculateAddress}
                hidingPriority={1}
                width={240}
            />
            <Column
                cssClass="cell-actions"
                caption="Actions"
                cellRender={data => renderActions(data, handleSelectCustomer)}
                alignment="center"
                width={80}
            />
        </DataGrid>
    );
};

export default CustomersTable;
