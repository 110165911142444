import React,
{
    useEffect,
    useCallback,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllStates } from '@src/store/actions/states';
import {
    isPageOverlayShown,
    getPageOverlayMessage,
    isRegistrationSucceeded as getIsRegistrationSucceeded,
    getNavigationStep,
} from '@src/store/reducers/registration';
import Loader from '@src/components/Loader';
import { Button, Col, Row } from 'antd';
import { Form } from 'react-final-form';
import { submitRegistration, previousPage, nextPage } from '@src/store/actions/registration';
import CorporateInformation from './corporateInformation/CorporateInformation';
import PersonalInformation from './personalInformation/PersonalInformation';
import ReviewAndRegister from './previewAndRegister/ReviewAndRegister';
import FinishRegistration from './finishRegistration/FinishRegistration';

const getInitialEmail = () => {
    const params = new URLSearchParams(document.location.search.substring(1));

    return params.get('email');
};

const initialValues = {
    billingAddressSameAsCompany: true,
    mailingInformation: {
        email: getInitialEmail(),
        state: 'NJ',
    },
    billingInformation: { state: 'NJ' },
};

const RegistrationContentContainer = () => {
    const dispatch = useDispatch();

    const activePage = useSelector(getNavigationStep);
    const showOverlay = useSelector(isPageOverlayShown);
    const overlayMessage = useSelector(getPageOverlayMessage);
    const isRegistrationSucceeded = useSelector(getIsRegistrationSucceeded);

    const [registrationData, setRegistrationData] = useState(initialValues);

    useEffect(() => {
        dispatch(getAllStates());
    }, []);

    const handleSubmit = useCallback(values => {
        const isLastPage = activePage === 'REV_REG';
        if (isLastPage) {
            const { billingInformation, ...valuesWithoutBillingInformation } = values;
            dispatch(submitRegistration(values.billingAddressSameAsCompany ? valuesWithoutBillingInformation : values));
        } else {
            dispatch(nextPage());
            setRegistrationData(values);
        }
    }, [activePage]);

    if (isRegistrationSucceeded) {
        return <FinishRegistration />;
    }

    return (
        <Form
            initialValues={registrationData}
            onSubmit={handleSubmit}
        >
            {({ handleSubmit, submitting, values }) => (
                <form onSubmit={handleSubmit}>
                    <Row justify="center">
                        <Col md={16} sm={20} xs={24}>
                            <Loader show={showOverlay} message={overlayMessage}>
                                {activePage === 'CORP_INF' && <CorporateInformation billingAsMailing={values.billingAddressSameAsCompany} />}
                                {activePage === 'PERS_INF' && <PersonalInformation />}
                                {activePage === 'REV_REG' && <ReviewAndRegister values={registrationData} />}
                            </Loader>
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col md={16} sm={20} xs={24}>
                            {
                                activePage !== 'REV_REG'
                                    ? (
                                        <Button
                                            htmlType="submit"
                                            className="pull-right"
                                            type="primary"
                                        >
                                            Next »
                                        </Button>
                                    )
                                    : (
                                        <Button
                                            htmlType="submit"
                                            className="pull-right"
                                            disabled={submitting}
                                            type="primary"
                                        >
                                            Submit
                                        </Button>
                                    )
                            }
                            {
                                activePage !== 'CORP_INF'
                                && (
                                    <Button
                                        className="pull-right right-buffer-xs"
                                        htmlType="button"
                                        onClick={() => dispatch(previousPage())}
                                    >
                                        « Previous
                                    </Button>
                                )
                            }
                        </Col>
                    </Row>
                </form>
            )}
        </Form>
    );
};

export default RegistrationContentContainer;
