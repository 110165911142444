import { padStart, padEnd } from './pad';

const docketJdg = (sequence, year, court) => {
    switch (getCourtType(court)) {
        case 'bankruptcy':
            return `${year.slice(-2)}-${padStart(sequence, 5, '0')}`;
        case 'superior':
            return court === 'J' ? padEnd(court, 2, ' ') + padStart(sequence, 6, '0') + year.slice(-2) : court.slice(0, 2) + padStart(sequence, 6, '0') + year.slice(-2);
        case 'district':
            return court.startsWith('RG') ? court + padStart(sequence, 5, '0') : year.slice(-2) + court.slice(0, 2) + padStart(sequence, 5, '0');
        default:
            return null;
    }
};

const courts = {
    'BK': 'bankruptcy',
    'J': 'superior',
    'DJ': 'superior',
    'ML': 'superior',
    'MP1': 'superior',
    'PD': 'superior',
    'AP': 'district',
    'CB': 'district',
    'CR': 'district',
    'CV': 'district',
    'MG': 'district',
    'MP2': 'district',
    'MS': 'district',
    'RG000': 'district',
    'RGCAM': 'district',
    'RGNEW': 'district',
    'RGTRN': 'district',
};

const getCourtType = court => courts[court];

export default docketJdg;

export const isOldDocket = value => {
    if (value.length !== 2) {
        return false;
    }
    if (+value[0] === 0) {
        return value[1] < 6;
    }

    return value > 90;
};
