import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'antd';
import Loader from '@src/components/Loader';
import { setSettingsContainerTitle } from '@src/store/actions/accountSettings';
import UserInformationContainer from './userInformation/UserInformationContainer';
import { getUsers } from '../../store/actions/userMaintanance';
import EditUser from './editUser/EditUser';
import AddNewUser from './editUser/AddNewUser';
import DeactivateUser from './editUser/DeactivateUser';
import { isUsersFetching } from '../../store/reducers/userMaintanance';

const UserMaintenanceContent = () => {
    const formLoading = useSelector(state => isUsersFetching(state));
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setSettingsContainerTitle('User Maintenance'));
        dispatch(getUsers());
    }, []);

    return (
        <div>
            <Loader show={formLoading}>
                <div id="userMaintenanceContent">
                    <Row className="bottom-buffer top-buffer-on-sm">
                        <Col md={18} xs={18}>
                            <AddNewUser />
                        </Col>
                        <Col md={6} xs={6} className="pull-right">
                            <DeactivateUser />
                        </Col>
                    </Row>
                    <EditUser />
                    <UserInformationContainer />
                </div>
            </Loader>
        </div>
    );
};

export default UserMaintenanceContent;
