import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { Row, Col, Button } from 'antd';
import { NavLink } from 'react-router-dom';
import Loader from '@src/components/Loader';
import {
    composeValidators,
    required,
    maxLength,
    conditionRequired,
    phoneNumber,
    requiredAtLeastOneInArray,
    multiEmailTags,
    maxLengthForTags,
} from '@src/utils/helpers/validationRules';
import createDecorator from 'final-form-focus';
import { getOptionsDefaultLoginPage } from '@src/utils/templates/accountSettings';
import { parseTemplatesFromArray } from '@src/utils/helpers/parse';
import { emailsJoiner, emailsSeparators } from '@src/utils/constants/formConstants';
import {
    customizingSubjectOfEmail,
    customizingAttachmentNameOfEmail,
    managedAtCompanyLevel,
} from '@src/utils/constants/strings';
import {
    fetchAccountTemplateKeys,
} from '@src/utils/apiServices/accountSettingsRequests';
import { saveAccountSettings, setSettingsContainerTitle } from '@src/store/actions/accountSettings';
import { getCurrentUser } from '@src/store/actions/userMaintanance';
import { ACCOUNT_SETTINGS_CHANGE_PASSWORD } from '@src/utils/constants/routerConstants';
import {
    getPersonalSettings,
    isAccountSettingsSaving,
    isAdmin as getIsAdmin,
    getReportSettings,
} from '@src/store/reducers/accountSettings';
import { getCurrentCustomer } from '@src/store/reducers/customers';
import { PERSONAL_SETTINGS } from '@src/utils/constants/settingsConstants';
import InputGroupAntTagBoxAdapter from '@src/components/input/InputGroupAntTagBoxAdapter';
import InputGroupAntSelectAdapter from '@src/components/input/InputGroupAntSelectAdapter';
import InputGroupAntInputAdapter from '@src/components/input/InputGroupAntInputAdapter';
import InputGroupAntCheckBoxAdapter from '@src/components/input/InputGroupAntCheckBoxAdapter';
import { phone } from '@src/utils/helpers/normalize';
import { multiEmailSplitter } from '@src/utils/helpers/regexps';
import { getDefaultPageOptions } from '@src/utils/helpers/permissions';
import { gutter } from '@src/utils/helpers/orders';

const focusOnError = createDecorator();

const ProfileSettingsContent = () => {
    const isAdmin = useSelector(getIsAdmin);
    const personalSettings = useSelector(getPersonalSettings);
    const reportSettings = useSelector(getReportSettings);
    const settings = isAdmin ? reportSettings : personalSettings;
    const profileSettings = {
        ...personalSettings,
        companyDeliverySettings: settings.companyDeliverySettings,
        outputControlledBy: settings.outputControlledBy,
    };
    const { outputControlledBy } = profileSettings;
    const isOutputControlledByCompany = outputControlledBy === 'COMPANY';
    const deliverySettings = isOutputControlledByCompany ? 'companyDeliverySettings' : 'individualDeliverySettings';
    const isSaving = useSelector(isAccountSettingsSaving);
    const { allowWebOrders, webDelivery } = useSelector(getCurrentCustomer);
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [templateKeys, setTemplateKeys] = useState([]);
    const isDisabledDeliveryMethod = isOutputControlledByCompany;

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const templateKeys = await fetchAccountTemplateKeys();
            setTemplateKeys(templateKeys);
            setLoading(false);
        };

        dispatch(setSettingsContainerTitle('Profile Settings'));
        fetchData();
    }, []);

    const initialValues = useMemo(() => ({
        ...profileSettings,
        email: profileSettings.email
            ? profileSettings.email
                .split(multiEmailSplitter)
                .filter(item => !!item.length)
            : [],
        [deliverySettings]: {
            ...profileSettings[deliverySettings],
            emailConfAddress: profileSettings[deliverySettings].emailConfAddress
                ? profileSettings[deliverySettings].emailConfAddress
                    .split(multiEmailSplitter)
                    .filter(item => !!item.length)
                : [],
        },
    }), [
        personalSettings,
        reportSettings,
        isAdmin,
        deliverySettings,
    ]);

    return (
        <Form
            onSubmit={data => {
                dispatch(saveAccountSettings(
                    {
                        ...data,
                        email: data.email.join(emailsJoiner),
                        [deliverySettings]: {
                            ...data[deliverySettings],
                            emailConfAddress: data[deliverySettings].emailConfAddress.join(emailsJoiner),
                        },
                    },
                    PERSONAL_SETTINGS,
                )).then(() => dispatch(getCurrentUser()));
            }}
            initialValues={initialValues}
            decorators={[focusOnError]}
            validate={(
                {
                    [deliverySettings]: {
                        emailConf,
                        emailConfAddress,
                        emailResults,
                        emailSubject,
                        attachmentName,
                    },
                },
            ) => ({
                [deliverySettings]: {
                    emailConfAddress: conditionRequired(emailConf, emailConfAddress.length),
                    emailSubject: conditionRequired(emailResults, emailSubject),
                    attachmentName: conditionRequired(emailResults, attachmentName),
                },
            })}
        >
            {
                ({
                    handleSubmit, form, values, pristine,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Loader show={loading || isSaving}>
                            <div className="formBlock">
                                <Row gutter={gutter}>
                                    <Col md={16} xs={24} className="top-buffer-xs">
                                        <Field
                                            name="firstName"
                                            component={InputGroupAntInputAdapter}
                                            controlId="firstName"
                                            isRequired
                                            labelText="First Name"
                                            validate={composeValidators(required, maxLength(25))}
                                            autoFocus
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={gutter}>
                                    <Col md={16} xs={24} className="top-buffer-xs">
                                        <Field
                                            name="lastName"
                                            component={InputGroupAntInputAdapter}
                                            controlId="lastName"
                                            isRequired
                                            labelText="Last Name"
                                            validate={composeValidators(required, maxLength(25))}
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={gutter}>
                                    <Col md={16} xs={24} className="top-buffer-xs">
                                        <Field
                                            name="email"
                                            controlId="email"
                                            labelText="Delivery Email"
                                            component={InputGroupAntTagBoxAdapter}
                                            tokenSeparators={emailsSeparators}
                                            validate={composeValidators(
                                                requiredAtLeastOneInArray,
                                                multiEmailTags,
                                                maxLengthForTags(70, emailsJoiner),
                                            )}
                                            isRequired
                                            formSubmit={form.submit}
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={gutter}>
                                    <Col md={12} xs={24} className="top-buffer-xs">
                                        <Field
                                            name="phone"
                                            component={InputGroupAntInputAdapter}
                                            controlId="phone"
                                            labelText="Phone"
                                            validate={composeValidators(required, phoneNumber)}
                                            format={phone}
                                            parse={phone}
                                            isRequired
                                        />
                                    </Col>
                                    <Col md={8} xs={24} className="top-buffer-xs">
                                        <Field
                                            name="ext"
                                            component={InputGroupAntInputAdapter}
                                            controlId="ext"
                                            labelText="ext"
                                            validate={maxLength(10)}
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={gutter}>
                                    <Col md={24} xs={24} className="top-buffer-xs">
                                        <NavLink
                                            id="changePassword"
                                            className="btn btn-default"
                                            to={ACCOUNT_SETTINGS_CHANGE_PASSWORD}
                                            activeClassName="selected"
                                        >
                                            Change Password
                                        </NavLink>
                                    </Col>
                                </Row>
                            </div>
                            <div className="formBlock">
                                <Row gutter={gutter}>
                                    <Col md={24} className="h4">
                                        Delivery Method
                                        {
                                            isDisabledDeliveryMethod
                                            && managedAtCompanyLevel
                                        }
                                    </Col>
                                </Row>
                                <Row className="form-composer" gutter={gutter}>
                                    <Col md={8} xs={24}>
                                        <Field
                                            controlId="emailConf"
                                            type="checkbox"
                                            name={`${deliverySettings}.emailConf`}
                                            component={InputGroupAntCheckBoxAdapter}
                                            labelText="Send Email Confirmation for Submitted Report"
                                            disabled={isDisabledDeliveryMethod}
                                        />
                                    </Col>
                                    <Col md={16} xs={24} hidden={!values[deliverySettings].emailConf}>
                                        <Field
                                            name={`${deliverySettings}.emailConfAddress`}
                                            controlId="emailConfAddress"
                                            labelText="Email Confirmation"
                                            disabled={isDisabledDeliveryMethod}
                                            component={InputGroupAntTagBoxAdapter}
                                            tokenSeparators={emailsSeparators}
                                            validate={composeValidators(
                                                multiEmailTags,
                                                maxLengthForTags(70, emailsJoiner),
                                            )}
                                            isRequired
                                            formSubmit={form.submit}
                                        />
                                    </Col>
                                    <Col md={24}>
                                        <hr className="delimiter" />
                                    </Col>
                                </Row>
                                <Row gutter={gutter}>
                                    <Col md={24}>
                                        <Field
                                            controlId="emailResults"
                                            type="checkbox"
                                            name={`${deliverySettings}.emailResults`}
                                            component={InputGroupAntCheckBoxAdapter}
                                            labelText="Email Results"
                                            disabled={isDisabledDeliveryMethod}
                                        />
                                    </Col>
                                </Row>
                                <div hidden={!values[deliverySettings].emailResults}>
                                    <Row gutter={gutter}>
                                        <Col md={24}>
                                            <Field
                                                name={`${deliverySettings}.emailSubject`}
                                                component={InputGroupAntInputAdapter}
                                                controlId="emailSubject"
                                                labelText="Email Subject Pattern"
                                                validate={composeValidators(maxLength(150))}
                                                infoBlock={parseTemplatesFromArray(
                                                    templateKeys, customizingSubjectOfEmail,
                                                )}
                                                disabled={isDisabledDeliveryMethod}
                                                isRequired
                                            />
                                        </Col>
                                    </Row>
                                    <Row gutter={gutter}>
                                        <Col md={24}>
                                            <Field
                                                name={`${deliverySettings}.attachmentName`}
                                                component={InputGroupAntInputAdapter}
                                                controlId="attachmentName"
                                                labelText="Email Attachment Name Pattern"
                                                validate={composeValidators(maxLength(150))}
                                                infoBlock={parseTemplatesFromArray(
                                                    templateKeys, customizingAttachmentNameOfEmail,
                                                )}
                                                disabled={isDisabledDeliveryMethod}
                                                isRequired
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            <div className="formBlock">
                                <Row gutter={gutter}>
                                    <Col md={24} className="h4">
                                        Default Login Page
                                    </Col>
                                    <Col md={12}>
                                        <Field
                                            name="defaultPage"
                                            component={InputGroupAntSelectAdapter}
                                            options={getDefaultPageOptions(
                                                getOptionsDefaultLoginPage(),
                                                allowWebOrders,
                                                webDelivery,
                                            )}
                                            controlId="defaultPage"
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div className="formBlock">
                                <Row gutter={gutter}>
                                    <Col md={24} className="h4">
                                        Next Order Settings
                                    </Col>
                                    <Col md={12}>
                                        <Field
                                            type="checkbox"
                                            name="copySelectedServices"
                                            component={InputGroupAntCheckBoxAdapter}
                                            labelText="Copy Selected Services"
                                            controlId="copySelectedServices"
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Loader>
                        <Row gutter={gutter}>
                            <Col md={24} xs={24}>
                                <Button
                                    id="saveChanges"
                                    className="btn btn-primary pull-right"
                                    htmlType="submit"
                                    type="primary"
                                    disabled={isSaving || pristine}
                                >
                                    Save
                                </Button>
                                <Button
                                    id="cancelChanges"
                                    className="cancel btn btn-default pull-right right-buffer-xs"
                                    htmlType="button"
                                    onClick={form.reset}
                                    disabled={isSaving || pristine}
                                >
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                    </form>
                )
            }
        </Form>
    );
};

export default ProfileSettingsContent;
