/**
 * Created by oleg on 1/3/17.
 */
import navigationStep from './navigation';
import pageOverlay from './overlay';
import submitRegistration from './register';

export { isRegistrationSucceeded } from './register';
export { getNavigationStep } from './navigation';
export { getPageOverlayMessage, isPageOverlayShown } from './overlay';

export default {
    navigationStep,
    pageOverlay,
    submitRegistration,
};
