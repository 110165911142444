import {
    USERNAME_CHECK_SUCCESS, USERNAME_CHECK_FAIL, USERNAME_CHECK_ACTION, USERNAME_CHANGED_ACTION,
} from '../../actions/userMaintanance';

const initialState = {
    checkInProgress: false,
    usernameChecked: false,
    usernameAlreadyInUse: false,
};

const username = (state = initialState, action) => {
    switch (action.type) {
        case USERNAME_CHANGED_ACTION:
            return {
                ...state,
                checkInProgress: false,
                usernameChecked: false,
                usernameAlreadyInUse: false,
            };
        case USERNAME_CHECK_ACTION:
            return {
                ...state,
                checkInProgress: true,
                usernameAlreadyInUse: false,
                usernameChecked: false,
            };
        case USERNAME_CHECK_SUCCESS:
            return {
                ...state,
                checkInProgress: false,
                usernameChecked: true,
                usernameAlreadyInUse: !action.response,
            };
        case USERNAME_CHECK_FAIL:
            return {
                ...state,
                checkInProgress: false,
                usernameChecked: false,
            };
        default:
            return state;
    }
};

export const isUsernameCheckInProgress = state => state.username.checkInProgress;
export const isUsernameIsFreeToUse = state => state.username.usernameChecked && !state.username.usernameAlreadyInUse;

export default username;
