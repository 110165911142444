import React from 'react';
import { v4 as uuid } from 'uuid';
import { Button, Divider } from 'antd';
import { toKebabCase } from '@src/utils/helpers/normalize';

const getInfoRow = (label, value, title, subTitle, action) => (
    title
        ? (
            <div key={uuid()}>
                <h4>{title}</h4>
                {subTitle && <h5>{subTitle}</h5>}
            </div>
        )
        : (
            <div key={uuid()}>
                {
                    subTitle
                    && (
                        <h5>
                            {subTitle}
                            {!!action && (
                                <Button
                                    icon={action.icon}
                                    size="small"
                                    onClick={action.method}
                                />
                            )}
                        </h5>
                    )
                }
                <dl className="dl-horizontal">
                    <dt>{label}</dt>
                    <dd className={`${toKebabCase(label)}-value`}>{value}</dd>
                </dl>
            </div>
        )
);

const OrderInfoBlock = ({
    title,
    subTitle,
    content,
    hideEmpty,
}) => (
    <>
        {title && <h4>{title}</h4>}
        {subTitle && <h5>{subTitle}</h5>}
        {content.map((section, index, array) => (
            <div className="section" key={uuid()}>
                {section.map(({
                    label,
                    value,
                    title: sectionTitle,
                    subTitle: sectionSubTitle,
                    action,
                }) => (
                    hideEmpty
                        ? (
                            (sectionTitle || sectionSubTitle || value || action)
                            && getInfoRow(label, value, sectionTitle, sectionSubTitle, action)
                        )
                        : (
                            (sectionTitle || sectionSubTitle || label || value || action)
                            && getInfoRow(label, value, sectionTitle, sectionSubTitle, action)
                        )
                ))}
                {array.length !== index + 1 && <Divider />}
            </div>
        ))}
    </>
);

export default OrderInfoBlock;
