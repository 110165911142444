import React from 'react';
import { getCurrentYear } from '@src/utils/helpers/dateUtils';

const Footer = () => (
    <div className="container text-center">
        <div className="row">
            <div className="row copyright">
                {`Copyright ©1925-${getCurrentYear()} State Capital. All rights reserved.`}
            </div>
        </div>
    </div>
);

export default Footer;
