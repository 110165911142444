import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '@src/components/Loader';
import ReportsFilter from '@src/components/ReportsFilter';
import {
    fetchingReports,
    fetchReportsFailed,
    fetchReportsSucceeded,
    fetchReports,
} from '@src/store/actions/reports';
import ReportsTable from '@src/components/ReportsTable';
import { judgementContinuationOrdersTableColumns } from '@src/utils/constants/tablesColumns';
import {
    getCombinedReports,
    getJudgmentContinuationReports,
    getFetching,
    getCanceling,
} from '@src/store/reducers/reports';
import { getCurrentCustomer } from '@src/store/reducers/customers';
import { getCurrentUser } from '@src/store/reducers/userMaintanance';
import {
    getDataGridFilters,
    getSortByFieldComparator,
    getSelectedChainsLastReportsIds,
} from '@src/utils/helpers/devExpressHelpers';
import { reportsTableType } from '@src/utils/templates/reportType';

const filterFields = ['refno', 'text', 'orderno', 'id'];

const initialFilterContains = { matchType: 'contains', value: [] };
const initialFilterEqual = { matchType: '=', value: [] };

const initialFilterFormValues = {
    refno: initialFilterContains,
    text: initialFilterContains,
    id: initialFilterEqual,
    orderno: initialFilterContains,
};

const reportsType = reportsTableType.judgementContinuation;

const preprocessData = (data, itemsPerPage) => {
    const groupedByOriginData = {};
    data.forEach(order => {
        const originNumber = order.origno || order.id;
        if (!groupedByOriginData[originNumber]) groupedByOriginData[originNumber] = [];
        groupedByOriginData[originNumber].push({ ...order });
    });

    Object.keys(groupedByOriginData).forEach(originNumber => {
        groupedByOriginData[originNumber] = groupedByOriginData[originNumber]
            .sort(getSortByFieldComparator('id', false));
        groupedByOriginData[originNumber][0].chainByOriginLength = groupedByOriginData[originNumber].length;
    });

    const flatData = Object.values(groupedByOriginData)
        .sort((a, b) => {
            if (a[0].id > b[0].id) return -1;
            if (b[0].id > a[0].id) return 1;
            return 0;
        })
        .reduce((acc, val) => acc.concat(val), []); // workaround for .flat() in IE

    let currentOrigin = flatData.length && (flatData[0].origno || flatData[0].id);
    let isEven = true;
    let indexOfFirstItemInGroup = 0;
    const chains = {};
    flatData.forEach((item, index) => {
        if (currentOrigin !== item.origno && currentOrigin !== item.id) {
            currentOrigin = item.origno || item.id;
            isEven = !isEven;
        }
        flatData[index].even = isEven;

        if (!chains[currentOrigin]) chains[currentOrigin] = [];
        chains[currentOrigin].push(item.id);
        flatData[index].chain = chains[currentOrigin];

        indexOfFirstItemInGroup = item.chainByOriginLength ? index : indexOfFirstItemInGroup;
        const isNotTheFirstItemInArray = !!index;
        const isTheFirstItemOnThePage = index % itemsPerPage === 0;
        const isItemFromTheChain = item.origno || item.id === flatData[indexOfFirstItemInGroup].origno;
        const isNotTheFirstItemFromTheChain = !item.chainByOriginLength;
        if (
            isNotTheFirstItemInArray
            && isTheFirstItemOnThePage
            && isItemFromTheChain
            && isNotTheFirstItemFromTheChain
        ) {
            // eslint-disable-next-line max-len,no-param-reassign
            item.chainByOriginLength = flatData[indexOfFirstItemInGroup].chainByOriginLength - (index - indexOfFirstItemInGroup);
            flatData[indexOfFirstItemInGroup].chainByOriginLength -= item.chainByOriginLength;
        }
    });

    return flatData;
};

const SelectOrderTable = ({
    selectAllOrders,
    selectOrder,
    setNextStep,
    shouldReloadData,
}) => {
    const dispatch = useDispatch();
    const [filters, setFilters] = useState(null);
    const [additionalValueForFilter, setAdditionalValueForFilter] = useState({ name: '', value: '' });
    const [pageSize, setPageSize] = useState(25);

    const isLoading = useSelector(getFetching);
    const isCanceling = useSelector(getCanceling);
    const combinedReports = useSelector(getCombinedReports);
    const judgmentContinuationReports = useSelector(getJudgmentContinuationReports);
    const { webDelivery } = useSelector(state => getCurrentCustomer(state));
    const { canSelectCustomer: isSctUser } = useSelector(getCurrentUser);

    const reports = judgmentContinuationReports.data.length
        ? preprocessData([...judgmentContinuationReports.data], pageSize)
        : judgmentContinuationReports.data;

    useEffect(() => {
        if (filters) {
            dispatch(fetchReports(
                reportsType,
                judgementContinuationOrdersTableColumns,
                { filter: getDataGridFilters(filters) },
            ));
        }
    }, [shouldReloadData]);

    return (
        <>
            <div className="contentHeader">
                <h1>
                    <span>Selection for Judgment Continuation or Append</span>
                </h1>
            </div>
            <Loader show={isLoading || isCanceling}>
                <ReportsFilter
                    initialValues={initialFilterFormValues}
                    isDisabled={isLoading}
                    onSubmit={data => {
                        setFilters(data);
                        dispatch(fetchReports(
                            reportsType,
                            judgementContinuationOrdersTableColumns,
                            { filter: getDataGridFilters(data) },
                        ));
                    }}
                    filterFields={filterFields}
                    additionalValueForFilter={additionalValueForFilter}
                />
                {
                    filters
                    && (
                        <ReportsTable
                            data={reports}
                            tableColumns={judgementContinuationOrdersTableColumns}
                            actionsColumn="judgementContinuationOrderActions"
                            handleLoadingStart={() => dispatch(fetchingReports(reportsType))}
                            handleLoadingSuccess={response => dispatch(fetchReportsSucceeded(response, reportsType))}
                            handleLoadingError={error => dispatch(fetchReportsFailed(error, reportsType))}
                            reportsType={reportsType}
                            isSelectable
                            combinedReports={combinedReports}
                            pushAdditionalValueForFilter={setAdditionalValueForFilter}
                            selectOrder={id => {
                                selectOrder(id);
                                setNextStep(id);
                            }}
                            onSelectChosenItems={e => {
                                if (judgmentContinuationReports.data && judgmentContinuationReports.data.length) {
                                    const ordersIds = getSelectedChainsLastReportsIds(
                                        e.component.getSelectedRowsData(),
                                    );
                                    const id = ordersIds.shift();
                                    selectOrder(id);
                                    selectAllOrders(ordersIds);
                                    setNextStep(id);
                                }
                            }}
                            onChangePageSize={setPageSize}
                            allowGrouping={false}
                            allowSorting={false}
                            isWebDelivery={webDelivery}
                            isSctUser={isSctUser}
                        />
                    )
                }
            </Loader>
        </>
    );
};

export default SelectOrderTable;
