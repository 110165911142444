import React from 'react';
import { DatePicker } from 'antd';
import FormGroup from '@src/components/input/FormGroup';
import { ranges, shortDateFormat } from '@src/utils/helpers/dateUtils';
import { getValidationState } from '@src/utils/helpers/validationRules';

const { RangePicker } = DatePicker;

const InputGroupAntRangePickerAdapter = ({
    input,
    controlId,
    isRequired,
    meta: { touched, error, warning },
    labelText,
    autoFocus,
    hideRanges,
    defaultRange = [],
    data,
    ...rest
}) => (
    <FormGroup
        controlId={controlId}
        validationState={getValidationState(touched, error, warning)}
        isRequired={isRequired}
        label={labelText}
        error={error}
        warning={warning}
        touched={touched}
    >
        <RangePicker
            {...input}
            {...rest}
            ranges={hideRanges || ranges}
            style={{ width: '100%' }}
            format={shortDateFormat}
            onChange={values => {
                const dates = values || [];
                input.onChange(
                    [
                        dates[0] ? dates[0].startOf('day') : defaultRange[0],
                        dates[1] ? dates[1].endOf('day') : defaultRange[1],
                    ],
                );
            }}
        />
    </FormGroup>
);

export default InputGroupAntRangePickerAdapter;
