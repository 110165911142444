export const CHECK_DEFAULT_CUSTOMER = 'CHECK_DEFAULT_CUSTOMER';
export const CHECK_DEFAULT_CUSTOMER_SUCCESS = 'CHECK_DEFAULT_CUSTOMER_SUCCESS';
export const CHECK_DEFAULT_CUSTOMER_FAIL = 'CHECK_DEFAULT_CUSTOMER_FAIL';
export const FETCHING_CUSTOMERS = 'FETCHING_CUSTOMERS';
export const FETCH_CUSTOMERS_SUCCESS = 'FETCH_CUSTOMERS_SUCCESS';
export const FETCHING_CURRENT_CUSTOMER = 'FETCHING_CURRENT_CUSTOMER';
export const FETCH_CURRENT_CUSTOMER_SUCCESS = 'FETCH_CURRENT_CUSTOMER_SUCCESS';
export const FETCH_CURRENT_CUSTOMER_FAIL = 'FETCH_CURRENT_CUSTOMER_FAIL';
export const FETCH_CUSTOMERS_FAIL = 'FETCH_CUSTOMERS_FAIL';
export const SELECTING_CUSTOMER = 'SELECTING_CUSTOMER';
export const SELECT_CUSTOMER_SUCCESS = 'SELECT_CUSTOMER_SUCCESS';
export const SELECT_CUSTOMER_FAIL = 'SELECT_CUSTOMER_FAIL';
export const FETCH_AFFILIATED_CUSTOMERS = 'FETCH_AFFILIATED_CUSTOMERS';
export const FETCH_AFFILIATED_CUSTOMERS_SUCCESS = 'FETCH_AFFILIATED_CUSTOMERS_SUCCESS';
export const FETCH_AFFILIATED_CUSTOMERS_FAIL = 'FETCH_AFFILIATED_CUSTOMERS_FAIL';
export const SELECT_AFFILIATED_CUSTOMER = 'SELECT_AFFILIATED_CUSTOMER';
export const SELECT_AFFILIATED_CUSTOMER_SUCCESS = 'SELECT_AFFILIATED_CUSTOMER_SUCCESS';
export const SELECT_AFFILIATED_CUSTOMER_FAIL = 'SELECT_AFFILIATED_CUSTOMER_FAIL';

export const fetchingCustomers = () => ({
    type: FETCHING_CUSTOMERS,
});

export const fetchCustomersSucceeded = response => ({
    type: FETCH_CUSTOMERS_SUCCESS,
    response,
});

export const fetchCustomersFailed = error => ({
    type: FETCH_CUSTOMERS_FAIL,
    error,
});

export const fetchingCurrentCustomer = () => ({
    type: FETCHING_CURRENT_CUSTOMER,
});

export const fetchCurrentCustomerSucceeded = response => ({
    type: FETCH_CURRENT_CUSTOMER_SUCCESS,
    response,
});

export const fetchCurrentCustomerFailed = error => ({
    type: FETCH_CURRENT_CUSTOMER_FAIL,
    error,
});

export const selectingCustomer = () => ({
    type: SELECTING_CUSTOMER,
});

export const selectCustomerSucceeded = () => ({
    type: SELECT_CUSTOMER_SUCCESS,
});

export const selectCustomerFailed = error => ({
    type: SELECT_CUSTOMER_FAIL,
    error,
});

export const checkDefaultCustomerAction = message => ({
    type: CHECK_DEFAULT_CUSTOMER,
    message,
});

export const checkDefaultCustomerSucceeded = response => ({
    type: CHECK_DEFAULT_CUSTOMER_SUCCESS,
    response,
});

export const checkDefaultCustomerFailed = error => ({
    type: CHECK_DEFAULT_CUSTOMER_FAIL,
    error,
});

export const fetchAffiliatedCustomersAction = () => ({
    type: FETCH_AFFILIATED_CUSTOMERS,
});

export const fetchAffiliatedCustomersSucceeded = response => ({
    type: FETCH_AFFILIATED_CUSTOMERS_SUCCESS,
    response,
});

export const fetchAffiliatedCustomersFailed = error => ({
    type: FETCH_AFFILIATED_CUSTOMERS_FAIL,
    error,
});

export const selectAffiliatedCustomerAction = () => ({
    type: SELECT_AFFILIATED_CUSTOMER,
});

export const selectAffiliatedCustomerSucceeded = response => ({
    type: SELECT_AFFILIATED_CUSTOMER_SUCCESS,
    response,
});

export const selectAffiliatedCustomerFailed = error => ({
    type: SELECT_AFFILIATED_CUSTOMER_FAIL,
    error,
});
