import React from 'react';
import { Col, Radio, Row } from 'antd';
import { Field } from 'react-final-form';
import InputGroupAntCheckBoxAdapter from '@src/components/input/InputGroupAntCheckBoxAdapter';
import InputGroupAntInputAdapter from '@src/components/input/InputGroupAntInputAdapter';
import InputGroupAntRadioAdapter from '@src/components/input/InputGroupAntRadioAdapter';
import AdditionalStatesField from '@src/containers/orderServiceNameSearch/AdditionalStatesField';

const ServiceGoodStndSearch = ({
    name,
    values,
    index,
    gutter,
    states,
    goodStndTypeOptions,
}) => (
    <div className="serviceBlock formBlock">
        <Row className="serviceHeader">
            <Col md={24} lg={16}>
                <Field
                    name={`${name}.selectedServicesTypes.goodStndSearch`}
                    component={InputGroupAntCheckBoxAdapter}
                    controlId={`${name}.selectedServicesTypes.goodStndSearch`}
                    labelText="CERTIFICATE OF GOOD STANDING"
                    type="checkbox"
                />
            </Col>
        </Row>
        {
            values.namesToSearch[index].selectedServicesTypes.goodStndSearch
            && (
                <>
                    <Row gutter={gutter}>
                        <Col md={24} lg={16}>
                            <Radio.Group
                                defaultValue={
                                    values
                                        .namesToSearch[values.namesToSearch.length - 1]
                                        .selectedServices
                                        .goodStndSearch
                                        .goodStndType
                                }
                                size="large"
                            >
                                {
                                    goodStndTypeOptions.map(({ label, value }) => (
                                        <div
                                            className="form-group"
                                            key={`${name}.selectedServices.goodStndSearch.goodStndType-${value}`}
                                        >
                                            <Field
                                                key={`${name}.selectedServices.goodStndSearch.goodStndType-${value}`}
                                                type="radio"
                                                component={InputGroupAntRadioAdapter}
                                                value={value}
                                                label={label}
                                                name={`${name}.selectedServices.goodStndSearch.goodStndType`}
                                            />
                                        </div>
                                    ))
                                }
                            </Radio.Group>
                        </Col>
                    </Row>
                    <Row gutter={gutter}>
                        <Col md={24} lg={16}>
                            <Field
                                name={`${name}.selectedServices.goodStndSearch.comments`}
                                component={InputGroupAntInputAdapter}
                                controlId={`${name}.selectedServices.goodStndSearch.comments`}
                                labelText="Comments"
                            />
                        </Col>
                    </Row>
                    <hr />
                    <AdditionalStatesField
                        name={name}
                        serviceName="goodStndSearch"
                        values={values}
                        index={index}
                        gutter={gutter}
                        states={states}
                    />
                </>
            )
        }
    </div>
);

export default ServiceGoodStndSearch;
