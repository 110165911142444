import React from 'react';

const CurrentCustomer = ({
    customerName = '',
    nickName = '',
}) => (
    <div className="customer-badge">
        <div className="info">
            <div className="info-content">
                <div className="info-item">
                    You're now using site as "
                    <b>{customerName}</b>
                    {
                        nickName
                        && <span className="text-secondary">{` - ${nickName}`}</span>
                    }
                    ".
                    <br />
                </div>
            </div>
        </div>
    </div>
);

export default CurrentCustomer;
