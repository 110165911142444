import {
    UPDATE_USER_ACTION, UPDATE_USER_SUCCESS, UPDATE_USER_FAIL,
    UPDATE_USER_ADMIN_STATE_ACTION, UPDATE_USER_ADMIN_STATE_SUCCESS,
    UPDATE_USER_ADMIN_STATE_FAIL, UPDATE_USER_ACTIVE_STATE_ACTION,
    UPDATE_USER_ACTIVE_STATE_SUCCESS, UPDATE_USER_ACTIVE_STATE_FAIL,
    RESEND_CONFIRMATION_CODE_ACTION, RESEND_CONFIRMATION_CODE_SUCCESS,
    RESEND_CONFIRMATION_CODE_FAIL,
} from '../../actions/userMaintanance';

const userUpdate = (state = [], action) => {
    switch (action.type) {
        case UPDATE_USER_ACTIVE_STATE_ACTION:
        case UPDATE_USER_ACTION:
        case UPDATE_USER_ADMIN_STATE_ACTION:
        case RESEND_CONFIRMATION_CODE_ACTION:
            return state.indexOf(action.username) === -1 ? [...state, action.username] : state;
        case UPDATE_USER_ACTIVE_STATE_SUCCESS:
        case UPDATE_USER_ACTIVE_STATE_FAIL:
        case UPDATE_USER_ADMIN_STATE_SUCCESS:
        case UPDATE_USER_ADMIN_STATE_FAIL:
        case UPDATE_USER_SUCCESS:
        case UPDATE_USER_FAIL:
        case RESEND_CONFIRMATION_CODE_SUCCESS:
        case RESEND_CONFIRMATION_CODE_FAIL: {
            const index = state.indexOf(action.username);
            return [...state.slice(0, index), ...state.slice(index + 1)];
        }
        default:
            return state;
    }
};

export const isUserOnUpdate = state => username => state.userUpdate.indexOf(username) !== -1;

export default userUpdate;
