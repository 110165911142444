import {
    login as loginApi,
    logout as logoutApi,
    setFirstPassword as setFirstPasswordApi,
    postVerificationCode as postVerificationCodeApi,
    resetPassword as resetPasswordApi,
} from '@src/utils/apiServices/autorizationRequests';
import { getCurrentUser } from '@src/store/actions/userMaintanance';
import { resetCurrentUser } from '@src/store/actions/userMaintanance/userMaintenanceControl';
import { fetchAffiliatedCustomers, fetchCurrentCustomer } from '@src/store/actions/customers';
import {
    loginAction, loginSucceeded, loginFailed,
    logoutAction, logoutFailed, logoutSucceeded,
    setFirstPasswordAction, setFirstPasswordFailed, setFirstPasswordSucceeded,
    resetPasswordAction, resetPasswordFailed, resetPasswordSucceeded,
    postVerificationCodeAction, postVerificationCodeFailed, postVerificationCodeSucceeded,
} from './authorizationControl';

export const login = data => dispatch => {
    dispatch(loginAction());

    return loginApi(data).then(response => {
        dispatch(loginSucceeded(response));
        if (!response.firstLogin) {
            Promise.all([
                dispatch(fetchCurrentCustomer()),
                dispatch(fetchAffiliatedCustomers()),
            ]).then(() => {
                dispatch(getCurrentUser());
                dispatch(loginSucceeded(response));
            });
        }
    }).catch(error => {
        dispatch(loginFailed(error.response.data));
    });
};

export const logout = data => dispatch => {
    dispatch(logoutAction());

    return logoutApi(data).then(response => {
        dispatch(logoutSucceeded(response));
        dispatch(resetCurrentUser());
    }).catch(error => {
        dispatch(logoutFailed(error.response.data));
    });
};

export const setFirstPassword = data => dispatch => {
    dispatch(setFirstPasswordAction());

    return setFirstPasswordApi(data).then(response => {
        dispatch(setFirstPasswordSucceeded(response));
        dispatch(getCurrentUser());
    }).catch(error => {
        dispatch(setFirstPasswordFailed(error.response.data));
    });
};

export const resetPassword = (data, cb = () => {}) => dispatch => {
    dispatch(resetPasswordAction());

    return resetPasswordApi(data).then(response => {
        dispatch(resetPasswordSucceeded(response));
        cb();
    }).catch(error => {
        dispatch(resetPasswordFailed(error.response.data));
    });
};

export const postVerificationCode = data => dispatch => {
    dispatch(postVerificationCodeAction());

    return postVerificationCodeApi(data).then(response => {
        dispatch(postVerificationCodeSucceeded(response));
    }).catch(error => {
        dispatch(postVerificationCodeFailed(error.response.data));
    });
};
