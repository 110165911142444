import { fetchAllStates, fetchMunicipalities } from '@src/utils/apiServices/states';
import {
    fetchStates, fetchStatesFailed, fetchStatesSucceeded,
    fetchingMunicipalities, fetchMunicipalitiesFailed, fetchMunicipalitiesSucceeded,
} from './statesControl';

export const getAllStates = () => dispatch => {
    dispatch(fetchStates());

    return fetchAllStates().then(response => {
        dispatch(fetchStatesSucceeded(response));
    }).catch(error => {
        dispatch(fetchStatesFailed(error));
    });
};

export const getMunicipalities = () => dispatch => {
    dispatch(fetchingMunicipalities());

    return fetchMunicipalities().then(response => {
        dispatch(fetchMunicipalitiesSucceeded(response));
    }).catch(error => {
        dispatch(fetchMunicipalitiesFailed(error));
    });
};

export default getAllStates;
