/**
 * Created by oleg on 11/30/16.
 */
import React from 'react';
import { Field } from 'react-final-form';
import InputGroupAntCheckBoxAdapter from '@src/components/input/InputGroupAntCheckBoxAdapter';
import BillingInformationContent from '@src/containers/registration/components/corporateInformation/BillingInformationContent';

const BillingInformation = ({ billingAsMailing }) => (
    <div>
        <div className="h4 clear-both">Billing Address</div>
        <Field
            type="checkbox"
            name="billingAddressSameAsCompany"
            component={InputGroupAntCheckBoxAdapter}
            labelText="Same as Mailing"
        />
        {!billingAsMailing && <BillingInformationContent />}
    </div>
);

export default BillingInformation;
