/**
 * Created by oleg on 12/6/16.
 */
import React from 'react';
import PropTypes from 'prop-types';

const NavigationStep = ({ stepName, activeStep, stepDesc }) => {
    let stepClass;

    if (stepName === activeStep) {
        stepClass = 'current';
    } else if (stepName < activeStep) {
        stepClass = 'done';
    }

    return (
        <li id="editOrder_step" className={stepClass}>
            <div>
                <div className="topTriangle" />
                <div className="bottomTriangle" />
            </div>
            <div className="stepContent"><span className="stepName">{stepDesc}</span></div>
            <div className="frontTriangle" />
        </li>
    );
};

NavigationStep.propTypes = {
    activeStep: PropTypes.string,
    stepName: PropTypes.string,
    stepDesc: PropTypes.string,
};

export default NavigationStep;
