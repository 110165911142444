import React from 'react';
import { Select } from 'antd';
import FormGroup from '@src/components/input/FormGroup';

import { getValidationState } from '@src/utils/helpers/validationRules';

const { Option, OptGroup } = Select;

const getOption = (value, label, disabled) => (
    <Option
        value={value}
        key={value}
        disabled={disabled}
        label={typeof label === 'string' ? label : label.key}
    >
        {label || <div className="option-empty-label" />}
    </Option>
);

const InputGroupAntSelectAdapter = ({
    input,
    controlId,
    isRequired,
    meta: { touched, error, warning },
    labelText,
    options,
    disabled,
    ...rest
}) => (
    <FormGroup
        controlId={controlId}
        validationState={getValidationState(touched, error, warning)}
        isRequired={isRequired}
        label={labelText}
        error={error}
        warning={warning}
        touched={touched}
    >
        <Select
            id={controlId}
            showArrow={!disabled}
            disabled={disabled}
            data-value={input.value}
            {...input}
            {...rest}
            style={{ width: '100%' }}
            dropdownRender={menu => (
                <div id={`${controlId}--dropdown`}>
                    {menu}
                </div>
            )}
        >
            {options.map(({
                value,
                label,
                disabled,
                options,
            }) => (
                options
                    ? (
                        <OptGroup key={label} label={label}>
                            {
                                options
                                    .map(({ value, label, disabled }) => getOption(value, label, disabled))
                            }
                        </OptGroup>
                    )
                    : getOption(value, label, disabled)
            ))}
        </Select>
    </FormGroup>
);

export default InputGroupAntSelectAdapter;
