/**
 * Created by oleg on 11/30/16.
 */
import React from 'react';
import { Row, Col } from 'antd';
import { Field } from 'react-final-form';
import {
    required, maxLength, phoneNumber, email, composeValidators,
} from '@src/utils/helpers/validationRules';
import { phone, upper } from '@src/utils/helpers/normalize';
import InputGroupAntInputAdapter from '@src/components/input/InputGroupAntInputAdapter';
import { gutter } from '@src/utils/helpers/orders';
import Address from '@src/containers/registration/components/utils/Address';

const MailingInformation = () => (
    <>
        <Row>
            <Col span={24}>
                <h4>Mailing Address</h4>
            </Col>
        </Row>
        <Row gutter={gutter}>
            <Col md={16} xs={24}>
                <Field
                    name="companyName"
                    component={InputGroupAntInputAdapter}
                    controlId="companyName"
                    isRequired
                    labelText="Company Name"
                    validate={composeValidators(required, maxLength(80))}
                    format={upper}
                    parse={upper}
                />
            </Col>
        </Row>

        <hr />

        <Row className="mailingInformation">
            <Col span={24} className="clear-both">
                <Address formSectionName="mailingInformation" />
            </Col>
        </Row>

        <hr />

        <Row gutter={gutter}>
            <Col md={12} xs={24} className="clear-both">
                <Field
                    name="businessPhone"
                    component={InputGroupAntInputAdapter}
                    controlId="businessPhone"
                    isRequired
                    labelText="Phone"
                    validate={composeValidators(required, phoneNumber)}
                    format={phone}
                    parse={phone}
                />
            </Col>
        </Row>
        <Row gutter={gutter} className="mailingInformation">
            <Col md={12} xs={24} className="top-buffer clear-both">
                <Field
                    name="mailingInformation.email"
                    component={InputGroupAntInputAdapter}
                    controlId="email"
                    isRequired
                    labelText="Email"
                    validate={composeValidators(required, email, maxLength(70))}
                />
            </Col>
        </Row>
    </>
);

export default MailingInformation;
