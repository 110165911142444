import { addErrorAlert, addSuccessAlert, addInfoAlert } from '@src/store/actions/alerts';
import {
    fetchReports as fetchReportsApi,
    fetchReportChain as fetchReportChainApi,
    fetchProperty as fetchPropertyApi,
    fetchTaxContinuationProperty as fetchTaxContinuationPropertyApi,
    combineReports as combineReportsApi,
    resendReportsByEmail as resendReportsByEmailApi,
    removeReportsFromNew as removeReportsFromNewApi,
    exportCsv as exportCsvApi,
} from '@src/utils/apiServices/reportsRequests';
import initDownload from '@src/utils/helpers/downloadFile';
import { reportsTableType } from '@src/utils/templates/reportType';
import {
    fetchingReports,
    fetchReportsSucceeded,
    fetchReportsFailed,
    fetchingReportChain,
    fetchReportChainSucceeded,
    fetchReportChainFailed,
    fetchingProperty,
    fetchPropertySucceeded,
    fetchPropertyFailed,
    fetchingTaxContinuationProperty,
    fetchTaxContinuationPropertySucceeded,
    fetchTaxContinuationPropertyFailed,
    combiningReports,
    combineReportsSucceeded,
    combineReportsFailed,
    resendingReportsByEmail,
    resendReportsByEmailSucceeded,
    resendReportsByEmailFailed,
    removingReportsFromNew,
    removeReportsFromNewSucceeded,
    removeReportsFromNewFailed,
    exportCsv as exportCsvAction,
    exportCsvSuccess,
    exportCsvFailed,
} from './reportsControl';

export const fetchReports = (type, includes, params, isSilent) => dispatch => {
    dispatch(fetchingReports(type, isSilent));

    return fetchReportsApi(type, includes, params).then(response => {
        dispatch(fetchReportsSucceeded(response || [], type));
    }).catch(error => {
        dispatch(fetchReportsFailed(error));
        dispatch(addErrorAlert('Something went wrong while fetching reports.', true));
    });
};

export const fetchReportChain = reportId => dispatch => {
    dispatch(fetchingReportChain());

    return fetchReportChainApi(reportId).then(response => {
        dispatch(fetchReportChainSucceeded(response));
    }).catch(error => {
        dispatch(fetchReportChainFailed(error));
        dispatch(addErrorAlert('Something went wrong while fetching report chain.', true));
    });
};

export const fetchProperty = params => dispatch => {
    dispatch(fetchingProperty());

    return fetchPropertyApi(params).then(response => {
        dispatch(fetchPropertySucceeded(response));
    }).catch(error => {
        dispatch(fetchPropertyFailed(error));
        dispatch(addErrorAlert('Something went wrong while fetching property.', true));
    });
};

export const fetchTaxContinuationProperty = id => dispatch => {
    dispatch(fetchingTaxContinuationProperty());

    return fetchTaxContinuationPropertyApi(id).then(response => {
        dispatch(fetchTaxContinuationPropertySucceeded(response));
    }).catch(error => {
        dispatch(fetchTaxContinuationPropertyFailed(error));
        dispatch(addErrorAlert('Something went wrong while fetching tax continuation property.', true));
    });
};

export const exportCsv = (filter, include, sort, type) => dispatch => {
    dispatch(exportCsvAction());
    exportCsvApi(filter, include, sort, type).then(response => {
        dispatch(exportCsvSuccess(response));
        initDownload(response, 'orders', 'csv');
    }).catch(error => {
        dispatch(exportCsvFailed(error));
    });
};

export const combineReports = (ids, type) => dispatch => {
    dispatch(combiningReports(ids));

    return combineReportsApi(ids).then(response => {
        dispatch(combineReportsSucceeded(ids, type, response));
    }).catch(error => {
        dispatch(combineReportsFailed(error, ids));
        dispatch(addErrorAlert('Something went wrong while combining reports.', true));
    });
};

export const resendReportsByEmail = (ids, email) => dispatch => {
    dispatch(resendingReportsByEmail());

    return resendReportsByEmailApi(ids, email).then(response => {
        dispatch(resendReportsByEmailSucceeded(response));
        dispatch(addInfoAlert('Message was successfully sent.', true));
    }).catch(error => {
        dispatch(resendReportsByEmailFailed(error));
        dispatch(addErrorAlert('Something went wrong while resending reports.', true));
    });
};

export const removeReportsFromNew = ids => dispatch => {
    dispatch(removingReportsFromNew());

    return removeReportsFromNewApi(ids).then(() => {
        dispatch(removeReportsFromNewSucceeded(ids, 'new'));
        dispatch(fetchReports(reportsTableType.new, [], {}, true));
        dispatch(addSuccessAlert('Report is removed from new', true));
    }).catch(error => {
        dispatch(removeReportsFromNewFailed(error));
        dispatch(addErrorAlert('Something went wrong while resending reports.', true));
    });
};
