import axios from 'axios';
import qs from 'qs';
import {
    ACCOUNT_VIEW_MY_ORDERS_COMPLETED_REPORTS,
    ACCOUNT_SETTINGS,
    LOGIN,
} from '@src/utils/constants/routerConstants';
import {
    urlBaseLogin,
} from '@src/utils/constants/endpointConstants';

const Axios = axios.create({
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
    withCredentials: true,
});

const commonRequestConfig = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
    },
    data: {},
};

export const getRequestConfig = {
    method: 'get',
};

export const postRequestConfig = {
    method: 'post',
    transformRequest: data => (typeof data === 'object' ? JSON.stringify(data) : data?.toString()),
    data: {},
};

export const putRequestConfig = {
    method: 'put',
    data: {},
};

Axios.interceptors.response.use(undefined, error => {
    if ([401, 403].includes(error.response.status)) {
        switch (error.response.headers.redirect) {
            case 'COMPLETED_REPORTS':
                document.location.href = ACCOUNT_VIEW_MY_ORDERS_COMPLETED_REPORTS;
                break;
            case 'SETTINGS':
                document.location.href = ACCOUNT_SETTINGS;
                break;
            case 'LOGOUT':
            default:
                if (error.config.url !== urlBaseLogin && document.location.pathname !== LOGIN) {
                    document.location.href = LOGIN;
                }
        }
    }
    return Promise.reject(error);
});

// Using for any request
export const request = async ({
    data, url, method, ...restParams
}) => {
    const result = await Axios({
        ...commonRequestConfig,
        method,
        url,
        data,
        ...restParams,
    });

    return result.data;
};
