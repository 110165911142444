import React from 'react';
import { Col, Row } from 'antd';
import { gutter } from '@src/utils/helpers/orders';
import { Field } from 'react-final-form';
import InputGroupAntInputAdapter from '@src/components/input/InputGroupAntInputAdapter';
import InputGroupAntCheckBoxAdapter from '@src/components/input/InputGroupAntCheckBoxAdapter';

const TaxContinuationSection = ({
    searchSupplier,
    values,
}) => (
    <div className="serviceBlock formBlock tax-continuation-search-block">
        <Row className="serviceHeader">
            <Col span={24}>
                <div className="providedBy text-light">
                    {searchSupplier}
                </div>
                <Field
                    name="selectedServicesTypes.taxContinuationService"
                    component={InputGroupAntCheckBoxAdapter}
                    controlId="selectedServicesTypes.taxContinuationService"
                    labelText="Tax Search Continuation"
                    type="checkbox"
                />
            </Col>
        </Row>
        {
            values.selectedServicesTypes.taxContinuationService
            && (
                <>
                    <Row gutter={gutter}>
                        <Col md={24} lg={16}>
                            <Field
                                name="selectedServices.taxContinuationService.comment"
                                component={InputGroupAntInputAdapter}
                                controlId="selectedServices.taxContinuationService.comment"
                                labelText="Comments"
                            />
                        </Col>
                    </Row>
                </>
            )
        }
    </div>
);

export default TaxContinuationSection;
