import React, { useState } from 'react';
import { Button, Drawer } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import LogoImage from '@src/images/statecapital-logo.png';
import YearImage from '@src/images/year_95.png';
import MainMenu from '@src/components/MainMenu';
import UserMenu from '@src/components/UserMenu';
import NewReportsBar from '@src/containers/newReportsBar/NewReportsBarContent';
import { STATIC_SITE_URL } from '@src/utils/constants/routerConstants';

const Navigation = ({
    onLogOut,
    accountNumber,
    address,
    phone,
    userName,
    isAuthorized,
    allowPropertySearch,
    allowWebOrders,
    isWebDelivery,
    canSelectCustomer,
}) => {
    const [isSideMenuVisible, setIsSideMenuVisible] = useState(false);

    return (
        <nav role="navigation" className="navbar bottom-buffer-no">
            <div className="container container-header">
                <div className="site-branding" id="logo">
                    <a href={STATIC_SITE_URL} id="indexPageLink">
                        <img
                            border="0"
                            alt="State Capital Title &amp; Abstract Co."
                            src={LogoImage}
                        />
                        <img
                            border="0"
                            alt="State Capital Title &amp; Abstract Co."
                            src={YearImage}
                        />
                    </a>
                </div>
                <div
                    id="mainNavbar"
                    className="main-nav"
                >
                    <MainMenu
                        isAuthorized={isAuthorized}
                        allowPropertySearch={allowPropertySearch}
                        allowWebOrders={allowWebOrders}
                        isWebDelivery={isWebDelivery}
                    />
                    <Button
                        type="text"
                        className="main-nav-mobile-toggle-btn"
                        icon={isSideMenuVisible ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
                        onClick={() => setIsSideMenuVisible(prevState => !prevState)}
                    />
                    <Drawer
                        width={250}
                        placement="right"
                        closable={false}
                        onClose={() => setIsSideMenuVisible(false)}
                        open={isSideMenuVisible}
                    >
                        <MainMenu
                            mode="inline"
                            isAuthorized={isAuthorized}
                            allowPropertySearch={allowPropertySearch}
                            allowWebOrders={allowWebOrders}
                            isWebDelivery={isWebDelivery}
                        />
                    </Drawer>
                </div>
                {
                    isAuthorized
                    && (
                        <>
                            {
                                isWebDelivery
                                && (
                                    <div className="newReportContent" id="newReportContent">
                                        <NewReportsBar />
                                    </div>
                                )
                            }
                            <div id="header-mini-nav">
                                <UserMenu
                                    onLogOut={onLogOut}
                                    accountNumber={accountNumber}
                                    address={address}
                                    phone={phone}
                                    userName={userName}
                                    canSelectCustomer={canSelectCustomer}
                                />
                            </div>
                        </>
                    )
                }
            </div>
        </nav>
    );
};

export default Navigation;
