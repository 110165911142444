import React from 'react';
import { Col, Row } from 'antd';
import { Field } from 'react-final-form';
import InputGroupAntCheckBoxAdapter from '@src/components/input/InputGroupAntCheckBoxAdapter';
import InputGroupAntInputAdapter from '@src/components/input/InputGroupAntInputAdapter';
import AdditionalStatesField from '@src/containers/orderServiceNameSearch/AdditionalStatesField';

const ServiceCorporateStatus = ({
    name,
    values,
    index,
    gutter,
    states,
}) => (
    <div className="serviceBlock formBlock">
        <Row className="serviceHeader">
            <Col md={24} lg={16}>
                <Field
                    name={`${name}.selectedServicesTypes.corporateStatus`}
                    component={InputGroupAntCheckBoxAdapter}
                    controlId={`${name}.selectedServicesTypes.corporateStatus`}
                    labelText={`${values.namesToSearch[index].selectedServices.corporateStatus.name}`}
                    type="checkbox"
                />
            </Col>
        </Row>
        {
            values.namesToSearch[index].selectedServicesTypes.corporateStatus
            && (
                <>
                    <Row gutter={gutter}>
                        <Col md={24} lg={16}>
                            <Field
                                name={`${name}.selectedServices.corporateStatus.comments`}
                                component={InputGroupAntInputAdapter}
                                controlId={`${name}.selectedServices.corporateStatus.comments`}
                                labelText="Comments"
                            />
                        </Col>
                    </Row>
                    <hr />
                    <AdditionalStatesField
                        name={name}
                        serviceName="corporateStatus"
                        values={values}
                        index={index}
                        gutter={gutter}
                        states={states}
                    />
                </>
            )
        }
    </div>
);

export default ServiceCorporateStatus;
