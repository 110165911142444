import React from 'react';
import { Col, Row } from 'antd';
import { Field } from 'react-final-form';
import InputGroupAntCheckBoxAdapter from '@src/components/input/InputGroupAntCheckBoxAdapter';
import InputGroupAntTransferAdapter from '@src/components/input/InputGroupAntTransferAdapter';
import { composeValidators, requiredAtLeastOneInArray } from '@src/utils/helpers/validationRules';
import WhenFieldChanges from '@src/components/input/WhenFieldChanges';
import InputGroupHiddenFieldAdapter from '@src/components/input/InputGroupHiddenFieldAdapter';

const AdditionalStatesField = ({
    name,
    serviceName,
    values,
    index,
    gutter,
    states,
}) => (
    <>
        <Row>
            <Col md={24} lg={24}>
                <Field
                    name={`${name}.selectedServices.${serviceName}.additionalStates`}
                    component={InputGroupAntCheckBoxAdapter}
                    controlId={`${name}.selectedServices.${serviceName}.additionalStates`}
                    labelText="Additional States"
                    type="checkbox"
                />
                <WhenFieldChanges
                    field={`${name}.selectedServices.${serviceName}.additionalStates`}
                    set={`${name}.selectedServices.${serviceName}.selectedStates`}
                    to={['NJ']}
                />
            </Col>
        </Row>
        {
            values.namesToSearch[index].selectedServices[serviceName].additionalStates
            && (
                <>
                    <Row gutter={gutter}>
                        <Col md={24} lg={16}>
                            <Field
                                name={`${name}.selectedServices.${serviceName}.selectedStates`}
                                component={InputGroupHiddenFieldAdapter}
                                controlId={`${name}.selectedServices.${serviceName}.selectedStates`}
                                hideMessage
                            />
                            <Field
                                name={`${name}.selectedServices.${serviceName}.selectedStates`}
                                component={InputGroupAntTransferAdapter}
                                controlId={`${name}.selectedServices.${serviceName}.selectedStates`}
                                dataSource={states}
                                showSearch
                                titles={['Available', 'Selected']}
                                validate={composeValidators(requiredAtLeastOneInArray)}
                                isRequired
                            />
                        </Col>
                    </Row>
                </>
            )
        }
    </>
);

export default AdditionalStatesField;
