import React from 'react';
import { Input, InputNumber } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import FormGroup from '@src/components/input/FormGroup';
import { getValidationState } from '@src/utils/helpers/validationRules';

const InputGroupAntInputAdapter = ({
    input,
    controlId,
    isRequired,
    meta: {
        touched,
        error,
        data,
    },
    labelText,
    infoBlock,
    hasAddonAbove,
    ...rest
}) => {
    const { warning } = data || {};
    let InputWithType = Input;
    if (input.type === 'password') InputWithType = Input.Password;
    if (input.type === 'number') InputWithType = InputNumber;
    if (input.type === 'mask') InputWithType = MaskedInput;

    const inputField = (
        <InputWithType
            {...input}
            {...rest}
            style={{ width: '100%' }}
            id={controlId}
        />
    );

    return (
        <FormGroup
            controlId={controlId}
            validationState={getValidationState(touched, error, warning)}
            isRequired={isRequired}
            label={labelText}
            info={infoBlock}
            hasAddonAbove={hasAddonAbove}
            error={error}
            warning={warning}
            touched={touched}
        >
            {inputField}
        </FormGroup>
    );
};

export default InputGroupAntInputAdapter;
