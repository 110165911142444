import React from 'react';
import FieldValidationMessage from '@src/components/input/FieldValidationMessage';

const FormGroup = ({
    controlId,
    children,
    validationState,
    isRequired,
    label,
    info,
    hasAddonAbove,
    error,
    warning,
    touched,
}) => (
    <div className={`form-group has-${validationState}`}>
        {
            label
                ? (
                    <span className={`input-group ${hasAddonAbove ? 'with-addon-above' : ''}`}>
                        <span className={`${isRequired ? 'required' : ''} input-group-addon`}>{label}</span>
                        {children}
                    </span>
                )
                : children
        }
        {
            touched
            && (
                <FieldValidationMessage
                    error={error}
                    warning={warning}
                    controlId={controlId}
                />
            )
        }
        {
            info
            && (
                <span className="help-block info-block">{info}</span>
            )
        }
    </div>
);

export default FormGroup;
