/**
 * Created by oleg on 12/23/16.
 */
import { REGISTRATION_SUBMIT, REGISTRATION_SUCCESS, REGISTRATION_ERROR } from '../../actions/registration';

const initialState = {
    show: false,
};

const pageOverlay = (state = initialState, action) => {
    switch (action.type) {
        case REGISTRATION_SUBMIT:
            return { ...state, show: true, message: action.message };
        case REGISTRATION_ERROR:
        case REGISTRATION_SUCCESS:
            return { ...state, show: false, message: '' };
        default:
            return state;
    }
};

export default pageOverlay;

export const isPageOverlayShown = state => state.pageOverlay.show;
export const getPageOverlayMessage = state => state.pageOverlay.message;
