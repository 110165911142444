/**
 * Created by oleg on 1/3/17.
 */
export const NEXT_STEP = 'NEXT_STEP';
export const PREV_STEP = 'PREV_STEP';

export const goToNextPage = currentPage => ({
    type: NEXT_STEP,
    pageName: currentPage,
});

export const goToPreviousPage = currentPage => ({
    type: PREV_STEP,
    pageName: currentPage,
});
