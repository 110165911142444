/**
 * Created by oleg on 12/2/16.
 */
import { NEXT_STEP, PREV_STEP } from '../../actions/registration';

const mapping = {
    CORP_INF: { prevStep: 'CORP_INF', nextStep: 'PERS_INF' },
    PERS_INF: { prevStep: 'CORP_INF', nextStep: 'REV_REG' },
    REV_REG: { prevStep: 'PERS_INF', nextStep: 'REV_REG' },
};

const navigationStep = (state = 'CORP_INF', action) => {
    switch (action.type) {
        case NEXT_STEP:
            return mapping[action.pageName].nextStep;
        case PREV_STEP:
            return mapping[action.pageName].prevStep;
        default:
            return state;
    }
};

export default navigationStep;

export const getNavigationStep = state => state.navigationStep;
