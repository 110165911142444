import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import Loader from '@src/components/Loader';
import { fetchOrderDetails } from '@src/store/actions/orders';
import { checkDefaultCustomer } from '@src/store/actions/customers';
import { getIsFetching, getOrderDetails } from '@src/store/reducers/orders';
import { getCurrentCustomer, getCurrentCustomerFetching } from '@src/store/reducers/customers';
import { getCurrentUser } from '@src/store/reducers/userMaintanance';
import { defaultPageUrls } from '@src/utils/templates/accountSettings';
import { getShouldRemoveFromNew } from '@src/utils/helpers/reports';
import OrderDetails from './OrderDetails';

const OrderDetailsContent = () => {
    const isFetching = useSelector(getIsFetching);
    const orderDetails = useSelector(getOrderDetails);
    const { number: currentCustomerNumber, groupCode, defaultCustomer } = useSelector(getCurrentCustomer);
    const isCurrentCustomerFetching = useSelector(getCurrentCustomerFetching);
    const { defaultPage, customerGroupCode } = useSelector(getCurrentUser);

    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const shouldRemoveFromNew = getShouldRemoveFromNew(defaultCustomer, groupCode, customerGroupCode);

    useEffect(() => {
        dispatch(fetchOrderDetails(id));
        dispatch(checkDefaultCustomer(id));
    }, []);

    useEffect(() => {
        if (currentCustomerNumber && isCurrentCustomerFetching) {
            navigate(defaultPageUrls[defaultPage]);
        }
    }, [isCurrentCustomerFetching]);

    return (
        <Loader show={isFetching}>
            <OrderDetails orderDetails={orderDetails} shouldRemoveFromNew={shouldRemoveFromNew} />
        </Loader>
    );
};

export default OrderDetailsContent;
