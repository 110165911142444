import React from 'react';
import { DatePicker } from 'antd';
import FormGroup from '@src/components/input/FormGroup';
import { getValidationState } from '@src/utils/helpers/validationRules';
import { shortDateFormat, shortDateFormatUniversal } from '@src/utils/helpers/dateUtils';

const InputGroupAntDatePickerAdapter = ({
    input,
    controlId,
    isRequired,
    meta: { touched, error, warning },
    labelText,
    autoFocus,
    data,
    ...rest
}) => (
    <FormGroup
        controlId={controlId}
        validationState={getValidationState(touched, error, warning)}
        isRequired={isRequired}
        label={labelText}
        error={error}
        warning={warning}
        touched={touched}
    >
        <DatePicker
            {...input}
            {...rest}
            style={{ width: '100%' }}
            format={shortDateFormatUniversal}
            placeholder={shortDateFormat}
        />
    </FormGroup>
);

export default InputGroupAntDatePickerAdapter;
