import React, { useRef } from 'react';
import { Button } from 'antd';
import { CloseOutlined, EyeOutlined, DownloadOutlined } from '@ant-design/icons';
import DataGrid, {
    Column,
    Pager,
    Paging,
    FilterRow,
    Selection,
    StateStoring,
} from 'devextreme-react/data-grid';
import { dxShortDateFormat } from '@src/utils/helpers/dateUtils';
import {
    onSelectionChangedHandler,
    onCellPreparedHandler,
    getDisabledKeysFromNewReports,
} from '@src/utils/helpers/devExpressHelpers';
import { ACCOUNT_VIEW_MY_ORDERS_ORDER_DETAILS } from '@src/utils/constants/routerConstants';

const renderActions = ({ row: { data: { orderno, url, id } } }, onRemove) => (
    <div className="buttons-block-wrapper">
        <Button.Group>
            <Button
                size="small"
                icon={<EyeOutlined />}
                type="link"
                title="View Order Details"
                href={`${ACCOUNT_VIEW_MY_ORDERS_ORDER_DETAILS}/${orderno}`}
                target="_blank"
                rel="noopener noreferrer"
            />
            <Button
                size="small"
                icon={<DownloadOutlined />}
                type="link"
                href={url}
                disabled={!(url && url.trim())}
                target="_blank"
                rel="noopener noreferrer"
                title="View File"
            />
            <Button
                size="small"
                icon={<CloseOutlined />}
                type="link"
                onClick={() => onRemove(id)}
                target="_blank"
                rel="noopener noreferrer"
                title="Remove from New Reports"
            />
        </Button.Group>
    </div>
);

const renderReportType = ({
    text,
    data: {
        url,
        id,
        state,
    },
}, removeFromNew, shouldRemoveFromNew) => {
    const isStateVisible = !!state;

    return (
        url && url.trim()
            ? (
                <a // eslint-disable-line react/jsx-no-target-blank
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                        if (shouldRemoveFromNew) {
                            removeFromNew(id);
                        }
                    }}
                >
                    {text}
                    {isStateVisible ? ` - ${state}` : ''}
                </a>
            )
            : text + (isStateVisible ? ` - ${state}` : '')

    );
};

// eslint-disable-next-line react/jsx-no-target-blank
const renderOrderNumber = ({ text }) => <a href={`${ACCOUNT_VIEW_MY_ORDERS_ORDER_DETAILS}/${text}`} target="_blank">{text}</a>;

const editorPreparedHandler = e => {
    if (e.dataField === 'refno') {
        e.editorElement.querySelector('.dx-texteditor-input').focus();
    }
};

const calculateFilterExpression = (name, defaultSelectedFilterOperation = 'contains') => (
    (value, selectedFilterOperation = defaultSelectedFilterOperation) => (
        [name, selectedFilterOperation, (value || '').trim()]
    )
);

const NewReportsTable = ({
    data,
    onSelectionChanged,
    onRemove,
    shouldRemoveFromNew,
}) => {
    const dataGridRef = useRef();

    return (
        <DataGrid
            ref={dataGridRef}
            id="gridContainer"
            dataSource={data}
            allowColumnReordering
            allowColumnResizing
            columnHidingEnabled
            columnAutoWidth
            wordWrapEnabled
            loadPanel={{ enabled: false }}
            keyExpr="id"
            width="100%"
            showBorders
            showRowLines
            showColumnLines
            rowAlternationEnabled
            headerFilter={{ visible: true }}
            onEditorPrepared={editorPreparedHandler}
            onOptionChanged={({ name, fullName }) => {
                if (name === 'columns') {
                    if ((fullName.endsWith('sortOrder') || (fullName.endsWith('filterValue')))) {
                        dataGridRef.current.instance.option('paging.pageIndex', 0);
                    }
                } else if (name === 'paging' && fullName.endsWith('pageSize')) {
                    dataGridRef.current.instance.option('paging.pageIndex', 0);
                }
            }}
            onCellPrepared={e => onCellPreparedHandler(e, e.data && e.data.typeInfo.type)}
            onSelectionChanged={e => onSelectionChangedHandler(e, getDisabledKeysFromNewReports, onSelectionChanged)}
        >
            <StateStoring enabled={false} type="localStorage" storageKey="newReportsTableSettings" />
            <FilterRow
                applyFilterText="Apply filter"
                visible
            />
            <Paging
                defaultPageSize={25}
            />
            <Pager
                showPageSizeSelector
                allowedPageSizes={[10, 25, 50, 100]}
                showInfo
            />
            <Selection
                mode="multiple"
                selectAllMode="page"
                showCheckBoxesMode="always"
            />
            <Column
                dataField="id"
                cssClass="cell-report-number"
                caption="Report #"
                dataType="string"
                allowHeaderFiltering={false}
                calculateFilterExpression={calculateFilterExpression('id')}
            />
            <Column
                dataField="orderno"
                cssClass="cell-order-number"
                caption="Order #"
                dataType="string"
                cellRender={renderOrderNumber}
                allowHeaderFiltering={false}
                calculateFilterExpression={calculateFilterExpression('orderno')}
            />
            <Column
                dataField="text"
                cssClass="cell-names"
                caption="Name(s)"
                allowHeaderFiltering={false}
                hidingPriority={5}
                calculateFilterExpression={calculateFilterExpression('text')}
            />
            <Column
                dataField="refno"
                caption="Reference #"
                cssClass="cell-reference-number"
                allowHeaderFiltering={false}
                hidingPriority={3}
                calculateFilterExpression={calculateFilterExpression('refno')}
            />
            <Column
                dataField="smItem.description"
                cssClass="cell-report-type"
                caption="Report Type"
                cellRender={data => renderReportType(data, onRemove, shouldRemoveFromNew)}
                hidingPriority={4}
                calculateFilterExpression={calculateFilterExpression('smItem.description')}
            />
            <Column
                dataField="pages"
                cssClass="cell-pages"
                caption="Pages"
                allowHeaderFiltering={false}
                hidingPriority={1}
            />
            <Column
                dataField="donedate"
                cssClass="cell-done-date"
                caption="Done Date"
                dataType="date"
                hidingPriority={2}
                format={dxShortDateFormat}
                defaultSortOrder="desc"
            />
            <Column
                caption="Actions"
                cssClass="cell-actions"
                cellRender={data => renderActions(data, onRemove)}
                alignment="center"
                minWidth={90}
            />
        </DataGrid>
    );
};

export default NewReportsTable;
