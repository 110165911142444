import {
    initialBusinessContact,
    initialIndividualSearches,
    initialProfileSettings,
    initialPropertySearches,
    initialReportSettings,
    initialReportDeliverySettings,
} from '@src/utils/templates/accountSettings';
import {
    BUSINESS_CONTACT_SETTINGS,
    INDIVIDUAL_SETTINGS,
    PERSONAL_SETTINGS,
    PROPERTY_SEARCH_SETTINGS,
    REPORT_SETTINGS,
    CHANGE_PASSWORD,
} from '@src/utils/constants/settingsConstants';
import {
    SET_SETTINGS_CONTAINER_TITLE,
    FETCHING_ACCOUNT_SETTINGS,
    FETCH_ACCOUNT_SETTINGS_SUCCESS,
    FETCH_ACCOUNT_SETTINGS_FAIL,
    SAVING_ACCOUNT_SETTINGS,
    SAVE_ACCOUNT_SETTINGS_SUCCESS,
    SAVE_ACCOUNT_SETTINGS_FAIL,
    RESET_CHANGED_PASSWORD,
    FETCH_ACCOUNT_DELIVERY_SETTINGS_SUCCESS,
} from '../../actions/accountSettings';

const initialState = {
    title: '',
    fetching: true,
    saving: false,
    error: false,
    [BUSINESS_CONTACT_SETTINGS]: initialBusinessContact,
    [INDIVIDUAL_SETTINGS]: initialIndividualSearches,
    [PERSONAL_SETTINGS]: initialProfileSettings,
    [PROPERTY_SEARCH_SETTINGS]: initialPropertySearches,
    [REPORT_SETTINGS]: initialReportSettings,
    [CHANGE_PASSWORD]: false,
    admin: false,
    reportDeliverySettings: initialReportDeliverySettings,
};

const settingsContainer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SETTINGS_CONTAINER_TITLE:
            return {
                ...state,
                title: action.title,
            };
        case FETCHING_ACCOUNT_SETTINGS:
            return { ...state, fetching: true };
        case FETCH_ACCOUNT_SETTINGS_SUCCESS: {
            return {
                ...state,
                ...action.response,
                fetching: false,
            };
        }
        case FETCH_ACCOUNT_DELIVERY_SETTINGS_SUCCESS: {
            const {
                confirmationEmail,
                confirmationEmailList,
                contact: { contactId, copySelectedServices },
                contactList,
                referenceNumberRequired,
                canMakeAccutitle,
                webDelivery,
                lastCertificationDate,
                beginCertificationDate,
                deliveryPreferenceDefaults: {
                    shouldEmailOrder,
                    availableEmails,
                    preselectedEmailIdx,
                    canChangeWebDelivery,
                },
            } = action.response;
            return {
                ...state,
                fetching: false,
                reportDeliverySettings: {
                    confirmationEmail,
                    confirmationEmailList,
                    contactId,
                    contactList,
                    lastCertificationDate,
                    beginCertificationDate,
                    copySelectedServices,
                    referenceRequired: referenceNumberRequired,
                    deliveryPreference: {
                        ...state.deliveryPreference,
                        email: availableEmails[preselectedEmailIdx] || (availableEmails.length && availableEmails[0]),
                        emailResults: shouldEmailOrder,
                        webDelivery,
                        canChangeWebDelivery,
                        canMakeAccutitle,
                        availableEmails,
                    },
                },
            };
        }
        case FETCH_ACCOUNT_SETTINGS_FAIL:
            return { ...state, fetching: false, error: action.payload };
        case SAVING_ACCOUNT_SETTINGS:
            return { ...state, saving: true };
        case SAVE_ACCOUNT_SETTINGS_SUCCESS:
            return {
                ...state,
                [action.settingsType]: { ...action.response },
                saving: false,
                error: false,
            };
        case SAVE_ACCOUNT_SETTINGS_FAIL:
            return { ...state, saving: false, error: action.payload };
        case RESET_CHANGED_PASSWORD:
            return { ...state, [CHANGE_PASSWORD]: false };
        default:
            return state;
    }
};

export const getSettingsContainerTitle = state => state.settingsContainer.title;
export const getBusinessContactSettings = state => state.settingsContainer[BUSINESS_CONTACT_SETTINGS];
export const getIndividualSettings = state => state.settingsContainer[INDIVIDUAL_SETTINGS];
export const getPersonalSettings = state => state.settingsContainer[PERSONAL_SETTINGS];
export const getPropertySearchSettings = state => state.settingsContainer[PROPERTY_SEARCH_SETTINGS];
export const getDeliverySettings = state => state.settingsContainer.reportDeliverySettings;
export const getReportSettings = state => state.settingsContainer[REPORT_SETTINGS];
export const getChangePassword = state => state.settingsContainer[CHANGE_PASSWORD];
export const getRequestError = state => state.settingsContainer.error;
export const isAccountSettingsFetching = state => state.settingsContainer.fetching;
export const isAccountSettingsSaving = state => state.settingsContainer.saving;
export const isAdmin = state => state.settingsContainer.admin;

export default settingsContainer;
