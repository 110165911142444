import React from 'react';
import { Row, Col } from 'antd';
import { Field } from 'react-final-form';
import InputGroupAntSelectAdapter from '@src/components/input/InputGroupAntSelectAdapter';

const OrderContactBlock = ({ confirmationEmail, confirmationEmailList, contactList }) => (
    <>
        <Row>
            <Col md={24} lg={16}>
                <Field
                    name="contactId"
                    component={InputGroupAntSelectAdapter}
                    controlId="contactId"
                    labelText="Contact Name"
                    options={contactList.map(({ contactName, contactId }) => (
                        { label: contactName, value: contactId }
                    ))}
                    dropdownMatchSelectWidth={false}
                    disabled={contactList.length < 2}
                />
            </Col>
        </Row>
        {
            !!confirmationEmail
            && (
                <Row>
                    <Col md={24} lg={16}>
                        <Field
                            name="confirmationEmail"
                            component={InputGroupAntSelectAdapter}
                            controlId="confirmationEmail"
                            labelText="Confirmation Email"
                            options={confirmationEmailList.map(email => (
                                { label: email, value: email }
                            ))}
                            dropdownMatchSelectWidth={false}
                            disabled={confirmationEmailList.length < 2}
                        />
                    </Col>
                </Row>
            )
        }
    </>
);

export default OrderContactBlock;
