import React from 'react';
import { connect } from 'react-redux';
import { removeReportsFromNew } from '@src/store/actions/reports';

const AbstractDetails = ({
    reportInfo, isOrderCompleted, removeReportsFromNew, shouldRemoveFromNew,
}) => {
    const {
        type, price, reportName, reportStatus, reportURL, newReport, requestNumber,
    } = reportInfo;
    const reportClass = `label ${reportStatus.toLowerCase()}Status`;

    return (
        <div className="formBlock table-responsive">
            <table id="orderDetailsTable" className="table">

                <thead>
                    <tr>
                        <th>Report</th>
                        <th>Name(s)</th>
                        {isOrderCompleted && <th>Price</th>}
                        <th>Status</th>
                    </tr>
                </thead>

                <tbody>

                    <tr>
                        <td>{type}</td>
                        <td>{reportName}</td>
                        {isOrderCompleted && <td>{reportStatus === 'COMPLETED' && price}</td>}
                        <td>
                            <div>
                                <span className={reportClass}>{reportStatus}</span>
                                {reportStatus === 'COMPLETED' && (
                                    <div className="inline-block">
                                        <a //eslint-disable-line react/jsx-no-target-blank
                                            href={reportURL}
                                            onClick={() => (
                                                newReport
                                                && shouldRemoveFromNew
                                                && removeReportsFromNew([requestNumber])
                                            )}
                                            className="typeLink"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Download file
                                        </a>
                                    </div>
                                )}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

const mapDispatchToProps = {
    removeReportsFromNew,
};

export default connect(null, mapDispatchToProps)(AbstractDetails);
