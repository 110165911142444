import React from 'react';
import { Radio } from 'antd';

const InputGroupAntRadioAdapter = ({
    input,
    label,
    ...rest
}) => (
    <Radio
        {...input}
        {...rest}
    >
        {label}
    </Radio>
);

export default InputGroupAntRadioAdapter;
