import React from 'react';
import {
    Menu,
    Button,
} from 'antd';
import { CloseOutlined, BankOutlined, UserOutlined } from '@ant-design/icons';

const OrderNamesMenu = ({
    activeItemIndex,
    setActiveItemIndex,
    onRemoveItem,
    namesToSearch,
    onSelect,
}) => (
    <>
        <h4>Names in This Order</h4>
        <div className="list-group">
            <Menu
                selectedKeys={[activeItemIndex.toString()]}
                onSelect={onSelect}
                mode="inline"
            >
                {namesToSearch.map((nameItem, index) => {
                    const { type } = nameItem.nameSearchCategory;
                    const {
                        companyName,
                        firstName,
                        lastName,
                        middleName,
                        prefix,
                        suffix,
                    } = nameItem.nameSearchCategory[type];
                    const itemTitle = companyName
                        || (
                            (firstName || lastName)
                                ? [prefix, firstName, middleName, lastName, suffix].filter(item => !!item).join(' ')
                                : 'Unnamed'
                        );

                    return (
                        // eslint-disable-next-line react/no-array-index-key
                        <Menu.Item key={index} title={itemTitle}>
                            <div className="menu-item-text">
                                {type === 'Company' ? <BankOutlined /> : <UserOutlined />}
                                {itemTitle}
                            </div>
                            <Button
                                type="link"
                                shape="circle"
                                icon={<CloseOutlined />}
                                onClick={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setActiveItemIndex(index > 0 ? index - 1 : 0);
                                    onRemoveItem(index);
                                }}
                            />
                        </Menu.Item>
                    );
                })}
            </Menu>
        </div>
    </>
);

export default OrderNamesMenu;
