import moment from 'moment';
import { shortDateFormat } from '@src/utils/helpers/dateUtils';
import {
    companyTypeOptions,
    copiesOfEntityFilingsTypeOptions,
    countyOptions,
    goodStndTypeOptions,
    nameQualifierOptions,
} from '@src/utils/templates/orderServicesOptions';

export const getCompanyTypeLabel = value => companyTypeOptions.find(option => value === option.value).sectionLabel;

export const getMainInformation = ({ namesToSearch }) => {
    const mainInformation = [];
    namesToSearch.map(({
        nameSearchCategory,
        selectedServicesTypes,
        selectedServices,
        // eslint-disable-next-line array-callback-return
    }) => {
        const mainInformationByName = { content: [] };
        mainInformationByName.content.push(sectionsPreview.name(nameSearchCategory));
        Object
            .entries(selectedServicesTypes)
            .filter(item => item[1])
            .map(item => (
                mainInformationByName.content.push(sectionsPreview[item[0]]?.(selectedServices[item[0]]) || [])
            ));
        mainInformation.push(mainInformationByName);
    });

    return mainInformation;
};

export const prepareDataForRequest = ({ namesToSearch, reference }) => {
    const preparedData = {
        reference,
        namesToSearch: [],
    };

    namesToSearch.forEach(nameToSearch => {
        const preparedSelectedServices = Object.entries(nameToSearch.selectedServicesTypes)
        // eslint-disable-next-line array-callback-return
            .reduce(
                (accumulator, currentValue) => (
                    currentValue[1] === true
                        ? [...accumulator, currentValue[0]]
                        : [...accumulator]
                ),
                [],
            )
            .map(serviceName => {
                const preparedSelectedService = { ...nameToSearch.selectedServices[serviceName] };

                if (serviceName === 'franchiseTaxReport') {
                    const { fromDate, toDate } = nameToSearch.selectedServices[serviceName];
                    preparedSelectedService.fromDate = fromDate ? fromDate.format(shortDateFormat) : '';
                    preparedSelectedService.toDate = toDate ? toDate.format(shortDateFormat) : '';
                }
                if (serviceName === 'judgmentSearch') {
                    const { from, to } = nameToSearch.selectedServices[serviceName];
                    preparedSelectedService.from = from.format(shortDateFormat);
                    preparedSelectedService.to = to.format(shortDateFormat);
                }
                if (serviceName === 'copiesOfEntityFiling') {
                    preparedSelectedService.certInc = preparedSelectedService.copiesOfEntityFilingsType === 'certInc';
                    preparedSelectedService.allDoc = preparedSelectedService.copiesOfEntityFilingsType === 'allDoc';
                }

                return preparedSelectedService;
            });

        preparedData.namesToSearch.push({
            nameSearchCategory: nameToSearch.nameSearchCategory[nameToSearch.nameSearchCategory.type],
            selectedServices: preparedSelectedServices,
        });
    });

    return preparedData;
};

export const getInitialDataFromPreviousOrder = (previousData, defaultFormData) => {
    const { namesToSearch } = previousData;
    const initialNameToSearch = namesToSearch[namesToSearch.length - 1];
    const selectedServicesNames = getSelectedServicesNames(initialNameToSearch.selectedServicesTypes);
    if (initialNameToSearch.nameSearchCategory.type === 'Company') {
        initialNameToSearch.nameSearchCategory = defaultFormData.namesToSearch[0].nameSearchCategory;
        initialNameToSearch.nameSearchCategory.type = 'Company';
    }
    if (initialNameToSearch.nameSearchCategory.type === 'Individual') {
        initialNameToSearch.nameSearchCategory.Company = defaultFormData.namesToSearch[0].nameSearchCategory.Company;
        initialNameToSearch.nameSearchCategory.Individual = {
            ...defaultFormData.namesToSearch[0].nameSearchCategory.Individual,
            lastName: initialNameToSearch.nameSearchCategory.Individual.lastName,
        };
        initialNameToSearch.nameSearchCategory.type = 'Individual';
    }
    selectedServicesNames.forEach(serviceName => {
        switch (serviceName) {
            case 'judgmentSearch': {
                initialNameToSearch.selectedServices[serviceName] = {
                    ...defaultFormData.namesToSearch[0].selectedServices[serviceName],
                    from: initialNameToSearch
                        .selectedServices[serviceName]
                        .from,
                    to: initialNameToSearch
                        .selectedServices[serviceName]
                        .to,
                };
                break;
            }
            default:
                initialNameToSearch.selectedServices[serviceName] = {
                    ...defaultFormData
                        .namesToSearch[0]
                        .selectedServices[serviceName],
                };
                break;
        }
    });
    const initialFormData = { ...previousData, namesToSearch: [initialNameToSearch] };

    return initialFormData;
};

export const defaultSelectedState = 'NJ';

export const getInitialServiceData = (from, to, defaultSelectedState) => ({
    reference: '',
    namesToSearch: [
        {
            nameSearchCategory: {
                'type': 'Individual',
                'Individual': {
                    type: 'Individual',
                    prefix: '',
                    firstName: '',
                    middleName: '',
                    lastName: '',
                    suffix: '',
                },
                'Company': {
                    type: 'Company',
                    companyName: '',
                    companyType: 'CRP',
                },
            },
            selectedServicesTypes: {
                'uccSearch': false,
                'judgmentSearch': false,
                'patriotSearch': false,
                'childSupportSearch': false,
                'litigationSearch': false,
                'corporateStatus': false,
                'franchiseTaxReport': false,
                'goodStndSearch': false,
                'copiesOfEntityFiling': false,
                'regAgentLetter': false,
                'offAndDirLetter': false,
                'nameAvailability': false,
            },
            selectedServices: {
                'uccSearch': {
                    'type': 'uccSearch',
                    'name': 'UCC Search',
                    'uccSearchType': 'ANY_ADDRESS',
                    'ANY_ADDRESS': {},
                    'EXACT_ADDRESS': {
                        addressLine1: '',
                        addressLine2: '',
                        city: '',
                        county: '',
                        zip: '',
                    },
                    'comments': '',
                    'additionalStates': false,
                    'selectedStates': [defaultSelectedState],
                },
                'judgmentSearch': {
                    type: 'judgmentSearch',
                    name: 'Judgment Search',
                    from: moment.utc(from),
                    to: moment.utc(to),
                    nameQualifier: 'SELECT_ONE',
                    exactName: false,
                    additionalStates: false,
                    selectedStates: [defaultSelectedState],
                    addressLine1: '',
                    addressLine2: '',
                    city: '',
                    zip: '',
                },
                'patriotSearch': {
                    type: 'patriotSearch',
                    name: 'Patriot Search',
                },
                'childSupportSearch': {
                    type: 'childSupportSearch',
                    name: 'Child Support Judgment Search',
                    exactName: false,
                    ssn: '',
                },
                'litigationSearch': {
                    type: 'litigationSearch',
                    name: 'Pending Litigation Search',
                    comments: '',
                    additionalStates: false,
                    selectedStates: [defaultSelectedState],
                },
                'corporateStatus': {
                    type: 'corporateStatus',
                    name: 'Corporate status',
                    comments: '',
                    additionalStates: false,
                    selectedStates: [defaultSelectedState],
                },
                'franchiseTaxReport': {
                    type: 'franchiseTaxReport',
                    name: 'Franchise Tax Report',
                    fromDate: '',
                    toDate: '',
                    comments: '',
                    additionalStates: false,
                    selectedStates: [defaultSelectedState],
                },
                'goodStndSearch': {
                    type: 'goodStndSearch',
                    name: 'Certificate of Good Standing',
                    goodStndType: 'Regular',
                    comments: '',
                    additionalStates: false,
                    selectedStates: [defaultSelectedState],
                },
                'copiesOfEntityFiling': {
                    type: 'copiesOfEntityFiling',
                    name: 'Copies of Entity Filings',
                    copiesOfEntityFilingsType: 'allDoc',
                    allDocAmend: false,
                    allDocCert: false,
                    certIncAmend: false,
                    certIncCert: false,
                    comments: '',
                    additionalStates: false,
                    selectedStates: [defaultSelectedState],
                    allDoc: true,
                    certInc: false,
                },
                'regAgentLetter': {
                    type: 'regAgentLetter',
                    name: 'Registered Agent Letter',
                },
                'offAndDirLetter': {
                    type: 'offAndDirLetter',
                    name: 'Officers and Directors Letter',
                },
                'nameAvailability': {
                    type: 'nameAvailability',
                    name: 'Name Availability',
                    primary: '',
                    second: '',
                    last: '',
                    additionalStates: false,
                    selectedStates: [defaultSelectedState],
                },
            },
        },
    ],
});

export const getOrderInformation = ({ reference }) => ({
    content: [[{ label: 'Reference Number', value: reference }]],
});

const getSelectedStates = (additionalStates, selectedStates) => ({
    label: 'For States',
    value: (
        additionalStates
            ? selectedStates.join(', ')
            : defaultSelectedState
    ),
});

const getNameSection = ({
    type,
    Individual: {
        prefix = '',
        firstName = '',
        middleName = '',
        lastName = '',
        suffix = '',
    },
    Company: {
        companyName,
    },
}) => {
    const name = type === 'Individual'
        ? `${prefix} ${firstName} ${middleName} ${lastName} ${suffix}`
        : companyName;
    const sectionData = [
        { title: `${type} Information` },
        { label: 'Name', value: name },
    ];

    return sectionData;
};

const getUccSearchSection = ({
    name,
    uccSearchType,
    EXACT_ADDRESS: {
        addressLine1,
        addressLine2,
        city,
        county,
        zip,
    },
    comments,
    additionalStates,
    selectedStates,
}) => {
    const sectionData = [{ title: name }];

    switch (uccSearchType) {
        case 'EXACT_ADDRESS':
            sectionData.push({ label: '', value: 'Copies of Liens at specific address only' });
            if (addressLine1) {
                sectionData.push({ label: 'Address Line1', value: addressLine1 });
            }
            if (addressLine2) {
                sectionData.push({ label: 'Address Line2', value: addressLine2 });
            }
            if (city) {
                sectionData.push({ label: 'City', value: city });
            }
            if (county) {
                sectionData.push({
                    label: 'County',
                    value: countyOptions.find(({ value }) => value === county).label,
                });
            }
            if (zip) {
                sectionData.push({ label: 'Zip', value: zip });
            }
            break;
        case 'ANY_ADDRESS':
            sectionData.push({ label: '', value: 'Copies of Liens at any address' });
            break;
        default:
            break;
    }

    if (comments) {
        sectionData.push({ label: 'Comments', value: comments });
    }
    if (selectedStates.length) {
        sectionData.push(getSelectedStates(additionalStates, selectedStates));
    }

    return sectionData;
};

const getJudgementSearchSection = ({
    name,
    from,
    to,
    nameQualifier,
    exactName,
    additionalStates,
    selectedStates,
    addressLine1,
    addressLine2,
    city,
    zip,
}) => {
    const nameQualifierLabel = nameQualifierOptions.find(({ value }) => value === nameQualifier).label;
    const sectionData = [{ title: name }];

    if (from) {
        sectionData.push({ label: 'From', value: from.format(shortDateFormat) });
    }
    if (to) {
        sectionData.push({ label: 'To', value: to.format(shortDateFormat) });
    }
    if (nameQualifierLabel) {
        sectionData.push({ label: 'Qualifier', value: nameQualifierLabel });
    }
    if (exactName) {
        sectionData.push({ label: '', value: 'Match Exact Name Only' });
    }
    if (additionalStates) {
        if (addressLine1) {
            sectionData.push({ label: 'Address Line1', value: addressLine1 });
        }
        if (addressLine2) {
            sectionData.push({ label: 'Address Line2', value: addressLine2 });
        }
        if (city) {
            sectionData.push({ label: 'City', value: city });
        }
        if (zip) {
            sectionData.push({ label: 'Zip', value: zip });
        }
    }
    if (selectedStates.length) sectionData.push(getSelectedStates(additionalStates, selectedStates));

    return sectionData;
};

const getOneFieldSection = ({ name }) => [{ title: name }];

const getPatriotSearchSection = getOneFieldSection;

const getChildSupportSearchSection = ({
    name,
    exactName,
}) => {
    const sectionData = [{ title: name }];

    if (exactName) {
        sectionData.push({ label: '', value: 'Match Exact Name Only' });
    }

    return sectionData;
};

const getLitigationSearchSection = ({
    name,
    comments,
    additionalStates,
    selectedStates,
}) => {
    const sectionData = [{ title: name }];
    if (comments) {
        sectionData.push({ label: 'Comments', value: comments });
    }
    if (selectedStates.length) {
        sectionData.push(getSelectedStates(additionalStates, selectedStates));
    }

    return sectionData;
};

const getCorporateStatusSection = ({
    name,
    comments,
    additionalStates,
    selectedStates,
}) => {
    const sectionData = [{ title: name }];
    if (comments) {
        sectionData.push({ label: 'Comments', value: comments });
    }
    if (selectedStates.length) {
        sectionData.push(getSelectedStates(additionalStates, selectedStates));
    }

    return sectionData;
};

const getFranchiseTaxReportSection = ({
    name,
    fromDate,
    toDate,
    comments,
    additionalStates,
    selectedStates,
}) => {
    const sectionData = [{ title: name }];

    if (fromDate) {
        sectionData.push({ label: 'Title Held From', value: fromDate.format(shortDateFormat) });
    }
    if (toDate) {
        sectionData.push({ label: 'To', value: toDate.format(shortDateFormat) });
    }
    if (comments) {
        sectionData.push({ label: 'Comments', value: comments });
    }
    if (selectedStates.length) {
        sectionData.push(getSelectedStates(additionalStates, selectedStates));
    }

    return sectionData;
};

const getGoodStndSearchSection = ({
    name,
    goodStndType,
    comments,
    additionalStates,
    selectedStates,
}) => {
    const sectionData = [{ title: name }];

    if (goodStndType) {
        sectionData.push({
            label: '',
            value: goodStndTypeOptions.find(({ value }) => value === goodStndType).label,
        });
    }
    if (comments) {
        sectionData.push({ label: 'Comments', value: comments });
    }
    if (selectedStates.length) {
        sectionData.push(getSelectedStates(additionalStates, selectedStates));
    }

    return sectionData;
};

const getCopiesOfEntityFilingSection = data => {
    const {
        name,
        copiesOfEntityFilingsType,
        comments,
        additionalStates,
        selectedStates,
    } = data;
    const sectionData = [{ title: name }];

    const type = copiesOfEntityFilingsTypeOptions.find(({ value }) => value === copiesOfEntityFilingsType).label;
    const amendments = data[`${copiesOfEntityFilingsType}Amend`] ? 'with Amendments' : '';
    const certifiedCopies = data[`${copiesOfEntityFilingsType}Cert`] ? '(Certified Copies)' : '';

    const copiesOfEntityFilingsTypeDescription = `${type} ${amendments} ${certifiedCopies}`;

    if (copiesOfEntityFilingsType) {
        sectionData.push({ label: '', value: copiesOfEntityFilingsTypeDescription });
    }
    if (comments) {
        sectionData.push({ label: 'Comments', value: comments });
    }
    if (selectedStates.length) {
        sectionData.push(getSelectedStates(additionalStates, selectedStates));
    }

    return sectionData;
};

const getRegAgentLetterSection = getOneFieldSection;

const getOffAndDirLetterSection = getOneFieldSection;

const getNameAvailabilitySection = ({
    name,
    primary,
    second,
    last,
    additionalStates,
    selectedStates,
}) => {
    const sectionData = [{ title: name }];

    if (primary) {
        sectionData.push({ label: 'Primary', value: primary });
    }
    if (second) {
        sectionData.push({ label: 'Second', value: second });
    }
    if (last) {
        sectionData.push({ label: 'Last', value: last });
    }
    if (selectedStates.length) {
        sectionData.push(getSelectedStates(additionalStates, selectedStates));
    }

    return sectionData;
};

const sectionsPreview = {
    'name': getNameSection,
    'uccSearch': getUccSearchSection,
    'judgmentSearch': getJudgementSearchSection,
    'patriotSearch': getPatriotSearchSection,
    'childSupportSearch': getChildSupportSearchSection,
    'litigationSearch': getLitigationSearchSection,
    'corporateStatus': getCorporateStatusSection,
    'franchiseTaxReport': getFranchiseTaxReportSection,
    'goodStndSearch': getGoodStndSearchSection,
    'copiesOfEntityFiling': getCopiesOfEntityFilingSection,
    'regAgentLetter': getRegAgentLetterSection,
    'offAndDirLetter': getOffAndDirLetterSection,
    'nameAvailability': getNameAvailabilitySection,
};

const getSelectedServicesNames = selectedServicesTypes => Object
    .entries(selectedServicesTypes)
    .filter(item => item[1])
    .map(item => item[0]);
