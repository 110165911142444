import moment from 'moment';
import { shortDateFormat } from '@src/utils/helpers/dateUtils';
import { request, postRequestConfig, getRequestConfig } from '@src/utils/apiServices/api';
import { interpolate } from '@src/utils/helpers/normalize';
import {
    urlOrders,
    urlOrderConfirmationFile,
    urlOrderConfirmation,
    urlInvoice,
} from '@src/utils/constants/endpointConstants';

export const postOrder = (type, params) => {
    const data = {
        ...params,
        deliveryPreference: { ...params.deliveryPreference },
    };
    const {
        deliveryPreference: {
            rushDate,
            rushOrder,
            emailResults,
            email,
            additionalEmail,
        },
        deliveryPreference,
        contactList,
        referenceRequired,
        copySelectedServices,
        confirmationEmailList,
        lastCertificationDate,
        beginCertificationDate,
    } = data;
    const formattedRushDate = moment.isMoment(rushDate) ? rushDate.format(shortDateFormat) : rushDate;

    if (deliveryPreference.availableEmails) delete data.deliveryPreference.availableEmails;
    if (deliveryPreference.canChangeWebDelivery) delete data.deliveryPreference.canChangeWebDelivery;
    if (deliveryPreference.canMakeAccutitle) delete data.deliveryPreference.canMakeAccutitle;
    if (contactList) delete data.contactList;
    if (confirmationEmailList) delete data.confirmationEmailList;
    if (lastCertificationDate) delete data.lastCertificationDate;
    if (beginCertificationDate) delete data.beginCertificationDate;
    if (referenceRequired || referenceRequired === false) delete data.referenceRequired;
    if (copySelectedServices || copySelectedServices === false) delete data.copySelectedServices;

    return (
        request({
            ...postRequestConfig,
            url: interpolate(urlOrders, type),
            data: {
                ...data,
                deliveryPreference: {
                    ...data.deliveryPreference,
                    rushDate: rushOrder ? formattedRushDate : null,
                    email: emailResults ? email : null,
                    additionalEmail: emailResults ? additionalEmail : null,
                },
            },
        })
    );
};

export const downloadConfirmation = orderId => (
    request({
        ...getRequestConfig,
        url: interpolate(urlOrderConfirmationFile, orderId),
    })
);

export const fetchOrderDetails = orderId => (
    request({
        ...getRequestConfig,
        url: interpolate(urlOrderConfirmation, orderId),
    })
);

export const getInvoice = orderId => (
    request({
        ...getRequestConfig,
        url: interpolate(urlInvoice, orderId),
    })
);
