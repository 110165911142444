import React from 'react';

const FieldValidationMessage = ({ error, warning, controlId }) => (
    <>
        {
            (
                error
                && <span className={`help-block fade-in-error-block ${controlId}-error`}>{error}</span>
            )
            || (
                warning
                && <span className={`help-block fade-in-warning-block ${controlId}-warning`}>{warning}</span>
            )
        }
    </>
);

export default FieldValidationMessage;
