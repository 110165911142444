import { sortArrayByObjectField } from '@src/utils/helpers/normalize';
import {
    FETCHING_CUSTOMERS,
    FETCH_CUSTOMERS_SUCCESS,
    FETCH_CUSTOMERS_FAIL,
    SELECTING_CUSTOMER,
    SELECT_CUSTOMER_SUCCESS,
    SELECT_CUSTOMER_FAIL,
    FETCHING_CURRENT_CUSTOMER,
    FETCH_CURRENT_CUSTOMER_SUCCESS,
    FETCH_CURRENT_CUSTOMER_FAIL,
    CHECK_DEFAULT_CUSTOMER_SUCCESS,
    FETCH_AFFILIATED_CUSTOMERS,
    FETCH_AFFILIATED_CUSTOMERS_SUCCESS,
    FETCH_AFFILIATED_CUSTOMERS_FAIL,
    SELECT_AFFILIATED_CUSTOMER,
    SELECT_AFFILIATED_CUSTOMER_SUCCESS,
    SELECT_AFFILIATED_CUSTOMER_FAIL,
} from '../../actions/customers';

const initialState = {
    fetching: false,
    selecting: false,
    selectingAffiliatedCustomer: false,
    confirming: false,
    error: false,
    items: [],
    count: 0,
    confirmationBillingInfo: null,
    currentCustomer: {},
    currentAffiliatedCustomerId: null,
    fetchingCurrentCustomer: false,
    isDefaultCustomer: false,
    affiliatedCustomers: [],
    fetchingAffiliatedCustomers: false,
};

const customers = (state = initialState, action) => {
    switch (action.type) {
        case FETCHING_CUSTOMERS:
            return {
                ...state,
                fetching: true,
                error: false,
            };
        case FETCH_CUSTOMERS_SUCCESS:
            return {
                ...state,
                items: action.response.data,
                count: action.response.total,
                fetching: false,
            };
        case FETCH_CUSTOMERS_FAIL:
            return {
                ...state,
                fetching: false,
                error: action.payload,
                count: 0,
            };
        case FETCHING_CURRENT_CUSTOMER:
            return {
                ...state,
                fetchingCurrentCustomer: true,
                error: false,
            };
        case FETCH_CURRENT_CUSTOMER_SUCCESS:
            return {
                ...state,
                currentCustomer: action.response,
                fetchingCurrentCustomer: false,
            };
        case FETCH_CURRENT_CUSTOMER_FAIL:
            return {
                ...state,
                fetchingCurrentCustomer: false,
                error: action.payload,
            };
        case FETCH_AFFILIATED_CUSTOMERS:
            return {
                ...state,
                fetchingAffiliatedCustomers: true,
                error: false,
            };
        case FETCH_AFFILIATED_CUSTOMERS_SUCCESS:
            return {
                ...state,
                affiliatedCustomers: action.response.data
                    ? sortArrayByObjectField(action.response.data, 'affiliateName')
                    : [],
                fetchingAffiliatedCustomers: false,
            };
        case FETCH_AFFILIATED_CUSTOMERS_FAIL:
            return {
                ...state,
                fetchingAffiliatedCustomers: false,
                error: action.payload,
            };
        case SELECT_AFFILIATED_CUSTOMER:
            return { ...state, selectingAffiliatedCustomer: true };
        case SELECT_AFFILIATED_CUSTOMER_SUCCESS:
            return {
                ...state,
                selectingAffiliatedCustomer: false,
                currentAffiliatedCustomerId: action.response,
            };
        case SELECT_AFFILIATED_CUSTOMER_FAIL:
            return { ...state, selectingAffiliatedCustomer: false, error: action.payload };
        case SELECTING_CUSTOMER:
            return { ...state, selecting: true };
        case SELECT_CUSTOMER_SUCCESS:
            return {
                ...state,
                selecting: false,
            };
        case SELECT_CUSTOMER_FAIL:
            return { ...state, selecting: false, error: action.payload };
        case CHECK_DEFAULT_CUSTOMER_SUCCESS:
            return { ...state, isDefaultCustomer: action.response.data };
        default:
            return state;
    }
};

export const getFetching = state => state.customers.fetching;
export const getSelecting = state => state.customers.selecting;
export const getSelectingAffiliatedCustomer = state => state.customers.selectingAffiliatedCustomer;
export const getCurrentAffiliatedCustomerId = state => state.customers.currentAffiliatedCustomerId;
export const getError = state => state.customers.error;
export const getCustomers = state => state.customers.items;
export const getCurrentCustomer = state => state.customers.currentCustomer;
export const getCurrentCustomerFetching = state => state.customers.fetchingCurrentCustomer;
export const getCount = state => state.customers.count;
export const getConfirmationBillingInfo = state => state.customers.confirmationBillingInfo;
export const getConfirming = state => state.customers.confirming;
export const getIsDefaultCustomer = state => state.customers.currentCustomer.defaultCustomer;
export const getAffiliatedCustomers = state => state.customers.affiliatedCustomers;
export const getFetchingAffiliatedCustomers = state => state.customers.fetchingAffiliatedCustomers;

export default customers;
