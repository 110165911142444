import { request, getRequestConfig, postRequestConfig } from '@src/utils/apiServices/api';
import {
    urlAccountSettings,
    urlAccountSettingsReport,
    urlAccountSettingsPersonal,
    urlAccountSettingsBusinessContact,
    urlAccountSettingsIndividualSearches,
    urlAccountSettingsPropertySearches,
    urlAccountSettingsChangePassword,
    urlOrdersDeliveryFormData,
    urlAccountTemplateKeys,
    urlStates,
} from '@src/utils/constants/endpointConstants';

import {
    BUSINESS_CONTACT_SETTINGS,
    INDIVIDUAL_SETTINGS,
    PERSONAL_SETTINGS,
    PROPERTY_SEARCH_SETTINGS,
    REPORT_SETTINGS,
    CHANGE_PASSWORD,
} from '@src/utils/constants/settingsConstants';

export const fetchAccountTemplateKeys = async () => {
    const result = await request({
        ...getRequestConfig,
        url: urlAccountTemplateKeys,
    });

    return result;
};

export const fetchAllStates = async () => {
    const result = await request({
        ...getRequestConfig,
        url: urlStates,
    });

    return result.data;
};

export const fetchAccountSettings = () => (
    request({
        ...getRequestConfig,
        url: urlAccountSettings,
    })
);

export const fetchAccountDeliverySettings = () => (
    request({
        ...getRequestConfig,
        url: urlOrdersDeliveryFormData,
    })
);

export const postAccountSettings = (data, settingsType) => (
    request({
        ...postRequestConfig,
        url: urlsForSettingsTypes[settingsType],
        data,
    })
);

const urlsForSettingsTypes = {
    [BUSINESS_CONTACT_SETTINGS]: urlAccountSettingsBusinessContact,
    [INDIVIDUAL_SETTINGS]: urlAccountSettingsIndividualSearches,
    [PERSONAL_SETTINGS]: urlAccountSettingsPersonal,
    [PROPERTY_SEARCH_SETTINGS]: urlAccountSettingsPropertySearches,
    [REPORT_SETTINGS]: urlAccountSettingsReport,
    [CHANGE_PASSWORD]: urlAccountSettingsChangePassword,
};
