import React from 'react';
import { Col, Row } from 'antd';
import { Field } from 'react-final-form';
import InputGroupAntCheckBoxAdapter from '@src/components/input/InputGroupAntCheckBoxAdapter';

const ServicePatriotSearch = ({
    name,
}) => (
    <div className="serviceBlock formBlock">
        <Row className="serviceHeader">
            <Col md={24} lg={16}>
                <Field
                    name={`${name}.selectedServicesTypes.patriotSearch`}
                    component={InputGroupAntCheckBoxAdapter}
                    controlId={`${name}.selectedServicesTypes.patriotSearch`}
                    labelText="PATRIOT SEARCH"
                    type="checkbox"
                />
            </Col>
        </Row>
    </div>
);

export default ServicePatriotSearch;
