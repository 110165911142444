import React from 'react';
import { NavLink } from 'react-router-dom';
import { ACCOUNT_VIEW_MY_ORDERS_NEW_REPORTS } from '@src/utils/constants/routerConstants';

const numberOfRows = 10;

const NewReportsBarTable = ({ reports }) => (
    <table>
        <thead>
            <tr>
                <th>Report #</th>
                <th>Order #</th>
                <th>Reference</th>
                <th>Report Type</th>
            </tr>
        </thead>
        <tbody>
            {reports.slice(0, numberOfRows).map(({
                id,
                orderno,
                smItem: { description },
                refno,
            }) => (
                <tr key={id}>
                    <td>{id}</td>
                    <td>{orderno}</td>
                    <td>
                        <div className="ellipsis">{refno}</div>
                    </td>
                    <td>
                        <div className="ellipsis">{description}</div>
                    </td>
                </tr>
            ))}
            {
                reports.length > numberOfRows
                && (
                    <tr>
                        <td colSpan="4" className="text-center">
                            <NavLink to={ACCOUNT_VIEW_MY_ORDERS_NEW_REPORTS}>
                                {`... and ${reports.length - numberOfRows} more`}
                            </NavLink>
                        </td>
                    </tr>
                )
            }
        </tbody>
    </table>
);

export default NewReportsBarTable;
