import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { Row, Col, Button } from 'antd';
import createDecorator from 'final-form-focus';
import Loader from '@src/components/Loader';
import {
    composeValidators, required, minLength, equalTo,
} from '@src/utils/helpers/validationRules';
import { initialPasswordSettings } from '@src/utils/templates/accountSettings';
import { saveAccountSettings, setSettingsContainerTitle, resetChangedPassword } from '@src/store/actions/accountSettings';
import { NavLink } from 'react-router-dom';
import { ACCOUNT_SETTINGS_PERSONAL } from '@src/utils/constants/routerConstants';
import { isAccountSettingsSaving, getChangePassword, getRequestError } from '@src/store/reducers/accountSettings';
import { CHANGE_PASSWORD } from '@src/utils/constants/settingsConstants';
import PasswordRequirements from '@src/components/PasswordRequirements';
import InputGroupAntInputAdapter from '@src/components/input/InputGroupAntInputAdapter';
import { gutter } from '@src/utils/helpers/orders';

const focusOnError = createDecorator();

const ChangePasswordContent = () => {
    const isSaving = useSelector(state => isAccountSettingsSaving(state));
    const changedPassword = useSelector(state => getChangePassword(state));
    const isError = useSelector(state => !!getRequestError(state));
    const dispatch = useDispatch();

    const [successPasswordChange, setSuccessPasswordChange] = useState(false);

    useEffect(() => {
        dispatch(setSettingsContainerTitle('Change Password'));
        return () => { dispatch(resetChangedPassword()); };
    }, []);

    useEffect(() => {
        if (changedPassword && !isError) setSuccessPasswordChange(true);
    }, [changedPassword]);

    return (
        !successPasswordChange
            ? (
                <Form
                    onSubmit={async data => dispatch(saveAccountSettings(data, CHANGE_PASSWORD))}
                    initialValues={initialPasswordSettings}
                    decorators={[focusOnError]}
                >
                    {
                        ({
                            handleSubmit, values, pristine, valid,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <Loader show={isSaving}>
                                    <div className="formBlock">
                                        <Row gutter={gutter}>
                                            <Col sm={24}>
                                                <PasswordRequirements />
                                            </Col>
                                        </Row>
                                        <Row gutter={gutter}>
                                            <Col md={16} xs={24} className="top-buffer">
                                                <Field
                                                    isRequired
                                                    type="password"
                                                    name="oldPassword"
                                                    component={InputGroupAntInputAdapter}
                                                    controlId="oldPassword"
                                                    labelText="Old Password"
                                                    validate={composeValidators(required)}
                                                />
                                            </Col>
                                        </Row>
                                        <Row gutter={gutter}>
                                            <Col md={16} xs={24}>
                                                <Field
                                                    isRequired
                                                    type="password"
                                                    name="password"
                                                    component={InputGroupAntInputAdapter}
                                                    controlId="password"
                                                    labelText="New Password"
                                                    validate={composeValidators(
                                                        required,
                                                        minLength(8),
                                                    )}
                                                />
                                            </Col>
                                        </Row>
                                        <Row gutter={gutter}>
                                            <Col md={16} xs={24}>
                                                <Field
                                                    isRequired
                                                    type="password"
                                                    name="repeatPassword"
                                                    component={InputGroupAntInputAdapter}
                                                    controlId="repeatPassword"
                                                    labelText="Confirm New Password"
                                                    validate={composeValidators(
                                                        required,
                                                        minLength(8),
                                                        equalTo(values.password, 'New Password'),
                                                    )}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                </Loader>
                                <Row gutter={gutter}>
                                    <Col md={24} xs={24}>
                                        <Button
                                            id="saveChanges"
                                            className="btn btn-primary pull-right"
                                            htmlType="submit"
                                            type="primary"
                                            disabled={isSaving || pristine || !valid}
                                        >
                                            Save
                                        </Button>
                                        <NavLink
                                            className="btn btn-default pull-right right-buffer-xs"
                                            to={ACCOUNT_SETTINGS_PERSONAL}
                                        >
                                            <Button
                                                className="right-buffer-xs"
                                            >
                                                Cancel
                                            </Button>
                                        </NavLink>
                                    </Col>
                                </Row>
                            </form>
                        )
                    }
                </Form>
            )
            : (
                <div className="changePasswordSuccess">
                    <Row className="formBlock" gutter={gutter}>
                        <Col md={16} sm={20} xs={24}>
                            <div className="h5 text-center">
                                Password was successfully changed.
                            </div>
                        </Col>
                        <Col md={8} sm={4} xs={24}>
                            <NavLink
                                id="nav-back"
                                className="pull-right"
                                to={ACCOUNT_SETTINGS_PERSONAL}
                            >
                                <Button type="link">
                                    Back to Settings
                                </Button>
                            </NavLink>
                        </Col>
                    </Row>
                </div>
            )
    );
};

export default ChangePasswordContent;
