/**
 * Created by oleg on 12/26/16.
 */
import React from 'react';
import { connect } from 'react-redux';
import { getNavigationStep } from '@src/store/reducers/registration';
import { Col, Row } from 'antd';
import NavigationStep from '@src/containers/registration/components/navigation/NavigationStep';

const NavigationMenu = ({ activeStep }) => (
    <Row id="navigationMenuBlock" justify="center">
        <Col md={16} sm={20} xs={24}>
            <ul id="navMenu" className="navMenu">
                <NavigationStep stepName="CORP_INF" stepDesc="Corporate Information" activeStep={activeStep} />
                <NavigationStep stepName="PERS_INF" stepDesc="Personal Information" activeStep={activeStep} />
                <NavigationStep stepName="REV_REG" stepDesc="Review &amp; Register" activeStep={activeStep} />
            </ul>
        </Col>
    </Row>
);

const mapStateToProps = state => ({
    activeStep: getNavigationStep(state),
});

export default connect(mapStateToProps)(NavigationMenu);
