import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { Row, Col, Button } from 'antd';
import createDecorator from 'final-form-focus';
import Loader from '@src/components/Loader';
import {
    composeValidators, maxLength, conditionRequired, multiEmailTags, maxLengthForTags,
} from '@src/utils/helpers/validationRules';
import { parseTemplatesFromArray } from '@src/utils/helpers/parse';
import { fetchAccountTemplateKeys } from '@src/utils/apiServices/accountSettingsRequests';
import { saveAccountSettings, setSettingsContainerTitle } from '@src/store/actions/accountSettings';
import { getReportSettings, isAccountSettingsSaving } from '@src/store/reducers/accountSettings';
import { REPORT_SETTINGS } from '@src/utils/constants/settingsConstants';
import {
    customizingSubjectOfEmail,
    customizingAttachmentNameOfEmail,
} from '@src/utils/constants/strings';
import InputGroupAntTagBoxAdapter from '@src/components/input/InputGroupAntTagBoxAdapter';
import { emailsJoiner, emailsSeparators } from '@src/utils/constants/formConstants';
import { multiEmailSplitter } from '@src/utils/helpers/regexps';
import InputGroupAntCheckBoxAdapter from '@src/components/input/InputGroupAntCheckBoxAdapter';
import InputGroupAntSelectAdapter from '@src/components/input/InputGroupAntSelectAdapter';
import InputGroupAntInputAdapter from '@src/components/input/InputGroupAntInputAdapter';
import { gutter } from '@src/utils/helpers/orders';

const focusOnError = createDecorator();

const optionsOutputControlledBy = [
    { value: 'INDIVIDUAL', label: 'Individual' },
    { value: 'COMPANY', label: 'Company' },
];

const controlledByIndividual = controlledBy => controlledBy === 'INDIVIDUAL';

const ReportOptionsContent = () => {
    const reportOptions = useSelector(state => getReportSettings(state));
    const isSaving = useSelector(state => isAccountSettingsSaving(state));
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [templateKeys, setTemplateKeys] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const templateKeys = await fetchAccountTemplateKeys();
            setTemplateKeys(templateKeys);
            setLoading(false);
        };

        dispatch(setSettingsContainerTitle('Report Options'));
        fetchData();
    }, []);

    return (
        <Form
            onSubmit={data => dispatch(saveAccountSettings({
                ...data,
                companyDeliverySettings: {
                    ...data.companyDeliverySettings,
                    emailConfAddress: data.companyDeliverySettings.emailConfAddress
                        ? data.companyDeliverySettings.emailConfAddress.join(emailsJoiner)
                        : [],
                },
            },
            REPORT_SETTINGS))}
            initialValues={{
                ...reportOptions,
                companyDeliverySettings: {
                    ...reportOptions.companyDeliverySettings,
                    emailConfAddress: reportOptions.companyDeliverySettings.emailConfAddress
                        ? reportOptions.companyDeliverySettings.emailConfAddress
                            .split(multiEmailSplitter)
                            .filter(item => !!item.length)
                        : [],
                },
            }}
            decorators={[focusOnError]}
            validate={
                ({
                    outputControlledBy,
                    companyDeliverySettings: {
                        emailConf,
                        emailConfAddress,
                        emailResults,
                        emailSubject,
                        attachmentName,
                    },
                }) => ({
                    companyDeliverySettings: {
                        emailConfAddress: controlledByIndividual(outputControlledBy)
                            ? undefined
                            : conditionRequired(emailConf, emailConfAddress.length),
                        emailSubject: controlledByIndividual(outputControlledBy)
                            ? undefined
                            : conditionRequired(emailResults, emailSubject),
                        attachmentName: controlledByIndividual(outputControlledBy)
                            ? undefined
                            : conditionRequired(emailResults, attachmentName),
                    },
                })
            }
        >
            {
                ({
                    handleSubmit, form, values, pristine,
                }) => {
                    const {
                        outputControlledBy,
                        companyDeliverySettings: {
                            emailConf,
                            emailResults,
                        },
                    } = values;

                    return (
                        <form onSubmit={handleSubmit}>
                            <Loader show={loading || isSaving}>
                                <div className="formBlock">
                                    <Row gutter={gutter}>
                                        <Col md={12}>
                                            <Field
                                                type="checkbox"
                                                name="referenceRequired"
                                                component={InputGroupAntCheckBoxAdapter}
                                                labelText="Reference Required"
                                            />
                                        </Col>
                                    </Row>
                                    <Row gutter={gutter}>
                                        <Col md={12}>
                                            <Field
                                                type="checkbox"
                                                name="webDelivery"
                                                component={InputGroupAntCheckBoxAdapter}
                                                labelText="Web Delivery"
                                            />
                                        </Col>
                                    </Row>
                                    <Row gutter={gutter}>
                                        <Col md={12}>
                                            <Field
                                                name="outputControlledBy"
                                                component={InputGroupAntSelectAdapter}
                                                options={optionsOutputControlledBy}
                                                labelText="Output Controlled by"
                                            />
                                        </Col>
                                    </Row>
                                    <Row gutter={gutter} hidden={controlledByIndividual(outputControlledBy)}>
                                        <Col md={8} xs={24}>
                                            <Field
                                                controlId="emailConf"
                                                type="checkbox"
                                                name="companyDeliverySettings.emailConf"
                                                component={InputGroupAntCheckBoxAdapter}
                                                labelText="Email Order Confirmation"
                                            />
                                        </Col>
                                        <Col md={16} xs={24} hidden={!emailConf}>
                                            <Field
                                                name="companyDeliverySettings.emailConfAddress"
                                                controlId="emailConfAddress"
                                                labelText="Email Confirmation"
                                                component={InputGroupAntTagBoxAdapter}
                                                tokenSeparators={emailsSeparators}
                                                validate={composeValidators(
                                                    multiEmailTags,
                                                    maxLengthForTags(50, emailsJoiner),
                                                )}
                                                isRequired
                                                formSubmit={form.submit}
                                            />
                                        </Col>
                                    </Row>
                                    <div hidden={controlledByIndividual(outputControlledBy)}>
                                        <Row gutter={gutter}>
                                            <Col md={24}>
                                                <hr className="delimiter" />
                                            </Col>
                                            <Col md={24}>
                                                <Field
                                                    controlId="emailResults"
                                                    type="checkbox"
                                                    name="companyDeliverySettings.emailResults"
                                                    component={InputGroupAntCheckBoxAdapter}
                                                    labelText="Email Results"
                                                />
                                            </Col>
                                        </Row>
                                        <Row gutter={gutter}>
                                            <Col md={24} hidden={!emailResults}>
                                                <Field
                                                    name="companyDeliverySettings.emailSubject"
                                                    component={InputGroupAntInputAdapter}
                                                    controlId="emailSubject"
                                                    labelText="Email Subject Pattern"
                                                    validate={composeValidators(maxLength(150))}
                                                    isRequired
                                                    infoBlock={parseTemplatesFromArray(
                                                        templateKeys, customizingSubjectOfEmail,
                                                    )}
                                                />
                                            </Col>
                                        </Row>
                                        <Row hidden={!emailResults}>
                                            <Col md={24}>
                                                <Field
                                                    name="companyDeliverySettings.attachmentName"
                                                    component={InputGroupAntInputAdapter}
                                                    controlId="attachmentName"
                                                    labelText="Email Attachment Name Pattern"
                                                    validate={composeValidators(maxLength(150))}
                                                    isRequired
                                                    infoBlock={parseTemplatesFromArray(
                                                        templateKeys, customizingAttachmentNameOfEmail,
                                                    )}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Loader>
                            <Row gutter={gutter}>
                                <Col md={24} xs={24}>
                                    <Button
                                        id="saveChanges"
                                        className="btn btn-primary pull-right"
                                        htmlType="submit"
                                        type="primary"
                                        disabled={isSaving || pristine}
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        id="cancelChanges"
                                        className="cancel btn btn-default pull-right right-buffer-xs"
                                        htmlType="button"
                                        onClick={form.reset}
                                        disabled={isSaving || pristine}
                                    >
                                        Cancel
                                    </Button>
                                </Col>
                            </Row>
                        </form>
                    );
                }
            }
        </Form>
    );
};

export default ReportOptionsContent;
