export const buildCayenneExpression = param => {
    const columnName = param[0];
    let operand = param[1];
    let value = typeof param[2] === 'string' ? param[2].trim() : param[2];

    switch (operand) {
        case 'contains':
            operand = 'like';
            value = `'%${value}%'`;
            break;
        case 'startswith':
            operand = 'like';
            value = `'${value}%'`;
            break;
        case 'endswith':
            operand = 'like';
            value = `'%${value}'`;
            break;
        case '=':
            operand = 'like';
            value = `'${value}'`;
            break;
        case 'notcontains':
            operand = 'not like';
            value = `'%${value}%'`;
            break;
        case '<>':
            operand = 'not like';
            value = `'${value}'`;
            break;
        default:
            break;
    }

    return `(${columnName} ${operand} ${value})`;
};

export const getCayenneExpression = params => {
    let result = '';

    if (params) {
        // eslint-disable-next-line no-prototype-builtins
        if (params.hasOwnProperty('columnIndex')) {
            result = buildCayenneExpression(params);
        } else {
            result = Array.isArray(params) && params.length
                ? `(${params.map(param => getCayenneExpression(param)).join(' ')})`
                : params;
        }
    }

    return result;
};
