import React from 'react';
import { Col, Row } from 'antd';
import { gutter } from '@src/utils/helpers/orders';
import { Field } from 'react-final-form';
import InputGroupAntInputAdapter from '@src/components/input/InputGroupAntInputAdapter';
import InputGroupAntCheckBoxAdapter from '@src/components/input/InputGroupAntCheckBoxAdapter';
import InputGroupHiddenFieldAdapter from '@src/components/input/InputGroupHiddenFieldAdapter';
import InputGroupAntSelectAdapter from '@src/components/input/InputGroupAntSelectAdapter';
import WhenFieldChanges from '@src/components/input/WhenFieldChanges';
import { composeValidators, naturalNumber } from '@src/utils/helpers/validationRules';

const FloodSearchSection = ({
    searchSupplier,
    values,
    states,
}) => {
    const serviceType = values.selectedServicesTypes.floodSearchService
        ? 'floodSearchService'
        : 'floodSearchServiceLifeOfLoan';
    return (
        <div className="serviceBlock formBlock flood-search-block">
            <Row className="serviceHeader">
                <Col span={24}>
                    <div className="providedBy text-light">
                        {searchSupplier}
                    </div>
                    <Field
                        name="selectedServicesTypes.floodSearchService"
                        component={InputGroupAntCheckBoxAdapter}
                        controlId="selectedServicesTypes.floodSearchService"
                        labelText="Flood Search"
                        type="checkbox"
                    />
                    <WhenFieldChanges
                        field="selectedServicesTypes.floodSearchService"
                        set="selectedServicesTypes.floodSearchServiceLifeOfLoan"
                        to={
                            values.selectedServicesTypes.floodSearchService
                                ? false
                                : values.selectedServicesTypes.floodSearchServiceLifeOfLoan
                        }
                    />
                </Col>
            </Row>
            <Row className="serviceHeader">
                <Col span={24}>
                    <Field
                        name="selectedServicesTypes.floodSearchServiceLifeOfLoan"
                        component={InputGroupAntCheckBoxAdapter}
                        controlId="selectedServicesTypes.floodSearchServiceLifeOfLoan"
                        labelText="Flood Search - Life Of Loan"
                        type="checkbox"
                    />
                    <WhenFieldChanges
                        field="selectedServicesTypes.floodSearchServiceLifeOfLoan"
                        set="selectedServicesTypes.floodSearchService"
                        to={
                            values.selectedServicesTypes.floodSearchServiceLifeOfLoan
                                ? false
                                : values.selectedServicesTypes.floodSearchService
                        }
                    />
                </Col>
            </Row>
            {
                (
                    values.selectedServicesTypes.floodSearchService
                    || values.selectedServicesTypes.floodSearchServiceLifeOfLoan
                )
                && (
                    <>
                        <Row gutter={gutter}>
                            <Col md={24} lg={16}>
                                <Field
                                    name={`selectedServices.${serviceType}.serviceName`}
                                    component={InputGroupHiddenFieldAdapter}
                                    controlId={`selectedServices.${serviceType}.serviceName`}
                                />
                            </Col>
                        </Row>
                        <Row gutter={gutter}>
                            <Col md={24} lg={16}>
                                <Field
                                    name={`selectedServices.${serviceType}.lender`}
                                    component={InputGroupAntInputAdapter}
                                    controlId={`selectedServices.${serviceType}.lender`}
                                    labelText="Lender"
                                />
                            </Col>
                        </Row>
                        <Row gutter={gutter}>
                            <Col md={24} lg={16}>
                                <Field
                                    name={`selectedServices.${serviceType}.addressLine1`}
                                    component={InputGroupAntInputAdapter}
                                    controlId={`selectedServices.${serviceType}.addressLine1`}
                                    labelText="Address Line 1"
                                />
                            </Col>
                        </Row>
                        <Row gutter={gutter}>
                            <Col md={24} lg={16}>
                                <Field
                                    name={`selectedServices.${serviceType}.addressLine2`}
                                    component={InputGroupAntInputAdapter}
                                    controlId={`selectedServices.${serviceType}.addressLine2`}
                                    labelText="Address Line 2"
                                />
                            </Col>
                        </Row>
                        <Row gutter={gutter}>
                            <Col md={24} lg={10}>
                                <Field
                                    name={`selectedServices.${serviceType}.city`}
                                    component={InputGroupAntInputAdapter}
                                    controlId={`selectedServices.${serviceType}.city`}
                                    labelText="City"
                                />
                            </Col>
                            <Col md={24} lg={6}>
                                <Field
                                    name={`selectedServices.${serviceType}.zip`}
                                    component={InputGroupAntInputAdapter}
                                    controlId={`selectedServices.${serviceType}.zip`}
                                    labelText="Zip"
                                />
                            </Col>
                        </Row>
                        <Row gutter={gutter}>
                            <Col md={24} lg={10}>
                                <Field
                                    name={`selectedServices.${serviceType}.state`}
                                    component={InputGroupAntSelectAdapter}
                                    controlId={`selectedServices.${serviceType}.state`}
                                    labelText="State"
                                    options={states.map(({ state, stateDesc }) => ({
                                        value: state,
                                        label: stateDesc,
                                    }))}
                                />
                            </Col>
                        </Row>
                        {
                            values.selectedServicesTypes.floodSearchServiceLifeOfLoan
                            && (
                                <>
                                    <hr />
                                    <Row gutter={gutter}>
                                        <Col md={24} lg={16}>
                                            <Field
                                                name="selectedServices.floodSearchServiceLifeOfLoan.loanId"
                                                component={InputGroupAntInputAdapter}
                                                controlId="selectedServices.floodSearchServiceLifeOfLoan.loanId"
                                                labelText="Loan ID"
                                            />
                                        </Col>
                                    </Row>
                                    <Row gutter={gutter}>
                                        <Col md={24} lg={16}>
                                            <Field
                                                name="selectedServices.floodSearchServiceLifeOfLoan.loadLength"
                                                component={InputGroupAntInputAdapter}
                                                controlId="selectedServices.floodSearchServiceLifeOfLoan.loadLength"
                                                labelText="Loan Length"
                                                validate={composeValidators(naturalNumber)}
                                            />
                                        </Col>
                                    </Row>
                                </>
                            )
                        }
                        <hr />
                        <Row gutter={gutter}>
                            <Col md={24} lg={16}>
                                <Field
                                    name={`selectedServices.${serviceType}.comment`}
                                    component={InputGroupAntInputAdapter}
                                    controlId={`selectedServices.${serviceType}.comment`}
                                    labelText="Comments"
                                />
                            </Col>
                        </Row>
                    </>
                )
            }
        </div>
    );
};

export default FloodSearchSection;
