import {
    EDIT_USER_ACTION, EDIT_USER_SUBMIT, EDIT_USER_CANCEL,
    CREATE_USER_ACTION, CREATE_USER_SUBMIT, CREATE_USER_CANCEL, CREATE_USER_SUCCESS, CREATE_USER_FAIL,
    UPDATE_USER_SUCCESS, UPDATE_USER_FAIL,
} from '../../actions/userMaintanance';

const initialState = {
    username: null,
    editing: false,
    newUser: false,
    postingOnServer: false,
};

const userOnEdit = (state = initialState, action) => {
    switch (action.type) {
        case EDIT_USER_ACTION:
            return {
                username: action.user.username, editing: true, newUser: false, postingOnServer: false,
            };
        case CREATE_USER_SUCCESS:
        case EDIT_USER_CANCEL:
        case CREATE_USER_CANCEL:
        case UPDATE_USER_SUCCESS:
            return {
                username: null, editing: false, newUser: false, postingOnServer: false,
            };
        case CREATE_USER_ACTION:
            return {
                username: null, editing: true, newUser: true, postingOnServer: false,
            };
        case CREATE_USER_SUBMIT:
        case EDIT_USER_SUBMIT:
            return { ...state, editing: true, postingOnServer: true };
        case CREATE_USER_FAIL:
        case UPDATE_USER_FAIL:
            return { ...state, postingOnServer: false };
        default:
            return state;
    }
};

export const getEditUserId = state => state.userOnEdit.username;
export const isUserEditing = state => state.userOnEdit.editing;
export const isCreateNewUser = state => state.userOnEdit.newUser;
export const isPostingUserChanges = state => state.userOnEdit.postingOnServer;

export default userOnEdit;
