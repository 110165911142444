/**
 * Created by oleg on 12/26/16.
 */
import React from 'react';
import { Field } from 'react-final-form';
import { Row, Col } from 'antd';
import InputGroupAntInputAdapter from '@src/components/input/InputGroupAntInputAdapter';
import { leadingWhitespacesNormalizer, phone } from '@src/utils/helpers/normalize';
import {
    required,
    maxLength,
    email,
    phoneNumber,
    composeValidators,
} from '@src/utils/helpers/validationRules';
import { uniqueUserName } from '@src/utils/helpers/validationRulesAsync';
import { gutter } from '@src/utils/helpers/orders';
import ContentHeader from '@src/containers/registration/components/utils/ContentHeader';

const PersonalInformation = () => (
    <div id="employeeInformationForm" className="employeeInformationForm">
        <ContentHeader contentName="Employee Information" />

        <Row className="formBlock" gutter={gutter}>
            <Col md={16} xs={24} className="top-buffer">
                <Field
                    name="employeeFirstName"
                    component={InputGroupAntInputAdapter}
                    controlId="employeeFirstName"
                    isRequired
                    labelText="First Name"
                    validate={composeValidators(required, maxLength(25))}
                    autoFocus
                />
            </Col>

            <Col md={16} xs={24} className="top-buffer">
                <Field
                    name="employeeLastName"
                    component={InputGroupAntInputAdapter}
                    controlId="employeeLastName"
                    isRequired
                    labelText="Last Name"
                    validate={composeValidators(required, maxLength(25))}
                />
            </Col>

            <Col md={16} xs={24} className="top-buffer">
                <Field
                    name="username"
                    component={InputGroupAntInputAdapter}
                    controlId="employeeUsername"
                    isRequired
                    labelText="Email"
                    validate={composeValidators(required, email, uniqueUserName, maxLength(70))}
                    parse={leadingWhitespacesNormalizer}
                />
            </Col>

            <Col md={10} xs={24} className="top-buffer clear-left">
                <Field
                    name="employeePhone"
                    component={InputGroupAntInputAdapter}
                    controlId="employeePhone"
                    isRequired
                    labelText="Phone"
                    validate={composeValidators(required, phoneNumber)}
                    format={phone}
                    parse={phone}
                />
            </Col>

            <Col md={6} xs={24} className="top-buffer">
                <Field
                    name="employeeExt"
                    component={InputGroupAntInputAdapter}
                    controlId="employeeExt"
                    labelText="ext"
                    validate={composeValidators(maxLength(10))}
                />
            </Col>
        </Row>
    </div>
);

export default PersonalInformation;
