import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '@src/store/reducers/userMaintanance/users';

const DatadogConfig = () => {
    const currentUser = useSelector(getCurrentUser);

    useEffect(() => {
        if (window.DD_RUM && process.env.BUILD_MODE === 'production' && currentUser) {
            window.DD_RUM.setUser({
                name: `${currentUser.firstName} ${currentUser.lastName}`,
            });
        }
    }, [currentUser]);

    useEffect(() => () => window.DD_RUM?.stopSessionReplayRecording(), []);

    return null;
};

export default DatadogConfig;
