export const FETCH_STATES = 'FETCH_STATES';
export const FETCH_STATES_SUCCESS = 'FETCH_STATES_SUCCESS';
export const FETCH_STATES_FAIL = 'FETCH_STATES_FAIL';
export const FETCHING_MUNICIPALITIES = 'FETCHING_MUNICIPALITIES';
export const FETCH_MUNICIPALITIES_SUCCESS = 'FETCH_MUNICIPALITIES_SUCCESS';
export const FETCH_MUNICIPALITIES_FAIL = 'FETCH_MUNICIPALITIES_FAIL';

export const fetchStates = () => ({ type: FETCH_STATES });

export const fetchStatesSucceeded = response => ({
    type: FETCH_STATES_SUCCESS,
    response,
});

export const fetchStatesFailed = error => ({
    type: FETCH_STATES_FAIL,
    error,
});

export const fetchingMunicipalities = () => ({ type: FETCHING_MUNICIPALITIES });

export const fetchMunicipalitiesSucceeded = response => ({
    type: FETCH_MUNICIPALITIES_SUCCESS,
    response,
});

export const fetchMunicipalitiesFailed = error => ({
    type: FETCH_MUNICIPALITIES_FAIL,
    error,
});
