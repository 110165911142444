import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'antd';
import OrderConfirmation from '@src/components/orders/OrderConfirmation';
import { downloadConfirmation } from '@src/store/actions/orders';
import { getPostedOrder, getPosting, getError } from '@src/store/reducers/orders';
import { getCurrentCustomer, getCurrentCustomerFetching } from '@src/store/reducers/customers';

import SelectOrderTable from '@src/containers/orderServiceJudgmentContinuation/SelectOrderTable';
import OrderForm from '@src/containers/orderServiceJudgmentContinuation/OrderForm';
import {
    getContactInformationConfirmation,
    getOrderInformationConfirmation,
    getDeliveryInformationConfirmation,
    getMainInformationConfirmation,
} from '@src/utils/helpers/orders';
import {
    ORDER_SERVICE_JUDGMENT_CONTINUATION_SELECT_TABLE,
    ORDER_SERVICE_JUDGMENT_CONTINUATION,
} from '@src/utils/constants/routerConstants';

const orderServiceModes = {
    SELECT: 'select',
    EDIT: 'edit',
    CONFIRMATION: 'confirmation',
};

const OrderServiceJudgmentContinuationContent = () => {
    const { EDIT, SELECT, CONFIRMATION } = orderServiceModes;
    const { id } = useParams();
    const isPostingOrder = useSelector(getPosting);
    const postedOrder = useSelector(getPostedOrder);
    const postedOrderError = useSelector(getError);
    const { number: currentCustomerId } = useSelector(getCurrentCustomer);
    const currentCustomerFetching = useSelector(getCurrentCustomerFetching);

    const [mode, setMode] = useState(id ? EDIT : SELECT);
    const [selectedOrders, setSelectedOrders] = useState([]);
    const [currentOrderId, setCurrentOrderId] = useState(id);

    const setSelectPage = useCallback(() => {
        setMode(SELECT);
        setCurrentOrderId(null);
        setSelectedOrders([]);
        window.history.pushState('', '', `${window.location.origin}${ORDER_SERVICE_JUDGMENT_CONTINUATION_SELECT_TABLE}`);
    });

    const setEditPage = useCallback(id => {
        setMode(EDIT);
        window.history.pushState(null, '', `${window.location.origin}${ORDER_SERVICE_JUDGMENT_CONTINUATION}/appendorderform/${id}`);
    });

    const dispatch = useDispatch();

    useEffect(() => {
        if (currentCustomerId && currentCustomerFetching) {
            setSelectPage();
        }
    }, [currentCustomerFetching, currentCustomerId]);

    return (
        <Row id="orderServiceJudgmentContinuationContainer">
            {
                mode === SELECT
                && (
                    <Col xs={{ span: 24, offset: 0 }} lg={{ span: 24, offset: 0 }}>
                        <SelectOrderTable
                            selectAllOrders={setSelectedOrders}
                            selectOrder={setCurrentOrderId}
                            setNextStep={setEditPage}
                            shouldReloadData={currentCustomerId}
                        />
                    </Col>
                )
            }
            {
                mode === EDIT
                && (
                    <Col xs={{ span: 24, offset: 0 }} lg={{ span: 16, offset: 4 }}>
                        <OrderForm
                            setNextStep={() => setMode(CONFIRMATION)}
                            setPrevStep={setSelectPage}
                            id={currentOrderId}
                        />
                    </Col>
                )
            }
            {
                mode === CONFIRMATION
                && (
                    <Col xs={{ span: 24, offset: 0 }} lg={{ span: 16, offset: 4 }}>
                        <OrderConfirmation
                            setNextStep={() => {
                                if (selectedOrders.length) {
                                    const newSelectedOrders = [...selectedOrders];
                                    const id = newSelectedOrders.shift();
                                    setSelectedOrders(newSelectedOrders);
                                    setCurrentOrderId(id);
                                    setEditPage(id);
                                } else {
                                    setSelectPage();
                                }
                            }}
                            downLoadConfirmation={orderId => dispatch(downloadConfirmation(orderId))}
                            isPostingOrder={isPostingOrder}
                            postedOrderError={postedOrderError}
                            order={postedOrder}
                            orderInformation={getOrderInformationConfirmation(postedOrder)}
                            contactInformation={getContactInformationConfirmation(postedOrder)}
                            deliveryInformation={getDeliveryInformationConfirmation(postedOrder)}
                            ordersList={getMainInformationConfirmation(postedOrder)}
                        />
                    </Col>
                )
            }
        </Row>
    );
};

export default OrderServiceJudgmentContinuationContent;
