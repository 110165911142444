import React from 'react';
import {
    NavLink,
} from 'react-router-dom';
import { Button, Dropdown } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import {
    ACCOUNT_SELECT_CUSTOMER,
    ACCOUNT_SETTINGS,
} from '@src/utils/constants/routerConstants';

const UserMenu = ({
    onLogOut,
    accountNumber = '',
    address = '',
    phone = '',
    userName = '',
    canSelectCustomer,
}) => (
    <Dropdown
        overlay={(
            <ul className="user-menu-dropdown menu-dropdown">
                <li>
                    <address>
                        <ul className="list-unstyled">
                            <li id="customer-info-dropdown--accountNumber">
                                <p className="small text-muted">Account Number</p>
                                {accountNumber}
                            </li>
                            <li id="customer-info-dropdown--address">
                                <p className="small text-muted">Address</p>
                                {address}
                            </li>
                            <li id="customer-info-dropdown--phoneNumber">
                                <p className="small text-muted">
                                    Phone Number
                                </p>
                                {phone}
                            </li>
                            <li className="divider bottom-buffer-no" role="separator" />
                            {
                                canSelectCustomer
                                && (
                                    <>
                                        <li className="bottom-buffer-no" id="customer-info-dropdown--selectCustomer">
                                            <NavLink to={ACCOUNT_SELECT_CUSTOMER} activeClassName="selected">
                                                Select Customer
                                            </NavLink>
                                        </li>
                                        <li className="divider bottom-buffer-no" role="separator" />
                                    </>
                                )
                            }
                            <li className="bottom-buffer-no" id="customer-info-dropdown--settings">
                                <NavLink to={ACCOUNT_SETTINGS} activeClassName="selected">
                                    Settings
                                </NavLink>
                            </li>
                            <li className="bottom-buffer-no" id="customer-info-dropdown--logout">
                                <Button id="logout" type="link" onClick={onLogOut}>
                                    Log Out
                                </Button>
                            </li>
                        </ul>
                    </address>
                </li>
            </ul>
        )}
        placement="bottomRight"
        trigger={['click', 'hover']}
    >
        <Button id="userOptions" type="primary" icon={<SettingOutlined />}>{userName}</Button>
    </Dropdown>
);

export default UserMenu;
