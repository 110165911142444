import React from 'react';
import { Col, Row } from 'antd';
import { gutter } from '@src/utils/helpers/orders';
import { Field } from 'react-final-form';
import InputGroupAntInputAdapter from '@src/components/input/InputGroupAntInputAdapter';
import InputGroupAntCheckBoxAdapter from '@src/components/input/InputGroupAntCheckBoxAdapter';

const TidelandsSearchSection = ({
    searchSupplier,
    values,
    customerName,
}) => (
    <div className="serviceBlock formBlock tide-lands-search-block">
        <Row className="serviceHeader">
            <Col span={24}>
                <div className="providedBy text-light">
                    {searchSupplier}
                </div>
                <Field
                    name="selectedServicesTypes.tidelandsSearchService"
                    component={InputGroupAntCheckBoxAdapter}
                    controlId="selectedServicesTypes.tidelandsSearchService"
                    labelText="Tidelands Search"
                    type="checkbox"
                />
            </Col>
        </Row>
        {
            values.selectedServicesTypes.tidelandsSearchService
            && (
                <>
                    <Row gutter={gutter}>
                        <Col md={24} lg={24}>
                            <div>
                                <span className="text-lighter right-buffer-xs ">
                                    Title Company
                                </span>
                                <strong><u>{customerName}</u></strong>
                            </div>
                            <div className="top-buffer-sm bottom-buffer-sm">
                                <span className="text-lighter">
                                    Issue Certificate To (if other than title company)
                                </span>
                            </div>
                        </Col>
                        <Col md={24} lg={16}>
                            <Field
                                name="selectedServices.tidelandsSearchService.name"
                                component={InputGroupAntInputAdapter}
                                controlId="selectedServices.tidelandsSearchService.name"
                                labelText="Name"
                            />
                        </Col>
                        <Col md={24} lg={16}>
                            <Field
                                name="selectedServices.tidelandsSearchService.address"
                                component={InputGroupAntInputAdapter}
                                controlId="selectedServices.tidelandsSearchService.address"
                                labelText="Address"
                            />
                        </Col>
                    </Row>
                    <Row gutter={gutter}>
                        <Col md={24} lg={16}>
                            <Field
                                name="selectedServices.tidelandsSearchService.comment"
                                component={InputGroupAntInputAdapter}
                                controlId="selectedServices.tidelandsSearchService.comment"
                                labelText="Comments"
                            />
                        </Col>
                    </Row>
                </>
            )
        }
    </div>
);

export default TidelandsSearchSection;
