import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

const AffiliatedCompany = ({
    customers,
    fetching,
    currentCustomerId,
    onSelectCustomer,
    isSctUser,
}) => {
    const {
        affiliateName: currentAffiliateName,
        custName: currentCustomerName,
    } = customers.find(({ custid }) => custid === currentCustomerId) || {};

    return (
        <div className="customer-badge inline-block full-width" id="affiliatedCustomerBadge">
            <div className="affil-info">
                <span className="affil-desc">
                    You're now using site as "
                    <b id="currentCustomer">{currentAffiliateName || currentCustomerName}</b>
                    ".
                    <br />
                    You can choose another affiliated company here
                </span>
                <div id="affiliatedCustomerDropdown" className="dropdown left-buffer-sm">
                    {
                        currentCustomerId
                        && (
                            <Select
                                defaultValue={currentCustomerId}
                                value={currentCustomerId}
                                dropdownMatchSelectWidth={false}
                                loading={fetching}
                                onChange={onSelectCustomer}
                            >
                                {
                                    customers.map(({
                                        custid,
                                        affiliateName,
                                        custName,
                                        creditHold,
                                    }) => (
                                        <Option value={custid} key={custid} disabled={creditHold && !isSctUser}>
                                            <span className="list-item-with-label">
                                                {affiliateName || custName}
                                                {creditHold && (
                                                    <span className="label label-danger left-buffer-sm font-weight-normal">
                                                        Account Inactive
                                                    </span>
                                                )}
                                            </span>
                                        </Option>
                                    ))
                                }
                            </Select>
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default AffiliatedCompany;
