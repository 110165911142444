import React from 'react';
import { Col, Row } from 'antd';
import { Field } from 'react-final-form';
import InputGroupAntCheckBoxAdapter from '@src/components/input/InputGroupAntCheckBoxAdapter';
import InputGroupAntInputAdapter from '@src/components/input/InputGroupAntInputAdapter';
import { composeValidators, onlyDigits, staticLength } from '@src/utils/helpers/validationRules';

const ServiceChildSupportSearch = ({
    name,
    values,
    index,
    gutter,
}) => (
    <div className="serviceBlock formBlock">
        <Row className="serviceHeader">
            <Col md={24} lg={16}>
                <Field
                    name={`${name}.selectedServicesTypes.childSupportSearch`}
                    component={InputGroupAntCheckBoxAdapter}
                    controlId={`${name}.selectedServicesTypes.childSupportSearch`}
                    labelText="CHILD SUPPORT JUDGMENT SEARCH"
                    type="checkbox"
                />
            </Col>
        </Row>
        {
            values.namesToSearch[index].selectedServicesTypes.childSupportSearch
            && (
                <Row gutter={gutter}>
                    <Col md={12} lg={6}>
                        <Field
                            name={`${name}.selectedServices.childSupportSearch.exactName`}
                            component={InputGroupAntCheckBoxAdapter}
                            controlId={`${name}.selectedServices.childSupportSearch.exactName`}
                            labelText="Exact Name Only"
                            type="checkbox"
                        />
                    </Col>
                    <Col md={12} lg={10}>
                        <Field
                            name={`${name}.selectedServices.childSupportSearch.ssn`}
                            component={InputGroupAntInputAdapter}
                            controlId={`${name}.selectedServices.childSupportSearch.ssn`}
                            labelText="Optional SSN Label"
                            placeholder="XXXX"
                            addonBefore="XXX-XX-"
                            maxLength={4}
                            validate={composeValidators(onlyDigits, staticLength(4))}
                        />
                    </Col>
                </Row>
            )
        }
    </div>
);

export default ServiceChildSupportSearch;
