import moment from 'moment';
import { v4 as uuid } from 'uuid';
import { shortDateFormat } from '@src/utils/helpers/dateUtils';
import { getSortByFieldComparator } from '@src/utils/helpers/devExpressHelpers';

export const prepareDataForRequest = data => {
    const {
        confirmationEmail,
        prevRequestID,
        contactId,
        deliveryPreference: {
            accutitle,
            additionalEmail,
            email,
            emailResults,
            rushDate,
            rushOrder,
            webDelivery,
        },
        newNames = [],
        oldNames = [],
        areNewNamesSelected,
        areOldNamesSelected,
    } = data;

    const preparedData = {
        confirmationEmail,
        contactId,
        deliveryPreference: {
            accutitle,
            additionalEmail,
            email,
            emailResults,
            rushDate: moment(rushDate).format(shortDateFormat),
            rushOrder,
            webDelivery,
        },
        newNames: newNames
            .filter(({ selectedToContinue }) => selectedToContinue && areNewNamesSelected)
            .map(item => ({
                ...item,
                selectedToContinue: item.selectedToContinue && areNewNamesSelected,
                originalSearchBegin: moment(item.originalSearchBegin).format(shortDateFormat),
                originalSearchEnd: moment(item.originalSearchEnd).format(shortDateFormat),
            })),
        oldNames: oldNames.map(({
            continuationSearchEnd,
            name,
            namePosition,
            nameQualifier,
            patriotSearch,
            selectedToContinue,
        }) => ({
            continuationSearchEnd: moment(continuationSearchEnd).format(shortDateFormat),
            name,
            namePosition,
            patriotSearch,
            selectedToContinue: selectedToContinue && areOldNamesSelected,
            nameQualifier: nameQualifier || null,
        })),
        prevRequestID,
    };

    return preparedData;
};

export const getInitialNewName = defaultJudgementSearchPeriod => ({
    category: 'E',
    companyName: '',
    exactMatch: false,
    individualNamePrefix: '',
    individualNameFirst: '',
    individualNameLast: '',
    individualNameMiddle: '',
    individualNameSuffix: '',
    name: '',
    nameQualifier: '',
    originalSearchBegin: defaultJudgementSearchPeriod[0],
    originalSearchEnd: defaultJudgementSearchPeriod[1],
    patriotSearch: false,
    selectedToContinue: true,
    uuid: uuid(),
});

export const getName = ({
    category,
    companyName = '',
    name = '',
    individualNamePrefix = '',
    individualNameFirst = '',
    individualNameLast = '',
    individualNameMiddle = '',
    individualNameSuffix = '',
}) => (
    category === 'I'
        // eslint-disable-next-line max-len
        ? `${individualNamePrefix.trim()} ${individualNameFirst.trim()} ${individualNameMiddle.trim()} ${individualNameLast.trim()} ${individualNameSuffix.trim()}`
        : companyName.trim() || name.trim()
);

export const getInitialServiceData = (deliveryInformation, judgementReportChain) => {
    const previousReport = judgementReportChain.reportList
        .sort(getSortByFieldComparator('id'))[judgementReportChain.reportList.length - 1];
    const { orderDate: lastOrdered, id: prevRequestID } = previousReport || { orderDate: '', id: '' };

    return (
        {
            selectedServices: {},
            areOldNamesSelected: true,
            areNewNamesSelected: false,
            isPatriotSearchSelected: false,
            contactId: deliveryInformation.contactId,
            confirmationEmail: deliveryInformation.confirmationEmail,
            deliveryPreference: {
                canMakeAccutitle: false,
                additionalEmail: '',
                rushDate: '',
                rushOrder: false,
                ...deliveryInformation.deliveryPreference,
            },
            prevRequestID,
            newNames: [],
            oldNames: judgementReportChain.namesFromLastRequest.map(({
                category,
                continuationSearchBegin,
                continuationSearchEnd,
                originalSearchBegin,
                originalSearchEnd,
                name,
                individualNamePrefix,
                individualNameFirst,
                individualNameLast,
                individualNameMiddle,
                individualNameSuffix,
                nameQualifier,
                namePosition,
                patriotSearch,
                exactMatch,
            }) => ({
                continuationSearchBegin: moment(continuationSearchBegin).format(shortDateFormat),
                continuationSearchEnd: moment(continuationSearchEnd),
                originalSearchBegin: moment(originalSearchBegin).format(shortDateFormat),
                originalSearchEnd: moment(originalSearchEnd).format(shortDateFormat),
                category,
                name,
                individualNamePrefix,
                individualNameFirst,
                individualNameLast,
                individualNameMiddle,
                individualNameSuffix,
                nameQualifier,
                namePosition,
                patriotSearch: false,
                exactMatch,
                selectedToContinue: moment(originalSearchBegin).add(20, 'year').isSame(moment(originalSearchEnd)),
                lastPatriotSearch: patriotSearch,
                lastOrdered: moment(lastOrdered).format(shortDateFormat),
            })),
        }
    );
};

export const getOriginalSearchInfo = judgementReportChain => {
    const originalReport = judgementReportChain.reportList.find(({ smItem }) => (
        ['Judgment Search', 'Judgment Search (via Snapclose)'].includes(smItem.description)
    ));
    const {
        id,
        orderno,
        orderDate,
        refno,
    } = originalReport || {
        id: '',
        orderno: '',
        orderDate: '',
        refno: '',
    };

    return [{
        title: 'Original Search',
        subTitle: `#${id}`,
        content: [
            [
                { label: 'Reference No', value: refno },
                { label: 'Order No', value: orderno },
                { label: 'Order Date', value: moment(orderDate).format(shortDateFormat) },
                { label: 'Report Number', value: id },
            ],
        ],
    }];
};

export const getPreviousSearchInfo = judgementReportChain => {
    const content = judgementReportChain.reportList
        .filter(({ smItem }) => smItem.description === 'Judg Continuation/Append')
        .sort(getSortByFieldComparator('id'))
        .map(({
            id,
            orderno,
            orderDate,
            refno,
        }) => (
            [
                { subTitle: `#${id}` },
                { label: 'Reference No', value: refno },
                { label: 'Order No', value: orderno },
                { label: 'Order Date', value: moment(orderDate).format(shortDateFormat) },
                { label: 'Report Number', value: id },
            ]
        ));

    return content.length
        ? [{
            title: 'Previous Continuation(s)',
            content,
        }]
        : [];
};
