import React from 'react';
import Footer from '@src/components/Footer';
import Header from '@src/containers/header/Header';

const OneColumnWithImageLayout = ({ children }) => (
    <div id="mainContainer" className={`bg-container background-${Math.floor(Math.random() * 10) + 1}`}>
        <header id="header">
            <Header />
        </header>
        <main className="container overlayedZone" role="main" id="main">
            {children}
        </main>
        <footer>
            <Footer />
        </footer>
    </div>
);

export default OneColumnWithImageLayout;
