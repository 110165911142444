import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import Loader from '@src/components/Loader';
import ReportsFilter from '@src/components/ReportsFilter';
import {
    fetchReports,
} from '@src/store/actions/reports';
import ReportsTable from '@src/components/ReportsTable';
import { taxContinuationOrdersTableColumns } from '@src/utils/constants/tablesColumns';
import {
    getTaxContinuationReports,
    getFetching,
    getCanceling,
} from '@src/store/reducers/reports';
import { getCurrentCustomer } from '@src/store/reducers/customers';
import { getCurrentUser } from '@src/store/reducers/userMaintanance';
import {
    getDataGridFilters,
} from '@src/utils/helpers/devExpressHelpers';
import { reportsTableType } from '@src/utils/templates/reportType';

const filterFields = ['refno', 'text', 'orderno', 'id'];

const initialFilterContains = { matchType: 'contains', value: [] };
const initialFilterEqual = { matchType: '=', value: [] };

const initialFilterFormValues = {
    refno: initialFilterContains,
    text: initialFilterContains,
    id: initialFilterEqual,
    orderno: initialFilterContains,
};

const reportsType = reportsTableType.taxContinuation;

const SelectOrderTable = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [filters, setFilters] = useState(null);
    const [filtersFromUrl, setFiltersFromUrl] = useState(initialFilterFormValues);
    const [additionalValueForFilter, setAdditionalValueForFilter] = useState({ name: '', value: '' });

    const isLoading = useSelector(getFetching);
    const isCanceling = useSelector(getCanceling);
    const taxContinuationReports = useSelector(getTaxContinuationReports);
    const { webDelivery, number: currentCustomerId } = useSelector(getCurrentCustomer);
    const { canSelectCustomer: isSctUser } = useSelector(getCurrentUser);

    const reports = taxContinuationReports.data;

    const fetchReportsWithUrlFilters = () => {
        const params = qs.parse(location.search);
        const filterParams = params['?filters'] || params.filters;
        if (filterParams) {
            const filters = JSON.parse(filterParams);
            setFilters(filters);
            setFiltersFromUrl(filters);
            dispatch(fetchReports(
                reportsType,
                taxContinuationOrdersTableColumns,
                { filter: getDataGridFilters(filters) },
            ));
        }
    };

    const fetchReportsWithFormFilters = () => {
        dispatch(fetchReports(
            reportsType,
            taxContinuationOrdersTableColumns,
            { filter: getDataGridFilters(filters) },
        ));
    };

    useEffect(() => {
        if (currentCustomerId) {
            if (filters) {
                fetchReportsWithFormFilters();
            } else if (location && location.search) {
                fetchReportsWithUrlFilters();
            }
        }
    }, [currentCustomerId]);

    return (
        <>
            <div className="contentHeader">
                <h1>
                    <span>Selection for Property Tax Continuation</span>
                </h1>
            </div>
            <Loader show={isLoading || isCanceling}>
                <ReportsFilter
                    initialValues={filtersFromUrl || initialFilterFormValues}
                    isDisabled={isLoading}
                    onSubmit={data => {
                        setFilters(data);
                        dispatch(fetchReports(
                            reportsType,
                            taxContinuationOrdersTableColumns,
                            { filter: getDataGridFilters(data) },
                        ));
                    }}
                    onClearFilters={() => setFiltersFromUrl(initialFilterFormValues)}
                    filterFields={filterFields}
                    additionalValueForFilter={additionalValueForFilter}
                />
                {
                    filters
                    && (
                        <ReportsTable
                            data={reports}
                            tableColumns={taxContinuationOrdersTableColumns}
                            actionsColumn="taxContinuationOrderActions"
                            reportsType={reportsType}
                            pushAdditionalValueForFilter={setAdditionalValueForFilter}
                            allowGrouping={false}
                            defaultSorting={{ fieldName: 'donedate', direction: 'desc' }}
                            filters={filters}
                            isWebDelivery={webDelivery}
                            isSctUser={isSctUser}
                        />
                    )
                }
            </Loader>
        </>
    );
};

export default SelectOrderTable;
