import React from 'react';
import { NavLink } from 'react-router-dom';
import { Col, Row, Button } from 'antd';
import { gutter } from '@src/utils/helpers/orders';
import { Field, Form } from 'react-final-form';
import InputGroupAntInputAdapter from '@src/components/input/InputGroupAntInputAdapter';
import createDecorator from 'final-form-focus';
import { composeValidators, maxLength, required } from '@src/utils/helpers/validationRules';
import { REGISTRATION, FORGOT_PASSWORD } from '@src/utils/constants/routerConstants';

const focusOnError = createDecorator();

const LoginForm = ({
    title,
    onSubmit,
    processing,
    error,
    isPasswordChanged,
}) => (
    <>
        {
            title
            && <h3>{title}</h3>
        }
        {
            error
            && <div className="error-section top-buffer">{error}</div>
        }
        {
            isPasswordChanged
            && (
                <div className="h5">
                    Password was successfully changed.
                </div>
            )
        }
        <Form
            onSubmit={onSubmit}
            decorators={[focusOnError]}
        >
            {
                params => {
                    const {
                        handleSubmit,
                    } = params;

                    return (
                        <form onSubmit={handleSubmit} id="login-form">
                            <Row gutter={gutter}>
                                <Col md={24} lg={8}>
                                    <Field
                                        name="username"
                                        component={InputGroupAntInputAdapter}
                                        controlId="username"
                                        labelText="Username"
                                        isRequired
                                        validate={composeValidators(required, maxLength(180))}
                                    />
                                </Col>
                                <Col md={24} lg={8}>
                                    <Field
                                        name="password"
                                        component={InputGroupAntInputAdapter}
                                        controlId="password"
                                        labelText="Password"
                                        isRequired
                                        validate={composeValidators(required, maxLength(180))}
                                        type="password"
                                    />
                                </Col>
                                <Col md={24} lg={8}>
                                    <Button
                                        className="right-buffer-xs"
                                        htmlType="submit"
                                        type="primary"
                                        loading={processing}
                                        id="login-form--submit-button"
                                    >
                                        Sign in
                                    </Button>
                                    <NavLink to={REGISTRATION} activeClassName="selected">
                                        <Button
                                            className="right-buffer-xs"
                                            id="login-form--registration-link"
                                        >
                                            Register
                                        </Button>
                                    </NavLink>
                                    <NavLink to={FORGOT_PASSWORD} activeClassName="selected">
                                        <Button
                                            type="link"
                                            id="login-form--forgot-password-link"
                                        >
                                            Forgot password
                                        </Button>
                                    </NavLink>
                                </Col>
                            </Row>
                        </form>
                    );
                }
            }
        </Form>
    </>
);

export default LoginForm;
