const phone = value => {
    if (!value) {
        // default formatter return epty string instead of null or undefined
        return '';
    }
    const onlyNums = value.replace(/[^\d]/g, '');

    if (onlyNums.length === 0) {
        return '';
    }
    if (onlyNums.length <= 3) {
        return `(${onlyNums}`;
    }
    if (onlyNums.length <= 6) {
        return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3)}`;
    }

    return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`;
};

const ext = value => {
    if (!value) {
        // default formatter return epty string instead of null or undefined
        return '';
    }
    const onlyNums = value.replace(/[^\d]/g, '');

    if (onlyNums.length === 0) {
        return '';
    }
    if (onlyNums.length <= 3) {
        return `${onlyNums}`;
    }

    return `${onlyNums.slice(0, 5)}`;
};

const upper = value => value && value.toUpperCase();

const leadingWhitespacesNormalizer = value => {
    if (!value) {
        return value;
    }
    return value.replace(/[\s]/g, '');
};

const replaceMultipleWhitespaces = value => value.replace(/ {1,}/g, ' ');

const interpolate = (literal, ...expressions) => {
    const literals = literal.split(/\[:(.*?)\]/gm);

    let string = ``;

    expressions.map((val, i) => { string += literals[i] + val; return string; });
    string += literals[literals.length - 1];

    return string;
};

const toKebabCase = (string = '') => string.toLowerCase().replace(' ', '-');

const toCommaSeparatedString = value => (
    Array.isArray(value)
        ? value.filter(i => i).join(', ')
        : ''
);

const sortArrayByObjectField = (array, fieldName) => array.sort((a, b) => (a[fieldName] > b[fieldName] ? 1 : -1));

const trimWhitespacesInObjectFields = object => {
    const trimmedObject = {};
    Object.keys(object).forEach(key => {
        trimmedObject[key] = typeof object[key] === 'string'
            ? object[key].trim()
            : object[key];
    });

    return trimmedObject;
};

const capitalize = s => (s && s[0].toUpperCase() + s.slice(1)) || '';

export {
    phone,
    upper,
    capitalize,
    leadingWhitespacesNormalizer,
    interpolate,
    ext,
    replaceMultipleWhitespaces,
    toKebabCase,
    sortArrayByObjectField,
    toCommaSeparatedString,
    trimWhitespacesInObjectFields,
};
