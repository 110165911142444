import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import qs from 'qs';
import { login, setFirstPassword } from '@src/store/actions/authorization';
import {
    getIsFirstLogin, getLoginProcessing, getSetFirstPasswordProcessing,
    getLoginError, getUserName, getSessionId,
} from '@src/store/reducers/authorization';
import {
    getCurrentUserFetching,
} from '@src/store/reducers/userMaintanance/users';
import LoginForm from './LoginForm';
import NewPasswordForm from './NewPasswordForm';

const LoginContent = () => {
    const dispatch = useDispatch();
    const isLoginProcessing = useSelector(getLoginProcessing);
    const isFirstLogin = useSelector(getIsFirstLogin);
    const userName = useSelector(getUserName);
    const sessionId = useSelector(getSessionId);
    const setFirstPasswordProcessing = useSelector(getSetFirstPasswordProcessing);
    const loginError = useSelector(getLoginError);
    const currentUserFetching = useSelector(getCurrentUserFetching);

    const params = qs.parse(window.location.search);
    const isPasswordChanged = params['?isPasswordChanged'] || params.isPasswordChanged;

    return (
        <>
            <div className="contentHeader"><h1>Sign in</h1></div>
            <div className="formBlock">
                {
                    isFirstLogin
                        ? (
                            <NewPasswordForm
                                title="Set your new password"
                                onSubmit={data => dispatch(setFirstPassword(data))}
                                processing={setFirstPasswordProcessing || currentUserFetching}
                                userName={userName}
                                sessionId={sessionId}
                            />
                        )
                        : (
                            <LoginForm
                                title="Sign in using your credentials"
                                onSubmit={data => dispatch(login(data))}
                                processing={isLoginProcessing || currentUserFetching}
                                error={loginError}
                                isPasswordChanged={isPasswordChanged}
                            />
                        )
                }
            </div>
        </>
    );
};

export default LoginContent;
