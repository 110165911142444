import React from 'react';
import { InfoCircleFilled } from '@ant-design/icons';

const InfoText = ({ message }) => (
    <span className="help-block">
        <InfoCircleFilled />
        <span>
            {` ${message}`}
        </span>
    </span>
);

export default InfoText;
