import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import userMaintenanceReducers from '@src/store/reducers/userMaintanance';
import registrationReducers from '@src/store/reducers/registration';
import settingsContainer from '@src/store/reducers/accountSettings';
import customers from '@src/store/reducers/customers';
import reports from '@src/store/reducers/reports';
import orders from '@src/store/reducers/orders';
import states from '@src/store/reducers/states';
import authorization from '@src/store/reducers/authorization';
import alerts from '@src/store/reducers/alerts';
import jdglookup from '@src/store/reducers/jdglookup';
import { createBrowserHistory } from 'history';
import { isSupportedBrowser } from '@src/utils/helpers/browserUtils';

export const history = createBrowserHistory();

const reducers = {
    settingsContainer,
    customers,
    reports,
    orders,
    states,
    authorization,
    alerts,
    jdglookup,
    ...registrationReducers,
    ...userMaintenanceReducers,
};

const middlewares = [thunk];
if (process.env.BUILD_MODE !== 'production' && isSupportedBrowser()) {
    middlewares.push(createLogger());
}

const store = createStore(combineReducers(reducers), applyMiddleware(...middlewares));
export default store;
