/* eslint-disable max-len */
import React from 'react';
import { Col, Radio, Row } from 'antd';
import { Field } from 'react-final-form';
import InputGroupAntCheckBoxAdapter from '@src/components/input/InputGroupAntCheckBoxAdapter';
import InputGroupAntInputAdapter from '@src/components/input/InputGroupAntInputAdapter';
import InputGroupAntRadioAdapter from '@src/components/input/InputGroupAntRadioAdapter';
import AdditionalStatesField from '@src/containers/orderServiceNameSearch/AdditionalStatesField';

const ServiceCopiesOfEntityFiling = ({
    name,
    values,
    index,
    gutter,
    states,
    copiesOfEntityFilingsTypeOptions,
}) => (
    <div className="serviceBlock formBlock">
        <Row className="serviceHeader">
            <Col md={24} lg={16}>
                <Field
                    name={`${name}.selectedServicesTypes.copiesOfEntityFiling`}
                    component={InputGroupAntCheckBoxAdapter}
                    controlId={`${name}.selectedServicesTypes.copiesOfEntityFiling`}
                    labelText="COPIES OF ENTITY FILINGS"
                    type="checkbox"
                />
            </Col>
        </Row>
        {
            values.namesToSearch[index].selectedServicesTypes.copiesOfEntityFiling
            && (
                <>
                    <Row gutter={gutter} className="form-group">
                        <Col md={24} lg={16}>
                            <Radio.Group
                                defaultValue={
                                    values
                                        .namesToSearch[values.namesToSearch.length - 1]
                                        .selectedServices
                                        .copiesOfEntityFiling
                                        .copiesOfEntityFilingsType
                                }
                                size="large"
                            >
                                {
                                    copiesOfEntityFilingsTypeOptions.map(({ label, value }) => (
                                        <Field
                                            key={`${name}.selectedServices.copiesOfEntityFiling.copiesOfEntityFilingsType-${value}`}
                                            type="radio"
                                            component={InputGroupAntRadioAdapter}
                                            value={value}
                                            label={label}
                                            name={`${name}.selectedServices.copiesOfEntityFiling.copiesOfEntityFilingsType`}
                                        />
                                    ))
                                }
                            </Radio.Group>
                        </Col>
                    </Row>
                    {
                        values.namesToSearch[index].selectedServices.copiesOfEntityFiling.copiesOfEntityFilingsType === 'certInc'
                        && (
                            <>
                                <Row>
                                    <Col md={24} lg={16}>
                                        <Field
                                            name={`${name}.selectedServices.copiesOfEntityFiling.certIncAmend`}
                                            component={InputGroupAntCheckBoxAdapter}
                                            controlId={`${name}.selectedServices.copiesOfEntityFiling.certIncAmend`}
                                            labelText="Amendments"
                                            type="checkbox"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={24} lg={16}>
                                        <Field
                                            name={`${name}.selectedServices.copiesOfEntityFiling.certIncCert`}
                                            component={InputGroupAntCheckBoxAdapter}
                                            controlId={`${name}.selectedServices.copiesOfEntityFiling.certIncCert`}
                                            labelText="Certified Copies"
                                            type="checkbox"
                                        />
                                    </Col>
                                </Row>
                            </>
                        )
                    }
                    {
                        values.namesToSearch[index].selectedServices.copiesOfEntityFiling.copiesOfEntityFilingsType === 'allDoc'
                        && (
                            <>
                                <Row>
                                    <Col md={24} lg={16}>
                                        <Field
                                            name={`${name}.selectedServices.copiesOfEntityFiling.allDocAmend`}
                                            component={InputGroupAntCheckBoxAdapter}
                                            controlId={`${name}.selectedServices.copiesOfEntityFiling.allDocAmend`}
                                            labelText="Amendments"
                                            type="checkbox"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={24} lg={16}>
                                        <Field
                                            name={`${name}.selectedServices.copiesOfEntityFiling.allDocCert`}
                                            component={InputGroupAntCheckBoxAdapter}
                                            controlId={`${name}.selectedServices.copiesOfEntityFiling.allDocCert`}
                                            labelText="Certified Copies"
                                            type="checkbox"
                                        />
                                    </Col>
                                </Row>
                            </>
                        )
                    }
                    <Row gutter={gutter}>
                        <Col md={24} lg={16}>
                            <Field
                                name={`${name}.selectedServices.copiesOfEntityFiling.comments`}
                                component={InputGroupAntInputAdapter}
                                controlId={`${name}.selectedServices.copiesOfEntityFiling.comments`}
                                labelText="Comments"
                            />
                        </Col>
                    </Row>
                    <hr />
                    <AdditionalStatesField
                        name={name}
                        serviceName="copiesOfEntityFiling"
                        values={values}
                        index={index}
                        gutter={gutter}
                        states={states}
                    />
                </>
            )
        }
    </div>
);

export default ServiceCopiesOfEntityFiling;
