import React from 'react';
import { NavLink } from 'react-router-dom';
import { ROOT } from '@src/utils/constants/routerConstants';

const PageNotFoundContent = () => (
    <>
        <div className="contentHeader"><h1>Oops! That page can’t be found.</h1></div>
        <p>It looks like nothing was found at this location.</p>
        <NavLink to={ROOT} activeClassName="selected">
            To Home Page
        </NavLink>
    </>
);

export default PageNotFoundContent;
