import docketJdg from '@src/utils/helpers/docketJdg';
import { addErrorAlert, addSuccessAlert } from '@src/store/actions/alerts';
import jdgLookup from '@src/utils/apiServices/jdgLookupRequests';
import initDownload from '@src/utils/helpers/downloadFile';
import { lookupFailed, lookupSubmit, lookupSuccess } from './jdglookupControl';

export const submitLookup = data => dispatch => {
    const docketJdgIds = data.jdgLookup.map(({ sequence, year, court }) => (
        docketJdg(sequence, year.toString(), court)
    ));

    if (docketJdgIds.length > 5) {
        dispatch(addErrorAlert('Judgment lookup cannot be done with more than 5 dockets.'));
        return null;
    }

    dispatch(lookupSubmit('Submiting judgment lookup'));
    return jdgLookup(docketJdgIds).then(response => {
        initDownload(response, `docketLookup${docketJdgIds.join('_')}`, 'pdf');
        dispatch(lookupSuccess(response));
        dispatch(addSuccessAlert('Judgment lookup successfully finished.', true));
    }).catch(error => {
        dispatch(lookupFailed(error));
        dispatch(addErrorAlert(error.response.data ? error.response.data
            : 'Something went wrong during judgment lookup. Please, try again later.', true));
    });
};

export default submitLookup;
