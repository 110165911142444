import React from 'react';
import { Button } from 'antd';

import EmailResend from '@src/components/EmailResend';
import InfoText from '@src/components/InfoText';

const ReportsActionsBlock = ({
    onCombineSelected,
    isCombineSelectedDisabled,
    onResendSelectedByEmail,
    onRemoveSelectedFromNew,
    isResendSelectedByEmailDisabled,
    isSelectedReportsPagesExceeded,
    isRemoveSelectedFromNewDisabled,
    pagesLimit,
}) => (
    <>
        <div className="dtActionsBlock">
            <Button
                htmlType="button"
                onClick={onCombineSelected}
                disabled={isCombineSelectedDisabled}
            >
                Combine Selected
            </Button>
            <EmailResend
                resendHandler={onResendSelectedByEmail}
                disabled={isResendSelectedByEmailDisabled}
            />
            {
                onRemoveSelectedFromNew
                && (
                    <Button
                        htmlType="button"
                        onClick={onRemoveSelectedFromNew}
                        disabled={isRemoveSelectedFromNewDisabled}
                    >
                        Remove from New Reports
                    </Button>
                )
            }
        </div>
        <>
            {
                isResendSelectedByEmailDisabled
                    ? (
                        <InfoText message="Please, select at least one report" />
                    )
                    : ' '
            }
            {
                isSelectedReportsPagesExceeded
                && (
                    <InfoText message={`Please, check less amount of pages. Maximum is ${pagesLimit}.`} />
                )
            }
        </>
    </>
);

export default ReportsActionsBlock;
