import React from 'react';
import { getNameWithDetails } from '@src/utils/helpers/reports';

const ReportInfoCombinedName = ({ serviceNames }) => (
    <div>
        {
            serviceNames && serviceNames.map(judgmentName => {
                const { nameIndex } = judgmentName;
                return (
                    <div className="judgmentName" key={nameIndex}>
                        {getNameWithDetails(judgmentName)}
                    </div>
                );
            })
        }
    </div>
);

export default ReportInfoCombinedName;
