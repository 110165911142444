/**
 * Created by oleg on 12/26/16.
 */
import React from 'react';
import 'url-search-params-polyfill';
import FormDelimiter from '@src/components/FormDelimiter';
import ContentHeader from '@src/containers/registration/components/utils/ContentHeader';
import MailingInformation from './MailingInformation';
import BillingInformation from './BillingInformation';

const CorporateInformation = ({ billingAsMailing }) => (
    <div id="corpInformationForm" className="corpInformationForm">
        <ContentHeader contentName="Business Contact Information" />

        <div className="formBlock">
            <MailingInformation />
            <FormDelimiter />
            <BillingInformation billingAsMailing={billingAsMailing} />
        </div>
    </div>
);

export default CorporateInformation;
