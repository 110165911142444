export const isSupportedBrowser = () => !(/MSIE 10/i.test(navigator.userAgent) || /MSIE 9/i.test(navigator.userAgent)
        || /rv:11.0/i.test(navigator.userAgent) || /Edge\/\d./i.test(navigator.userAgent));

const fallbackCopyTextToClipboard = text => {
    const textArea = document.createElement('textarea');
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    document.execCommand('copy');

    document.body.removeChild(textArea);
};

export const copyTextToClipboard = text => {
    if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
    }
    navigator.clipboard.writeText(text).then(() => {
        // eslint-disable-next-line no-console
        console.log('Async: Copying to clipboard was successful!');
    }, err => {
        // eslint-disable-next-line no-console
        console.error('Async: Could not copy text: ', err);
    });
};
