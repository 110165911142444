/**
 * Created by oleg on 7/7/17.
 */
import buildErrorMessage from '@src/utils/helpers/messageUtils';

export const ADD_ALERT = 'ADD_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

export const addSuccessAlert = (message, autoClosable = false) => ({
    type: ADD_ALERT,
    message,
    alertType: 'success',
    autoClosable,
});

export const addInfoAlert = (message, autoClosable = false) => ({
    type: ADD_ALERT,
    message,
    alertType: 'info',
    autoClosable,
});

export const addWarningAlert = (message, autoClosable = false) => ({
    type: ADD_ALERT,
    message,
    alertType: 'warning',
    autoClosable,
});

export const addErrorAlert = (message, autoClosable = false) => {
    const msg = buildErrorMessage(message);
    return {
        type: ADD_ALERT,
        message: msg,
        alertType: 'error',
        autoClosable,
    };
};

export const removeAlert = id => ({
    type: REMOVE_ALERT,
    id,
});
