import React from 'react';
import { NavLink } from 'react-router-dom';
import {
    ACCOUNT_SETTINGS_PERSONAL,
    ACCOUNT_SETTINGS_REPORTS,
    ACCOUNT_SETTINGS_PROPERTY_SEARCH,
    ACCOUNT_SETTINGS_BUSINESS_CONTACT,
    ACCOUNT_SETTINGS_INDIVIDUAL,
    ACCOUNT_SETTINGS_USER_MAINTENANCE,
} from '@src/utils/constants/routerConstants';

const SettingsNavigation = () => (
    <ul className="nav nav-pills nav-stacked">
        <li className="dropdown"><NavLink to={ACCOUNT_SETTINGS_PERSONAL} activeClassName="selected">Profile Settings</NavLink></li>
        <li className="dropdown"><NavLink to={ACCOUNT_SETTINGS_BUSINESS_CONTACT} activeClassName="selected">Business Contact Information</NavLink></li>
        <li className="dropdown"><NavLink to={ACCOUNT_SETTINGS_REPORTS} activeClassName="selected">Report Options</NavLink></li>
        <li className="dropdown"><NavLink to={ACCOUNT_SETTINGS_INDIVIDUAL} activeClassName="selected">Individual Searches</NavLink></li>
        <li className="dropdown"><NavLink to={ACCOUNT_SETTINGS_PROPERTY_SEARCH} activeClassName="selected">Property Searches</NavLink></li>
        <li className="dropdown"><NavLink to={ACCOUNT_SETTINGS_USER_MAINTENANCE} activeClassName="selected">User Maintenance</NavLink></li>
    </ul>
);

export default SettingsNavigation;
