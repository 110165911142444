import React from 'react';

const PasswordRequirements = () => (
    <div id="passwordRequiremetnsBlock" className="infoCard">
        <h4>Password Requirements</h4>
        <div id="passwordRequirements">
            <ul className="list-style-circle">
                <li><span>length should be at least 8 characters</span></li>
                <li><span>at least one character in upper case</span></li>
                <li><span>at least one number</span></li>
            </ul>
        </div>
    </div>
);

export default PasswordRequirements;
