import React from 'react';
import moment from 'moment';
import { Col, Row } from 'antd';
import ContentHeader from '@src/components/ContentHeader';
import orderType from '@src/utils/templates/orderType';
import NameDetails from './NameDetails';
import ChanceryAbstractDetails from './ChanceryAbstractDetails';
import PropertyDetails from './PropertyDetails';

export function joinNonBlank(array, delimiter) {
    return array.filter(val => val).join(delimiter);
}
export const shortDateFormat = 'MM/DD/YYYY';
export const timeFormat = 'hh:mm A';

export function formatDate(value, format = shortDateFormat) {
    return value ? moment(value).format(format) : (value || '');
}

const reportDetails = (orderDetails, isOrderCompleted, shouldRemoveFromNew) => {
    switch (orderDetails.orderType) {
        case orderType.NAME:
        case orderType.JDG_CONTINUATION: {
            return (
                <NameDetails
                    reportInfoList={orderDetails.reportInfoList}
                    isOrderCompleted={isOrderCompleted}
                    shouldRemoveFromNew={shouldRemoveFromNew}
                />
            );
        }
        case orderType.ABSTRACT: {
            return (
                <ChanceryAbstractDetails
                    reportInfo={orderDetails.reportInfoList[0]}
                    isOrderCompleted={isOrderCompleted}
                    shouldRemoveFromNew={shouldRemoveFromNew}
                />
            );
        }
        case orderType.TAX_CONTINUATION:
        case orderType.PROPERTY_SEARCH: {
            return (
                <PropertyDetails
                    reportInfoList={orderDetails.reportInfoList}
                    isOrderCompleted={isOrderCompleted}
                    shouldRemoveFromNew={shouldRemoveFromNew}
                />
            );
        }
        default:
            return null;
    }
};

const orderCompleted = orderDetails => (
    orderDetails.reportInfoList.some(reportInfo => reportInfo.reportStatus === 'COMPLETED')
);

const OrderDetails = ({ orderDetails, shouldRemoveFromNew }) => {
    if (orderDetails) {
        const {
            deliveryPreference, contactInformation, orderId, referenceNumber, orderInformation,
        } = orderDetails;
        const { contactName, confirmationEmail } = contactInformation;
        const {
            orderDate, completionDate, orderMethod = '', deliveryMethod,
        } = orderInformation;
        const {
            emailResults, email, additionalEmail, rushOrder, rushDate,
        } = deliveryPreference;
        const isOrderCompleted = orderCompleted(orderDetails);
        const rushLabel = orderDetails.orderType === orderType.ABSTRACT ? 'Due' : 'Rush';

        return (
            <div className="panel-body">
                <ContentHeader contentName={`Order #${orderId}`} />
                <Row justify="center" id="order-info-section">
                    <Col md={16} sm={20} xs={24}>
                        <div className="formBlock">
                            <h4>Order Information</h4>
                            <div className="orderInfo">
                                <dl className="dl-horizontal">
                                    <dt>Contact name</dt>
                                    <dd>{contactName}</dd>

                                    {confirmationEmail && (
                                        <div>
                                            <dt>Contact Email</dt>
                                            <dd>{confirmationEmail}</dd>
                                        </div>
                                    )}

                                    {referenceNumber && (
                                        <div>
                                            <dt>Reference Number</dt>
                                            <dd>{referenceNumber}</dd>
                                        </div>
                                    )}

                                    {orderDetails.orderType === orderType.ABSTRACT && (
                                        <div>
                                            <dt>Docket Number</dt>
                                            <dd>{orderDetails.abstractDetails.docket}</dd>
                                        </div>
                                    )}
                                </dl>
                            </div>

                            <Row className="orderInfo">
                                <Col span={12}>
                                    <dl className="dl-horizontal col-md-6 col-sm-6">
                                        <dt>Order Date</dt>
                                        <dd>{formatDate(orderDate)}</dd>

                                        <dt>Completion Date</dt>
                                        <dd>{formatDate(completionDate)}</dd>

                                        <dt>Order Method</dt>
                                        <dd>{orderMethod.toLowerCase()}</dd>
                                    </dl>
                                </Col>
                                <Col span={12}>
                                    <dl className="dl-horizontal col-md-6 col-sm-6">
                                        <dt>Time</dt>
                                        <dd>{formatDate(orderDate, timeFormat)}</dd>

                                        <dt>Time</dt>
                                        <dd>{formatDate(completionDate, timeFormat)}</dd>

                                        <dt>Delivery Method</dt>
                                        <dd>{deliveryMethod}</dd>
                                    </dl>
                                </Col>
                            </Row>

                            <hr />

                            <h4>Delivery Information</h4>
                            <div className="orderInfo" id="delivery-info-section">
                                <dl className="dl-horizontal">
                                    {emailResults && (
                                        <div>
                                            <dt>Email</dt>
                                            <dd>{joinNonBlank([email, additionalEmail], ', ')}</dd>
                                        </div>
                                    )}
                                    {rushOrder && (
                                        <div>
                                            <dt>
                                                {`${rushLabel} Date`}
                                            </dt>
                                            <dd>{formatDate(rushDate)}</dd>
                                        </div>
                                    )}
                                </dl>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row justify="center" id="order-info-section">
                    <Col md={16} sm={20} xs={24}>
                        {reportDetails(orderDetails, isOrderCompleted, shouldRemoveFromNew)}
                    </Col>
                </Row>
            </div>
        );
    }

    return <div style={{ height: 200, width: '100%' }} />;
};

export default OrderDetails;
