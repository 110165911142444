import React, { useMemo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    getCurrentCustomer,
    getFetching,
    getSelecting,
} from '@src/store/reducers/customers';
import {
    selectCustomer,
    fetchingCustomers,
    fetchCustomersSucceeded,
    fetchCustomersFailed,
} from '@src/store/actions/customers';
import { customerTableColumns } from '@src/utils/constants/tablesColumns';
import Loader from '@src/components/Loader';
import { ACCOUNT_VIEW_MY_ORDERS_COMPLETED_REPORTS } from '@src/utils/constants/routerConstants';
import CustomersTable from './CustomersTable';

const CustomersContent = () => {
    const [selectedCustomerId, setSelectedCustomerId] = useState(null);

    const isLoading = useSelector(state => getFetching(state));
    const isSelecting = useSelector(state => getSelecting(state));
    const { number: customerId } = useSelector(getCurrentCustomer);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (selectedCustomerId === customerId) {
            navigate(ACCOUNT_VIEW_MY_ORDERS_COMPLETED_REPORTS);
        }
    }, [customerId, selectedCustomerId]);

    const customersTable = useMemo(() => (
        <CustomersTable
            tableColumns={customerTableColumns}
            handleSelectCustomer={id => {
                dispatch(selectCustomer(id));
                setSelectedCustomerId(id);
            }}
            handleLoadingStart={() => dispatch(fetchingCustomers())}
            handleLoadingSuccess={response => dispatch(fetchCustomersSucceeded(response))}
            handleLoadingError={error => dispatch(fetchCustomersFailed(error))}
        />
    ), []);

    return (
        <div id="customersContainer">
            <div className="contentHeader"><h1>Select Customer</h1></div>
            <Loader show={isSelecting || isLoading}>
                {customersTable}
            </Loader>
        </div>
    );
};

export default CustomersContent;
